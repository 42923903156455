import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import GET from "../../api/GET";
import {checkAllApi} from "../../api/DefaultApi";
import LoadingBeatLoader from "../../LoadingBeatLoader";
import {setGroups} from "../../../redux/action";
import {useSelector} from "react-redux";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupWorkIcon from '@mui/icons-material/GroupWork';


export const handleGetGroups=async ()=>{
    const res=await GET.getAllGroupsByTeacherId();
    checkAllApi(res);
    if (res.success){
        setGroups(res.res.data.groups);
        return true;
    }

    return false;
}

export default function GroupsMenu(){

    const groupList=useSelector(state => state.items.groups);
    const [isLoading, setIsLoading]=useState(true);



    useEffect(()=>{
        handleGetGroups().then((res)=>{
            res===true&&setIsLoading(false);
        });
    }, []);




    const [open, setOpen] = useState(false);

    const navigate=useNavigate();

    const handleClick = () => {
        setOpen(!open);
    };


    const getColor=(status)=>{
        if (status==0){
            return "red";
        }else if (status==1){
            return "green";
        }
        else if (status==2){
            return "#d38e52";
        }
    }

    return (
        <>
            {isLoading?
                <LoadingBeatLoader/>
                :
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Nested List Items
                    //     </ListSubheader>
                    // }
                >
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <GroupWorkIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Guruhlar"} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {groupList.map(value=>{
                                return (
                                    <ListItemButton sx={{ pl: 4, color:getColor(value.status) }} onClick={()=>{navigate("/group/?groupId="+value.id)}}>
                                        <ListItemIcon sx={{color:getColor(value.status)}}>
                                            <Diversity3Icon/>
                                        </ListItemIcon>
                                        <ListItemText primary={value.name} />
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </Collapse>
                </List>}
        </>
    );




}