import Box from "@mui/material/Box";
import {Pagination} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useEffect, useState} from "react";
import GET from "../../api/GET";
import {useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";

const data=[
    {id:1, battleTime:"12-03-2023", testAmount:10,
        users:[
            {id:1, name:"Jamshid", result:5, time: "100s"},
            {id:1, name:"Alisher", result:6, time: "150s"},
            {id:1, name:"Jamshid", result:7, time: "200s"},
            {id:1, name:"Jamshid", result:8, time: "250s"},
            {id:1, name:"Jamshid", result:9, time: "300s"},
        ]
    },

    {id:2, battleTime:"13-03-2023", testAmount:20,
        users:[
            {id:1, name:"Jamshid", result:15, time: "100s"},
            {id:1, name:"Alisher", result:8, time: "150s"},
            {id:1, name:"Jamshid", result:16, time: "200s"},
            {id:1, name:"Jamshid", result:19, time: "250s"},
            {id:1, name:"Jamshid", result:12, time: "300s"},
        ]
    },

    {id:3, battleTime:"14-03-2023", testAmount:10,
        users:[
            {id:1, name:"Jamshid", result:5, time: "100s"},
            {id:1, name:"Alisher", result:6, time: "150s"},
            {id:1, name:"Jamshid", result:5, time: "200s"},
            {id:1, name:"Jamshid", result:5, time: "250s"},
            {id:1, name:"Jamshid", result:5, time: "300s"},
        ]
    },
]





export default function GroupBattleInfo(){


    const [battleList, setBattleList]=useState();

    const [page, setPage]=useState(0);
    const [lastPage, setLastPage]=useState(1);

    const [loader, setLoader]=useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");

    const handlerGetBattleList=async ()=>{
        const res=await GET.getBattleInfoByGroupId(groupId, page);
        if (res.success){
            setBattleList(res.res.data.group_battles.data);
            setLastPage(res.res.data.group_battles.last_page);

            setLoader(false)
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false)
        }
    }

    useEffect(()=>{
        handlerGetBattleList();
    }, [])

    useEffect(()=>{
        handlerGetBattleList();
    }, [page])

    // console.log(battleList);

    return (
        <>
            <Box>
                Battle
            </Box>

            <Box sx={{marginY:"4px"}}>

                {/*<Box>*/}
                {/*    {JSON.stringify(battleList)}*/}
                {/*</Box>*/}




                {data.map((value, index) =>
                    <Box sx={{marginY:"4px"}}>
                        <Accordion style={{width:'100%'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header" style={{width:"100%"}}
                            >
                                <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                                    <Typography>{index+1}. Sanasi:{value.battleTime}</Typography>
                                    <Typography>Savollar soni: {value.testAmount}</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>F.I.SH</TableCell>
                                                <TableCell align={"center"}>Natijasi</TableCell>
                                                <TableCell align={"center"}>Vaqti</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {value.users.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.name}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"}>{(value1.result/value.testAmount)*100}%</TableCell>
                                                    <TableCell align={"center"}>{value1.time}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                )}

            </Box>

            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
            </Box>

        </>
    );
}