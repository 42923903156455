import React, { useEffect, useState } from "react";
import { checkAllApi, getTeacherId } from "../../api/DefaultApi";
import { handleGetGroups } from "../../group/GroupsMenu";
import toast from "react-hot-toast";
import POST from "../../api/POST";
import { useForm } from "react-hook-form";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CurrencyInput from "react-currency-input-field";
import {functionEndDate, StringToDouble} from "../../Other/useWindowSize";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {useNavigate} from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export default function PassTheLesson({ lessonId, groupId }) {

  const [loader, setLoader] = useState(true);

  let navigateFunction = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();


  const onSubmit = async (data) => {

    const res = await POST.editLessonDateAndTime({...data, id:lessonId});
    checkAllApi(res);
    if (res.success) {
      toast.success("Darsni o'tishga kirishingiz mumkin.");
      reset();
      handleClose();
      setTimeout(()=>{
        navigateFunction("/pass-the-lesson/"+lessonId+"?group_id="+groupId);
      }, 500)
    }
    if (!res.success) {
      toast.error("Xatolik");
    }
  };




  const refresh = () => {
    reset();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        {(localStorage.getItem("role") === "admin" ||
          localStorage.getItem("role") === "teacher" ||
          localStorage.getItem("role") === "superadmin") && (
          // <Button variant={"contained"} color={"success"} onClick={handleOpen}>
          //   Qo`shish
          // </Button>
            <Tooltip title={"Darsni o'tish"}>
              <IconButton onClick={()=>handleOpen()}>
                <PlayArrowIcon/>
              </IconButton>
            </Tooltip>
        )}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography>Sanasini kiriting:</Typography>
              <TextField
                fullWidth
                type={"date"}
                defaultValue={functionEndDate}
                {...register("date", {
                  required: "Bo`sh bo`lishi mumkin emas.",
                  minLength:10, maxLength:10
                })}
              />

              {errors.date && (
                <Typography color={"error"}>{errors.date.message}</Typography>
              )}
            </Box>

            <Box>
              <Typography>Dars boshlanish vaqtini kiriting:</Typography>

              <TextField fullWidth type={"time"} {...register("start_time", {required:"Bo'sh bo'lishi mumkin emas", minLength:5, maxLength:5})} />
              {errors.start_time && (
                  <Typography color={"error"}>{errors.start_time.message}</Typography>
              )}
            </Box>

            <Box>
              <Typography>Dars tugash vaqtini kiriting:</Typography>
              <TextField fullWidth type={"time"} {...register("end_time", {required:"Bo'sh bo'lishi mumkin emas", minLength:5, maxLength:5})} />
              {errors.end_time && (
                  <Typography color={"error"}>{errors.end_time.message}</Typography>
              )}
            </Box>


            <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
              <Button type={"submit"} color={"success"} variant={"contained"}>
                Saqlash
              </Button>
              <Button color={"warning"} variant={"contained"} onClick={refresh}>
                Tozalash
              </Button>
              <Button
                onClick={handleClose}
                color={"error"}
                variant={"contained"}
              >
                Yopish
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
