import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GET from "../../components/api/GET";
import React, {useEffect, useState} from "react";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import toast from "react-hot-toast";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../components/api/DELETE";
import {useNavigate} from "react-router-dom";
import {getDateInfo} from "../Chat";
import AddAndEditOutputTypeModal from "../../components/modals/AddAndEditOutputTypeModal";
import DeleteOutputTypeModal from "../../components/modals/DeleteModal/DeleteOutputTypeModal";


export default function OutputType(){


    const navigate=useNavigate();

    const [loader, setLoader]=useState(true);

    const [outputTypeList, setOutputTypeList]=useState([]);

    const handlerGetOutputType=async ()=>{
        setLoader(setLoader);
        const res=await GET.getAllOutputType();
        if (res.success){
            setOutputTypeList(res.res.data.expenses);
            setLoader(false);
        }else if (!res.success){
            toast.error("Chiqim turlarini olishda xatolik");
            setLoader(false);
        }
    }



    useEffect(()=>{
        handlerGetOutputType();
    }, [])

    // console.log("Salom");






    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} alignItems={"center"}>
                <Typography>Chiqim turlari</Typography>
                <AddAndEditOutputTypeModal type={"add"} handlerGetOutputType={handlerGetOutputType}/>
            </Box>


            {loader?<LoadingBeatLoader/>:
                <Box>
                    {outputTypeList.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Nomi</TableCell>
                                            <TableCell align={"center"}>Tavsifi</TableCell>
                                            <TableCell align={"center"}>Kim tomonidan qo`shilgan</TableCell>
                                            <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                            <TableCell width={"100px"} >Tahrirlangan vaqti</TableCell>
                                            <TableCell width={"100px"} >Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {outputTypeList.map((row, index) =>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>{index + 1}</TableCell>
                                                <TableCell align={"center"}>{row.name}</TableCell>
                                                <TableCell align={"center"}>{row.description}</TableCell>
                                                <TableCell align={"center"}>{row.user_name?row.user_name:"aniqlanmadi"}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(row.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(row.updated_at)}</TableCell>
                                                <TableCell width={"150px"} align={"center"} sx={{display:"flex", alignItems:"center"}}>
                                                    <AddAndEditOutputTypeModal type={"edit"} outputType={row} handlerGetOutputType={handlerGetOutputType}/>

                                                    <DeleteOutputTypeModal id={row.id} refresh={handlerGetOutputType}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        :
                        <Box>
                            <Typography>Chiqim turlari mavjud emas</Typography>
                        </Box>
                    }
                </Box>
            }
        </>
    );
}