import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from "@mui/material/CssBaseline";
import {Provider} from "react-redux";
import story from "./redux/story";
import 'react-select-search/style.css'
import { PrimeReactProvider } from "primereact/api";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={story}>
        <CssBaseline />
        <PrimeReactProvider value={{ unstyled: true }}>
            <App />
        </PrimeReactProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
