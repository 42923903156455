import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddNewsModal from "../../components/modals/AddNewsModal";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {FileBaseUrl} from "../../components/api/DefaultApi";
import ReactPlayer from "react-player";
import {Pagination, Switch, Tooltip} from "@mui/material";
import EditNewsModal from "../../components/modals/EditNewsModal";
import POST from "../../components/api/POST";
import DeleteNewsModal from "../../components/modals/DeleteModal/DeleteNewsModal";


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function News(){
    const [page, setPage]=useState(1);
    const [loader, setLoader]=useState(false);
    const [lastPage, setLastPage]=useState(1);

    const handlerGetNews=async ()=>{
        const res=await GET.getAllNewsForAdmin(page);
        if (res.success){
            setData(res.res.data.news.data);
            setLastPage(res.res.data.news.last_page);
        }
    }



    const [age, setAge]=useState();

    useEffect(()=>{
        setLoader(true);
        handlerGetNews();
    }, [page])

    const [data, setData]=useState(undefined);


    const handlerEnabledOrDisabledNews=async (value, active)=>{
        const res=await POST.addNews({...value, status:active?"active":"inactive"});
        if (res.success){
            toast.success("News "+(active?"aktivlashtirildi.":"bloklandi."));
            handlerGetNews();
        }else if (!res.success) {
            toast.success("News topilmadi");
        }
    }


    return (
        <>
            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Typography>News</Typography>
                <AddNewsModal page={page} handlerGetNews={handlerGetNews}/>
            </Box>



            <Box width={"100%"}>
                <Box sx={{marginY:"4px"}}>
                    {data!==undefined&&data.length>0?
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Title</TableCell>
                                            <TableCell align={"center"}>Description</TableCell>
                                            <TableCell align={"center"}>Fayl</TableCell>
                                            <TableCell align={"center"}>Telifon raqami</TableCell>
                                            <TableCell align={"center"}>Email</TableCell>
                                            <TableCell align={"center"}>Boshlanish vaqti</TableCell>
                                            <TableCell align={"center"}>Tugash vaqti</TableCell>
                                            <TableCell align={"center"}>Holati</TableCell>
                                            <TableCell align={"center"}>Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data&&data.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {((page-1)*10)+index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.title}</Typography>
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    <Typography
                                                        dangerouslySetInnerHTML={{__html: value1.description?value1.description:"Mavjud emas"}}
                                                    >
                                                        {/*{lesson.description?lesson.description:"Aniqlanmadi"}*/}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {value1.type==0&&
                                                        <Box>
                                                            <img style={{objectFit: "contain"}} width={"200px"} height={"200px"} src={FileBaseUrl+value1.file}/>
                                                        </Box>
                                                    }
                                                    {value1.type==1&&
                                                        <Box>
                                                            <ReactPlayer width={"100%"}
                                                                         height={"200px"}
                                                                         controls={true}
                                                                         url={FileBaseUrl+value1.file}/>
                                                        </Box>
                                                    }
                                                    {value1.file===null&&<Typography>Mavjud emas</Typography>}
                                                </TableCell>
                                                <TableCell align={"center"}>{value1.phone}</TableCell>
                                                <TableCell align={"center"}>{value1.email}</TableCell>
                                                <TableCell align={"center"}>{value1.start_time}</TableCell>
                                                <TableCell align={"center"}>{value1.end_time}</TableCell>
                                                <TableCell align={"center"}>{value1.status}</TableCell>
                                                <TableCell align={"center"} >
                                                    <Box sx={{display:"flex"}}>
                                                        <Tooltip title={value1.status==="active"?"Bloklash":"Aktivlashtirish"}>
                                                            <Switch {...label} onChange={()=>handlerEnabledOrDisabledNews(value1, value1.status==="active"?false:true )} defaultChecked={value1.status==="active"?true:false} />
                                                        </Tooltip>

                                                        <EditNewsModal newGetById={value1} page={page} handlerGetNews={handlerGetNews}/>
                                                        <DeleteNewsModal id={value1.id} refresh={handlerGetNews}/>

                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </Box>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>News mavjud emas</Typography>
                        </Box>
                    }
                </Box>
            </Box>



        </>
    );
}