import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Autocomplete, Button, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CurrencyInput from "react-currency-input-field";
import {StringToDouble} from "../../Other/useWindowSize";
import SelectSearch from 'react-select-search';
import {AutoComplete} from "primereact/autocomplete";
import {PrimeReactProvider} from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function AddDiscountModal({sum, handlerGetDiscounts, users}){


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();

    const [student, setStudent]=useState("");
    const onSubmit = async (data) => {

        if (student===""){
            toast.error("Talabani tanlagn");
        }

        const handlerSum=StringToDouble(data.value);
        if (handlerSum<=0){
            toast.error("Musbat qiymat kiriting. ");
            return;
        }

        const res=await POST.addDiscountForAdmin({student_id:student.value, sum:sum>0?handlerSum:-handlerSum, description:data.description});
        checkAllApi(res);
        if (res.success){
            toast.success(sum>0?"Mukofot berildi":"Jarima qo`llandi");
            reset();
            handleClose();  
            handlerGetDiscounts();
            setStudent("")
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const [text, setText]=useState("");


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [selectUsers, setSelectUsers]=useState([]);
    useEffect(()=>{
        search();
    }, [text]);

    console.log(selectUsers);


    const search = () => {
        const data=[];
        users.map((value)=>{
            if (value.label.toLowerCase().includes(text.toLowerCase())){
                data.push(value);
            }
        })
        setSelectUsers(data);
    }

    console.log(selectUsers);



    return (
        <>

            <Box>
                {sum>0?
                    <Button onClick={handleOpen} color={"success"} variant={"contained"}>
                        Mukofotni berish
                    </Button>
                    :
                    <Button onClick={handleOpen} color={"error"} variant={"contained"}>
                        Jarimani qo`llash
                    </Button>
                }
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        {open&&
                            <Box width={"100%"}>
                                <TextField value={text} fullWidth label={"Qidirish uchun nomini kiriting:"}
                                           onChange={(event)=>{setText(event.target.value)}}     />
                                <Box style={{overflowX:"auto", height:"120px"}}>
                                    {selectUsers.map(value => <Typography sx={{'&:hover': {
                                            cursor: 'pointer', backgroundColor:"#C8C8C8"
                                        }}} onClick={()=>{setStudent(value)}}>{value.label}</Typography>)}
                                </Box>

                            </Box>

                        }


                        <Box >
                            <Typography>
                                {student.label} ga bonus berish uchun qiymatni kiriting:
                            </Typography>
                            <Box sx={{display:"flex", alignItems:"center", border:"1px solid black", borderRadius:"4px"}}>
                                <AttachMoneyOutlinedIcon/>
                                <CurrencyInput placeholder={(sum>0?"Bonus":"Jarima")+" miqdorni kiriting:"}
                                               style={{width:"100%", padding:"12px 12px", fontSize:"16px", border:"none", outline:"none"}}
                                               groupSeparator={" "}
                                               decimalSeparator={"."}
                                               {...register("value",
                                                   {required:"Bo`sh bo`lishi mumkin emas."})}
                                />

                            </Box>
                            {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                        </Box>

                        <Box>
                            <Typography>
                                Ta`rifini kiriting
                            </Typography>

                            <textarea style={{width:"100%"}} rows={4}
                                {...register("description")}
                            />
                        </Box>


                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>{sum>0?"Bunus berish":"Jarima qo`llash"}</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}