import Box from "@mui/material/Box";
import {Pagination, Tooltip} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useEffect, useState} from "react";
import LoadingBeatLoader from "../../LoadingBeatLoader";
import {useParams} from "react-router-dom";
import GET from "../../api/GET";
import {getDateInfo} from "../../../page/Chat";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../api/DELETE";
import toast from "react-hot-toast";
import BonusModal from "../../modals/BonusModal";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';


export default function UserDiscount(){

    const params=useParams();


    const [data, setData]=useState(undefined);
    const [page, setPage]=useState(0);

    const [lastPage, setLastPage]=useState(1);

    const handlerGetUserDiscounts=async ()=>{
        const res=await GET.getByStudentDiscount(params.id, page);
        if (res.success){
            setData(res.res.data.discounts.data);
            setLastPage(res.res.data.discounts.last_page);
        }
        res&&setLoader(false);
    }

    useEffect(()=>{
        handlerGetUserDiscounts();
    }, [page]);


    const handlerDeleteDiscount=async (id)=>{
        const res=await DELETE.deleteDiscount(id);
        if (res.success){
            toast.success("O`chirildi");
            handlerGetUserDiscounts();
        }
        res.res.response.status===409&&toast.error("Tizim orqali berilgan bonus/jarimalar o`chirilmaydi.");
    }


    const [loader, setLoader]=useState(true);

    return (
        <>
            {loader===true?
                <LoadingBeatLoader/>
                :
                <Box width={"100%"}>
                    <Box sx={{display:'flex', alignItems:"center", justifyContent:"space-between"}}>
                        <Typography>
                            Bonus va jarimalar
                        </Typography>

                        <Box sx={{display:"flex", alignItems:"center", border:"1px solid gray", borderRadius:"4px"}}>
                            <BonusModal type={"add"} id={params.id} handlerGetUserDiscounts={handlerGetUserDiscounts}/>
                            <MonetizationOnIcon/>
                            <BonusModal type={"minus"} id={params.id} handlerGetUserDiscounts={handlerGetUserDiscounts}/>
                        </Box>
                    </Box>

                    <Box sx={{marginY:"4px"}}>
                        {data!==undefined&&data.length>0?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Nima maqsadda berilgan</TableCell>
                                                <TableCell align={"center"}>Miqdori</TableCell>
                                                <TableCell align={"center"}>Jarima/Bonus</TableCell>
                                                <TableCell align={"center"}>Berilgan vaqti</TableCell>
                                                <TableCell align={"center"}>Kim bergan</TableCell>
                                                <TableCell align={"center"}>O`chirish</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data&&data.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.type}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"} sx={{color:value1.sum>0?"green":"red"}}>{value1.sum>0&&"+"}{value1.sum}</TableCell>
                                                    <TableCell align={"center"}>
                                                        <Box display={'flex'} justifyContent={"center"}>
                                                            <Typography sx={{backgroundColor:value1.sum>0?"green":"red", borderRadius:"50px", width:"100px", color:"white"}}>
                                                                {value1.sum>0?"Bonus":"Jarima"}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                    <TableCell align={"center"}>{value1.author_id?value1.author.name:"Tizim"}</TableCell>
                                                    <TableCell align={"center"}>
                                                        <Tooltip title={"O`chirish"}>
                                                            <IconButton color={"error"} onClick={()=>handlerDeleteDiscount(value1.id)}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                    <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                                </Box>
                            </>
                            :
                            <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                                <Typography>Ushbu foydalanuvchining bonus-jarimalari mavjud emas</Typography>
                            </Box>
                        }




                    </Box>





                </Box>
            }
        </>
    );
}