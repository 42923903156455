import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import GET from "../api/GET";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {formatPrice} from "../Other/useWindowSize";
import {getDateInfo} from "../../page/Chat";
import {Button} from "@mui/material";
import AddOrEditUserSalaryItemModal from "../modals/AddOrEditUserSalaryItemModal";


export default function UserIdSalaryItems(){

    const params=useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const name=searchParams.get("name");

    const [userItemList, setUserItemsList]=useState();

    const handlerGetUserItemsByUserId=async ()=>{
        const res=await GET.getSalaryItemsByUserId(params.id);
        if (res.success){
            setUserItemsList(res.res.data.userItemsSalary);
        }else {
            toast.error("Ma`lumot olishda xatolik");
        }
    }

    useEffect(() => {
        handlerGetUserItemsByUserId();
    }, []);

    return (
        <>
            <div>
                <Box sx={{display:"flex", justifyContent:"space-between", marginY:"16px"}}>
                    <Typography>
                        {name}
                    </Typography>
                    <Box sx={{display:"flex", gap:2}}>
                        {localStorage.getItem("role")==="superadmin"&&
                            <Box>
                                {userItemList&&
                                    <AddOrEditUserSalaryItemModal
                                        salaryItem={userItemList[0]}/>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
                {userItemList?
                    <Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{backgroundColor:'grey'}}>
                                    <TableRow>
                                        <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                        <TableCell align={"center"}>Kasbi</TableCell>
                                        <TableCell align={"center"}>Miqdori</TableCell>
                                        <TableCell align={"center"}>Foizi</TableCell>
                                        <TableCell align={"center"}>Berilgan vaqti</TableCell>
                                        <TableCell align={"center"}>Oylik turi</TableCell>
                                        <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                        <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userItemList&&userItemList.map((value1, index) =>
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                "&:hover":{
                                                    backgroundColor:"#cbcbcb"
                                                }
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index+1}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                                <Typography>{value1.profession.title}</Typography>
                                            </TableCell>
                                            <TableCell align={"right"} >
                                                <Typography >
                                                    {formatPrice(value1.amount)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align={"right"} >
                                                <Typography >
                                                    {formatPrice(value1.protsent)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align={"center"} >
                                                <Typography
                                                >
                                                    {value1.date}
                                                </Typography>
                                            </TableCell>

                                            <TableCell align={"right"}>{value1.calc_salary_type.name}</TableCell>
                                            <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                            <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>:
                    <Box>

                    </Box>

                }
            </div>
        </>
    );
}