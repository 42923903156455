import Box from "@mui/material/Box";
import {Button, InputLabel, Select, MenuItem} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import {useSearchParams} from "react-router-dom";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import {checkAllApi, getTeacherId} from "../../api/DefaultApi";
import GET from "../../api/GET";
import {useSelector} from "react-redux";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function ImportMainLesson() {

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");



    const onSubmit = async (data) => {
        let mailLessonId = data.mainLessonId;
        const res=await POST.importMainLesson({group_id:parseInt(groupId), mainlessons:[mailLessonId]});
        checkAllApi(res);

        if (res.success){
            handleGetByIdGroup(groupId);
            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    

    const refresh=()=>{
        reset();
    }





    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [mainLessons, setMainLessons]=useState([]);

    const lessonLevelList=useSelector(state => state.otherInfoList.levels);
    const [levelId, setLevelId]=useState(undefined);




    const handlerGetMainLessons=async ()=>{
        const res=await GET.getAllMainLessonByLevelId(levelId);
        // console.log(res);
        if (res.success){
            setMainLessons(res.res.data.level[0].main_lessons);
        }
    }


    useEffect(()=>{
        if (levelId!==undefined){
            handlerGetMainLessons();
        }
    }, [levelId])


    // console.log(mainLessons);


    return (
        <Box>
            {<Box>
                    {mainLessons&&<Button startIcon={<VerticalAlignBottomIcon/>} sx={{backgroundColor:"#BEBEBE"}} variant={"contained"}
                                                 onClick={handleOpen}  color={"secondary"}>Asosiy darslardan import qilish</Button>}

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>


                                <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                    <Box width={"100%"}>
                                        <InputLabel id="demo-multiple-name-label">Dars darajasini tanlang</InputLabel>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                MenuProps={MenuProps}
                                                value={levelId}
                                                onChange={(event)=>setLevelId(event.target.value)}
                                        >
                                            {lessonLevelList.map((value) => (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>

                                    {levelId&&
                                        <Box>
                                            <Box width={"100%"}>
                                                <InputLabel id="demo-multiple-name-label">Darsni tanlang</InputLabel>
                                                <Select sx={{width:"100%"}}
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        MenuProps={MenuProps}
                                                        error={errors.mainLessonId}
                                                        {...register("mainLessonId",{required:"Tanlash shart"})}
                                                >
                                                    {mainLessons.map((value) => (
                                                        <MenuItem
                                                            key={value.id}
                                                            value={value.id}
                                                        >
                                                            {value.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                            {errors.mainLessonId&&<Typography color={"error"}>{errors.mainLessonId.message}</Typography>}
                                        </Box>
                                    }
                                </Box>



                                <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                    <Button type={"submit"} color={"success"} variant={"contained"}>Import qilish</Button>
                                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </Box>}
        </Box>
    );
}