import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const days=[
    {name:"Dushanba", schedules:[]},
    {name:"Seshanba", schedules:[]},
    {name:"Chorshanba", schedules:[]},
    {name:"Payshanba", schedules:[]},
    {name:"Juma", schedules:[]},
    {name:"Shanba", schedules:[]},
    {name:"Yakshanba", schedules:[]},
]

export default function RoomStatus(){


    const [roomStatus, setRoomStatus]=useState([]);

    // console.log(roomStatus);

    const [loader, setLoader]=useState();

    const handlerGetRoomStatus=async ()=>{
        const res=await GET.getRoomStatus();
        if (res.success){
            const data=res.res.data.schedules;
            const xonalar=[];

            for(let i=0; i<data.length; i++){
                let find=xonalar.find((value)=>value.name===data[i].room);
                if (!find){
                    xonalar.push({name:data[i].room, days:days});
                    find=xonalar.find((value)=>value.name===data[i].room);
                }

                let findDay=find.days.find(value => value.name===data[i].day);
                data[i].collection.map(value => findDay.schedules.push(value));

            }
            setRoomStatus(xonalar)
        }else if (!res.success){
            toast.error("Xatolik yuz berdi. ");
        }
    }


    useEffect(()=>{
        handlerGetRoomStatus();
    }, [])


    return (
        <>
            <Box>
                {JSON.stringify(roomStatus)}
            </Box>


            <Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Dushanba</StyledTableCell>
                                <StyledTableCell>Seshanba</StyledTableCell>
                                <StyledTableCell>Chorshanba</StyledTableCell>
                                <StyledTableCell>Payshanba</StyledTableCell>
                                <StyledTableCell>Juma</StyledTableCell>
                                <StyledTableCell>Shanba</StyledTableCell>
                                <StyledTableCell>Yakshanba</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roomStatus.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    {row.days.map((value, index)=>
                                        <StyledTableCell>
                                            {value.schedules.map((value1, index1) =>
                                                <Typography>
                                                    {value1.time}-{value1.end_time}   {value1.group_name}
                                                </Typography>)}
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </>
    );
}