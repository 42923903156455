import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {Pagination, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {getDateInfo} from "../Chat";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import DELETE from "../../components/api/DELETE";
import {formatPrice} from "../../components/Other/useWindowSize";
import AddOrEditProfessionModal from "../../components/modals/AddOrEditProfessionModal";
import DeleteProfissionModal from "../../components/modals/DeleteModal/DeleteProfissionModal";

export default function Profession(){


    const [professionList, setProfessionList]=useState([]);


    const [loader, setLoader]=useState(true);


    const handlerGetProfessions=async ()=>{
        setLoader(true)
        const res=await GET.getAllProfession();
        if (res.success){
            setProfessionList(res.res.data.permissions);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }



    useEffect(()=>{
        handlerGetProfessions();
    }, [])




    return (
        <>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} marginBottom={"8px"}>
                <Typography>
                    Kasblar
                </Typography>

                <Box display={"flex"} gap={"8px"}>
                    <AddOrEditProfessionModal type={"add"} handlerGetProfessions={handlerGetProfessions}/>
                </Box>
            </Box>

            {loader?<LoadingBeatLoader/>:
                <Box>
                    {professionList!==undefined&&professionList.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Student</TableCell>
                                            <TableCell align={"center"}>Description</TableCell>
                                            <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                            <TableCell align={"center"}>Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {professionList&&professionList.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.title}</Typography>
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    <Typography
                                                    >
                                                        {value1.description}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                <TableCell align={"right"} >
                                                    <Box sx={{display:"flex"}}>
                                                        <AddOrEditProfessionModal type={"edit"} profession={value1} handlerGetProfessions={handlerGetProfessions}/>
                                                        <DeleteProfissionModal id={value1.id} refresh={handlerGetProfessions}/>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>Kasblar mavjud emas</Typography>
                        </Box>
                    }
                </Box>}
        </>
    );
}