import React, {useEffect, useState} from "react";
import GET from "../api/GET";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {formatPrice} from "../Other/useWindowSize";
import {getDateInfo} from "../../page/Chat";
import AddOrEditWorkerBonusModal from "../modals/AddOrEditWorkerBonusModal";
import DeleteWorkerBonusesModal from "../modals/DeleteModal/DeleteWorkerBonusesModal";
import {Pagination} from "@mui/material";


export default function MyBonus(){

    const params=useParams();

    const [page, setPage]=useState(1);
    const [totalPrice, setTotalPrice]=useState(0);
    const [myBonuses, setMyBonuses]=useState([]);
    const [lastPage, setLastPage]=useState(1);

    const handlerMyBonus=async ()=>{
        const res=await GET.getAllWorkerBonus({user_id:params.id, page:page});
        if (res.success){
            setMyBonuses(res.res.data.bonuses.data);
            setLastPage(res.res.data.bonuses.last_page);
            setTotalPrice(res.res.data.total_price);
        }else {
            toast.error("Xatolik");
        }
    }

    useEffect(()=>{
        handlerMyBonus();
    },[]);

    useEffect(()=>{
        handlerMyBonus();
    },[page]);

    return (<>
                <div>
                    <Box>

                    </Box>
                    <Box>
                        {myBonuses!==undefined&&myBonuses.length>0?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead sx={{backgroundColor:"grey"}}>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>FIO</TableCell>
                                                <TableCell align={"center"}>Miqdori</TableCell>
                                                <TableCell align={"center"}>Sanasi</TableCell>
                                                <TableCell align={"center"}>Kim tomonidan berilgan</TableCell>
                                                <TableCell align={"center"}>Berilgan vaqti</TableCell>
                                                <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {myBonuses&&myBonuses.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                        "&:hover":{ backgroundColor:"#cbcbcb" }
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {((page-1)*20)+index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.user_name}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"right"} >
                                                        <Typography color={value1.sum>0?"green":"error"}>
                                                            {formatPrice(value1.sum)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.date}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"right"}>{value1.admin_name}</TableCell>
                                                    <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                    <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                </TableRow>
                                            )}

                                            <TableRow>
                                                <TableCell align={"center"} colSpan={2}>
                                                    Jami:
                                                </TableCell>
                                                <TableCell sx={{fontSize:"18px"}} align={"right"} colSpan={1}>
                                                    {formatPrice(totalPrice)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                    <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                                </Box>
                            </>
                            :
                            <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                                <Typography>Bonus/Jarimalar mavjud emas</Typography>
                            </Box>
                        }
                    </Box>
                </div>
            </>);
}