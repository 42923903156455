import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {Button, MenuItem, Pagination, Select, TextField} from "@mui/material";
import toast from "react-hot-toast";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import Typography from "@mui/material/Typography";
import {formatPrice, functionEndDate, functionStartDate} from "../../components/Other/useWindowSize";
import {checkAllApi} from "../../components/api/DefaultApi";


export default function PaymentsType(){

    const [text, setText]=useState(null);

    const [data, setData]=useState([]);

    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);

    const [loader, setLoader]=useState(true);



    const [endDate, setEndDate]=useState(functionEndDate);
    const [startDate, setStartDate]=useState(functionStartDate);
    const [totalPrice, setTotalPrice]=useState(0);

    const [paymentType, setPaymentType]=useState(null);



    const handlerGetPayments=async ()=>{
        let data={search:text, page:page, start_time:startDate, end_time:endDate, payment_name:paymentType}
        const res=await GET.getAllPaymentsType(data);
        if (res.success){
            setData(res.res.data.payment_transactions.data);
            setTotalPrice(res.res.data.total_price);
            setLastPage(res.res.data.payment_transactions.last_page);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma'lumot olishda xatolik xatolik");
            setLoader(false);
        }
    }
    const [paymentList, setPaymentList]=useState([]);

    const handlerPaymentsType=async ()=>{
            const res=await GET.getPaymentsType();
            checkAllApi(res);
            if (res.success){
                setPaymentList(res.res.data.payments);
                setLoader(false);
            }else if (!res.success){
                toast.error("To`lov turlarini olishda xatolik");
                setLoader(false);
            }
    }


    useEffect(()=>{
        handlerGetPayments();
        handlerPaymentsType();
    }, [])
    useEffect(()=>{
        handlerGetPayments();
    }, [page, text, startDate, endDate, paymentType]);



    return (
        <>


            <Box sx={{marginY:"8px", display:"flex", gap:"4px", flexWrap:"wrap", justifyContent:"space-between", alignItems:"center"}}>
                <Box sx={{display:"flex", alignItems:"center", gap:"4px"}}>
                    <Box>
                        <Typography>
                            Ismini kiriting
                        </Typography>
                        <TextField value={text} onChange={(event) => {
                            setText(event.target.value)
                        }}/>
                    </Box>

                    <Box>
                        <Typography>
                            Boshlanish sanasi
                        </Typography>
                        <TextField type={"date"} value={startDate} onChange={(event)=>{setStartDate(event.target.value)}}/>
                    </Box>

                    <Box>
                        <Typography>
                            Tugash sanasi
                        </Typography>
                        <TextField type={"date"} value={endDate} onChange={(event)=>{setEndDate(event.target.value)}}/>
                    </Box>
                   <Box sx={{width:"200px"}}>
                       <Typography>
                           To'lov turi
                       </Typography>
                        <Select  sx={{width:"100%"}}
                                   labelId="demo-simple-select-label"
                                   id="demo-simple-select"
                                   value={paymentType}
                                   label="Age"
                                   defaultChecked={null}
                                   onChange={(event)=>{setPaymentType(event.target.value)}}
                                 >
                                   <MenuItem value={null} >Hammasi</MenuItem>
                             {paymentList.map((value)=>
                                   <MenuItem value={value.name}>{value.name}</MenuItem>
                             )}
                         </Select>
                       </Box>


                    <Box>
                        <Button onClick={() => {
                            handlerGetPayments()
                        }} color={"info"} variant={"contained"}>
                            Qidirish
                        </Button>
                    </Box>






                </Box>
            </Box>
            {loader?<LoadingBeatLoader/>
                :<Box>
                    {data.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:"grey"}}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Buyurtma raqami</TableCell>
                                            <TableCell align={"center"}>Foydanaluvchi</TableCell>
                                            <TableCell align={"center"}>Telifon raqami</TableCell>
                                            <TableCell align={"center"}>Guruhi</TableCell>
                                            <TableCell align={"center"}>Miqdori</TableCell>
                                            <TableCell align={"center"}>Sanasi</TableCell>
                                            <TableCell align={"center"}>To'lov turi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.length>0&&data.map((value, index)=>{
                                            return <TableRow>
                                                <TableCell>
                                                    {((page-1)*30)+(index+1)}
                                                </TableCell>
                                                <TableCell>
                                                    {value.order_id}
                                                </TableCell>
                                                <TableCell>
                                                    {value.name} {value.lastname&&value.lastname}
                                                </TableCell>
                                                <TableCell>
                                                    {value.phone}
                                                </TableCell>
                                                <TableCell>
                                                    {value.group_name}
                                                </TableCell>
                                                <TableCell align={"right"}>
                                                    {formatPrice(value.amount)}
                                                </TableCell>
                                                <TableCell>
                                                    {value.date_add}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {value.pay_method}
                                                </TableCell>
                                            </TableRow>
                                        })}

                                        <TableRow>
                                            <TableCell >
                                                Jami:
                                            </TableCell>
                                            <TableCell>
                                                {totalPrice}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                            {/*<Box>*/}
                            {/*    {JSON.stringify(data)}*/}
                            {/*</Box>*/}

                        </>
                        :<Box>{"Ma'lumot mavjud emas"}</Box>
                    }
                </Box>
            }
        </>
    );
}