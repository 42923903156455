import axios from "axios";
import {BaseUrl} from "./DefaultApi";
import {setGroupsById} from "../../redux/action";



const token = localStorage.getItem("token");

const headers = {
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": `Bearer ${token}`
    }
}



const UploadFile=BaseUrl+"/api/teacher/practice/file";            // file yuklash uchun
const addTest=BaseUrl+"/api/teacher/questions/store";             // test qo`shish
const addLesson=BaseUrl+"/api/teacher/lesson/store";              // Lesson qo`sish
const addGroup=BaseUrl+"/api/teacher/group/store";                // Guruh qo`sish
const sortLesson=BaseUrl+"/api/teacher/lesson/sort";                // Guruh sortlash
const importLessons=BaseUrl+"/api/teacher/group/copy_group";                // guruhni copy qilish
const addClassTime=BaseUrl+"/api/admin/schedules/store";                // Schedules qo`shish
const addRoom=BaseUrl+"/api/teacher/rooms/store";                // rooms qo`shish
const takeDiscount=BaseUrl+"/api/teacher/take-discount";                // rooms qo`shish
const sendMessage=BaseUrl+"/api/teacher/send-message";                // rooms qo`shish
const editBattleTime=BaseUrl+"/api/teacher/group/battle_time";                // battle vaqtni o`zgartirish
const login=BaseUrl+"/api/login";                                     // rooms qo`shish
const addImportantNotes=BaseUrl+"/api/teacher/important-note/store";                                     // rooms qo`shish
const addFile=BaseUrl+"/api/teacher/uploaded/file";                                     // rooms qo`shish
const addMainLesson=BaseUrl+"/api/teacher/mainlesson/store";                                     // rooms qo`shish
const sortMainLesson=BaseUrl+"/api/teacher/mainlesson/sort";                                     // rooms qo`shish
const addNotification=BaseUrl+"/api/teacher/notification/insert";                                     // rooms qo`shish
const importMainLesson=BaseUrl+"/api/teacher/copy-mainLesson";                                     // rooms qo`shish
const addUserForAdmin=BaseUrl+"/api/admin/student/store";                                     // rooms qo`shish
const addNews=BaseUrl+"/api/admin/new/store";                                     // rooms qo`shish
const addGroupOrStudentsNotification=BaseUrl+"/api/admin/groups-students/notifications";
const addDiscountForAdmin=BaseUrl+"/api/teacher/discount/store";
const addPaymentForAdmin=BaseUrl+"/api/admin/payment/student";
const addSMS=BaseUrl+"/api/admin/send-sms";
const addOutputType=BaseUrl+"/api/admin/expense/store";
const addOutput=BaseUrl+"/api/admin/output/store";
const addProfession=BaseUrl+"/api/salary/profession/store";
const addSalaryUserItem=BaseUrl+"/api/salary/user-items/store";
const addSalaryWorkerBonus=BaseUrl+"/api/salary/bonuses/store";
const addSalaryWorkerAdvances=BaseUrl+"/api/salary/advances/store";
const editExampTime=BaseUrl+"/api/teacher/group/examp/store";
const addGenerateOrder=BaseUrl+"/api/admin/order/generate";
const passwordRecovery=BaseUrl+"/api/admin/student/password-update";
const userStatusChange=BaseUrl+"/api/admin/user/status-update";
const addLessonLevel=BaseUrl+"/api/admin/lesson/level/store";
const addUserMotion=BaseUrl+"/api/user-motion/store";
const editDiscountsLevel=BaseUrl+"/api/admin/battle-discount/store"
const editLessonDateAndTime=BaseUrl+"/api/teacher/lesson/add-date-time"
const addForLessonDiscount=BaseUrl+"/api/teacher/lesson/students";
const addOrEditExamTest=BaseUrl+"/api/exam/question/store";
const addOrEditExam=BaseUrl+"/api/exam/store";
const addOrEditExamGroup=BaseUrl+"/api/exam/exam-groups/store";
const sendSMSLessonInfo=BaseUrl+"/api/teacher/send-sms/student-parents";





const POST = {
    uploadFile: async (formData) => {
        try {
            const res = await axios.post(UploadFile, formData, {
                headers: {'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token}` }
            });
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    sendSMSLessonInfo: async (data) => {
        try {
            const res=await axios.post(sendSMSLessonInfo, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addOrEditExam: async (data) => {
        try {
            const res=await axios.post(addOrEditExam, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addOrEditExamGroup: async (data) => {
        try {
            const res=await axios.post(addOrEditExamGroup, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addForLessonDiscount: async (data) => {
        try {
            const res=await axios.post(addForLessonDiscount, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addOrEditExamTest: async (data) => {
        try {
            const res=await axios.post(addOrEditExamTest, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addTest: async (data) => {
        try {
            const res=await axios.post(addTest, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addLessonLevel: async (data) => {
        try {
            const res=await axios.post(addLessonLevel, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addGenerateOrder: async (data) => {
        try {
            const res=await axios.post(addGenerateOrder, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    passwordRecovery: async (data) => {
        try {
            const res=await axios.post(passwordRecovery, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    editDiscountsLevel: async (id, data) => {
        try {
            const res=await axios.post(editDiscountsLevel+"/"+id, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    editExampTime: async (data) => {
        try {
            const res=await axios.post(editExampTime, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addSalaryWorkerBonus: async (data) => {
        try {
            const res=await axios.post(addSalaryWorkerBonus, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addSalaryWorkerAdvances: async (data) => {
        try {
            const res=await axios.post(addSalaryWorkerAdvances, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addSalaryUserItem: async (data) => {
        try {
            const res=await axios.post(addSalaryUserItem, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addProfession: async (data) => {
        try {
            const res=await axios.post(addProfession, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addPaymentForAdmin: async (data) => {
        try {
            const res=await axios.post(addPaymentForAdmin, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    editBattleTime: async (data) => {
        try {
            const res=await axios.post(editBattleTime, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addRoom: async (data) => {
        try {
            const res=await axios.post(addRoom, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    userStatusChange: async (data) => {
        try {
            const res=await axios.post(userStatusChange, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    importLessons: async (data) => {
        try {
            const res=await axios.post(importLessons, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addFile: async (data) => {
        try {
            const res=await axios.post(addFile, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addLesson: async (data) => {
        try {
            const res=await axios.post(addLesson, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    takeDiscount: async (data) => {
        try {
            const res=await axios.post(takeDiscount, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addClassTime: async (data) => {
        try {
            const res=await axios.post(addClassTime, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addGroup: async (data) => {
        try {
            const res=await axios.post(addGroup, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    sortLesson: async (data) => {
        try {
            const res=await axios.post(sortLesson, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addOutput: async (data) => {
        try {
            const res=await axios.post(addOutput, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    sendMessage: async (data) => {
        try {
            const res=await axios.post(sendMessage, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    login: async (data) => {
        try {
            const res=await axios.post(login, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addMainLesson: async (data) => {
        try {
            const res=await axios.post(addMainLesson, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addUserMotion: async (data) => {
        try {
            const res=await axios.post(addUserMotion, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },

    addNotification: async (data) => {
        try {
            const res=await axios.post(addNotification, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    sortMainLesson: async (data) => {
        try {
            const res=await axios.post(sortMainLesson, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    importMainLesson: async (data) => {
        try {
            const res=await axios.post(importMainLesson, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addUserForAdmin: async (data) => {
        try {
            const res=await axios.post(addUserForAdmin, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addGroupOrStudentsNotification: async (data) => {
        try {
            const res=await axios.post(addGroupOrStudentsNotification, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addNews: async (data) => {
        try {
            const res=await axios.post(addNews, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addImportantNotes: async (data) => {
        try {
            const res=await axios.post(addImportantNotes, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addDiscountForAdmin: async (data) => {
        try {
            const res=await axios.post(addDiscountForAdmin, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addSMS: async (data) => {
        try {
            const res=await axios.post(addSMS, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    addOutputType: async (data) => {
        try {
            const res=await axios.post(addOutputType, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },
    editLessonDateAndTime: async (data) => {
        try {
            const res=await axios.post(editLessonDateAndTime, data, headers);
            return {success:true, res:res}
        }catch (e){
            return {success:false, res:e}
        }
    },


}

export default POST;