import {Button, TextareaAutosize, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {BaseUrl, checkAllApi, FileBaseUrl} from "../../api/DefaultApi";
import ReactPlayer from "react-player";
import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";


const styleChild = {
    padding:"10px",
    overflow:'auto',
    height:'50%',
    minWidth:"70%",
    display:'flex',
    gap:1,
    flexDirection:'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function AddImportantNotes({handlerImportantNotes, page}){

    // const [description, setDescription]=useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");


    const [open, setOpen] = useState(false);


    const handleOpen = () => {
        setOpen(true)
    };


    const handleClose = () => setOpen(false);


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {


        const res=await POST.addImportantNotes({...data, group_id:groupId});
        // console.log(res);
        // let checkAllApi1 = checkAllApi(res);
        // // if (!checkAllApi1){
        // //     toast.error("Xatolik");
        // //     return;
        // // }

        if (res.success){
            toast.success("Yaratildi.");
            reset();
            handleClose();
            handlerImportantNotes(groupId, page);
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    return (
        <>



            <Box>
                <Button onClick={handleOpen} variant={"contained"} color={"success"}>
                    Qo`shish
                </Button>

            </Box>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={styleChild} component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"}>
                        <Box>
                            <TextField label={"Titleni kiriting:"}
                                       fullWidth
                                       {...register("title",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>


                        <Box>
                            <Typography>Tavsifini kiriting:</Typography>
                            <textarea style={{width:"100%"}} rows={8}
                                      label={"Tavsifini kiriting: "} sx={{marginBottom:"4px"}}
                                      {...register("description")}
                                      fullWidth/>
                        </Box>

                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                            <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>


        </>
    );
}