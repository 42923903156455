import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddGroupModal from "../../components/modals/AddGroupModal";
import GET from "../../components/api/GET";
import React, {useEffect, useState} from "react";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import toast from "react-hot-toast";
import {formatPrice} from "../../components/Other/useWindowSize";
import {Pagination, TextField, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import EditGroupModal from "../../components/modals/EditGroupModal";
import DeleteGroupModal from "../../components/modals/DeleteModal/DeleteGroupModal";
import {useSelector} from "react-redux";


export default function Groups(){


    const navigate=useNavigate();

    const [page, setPage]=useState(1);

    const [lastPage, setLastPage]=useState(1);

    const [loader, setLoader]=useState(true);

    const [groupList, setGroupList]=useState([]);

    const [text, setText]=useState("");


    const handlerGetGroups=async ()=>{
        const res=await GET.getAllGroupsForAdmin(page, text);
        if (res.success){
            setGroupList(res.res.data.groups.data);
            setLastPage(res.res.data.groups.last_page);
            setLoader(false);
        }else if (!res.success){
            toast.error("Guruhlarni olishda xatolik");
            setLoader(false);
        }
    }

    const teacherList=useSelector(state => state.otherInfoList.teachers);

    useEffect(()=>{
        handlerGetGroups();

    }, [])

    useEffect(()=>{
        handlerGetGroups();
    }, [page]);

    useEffect(() => {
        if (text.length>3){
            handlerGetGroups();
        }
    }, [text]);







    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} alignItems={"center"}>
                <Typography>Guruhlar</Typography>
                <AddGroupModal handlerGetGroups={handlerGetGroups} teacherList={teacherList}/>
            </Box>

            <Box sx={{width:"100%", marginY:"8px"}}>
                <TextField
                    inputProps={{
                        autocomplete: 'off',
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                    value={text} onChange={(event)=>setText(event.target.value)} fullWidth label={"Qidirish uchun kiriting:"}/>
            </Box>


            {loader?<LoadingBeatLoader/>:
                <Box>
                    {groupList.length>0?
                        <>
                            <TableContainer component={Paper} sx={{marginY:2}}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:"grey", }}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Nomi</TableCell>
                                            <TableCell align={"center"}>Narxi</TableCell>
                                            <TableCell align={"center"}>Darajasi</TableCell>
                                            <TableCell align={"center"}>Battle time</TableCell>
                                            <TableCell width={"100px"} align={"center"} >Boshlangan vaqti</TableCell>
                                            <TableCell width={"100px"} >O`qutuvchisi</TableCell>
                                            <TableCell width={"100px"} >Holati</TableCell>
                                            <TableCell width={"100px"} >Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupList.map((row, index) =>
                                            <TableRow sx={{
                                                ":hover": {cursor: "pointer", backgroundColor: "#cbcbcb"}
                                            }}>
                                                <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} width={"30px"} align={"center"}>{(((page - 1) * 10) + index + 1)}</TableCell>
                                                <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>{row.name}</TableCell>
                                                <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>{formatPrice(row.price)}</TableCell>
                                                <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>{row.level_id}</TableCell>
                                                <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>{row.battle_time}</TableCell>
                                                <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>{row.start_date}</TableCell>
                                                <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>{row.teacher_name}</TableCell>
                                                {row.status==0&&
                                                    <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>
                                                        <Typography sx={{backgroundColor:"#FF0000", width:"objectFit", color:"white", borderRadius:"50px", padding:"4px 8px"}} >
                                                            yopilgan
                                                        </Typography>
                                                    </TableCell>

                                                }
                                                {row.status==1&&
                                                    <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>
                                                        <Typography sx={{backgroundColor:"#2E7D32", width:"objectFit", color:"white", borderRadius:"50px", padding:"4px 8px"}} >
                                                            aktive
                                                        </Typography>
                                                    </TableCell>
                                                }
                                                {row.status==2&&
                                                    <TableCell onClick={()=>navigate("/group?groupId=" + row.id)} align={"center"}>
                                                        <Typography sx={{backgroundColor:"#ED6C02", width:"objectFit", color:"white", borderRadius:"50px", padding:"4px 8px"}} >
                                                            to`lmoqda
                                                        </Typography>
                                                    </TableCell>
                                                }
                                                {/*{row.schedules.map((value, index1) =>*/}
                                                {/*    <Typography>*/}
                                                {/*        {value.day}: {value.time} - {value.end_time},  {value.room&&value.room.name+"-xona"}*/}
                                                {/*    </Typography>)*/}
                                                {/*}*/}
                                                <TableCell width={"150px"} align={"center"} sx={{display:"flex", alignItems:"center"}}>
                                                    {/*<Tooltip title={"Batafsil ko`rish"} >*/}
                                                    {/*    <IconButton color={"info"} onClick={()=>navigate("/group?groupId="+row.id)}>*/}
                                                    {/*        <VisibilityIcon/>*/}
                                                    {/*    </IconButton>*/}
                                                    {/*</Tooltip>*/}

                                                    <EditGroupModal group={row} handlerGetGroups={handlerGetGroups} teacherList={teacherList}/>

                                                    <DeleteGroupModal id={row.id} refresh={handlerGetGroups}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :
                        <Box>
                            <Typography>Guruhlar mavjud emas</Typography>
                        </Box>
                    }
                </Box>
            }
        </>
    );
}