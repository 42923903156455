import {Button, Switch, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {BaseUrl, checkAllApi, FileBaseUrl} from "../../api/DefaultApi";
import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import axios from "axios";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";


const label = { inputProps: { 'aria-label': 'Switch demo' } };

const styleChild = {
    padding:"10px",
    overflow:'auto',
    height:'50%',
    minWidth:"70%",
    display:'flex',
    gap:1,
    flexDirection:'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function AddOrEditProfessionModal({handlerGetProfessions, type, profession}){


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => setOpen(false);

    const { register,setValue, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        let newData;

            type==="add"?
                newData={...data}
                :
                newData={...data, id:profession.id}

        const res=await POST.addProfession(newData);
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast.error("Xatolik");
            return;
        }

        if (res.success){
            toast.success("Yaratildi.");
            reset();
            handleClose();
            handlerGetProfessions();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    useEffect(() => {
        if (type==="edit"){
            setValue("title", profession.title);
            setValue("description", profession.description)
        }
    }, [profession]);

    return (
        <>
            <Box>
                {type==="add"&&
                    <Button onClick={handleOpen} variant={"contained"} color={"success"}>
                        Qo`shish
                    </Button>
                }

                {type==="edit"&&
                    <Tooltip title={"Tahrirlash"}>
                        <IconButton onClick={handleOpen} color={"warning"}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={styleChild} component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"}>
                        <Typography variant={"h5"} >{type==="add"?"Kasb qo`shish":"Kasbni tahrirlash"}</Typography>


                        <Box>
                            <Typography>Nomini kiriting:</Typography>
                            <TextField
                                       fullWidth
                                       {...register("title",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>


                        <Box>
                            <Typography>Tavsifini kiriting:</Typography>
                            <textarea style={{width:"100%"}} rows={5}
                                      label={"Tavsifini kiriting: "} sx={{marginBottom:"4px"}}
                                      {...register("description")}
                                      fullWidth/>
                        </Box>



                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                            <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>


        </>
    );
}