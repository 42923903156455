import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import MenuIcon from '@mui/icons-material/Menu';
import {TextareaAutosize, TextField, Tooltip} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {Button} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import DELETE from "../../api/DELETE";
import {checkAllApi, FileBaseUrl} from "../../api/DefaultApi";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import GET from "../../api/GET";
import {setGroupsById} from "../../../redux/action";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {BaseUrl} from "../../api/DefaultApi";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import LessonAdd from "../../modals/LessonAdd";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DeleteLessonModal from "../../modals/DeleteModal/DeleteLessonModal";

const style = {
    padding:"10px",
    overflow:'auto',
    height:'50%',
    minWidth:"70%",
    display:'flex',
    gap:1,
    flexDirection:'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}


export default function LessonsForGroup({child, parent_id}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");
    const navigate=useNavigate();

    const {lessons}=useSelector(state => state.items.group_id);

    const [childLesson, setChildLesson]=useState(child?child:[]);

    const [length, setLength]=useState(0);

    useEffect(()=>{
        setChildLesson(child);
        setLength(child.length);
    }, [lessons]);



    const handleDeleteLesson=async (id)=>{
        const res=await DELETE.deleteLesson(id);
        checkAllApi(res);

        if (res.success){
            toast.success("O`chirldi. ");
            handleGetByIdGroup(groupId);
            return;
        }

        if (!res.success){
            toast.error("Xatolik");
        }
    }

    const [description, setDescription]=useState("")



    const handleEditLesson=async (data, message)=>{
        const res=await POST.addLesson(data);
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast("Xatolik")
            return;
        }
        if (res.success){
            toast.success(message);
            handleGetByIdGroup(groupId)
            return;
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    }

    return (
        <div className="App">
            {childLesson?
                <DragDropContext
                    onDragEnd={(param) => {
                        if (localStorage.getItem("role")==="teacher") {
                            const srcI = param.source.index;
                            const desI = param.destination?.index;
                            const handleOrderList = [...childLesson];
                            handleOrderList.splice(desI, 0, handleOrderList.splice(srcI, 1)[0])
                            handleOrderList.map((value, index) => value.order=index)

                            let data=[];
                            handleOrderList.map((value, index) =>{
                                data.push({id:value.id, order:value.order})
                            })
                            setChildLesson(handleOrderList);

                            // if (desI) {
                                POST.sortLesson(data).then((res)=>{
                                    if (res.success){
                                        // handleGetByIdGroup(groupId);
                                        toast.success("Qayta tartiblandi. ");
                                    }
                                });
                            // }
                        }else {
                            toast.error("Sizda huquq yo`q");
                        }
                    }}
                >
                    <Box>
                        <Droppable droppableId="droppable-1">
                            {(provided, _) => (
                                <Box sx={{display:'flex', flexDirection:"column", gap:"4px"}} ref={provided.innerRef} {...provided.droppableProps}>
                                    {childLesson.map((item, i) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={"draggable-" + item.id}
                                            index={i}
                                        >
                                            {(provided, snapshot) => (
                                                <Box sx={{display:"flex", gap:"4px", alignItems:"center", justifyContent:"space-between", backgroundColor:"#EFF1F5", padding:"4px"}}
                                                     width={"100%"}
                                                     ref={provided.innerRef}
                                                     {...provided.draggableProps}
                                                    // style={{
                                                    //     ...provided.draggableProps.style,
                                                    //     boxShadow: snapshot.isDragging
                                                    //         ? "0 0 .4rem #666"
                                                    //         : "none",
                                                    // }}
                                                >
                                                    <Box display={"flex"} gap={"4px"} >
                                                        <img src={"/assets/icon/cursor.svg"} {...provided.dragHandleProps} />
                                                        <span>{i+1+". "}{item.name}</span>
                                                    </Box>
                                                    <Box>
                                                        {localStorage.getItem("role")==="teacher"&&
                                                            <Tooltip title={item.ready===0?"Darsni ochish":"Darsni yopish"}>
                                                                <IconButton color={item.ready===1?"success":"error"} onClick={()=>handleEditLesson({...item,ready:item.ready===0?1:0}, item.ready===0?"Dars ochildi":"Dars yopildi")}>
                                                                    {item.ready===1?<LockOpenIcon/>:<LockIcon/>}
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        <Tooltip title={"Tahrirlash"}>
                                                            <IconButton color={"warning"} onClick={()=>navigate("/lesson?groupId="+groupId+"&lessonId="+item.id)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        {localStorage.getItem("role")==="teacher"&&
                                                            <DeleteLessonModal id={item.id} groupId={groupId} refresh={handleGetByIdGroup}/>
                                                        }

                                                    </Box>
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </Box>
                </DragDropContext>
                :<Typography>Darslar mavjud emas</Typography>
            }

            {localStorage.getItem("role")==="teacher"&&
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <LessonAdd parent_id={parent_id} length={length}/>
                </Box>
            }

        </div>
    );
}