import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {Button, Pagination, TextField} from "@mui/material";
import toast from "react-hot-toast";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {Link} from "react-router-dom";
import PaymentId from "../../components/PaymentId";
import Typography from "@mui/material/Typography";
import {functionEndDate, functionStartDate} from "../../components/Other/useWindowSize";


export default function Payments({status}){

    const [text, setText]=useState(null);

    const [data, setData]=useState([]);

    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);

    const [loader, setLoader]=useState(true);



    const [endDate, setEndDate]=useState(functionEndDate);
    const [startDate, setStartDate]=useState(functionStartDate);
    const [totalPrice, setTotalPrice]=useState(0);



    const handlerGetPayments=async ()=>{
        let data={status:status, search:text, page:page, start_time:startDate, end_time:endDate}
        const res=await GET.getAllPayments(data);
        if (res.success){
            setData(res.res.data.orders.data);
            setTotalPrice(res.res.data.total_price);
            setLastPage(res.res.data.orders.last_page);
            setLoader(false);
        }else if (!res.success){
            toast.error(status===1?"To`lovlarni":"Qarzdozlarni"+" olishda xatolik xatolik");
            setLoader(false);
        }
    }

    useEffect(()=>{
        handlerGetPayments();
    }, [])
    useEffect(()=>{
        handlerGetPayments();
    }, [status, page, text, startDate, endDate])

    return (
        <>


            <Box sx={{marginY:"8px", display:"flex", gap:"4px", flexWrap:"wrap", justifyContent:"space-between", alignItems:"center"}}>
                <Box sx={{display:"flex", alignItems:"center", gap:"4px"}}>
                    <Box>
                        <Typography>
                            Ismini kiriting
                        </Typography>
                        <TextField value={text} onChange={(event) => {
                            setText(event.target.value)
                        }}/>
                    </Box>

                    <Box>
                        <Typography>
                            Boshlanish sanasi
                        </Typography>
                        <TextField type={"date"} value={startDate} onChange={(event)=>{setStartDate(event.target.value)}}/>
                    </Box>

                    <Box>
                        <Typography>
                            Tugash sanasi
                        </Typography>
                        <TextField type={"date"} value={endDate} onChange={(event)=>{setEndDate(event.target.value)}}/>
                    </Box>
                    <Box>
                        <Button onClick={() => {
                            handlerGetPayments()
                        }} color={"info"} variant={"contained"}>
                            Qidirish
                        </Button>
                    </Box>
                </Box>

                {status === 0 &&
                    <Link to={"/add-debtor"}>
                        <Button>
                            Qarzdorlikni yaratish
                        </Button>
                    </Link>
                }
            </Box>
            {loader?<LoadingBeatLoader/>
                :<Box>
                    {data.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:"grey"}}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Foydalanuvchi</TableCell>
                                            <TableCell align={"center"}>Guruhi</TableCell>
                                            <TableCell align={"center"}>Miqdori</TableCell>
                                            {status===0&&
                                                <TableCell align={"center"}>Qoldiq</TableCell>
                                            }
                                            <TableCell align={"center"}>Sanasi</TableCell>
                                            <TableCell align={"center"}>Holati</TableCell>
                                            <TableCell align={"center"}>Telifon raqami</TableCell>
                                            <TableCell align={"center"}>Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((value1, index) =>
                                            <PaymentId status={status} payment={value1} index={index} page={page} handlerGetPayments={handlerGetPayments} />
                                        )}

                                        <TableRow>
                                            <TableCell >
                                                Jami:
                                            </TableCell>
                                            <TableCell>
                                                {totalPrice}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :<Box>{status===1?"To`lovlar mavjud emas":"Qarzdorlar mavjud emas"}</Box>
                    }
                </Box>
            }
        </>
    );
}