import Typography from "@mui/material/Typography";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import UserDiscount from "../../components/users/UserDiscount";
import UserPractise from "../../components/users/UserPractise";
import UserExercise from "../../components/users/UserExercise";
import UserInfo from "../../components/UserInfo";
import UserIdPayments from "../../components/UserIdPayments";
import UserStatistic from "../../components/UsersForAdmin/UserStatistic";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function User() {
  const params = useParams();

  const [value, setValue] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const tab = searchParams.get("tab");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ groupId: groupId, tab: newValue });
  };

  useEffect(() => {
    tab && setValue(parseInt(tab));
  }, []);

  return (
    <>
      {(groupId !== "null" || groupId === undefined || groupId === null) && (
        <Link
          to={"/group/?groupId=" + groupId}
          style={{
            textDecoration: "none",
            padding: "8px",
            backgroundColor: "#1976D2",
            borderRadius: "4px",
            color: "white",
          }}
        >
          Guruhga qaytish
        </Link>
      )}

      <Box
        sx={{
          maxWidth: { xs: "320px", sm: "100%" },
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Asosiy ma`lumotlar" {...a11yProps(0)} />
          <Tab label="Bonus va jarimalar" {...a11yProps(1)} />
          <Tab label="Practices" {...a11yProps(2)} />
          <Tab label="Exercise" {...a11yProps(3)} />
          {(localStorage.getItem("role") === "admin" ||
            localStorage.getItem("role") === "superadmin") && (
            <Tab label="To`lov amaliyotlari" {...a11yProps(4)} />
          )}
          <Tab label="Userning tizimdagi xarakatlari" {...a11yProps(5)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Box>
            <UserInfo />
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <UserDiscount />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <UserPractise />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <UserExercise />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <UserIdPayments />
        </TabPanel>

        <TabPanel value={value} index={5}>
          <UserStatistic />
        </TabPanel>
      </Box>
    </>
  );
}
