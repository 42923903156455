import {BeatLoader} from "react-spinners";
import React from "react";
import Box from "@mui/material/Box";


export default function LoadingBeatLoader(){


    return (
        <Box display={"flex"} height={"350px"} alignItems={"center"} justifyContent={"center"}>
            <BeatLoader color="#36d7b7"/>
        </Box>
    );
}