import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {Button, TextField, InputLabel, Select, MenuItem} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import {checkAllApi, getTeacherId} from "../../api/DefaultApi";
import GET from "../../api/GET";
import {formatPrice, StringToDouble} from "../../Other/useWindowSize";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyInput from "react-currency-input-field";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function AddPaymentModal({detail, handlerGetOrdersByUserId, handlerGetOrderPayments,id}) {

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();

    const [paymentTypeList, setPaymentTypeList]=useState([]);
    // console.log(id);


    const handleGetPaymentsType=async ()=>{
        const res=await GET.getPaymentsType();
        let checkAllApi1 = checkAllApi(res);
        if (res.success){
            const data=[];

            res.res.data.payments.map((value)=>{
                if (value.type==="payme"){
                    return;
                }
                if (value.type==="click"){
                    return;
                }
                data.push(value);
            });
            setPaymentTypeList(data);
        }
    }

    useEffect(()=>{
        handleGetPaymentsType();
    }, []);


    const onSubmit = async (data) => {
        const handlerAmount=StringToDouble(data.priceHandler);
        if (handlerAmount>detail.price){
            toast.error("Siz to`lov summasini maksimal qarzdan ortiqcha to`lashga harakat qilayapsiz");
            return;
        }
        const newData={...data, amount:StringToDouble(data.priceHandler), order_id:id};
        const res=await POST.addPaymentForAdmin(newData);
        checkAllApi(res);

        if (res.success){
            toast.success("To`landi.");
            reset();
            handleClose();
            handlerGetOrdersByUserId();
            handlerGetOrderPayments();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }


    const {group, lessons}=useSelector(state => state.items.group_id);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    // console.log(detail);

    return (
        <Box>
                <Box>
                    <Button startIcon={<AttachMoneyIcon/>} sx={{backgroundColor:"green"}} variant={"contained"}
                                                 onClick={handleOpen}  color={"secondary"}>
                        To`lash
                    </Button>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            {detail.status==0?
                                <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>


                                    <Box>
                                        <Typography>
                                            To'lov raqami: {detail.order_id}
                                        </Typography>
                                        <Typography>
                                            Talabaning ismi sharifi: {detail.name} {detail.lastname&&detail.lastname}
                                        </Typography>
                                        <Typography>
                                            To`lov miqdori: {formatPrice(detail.sum)}
                                        </Typography>
                                        <Typography>
                                            Qoldiq miqdori: {formatPrice(detail.price)}
                                        </Typography>
                                        <Typography>
                                            Guruhi : {detail.group_name}
                                        </Typography>
                                    </Box>

                                    <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                        <Box width={"100%"}>
                                            <InputLabel id="demo-multiple-name-label">To`lov turini tanlang</InputLabel>
                                            <Select sx={{width:"100%"}}
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    MenuProps={MenuProps}
                                                    error={errors.payment_id}
                                                    {...register("payment_id", {required:"Tanlash kerak"})}
                                            >
                                                {paymentTypeList.map((value) => (
                                                    <MenuItem
                                                        key={value.id}
                                                        value={value.id}
                                                    >
                                                        {value.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                        {errors.payment_id&&<Typography color={"error"}>{errors.payment_id.message}</Typography>}
                                    </Box>


                                    <Box>
                                        <Typography>
                                            Miqdorini kiriting:
                                        </Typography>
                                        <CurrencyInput
                                            groupSeparator={" "}
                                            decimalSeparator={"."}
                                            style={{padding:"12px", width:"100%", fontSize:"16px"}}
                                            fullWidth
                                            {...register("priceHandler")}
                                            defaultValue={detail.price}
                                        />
                                        {errors.priceHandler&&
                                            <Typography color={"error"} >
                                                {errors.priceHandler.message}
                                            </Typography>
                                        }
                                    </Box>



                                    <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                        <Button type={"submit"} color={"success"} variant={"contained"}>To`lash</Button>
                                        <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                    </Box>
                                </Box>
                                :
                            <Box sx={style}>

                                <Box display={"flex"} gap={"4px"} justifyContent={"center"} >
                                    <Typography color={"white"} sx={{backgroundColor:"green", padding:"4px", borderRadius:"8px"}}>To`langan</Typography>
                                </Box>
                                <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                </Box>

                            </Box>}
                        </Fade>
                    </Modal>
                </Box>
        </Box>
    );
}