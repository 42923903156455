import React, {useEffect, useState} from "react";
import Loader from "../Loader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddOrEditExamGroupModal from "../../components/modals/AddOrEditExamGroupModal";
import {useSelector} from "react-redux";
import GET from "../../components/api/GET";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {getDateInfo} from "../Chat";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteExamModal from "../../components/modals/DeleteModal/DeleteExamModal";
import TableContainer from "@mui/material/TableContainer";
const statusList=[
    {id:0, name:"Kutilayapti", color:"warning"},
    {id:1, name:"O'tkazilgan", color: "success"},
    {id:2, name:"Bekor qilingan", color: "error"}
];


export default function Exams(){

    const [loader, setLoader]=useState(false);

    const groups=useSelector(state => state.items.groups);

    const [examTypeList, setExamTypeList]=useState([]);
    const [examGroupList, setExamGroupList]=useState([]);
    const handlerGetExam=async ()=>{
        const res=await GET.getAllExam();
        if (res.success){
            setExamTypeList(res.res.data.exams);
            setLoader(false);
        }else {

        }
    }

    const handerExamGroups=async ()=>{
        const res=await GET.getAllExamGroup();
        if (res.success){
            setExamGroupList(res.res.data.allExamsGroups);
            setLoader(false);
        }else {

        }
    }

    useEffect(() => {
        handlerGetExam();
        handerExamGroups();
    }, []);

    const getTextStatus=(id)=>{
        console.log(id)
        if (id==0){
            return  statusList[0];
        }
        let find = statusList.find((value)=>value.id==id);
        console.log(find)
        return find?find:null;
    }

    return (<>
        <div>
            {loader?
                <Loader/>:
                <div>
                    <Box style={{display:"flex", justifyContent:"space-between"}}>
                       <Typography>
                           Imtihonlar
                       </Typography>
                        <AddOrEditExamGroupModal statusList={statusList}
                                                 type={"add"}
                                                 groups={groups}
                                                 examTypeList={examTypeList}
                                                 refresh={handerExamGroups}
                        />
                    </Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead sx={{backgroundColor:"grey"}}>
                                <TableRow>
                                    <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                    <TableCell align={"center"}>Guruhi</TableCell>
                                    <TableCell align={"center"}>Imtihon turi</TableCell>
                                    <TableCell align={"center"}>Kun</TableCell>
                                    <TableCell align={"center"}>Vaqt</TableCell>
                                    <TableCell align={"center"}>Davomiyligi</TableCell>
                                    <TableCell align={"center"}>Holati</TableCell>
                                    <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                    <TableCell align={"center"}>Amallar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {examGroupList&&examGroupList.map((value1, index) =>
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                            "&:hover":{ backgroundColor:"#cbcbcb" }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {index+1}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.group_id&&groups.find((group)=>group.id==value1.group_id).name}
                                        </TableCell>
                                        <TableCell align={"left"} >
                                            {value1.exam&&value1.exam.title}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.date}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.time}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.lifetime}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.status!=null&&getTextStatus(value1.status).name}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {getDateInfo(value1.created_at)}
                                        </TableCell>
                                        <TableCell align={"center"} >
                                            <Box sx={{display:"flex", justifyContent:"end"}}>
                                                {/*<Tooltip title={"Batafsil ko'rish"}>*/}
                                                {/*    <IconButton color={"primary"}>*/}
                                                {/*        <VisibilityIcon />*/}
                                                {/*    </IconButton>*/}
                                                {/*</Tooltip>*/}
                                                <AddOrEditExamGroupModal
                                                    type={"edit"}
                                                    groups={groups}
                                                    examTypeList={examTypeList}
                                                    refresh={handerExamGroups}
                                                    exam={value1}
                                                    statusList={statusList}
                                                />
                                                {/*<AddOrEditExamTest type={"edit"}*/}
                                                {/*                   refresh={handlerQuestionList}*/}
                                                {/*                   level={lessonLevelList[activeFolder]}*/}
                                                {/*                   question={value1}*/}

                                                {/*/>*/}
                                                {/*<DeleteExamModal refresh={handlerGetExam}*/}
                                                {/*                 id={value1.id}/>*/}

                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    </>);
}