import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import GET from "../../components/api/GET";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Pagination, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import AddScheduleModal from "../../components/modals/AddScheduleModal";
import DELETE from "../../components/api/DELETE";
import toast from "react-hot-toast";
import EditScheduleModal from "../../components/modals/EditScheduleModal";
import { Link } from "react-router-dom";
import DeleteScheduleModal from "../../components/modals/DeleteModal/DeleteScheduleModal";
import {useSelector} from "react-redux";

export default function Schedule() {
  const [groups, setGroups] = useState([]);

  const [page, setPage] = useState(0);

  const [lastPage, setLastPage] = useState(1);

  const [isLoader, setLoader] = useState(true);

  const rooms = useSelector(state => state.otherInfoList.rooms);


  const handlerGetGroupSchedule = async () => {
    const res = await GET.getAllScheduleForGroups(page);
    if (res.success) {
      setGroups(res.res.data.groups.data);
      setLastPage(res.res.data.groups.last_page);
      setLoader(false);
    }
  };

  useEffect(() => {
    handlerGetGroupSchedule();
  }, []);

  useEffect(() => {
    handlerGetGroupSchedule();
  }, [page]);


  return (
    <>
      {isLoader ? (
        <LoadingBeatLoader />
      ) : (
        <Box>
          <Box>
            <Typography variant={"h5"} align={"center"} marginButtom={"4px"}>
              Dars jadvali
            </Typography>
            {/*<Link to={"/room-status"}>Xonalar xolati</Link>*/}
          </Box>
          {groups.map((value) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {<Typography>{value.name}</Typography>}
              </AccordionSummary>
              <AccordionDetails>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  {(localStorage.getItem("role") === "admin"||localStorage.getItem("role") === "superadmin") &&
                      <Box>
                        <AddScheduleModal
                            handlerGetGroupSchedule={handlerGetGroupSchedule}
                            rooms={rooms}
                            groupId={value.id}
                        />
                      </Box>
                  }
                </Box>
                {value.schedules.length > 0 ? (
                  <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell width={"30px"} align={"center"}>
                              T/R
                            </TableCell>
                            <TableCell align={"center"}>Nomi</TableCell>
                            <TableCell align={"center"}>
                              Dars boshlanish vaqti
                            </TableCell>
                            <TableCell align={"center"}>
                              Dars tugash vaqti
                            </TableCell>
                            <TableCell align={"center"}>Xona</TableCell>
                            {(localStorage.getItem("role") === "admin"||localStorage.getItem("role") === "superadmin") && (
                              <TableCell width={"100px"} align={"center"}>
                                Amallar
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {value.schedules.map((schedule, index) => (
                            <TableRow
                              key={schedule.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell align={"center"}>
                                {schedule.day}
                              </TableCell>
                              <TableCell align={"center"}>
                                {schedule.time}
                              </TableCell>
                              <TableCell align={"center"}>
                                {schedule.end_time}
                              </TableCell>
                              <TableCell align={"center"}>
                                {schedule.room.name}
                              </TableCell>

                              {(localStorage.getItem("role") === "admin" ||
                                localStorage.getItem("role") === "superadmin") && (
                                <TableCell
                                  align={"center"}
                                  width={"150px"}
                                  sx={{ display: "flex" }}
                                >
                                  <EditScheduleModal
                                    page={page}
                                    handlerGetGroupSchedule={
                                      handlerGetGroupSchedule
                                    }
                                    groupId={value.id}
                                    schedule={schedule}
                                  />
                                  <DeleteScheduleModal id={schedule.id} refresh={handlerGetGroupSchedule}/>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <Box display={"flex"} flexDirection={"column"}>
                    {/*<Typography>Dars jadvali mavjud emas</Typography>*/}
                    {/*{localStorage.getItem("role") === "admin" && (*/}
                    {/*  <Box alignSelf={"self-end"}>*/}
                    {/*    <AddScheduleModal*/}
                    {/*      handlerGetGroupSchedule={handlerGetGroupSchedule}*/}
                    {/*      rooms={rooms}*/}
                    {/*      groupId={value.id}*/}
                    {/*    />*/}
                    {/*  </Box>*/}
                    {/*)}*/}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}

          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ marginTop: "12px" }}
          >
            <Pagination
              count={lastPage}
              onChange={(event) => {
                setPage(event.currentTarget.textContent);
              }}
              color="primary"
            />
          </Box>
        </Box>
      )}
    </>
  );
}
