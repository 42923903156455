import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import { BaseUrl, FileBaseUrl } from "../api/DefaultApi";
import { Button, TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import DELETE from "../api/DELETE";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ReactPlayer from "react-player";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import POST from "../api/POST";
import DeleteRoomModal from "../modals/DeleteModal/DeleteRoomModal";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};
export default function RoomId({ room, index, handlerGetAllRooms }) {


  useEffect(() => {
    setFileInfo({ type: 0, image: room.image });
  }, [room]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [fileInfo, setFileInfo] = useState(undefined); //type, file   berib yuboramiz.
  const [progress, setProgress] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUploadFile = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    try {
      const res = await axios.post(
        BaseUrl + "/api/teacher/practice/file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      setFileInfo(res.data);
    } catch (e) {
      toast("Xatolik");
    }
  };

  const onSubmit = async (data) => {
    let newData;

    fileInfo !== undefined
      ? (newData = { ...data, image: fileInfo.image, id: room.id })
      : (newData = { ...data, id: room.id });

    const res = await POST.addRoom(newData);
    if (res.success) {
      handlerGetAllRooms();
      toast.success("Yaratildi");
      handleClose();
      refresh();
      return;
    }

    toast.error("Xatolik");
  };

  const refresh = () => {
    reset();
    setFileInfo(undefined);
  };

  return (
    <>
      <TableRow
        key={room.name + room.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell align={"center"}>{room.name}</TableCell>
        <TableCell align={"center"}>
          <img
            src={FileBaseUrl + room.image}
            style={{ objectFit: "contain" }}
            width={"220px"}
            height={"150px"}
          />
        </TableCell>
        {(localStorage.getItem("role") === "admin" ||
          localStorage.getItem("role") === "superadmin") && (
            <TableCell align={"center"} width={"150px"}>
              <Tooltip title={"Tahrirlash"}>
                <IconButton color={"warning"} onClick={handleOpen}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <DeleteRoomModal id={room.id} refresh={handlerGetAllRooms}/>
            </TableCell>
          )}
      </TableRow>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <TextField
                label={"Xona nomini kiriting:"}
                fullWidth
                defaultValue={room.name}
                {...register("name", {
                  required: "Bo`sh bo`lishi mumkin emas.",
                  minLength: {
                    value: 2,
                    message: " 2 ta belgidan kam bo`lmasligi kerak",
                  },
                  maxLength: {
                    value: 101,
                    message: "100 ta belgidan ortiq bo`lishi mumkin emas",
                  },
                })}
              />
              {errors.value && (
                <Typography color={"error"}>{errors.value.message}</Typography>
              )}
            </Box>

            {fileInfo === undefined && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#d9dbe0",
                  padding: "8px",
                  justifyContent: "center",
                }}
              >
                <label>
                  <input
                    style={{ display: "none" }}
                    type={"file"}
                    accept={"image/png, image/gif, image/jpeg"}
                    onChange={handleUploadFile}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={"/assets/icon/upload.svg"}
                      width={"24px"}
                      height={"24px"}
                    />
                    <span>Faylni yuklang</span>
                  </div>
                </label>
              </Box>
            )}

            <Box width={"100%"}>
              {progress > 0 && 100 > progress && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      backgroundColor: "gray",
                      width: progress + "%",
                      height: "4px",
                    }}
                  ></div>
                  <span>{progress}%</span>
                </Box>
              )}
            </Box>

            {fileInfo !== undefined && (
              <Box>
                {fileInfo.type === 0 && (
                  <Box>
                    <img
                      style={{ objectFit: "contain" }}
                      width={"100%"}
                      height={"200px"}
                      src={FileBaseUrl + fileInfo.image}
                    />
                  </Box>
                )}
                {fileInfo.type === 1 && (
                  <Box>
                    <ReactPlayer
                      width={"100%"}
                      height={"200px"}
                      controls={true}
                      url={FileBaseUrl + fileInfo.image}
                    />
                  </Box>
                )}
                {fileInfo.type === 2 && (
                  <Box>
                    <a
                      href={FileBaseUrl + fileInfo.image}
                      download
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img src={"/assets/icon/file.svg"} width={"50px"} />
                    </a>
                  </Box>
                )}
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    onClick={() => setFileInfo(undefined)}
                    color={"error"}
                    variant={"contained"}
                    sx={{ marginTop: "4px" }}
                  >
                    O`chirish
                  </Button>
                </Box>
              </Box>
            )}

            <Box display={"flex"} gap={"4px"} justifyContent={"center   "}>
              <Button
                type={"submit"}
                color={"success"}
                variant={"contained"}
                disabled={progress > 0 && progress < 100 && true}
              >
                Saqlash
              </Button>
              {/*<Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>*/}
              <Button
                onClick={handleClose}
                color={"error"}
                variant={"contained"}
              >
                Yopish
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
