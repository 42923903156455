import GET from "../../components/api/GET";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {checkAllApi, FileBaseUrl} from "../../components/api/DefaultApi";
import Box from "@mui/material/Box";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ReactPlayer from "react-player";
import TableContainer from "@mui/material/TableContainer";
import {getDateInfo} from "../Chat";


export default function PaymentType(){


    const [paymnetList, setPaymentList]=useState([]);
    const [loader, setLoader]=useState(true);


    const handlerPaymentsType=async ()=>{
        const res=await GET.getPaymentsType();
        checkAllApi(res);
        if (res.success){
            setPaymentList(res.res.data.payments);
            setLoader(false);
        }else if (!res.success){
            toast.error("To`lov turlarini olishda xatolik");
            setLoader(false);
        }
    }

    useEffect(()=>{
        handlerPaymentsType();
    }, [])



    return (
        <>
            {loader?
                <LoadingBeatLoader/>
                :
                <Box>
                    {paymnetList.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Nomi</TableCell>
                                            <TableCell align={"center"}>Type</TableCell>
                                            <TableCell align={"center"}>Foto</TableCell>
                                            <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paymnetList.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.name}</Typography>
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    {value1.type}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                        <Box>
                                                            <img style={{objectFit: "contain"}} width={"200px"} height={"200px"} src={FileBaseUrl+value1.image}/>
                                                        </Box>
                                                </TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        :
                        <Box>
                            <Typography>To`lov turlari mavjud emas</Typography>
                        </Box>
                    }
                </Box>
            }


        </>
    );
}