import * as React from "react";
import {
    handlerGetProfissions,
    handlerGetRoles,
    handlerGetSalaryType,
    handlerGetStudents, handlerGetWorkers,
    handlerLevels
} from "../../redux/functions";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Groups from "../group/GroupsMenu";
import { Avatar, Button, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GET from "../api/GET";
import { AvatarBaseUrl } from "../api/DefaultApi";
import HomeIcon from "@mui/icons-material/Home";
import { MenuData } from "./MenuData";
import CachedIcon from '@mui/icons-material/Cached';

const drawerWidth = 240;

const refreshItems=[
    {title: "Dars darajalarini yangilash", task:handlerLevels()},
    {title: "Kasblarni yangilash", task: handlerGetProfissions()},
    {title: "Talabalarni yangilash", task:handlerGetStudents()},
    {title: "Rolelarni yangilash", task: handlerGetRoles()},
    {title: "Oylik turlarini yangilash", task: handlerGetSalaryType()},
    {title:"Ishchilarni yangilash",task:handlerGetWorkers()}
];

export default function Dashboard(props) {
  const [userInfo, setUserInfo] = useState();

  const handlerGetUserInfo = async () => {
    const res = await GET.getUserInfo();
    if (res.success) {
      // console.log(res);
      setUserInfo(res.res.data.user);
    }
  };

  const location = useLocation();

  useEffect(() => {
    handlerGetUserInfo();
  }, []);

  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar style={{ padding: 8 }}>
        <Box
          display={"flex"}
          gap={"8px"}
          flexDirexction={"space-between"}
          // sx={{
          //     "&:hover": {
          //         cursor: "pointer"
          //     }
          // }}
          // onClick={() => navigate("/")}
        >
          <Avatar src={userInfo && AvatarBaseUrl + userInfo.avatar} />
          <Box display={"flex"} alignItems={"start"} flexDirection={"column"}>
            <Typography fontSize={"16px"}>
              {userInfo && userInfo.name}
            </Typography>
            <Typography fontSize={"14px"}>
              {userInfo && userInfo.phone}
            </Typography>
          </Box>
        </Box>
      </Toolbar>

      <Divider />
      <ListItem
        selected={location.pathname === "/"}
        disablePadding
        onClick={() => navigate(`/`)}
      >
        <ListItemButton>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItemButton>
      </ListItem>

      <Divider />

      {localStorage.getItem("role") === "teacher" && (
        <>
          <Groups />
          <Divider />
        </>
      )}

      {MenuData.map((value) => (
        <>
          {value.role.includes(localStorage.getItem("role")) && (
            <Link to={value.path} style={{ textDecoration: "none" }}>
              <ListItem
                selected={location.pathname === value.path}
                key={value.path}
                disablePadding
                sx={{
                  color: location.pathname === value.path ? "" : "grey",
                  fontWeight: location.pathname === value.path ? 800 : 400,
                }}
              >
                <ListItemButton>
                  <ListItemIcon>{value.icon}</ListItemIcon>
                  <ListItemText primary={value.name} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Link>
          )}
        </>
      ))}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box style={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        // style={{backgroundColor:"white"}}
      >
        <Toolbar sx={{display:'flex', justifyContent:'space-between'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }}}
          >
            <MenuIcon />
          </IconButton>
          <Box display={"flex"} justifyContent={"space-between"} gap={5} witdh={"100%"} alignItems={"center"}>
              <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  onClick={() => navigate("/")}
              >
                  Boshqaruv paneli
              </Typography>
              <Box>
                  <Link to={"/sms/add"} >
                      <Button variant={"contained"} color={"success"}>
                          SMS jo`natish
                      </Button>
                  </Link>
              </Box>

              <Box>
                  <Link to={"/notification/add"} >
                      <Button variant={"contained"} color={"success"}>
                          Notificatsiya jo`natish
                      </Button>
                  </Link>
              </Box>

              {refreshItems.map((value)=>
                  <Tooltip title={value.title}>
                      <IconButton color={"inherit"} onClick={()=>value.task}>
                          <CachedIcon/>
                      </IconButton>
                  </Tooltip>
              )}


          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,

              //TODO bu sidebarni rangini o`zgartiradi.
              // backgroundColor:"#c4c8c9", color:"inherit"
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // backgroundColor:"#E4F8FF",
          maxHeight: "100%",
          minHeight: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
