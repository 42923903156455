import {GridLoader} from "react-spinners";
import Box from "@mui/material/Box";


export default function Loader(){

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{height:"100%"}}>
            <GridLoader color="#3e4dd8" size={50}/>
        </Box>
    );
}