import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useEffect, useState} from "react";
import LoadingBeatLoader from "../../LoadingBeatLoader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Kirim chiqim diagrammasi',
        },
    },
};

// const labels=[]
// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Xarajatlar',
//             data: [10, 12, 15, 16, 19, 17, 10],
//             borderColor: 'rgb(255, 99, 132)',
//             backgroundColor: 'rgba(255, 99, 132, 0.5)',
//         },
//         {
//             label: 'Tushumlar',
//             data: [15, 20, 25, 28, 50, 15, 90],
//             borderColor: 'rgb(53, 162, 235)',
//             backgroundColor: 'rgba(53, 162, 235, 0.5)',
//         },
//     ],
// };

export default function ChartjsLine({expenseList, revenueList}){


    const [loader, setLoader]=useState(true);
    
    const [data, setData]=useState({});


    useEffect(() => {
        const labels=[];
        const expenseListData=[];
        const revenueListData=[];
        for (let i = 0; i < expenseList.length; i++) {
            labels.push(expenseList[i].date);
            expenseListData.push(expenseList[i].sum);
        }

        for (let i = 0; i < revenueList.length; i++) {
            revenueListData.push(revenueList[i].sum);
        }

        const handlerData={labels:labels,
            datasets:[
                {
                    label: 'Xarajatlar',
                    data: expenseListData,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'Tushumlar',
                    data: revenueListData,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            ]
        };


        setData(handlerData);
        setLoader(false);
    }, [expenseList, revenueList]);

    console.log(data);

    return (
        <>
            <div>
                {loader?
                    <LoadingBeatLoader/>:
                    <Line options={options} data={data}/>
                }
            </div>
        </>
    );
}