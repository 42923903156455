import GET from "../../components/api/GET";
import {useEffect, useState} from "react";
import ChartjsLine from "../../components/ChartList/ChartjsLine";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import toast from "react-hot-toast";
import {formatPrice} from "../../components/Other/useWindowSize";

const months=[
    {id:1, name:"Yanvar"},
    {id:2, name:"Fevral"},
    {id:3, name:"Mart"},
    {id:4, name:"Aprel"},
    {id:5, name:"May"},
    {id:6, name:"Iyun"},
    {id:7, name:"Iyul"},
    {id:8, name:"Avgust"},
    {id:9, name:"Sentabr"},
    {id:10, name:"Oktabr"},
    {id:11, name:"Noyabr"},
    {id:12, name:"Dekabr"},
]

const firstYear=2023;


const boxStyle={
    display:"flex",
    justifyContent:"space-between",
    width:"100%",
    padding:"8px",
    backgroundColor: "#fff151"
}

export default function StatisticMoney(){



    const [loader, setLoader]=useState(true);
    const [revenueList, setRevenueList]=useState([]);
    const [revenueSum, setRevenueSum]=useState(0);
    const [expenseList, setExpenseList]=useState([]);
    const [expenseSum, setExpenseSum]=useState(0);

    const [years, setYears]=useState([]);
    const [year, setYear]=useState("");
    const [isYearError, setisYearError]=useState(false);
    const [month, setMonth]=useState("");
    const [isMountError, setisMountError]=useState(false);

    const getInformation=()=>{
        const date=new Date();
        const handlerYear=date.getFullYear();
        const handlerMonth=date.getMonth()+1;
        setYear(handlerYear)
        setMonth(handlerMonth);
        let fullYear = date.getFullYear();
        const handlerYearList=[firstYear];

        for (let i = firstYear+1; i < fullYear; i++) {
            handlerYearList.push(i);
        }

        if (fullYear!==firstYear){
            handlerYearList.push(fullYear);
        }

        setYears(handlerYearList);
    }

    const handlerGetMoneyInformation=async ()=>{
        const res=await GET.getAllMoneyInformation({year:year, month:month});
        if (res.success){
            setRevenueList(res.res.data.revenueList);
            setRevenueSum(res.res.data.revenueSum);
            setExpenseList(res.res.data.expenseList);
            setExpenseSum(res.res.data.expenseSum);
            setLoader(false);
        }else if (!res.success) {
            toast.error("Xatolik");
            setLoader(false);
        }
    }

    console.log(revenueList);
    console.log(expenseList);


    useEffect(() => {
        getInformation()
        handlerGetMoneyInformation();
    }, []);

    useEffect(() => {
        handlerGetMoneyInformation();
    }, [year, month]);



    return (
        <>
            <div>
                {loader?
                    <LoadingBeatLoader/>
                    :
                    <Box>
                        <Box>
                            <FormControl fullWidth sx={{display:"flex", flexDirection:"row", gap:4}}>
                                <Box width={"100%"}>
                                    <Typography></Typography>
                                    Yilni tanlang
                                    <Select fullWidth
                                            value={year}
                                            onChange={(event)=>{
                                                setYear(event.target.value);
                                                setisYearError(false);
                                            }}
                                    >
                                        {years.map((value, index) =>
                                            <MenuItem value={value}>{value}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography>

                                    </Typography>
                                </Box>
                                <Box width={"100%"}>
                                    <Typography></Typography>
                                    Oyni tanlang
                                    <Select fullWidth
                                            value={month}
                                            onChange={(event)=>{
                                                setMonth(event.target.value);
                                                setisYearError(false);
                                            }}
                                    >
                                        {months.map((value, index) =>
                                            <MenuItem value={value.id}>{value.name}</MenuItem>
                                        )}
                                    </Select>
                                    <Typography>

                                    </Typography>
                                </Box>
                            </FormControl>
                        </Box>
                        <ChartjsLine expenseList={expenseList} revenueList={revenueList}/>


                        <Box sx={{display:"flex", justifyContent:"space-between", gap:"50px"}}>
                            <Box sx={boxStyle} >
                                <Typography>
                                    Tushumlar:
                                </Typography>

                                <Typography>
                                    {formatPrice(revenueSum)}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography>
                                    Chiqimlar:
                                </Typography>

                                <Typography>
                                    {formatPrice(expenseSum)}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography>
                                    Daromad:
                                </Typography>

                                <Typography>
                                    {formatPrice(revenueSum-expenseSum)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                }
            </div>
        </>
    );
}