import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddNotificationModal from "../modals/AddNotificationModal";
import GET from "../api/GET";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {getDateInfo} from "../../page/Chat";
import {Pagination, Tooltip} from "@mui/material";
import {FileBaseUrl} from "../api/DefaultApi";
import ReactPlayer from "react-player";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNotificationModal from "../modals/EditNotificationModal";
import DELETE from "../api/DELETE";
import toast from "react-hot-toast";


export default function Notification(){


    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");




    const [page, setPage]=useState(0);
    const [loader, setLoader]=useState(false);
    const [lastPage, setLastPage]=useState(1);

    const handlerGetNotification=async ()=>{
        const res=await GET.getByIdNotification(groupId, page);
        if (res.success){
            setData(res.res.data.notifications.data);
            setLastPage(res.res.data.notifications.last_page);
        }
    }

    useEffect(()=>{
            handlerGetNotification();
    }, [page, groupId])

    const [data, setData]=useState(undefined);

    const handlerDeleteNotification=async (id)=>{
        const res=await DELETE.deleteNotification(id);
        if (res.success){
            toast.success("O`chirildi. ");
            handlerGetNotification();
        }
    }


    return (
        <>
            <Box sx={{display:'flex',justifyContent:"space-between"}}>
                <Typography component={"h2"} variant={"h6"}>Notification</Typography>
                {localStorage.getItem("role")==="teacher"&&
                    <AddNotificationModal handlerGetNotification={handlerGetNotification} page={page} group_id={groupId}/>
                }
            </Box>



            <Box width={"100%"}>
                <Box sx={{marginY:"4px"}}>
                    {data!==undefined&&data.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Title</TableCell>
                                            <TableCell align={"center"}>Description</TableCell>
                                            <TableCell align={"center"}>Fayl</TableCell>
                                            <TableCell align={"center"}>Yozilgan vaqti vaqti</TableCell>
                                            <TableCell align={"center"}>O`zgartirilgan vaqti</TableCell>
                                            {localStorage.getItem("role")==="teacher"&&
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data&&data.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.title}</Typography>
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    <Typography
                                                        dangerouslySetInnerHTML={{__html: value1.description?value1.description:"Mavjud emas"}}
                                                    >
                                                        {/*{lesson.description?lesson.description:"Aniqlanmadi"}*/}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {value1.type==0&&
                                                        <Box>
                                                            <img style={{objectFit: "contain"}} width={"100%"} height={"200px"} src={FileBaseUrl+value1.file}/>
                                                        </Box>
                                                    }
                                                    {value1.type==1&&
                                                        <Box>
                                                            <ReactPlayer width={"100%"}
                                                                         height={"200px"}
                                                                         controls={true}
                                                                         url={FileBaseUrl+value1.file}/>
                                                        </Box>
                                                    }
                                                    {value1.file===null&&<Typography>Mavjud emas</Typography>}
                                                </TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                {localStorage.getItem("role")==="teacher"&&
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>
                                                            <EditNotificationModal page={page} handlerGetNotification={handlerGetNotification} notification={value1}/>
                                                            <Tooltip title={"O`chirish"}>
                                                                <IconButton color={"error"} onClick={()=>handlerDeleteNotification(value1.id)}>
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Tooltip>

                                                        </Box>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>Notification mavjud emas</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    );
}