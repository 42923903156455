import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import AddOrEditExamTest from "../../components/modals/AddOrEditExamTest";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatPrice} from "../../components/Other/useWindowSize";
import {getDateInfo} from "../Chat";
import AddOrEditWorkerBonusModal from "../../components/modals/AddOrEditWorkerBonusModal";
import DeleteWorkerBonusesModal from "../../components/modals/DeleteModal/DeleteWorkerBonusesModal";
import TableContainer from "@mui/material/TableContainer";
import Loader from "../Loader";
import DeleteExamTestModal from "../../components/modals/DeleteModal/DeleteExamTestModal";


export default function ExamTest(){

    const lessonLevelList=useSelector(state => state.otherInfoList.levels);
    const [tab, setTab]=useState(0);
    const [activeFolder, setActiveFolder]=useState(null);

    const [questionList, setQuestionList]=useState([]);

    const [loader, setLoader]=useState(true);

    const handlerQuestionList=async ()=>{
        setLoader(true)
        const res=await GET.getAllQuestionByLevel(lessonLevelList[activeFolder].id);
        if (res.success){
            setQuestionList(res.res.data.examQuestions);
            setLoader(false)
        }else {
            toast.error("Testlarni olishda xatolik");
        }
    }

    useEffect(()=>{
        if (activeFolder){
            handlerQuestionList();
        }
    }, [activeFolder])

    return (
        <>
            {tab==0&&
                <div>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
                        gridGap: "10px"
                    }}>
                        {lessonLevelList.map((level, index) => {
                            return <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100px",
                                flexDirection: "column",
                                userSelect: "none",
                                opacity: (activeFolder == index) ? "0.5" : "1"
                            }}
                                        onClick={() => setActiveFolder(index)}
                                        onDoubleClick={() => {
                                            setTab(1);
                                            setActiveFolder(index)
                                        }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="#FFF">
                                    <path fill="#FFB900"
                                          d="M45,24l-4.2426-4.2426C39.6321,18.6321,38.106,18,36.5147,18H9c-1.6569,0-3,1.3431-3,3v56 c0,0.5523,0.4477,1,1,1h82c0.5523,0,1-0.4477,1-1V27c0-1.6569-1.3431-3-3-3H45z"/>
                                    <path fill="#FFD75E"
                                          d="M45,24l-4.2426,4.2426C39.6321,29.3679,38.106,30,36.5147,30H6v47c0,0.5523,0.4477,1,1,1h82 c0.5523,0,1-0.4477,1-1V27c0-1.6569-1.3431-3-3-3H45z"/>
                                    <linearGradient id="a" x1="48" x2="48" y1="24" y2="78"
                                                    gradientUnits="userSpaceOnUse">
                                        <stop offset="0" stop-color="#fff" stop-opacity="0"/>
                                        <stop offset="1" stop-color="#ffd75e" stop-opacity=".3"/>
                                    </linearGradient>
                                    <path fill="url(#a)"
                                          d="M45,24l-4.2426,4.2426C39.6321,29.3679,38.106,30,36.5147,30H6v47c0,0.5523,0.4477,1,1,1h82 c0.5523,0,1-0.4477,1-1V27c0-1.6569-1.3431-3-3-3H45z"/>
                                    <path opacity=".4"
                                          d="M6,30v1h30.6005c1.8565,0,3.637-0.7375,4.9497-2.0503L46.5,24H45l-4.2426,4.2426 C39.6321,29.3679,38.106,30,36.5147,30H6z"/>
                                    <path fill="#DA7B16"
                                          d="M89,78H7c-0.5523,0-1-0.4477-1-1l0,0h84l0,0C90,77.5523,89.5523,78,89,78z"/>
                                </svg>
                                <label style={{
                                    userSelect: "none",
                                    textAlign: "center"
                                }}>
                                    {level.name}
                                </label>

                            </div>
                        })}
                    </div>
                </div>
            }

            {tab==1&&
                <div>
                    <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginY:1}} >
                        <Button variant={"contained"} onClick={()=>{
                            setActiveFolder(null);
                            setTab(0);
                            setQuestionList([]);
                        }}>
                            Darajalarga qaytish
                        </Button>
                        <Typography>
                            {lessonLevelList[activeFolder].name} darajasi uchun test savollari
                        </Typography>
                        <AddOrEditExamTest
                            type={"add"}
                            level={lessonLevelList[activeFolder]}
                            refresh={handlerQuestionList} />
                    </Box>


                    {loader?
                        <Loader/>
                        :
                        <Box>
                            {questionList.length>0?

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead sx={{backgroundColor:"grey"}}>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Savol</TableCell>
                                                <TableCell align={"center"}>To'g'ri javob</TableCell>
                                                <TableCell align={"center"}>Variantlar</TableCell>
                                                <TableCell align={"center"}>Sanasi</TableCell>
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {questionList&&questionList.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                        "&:hover":{ backgroundColor:"#cbcbcb" }
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {value1.question}
                                                    </TableCell>
                                                    <TableCell align={"left"} >
                                                        {value1.test_question_option.map((option)=>{
                                                            if (option.type==1){
                                                                return <Typography>
                                                                    {option.value}
                                                                </Typography>
                                                            }
                                                        })}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {value1.test_question_option.map((option, index)=>{
                                                            let count=0;
                                                            if (option.type==0){
                                                                return <Typography>
                                                                    {(++count)} {option.value}
                                                                </Typography>
                                                            }
                                                        })}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {getDateInfo(value1.created_at)}
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>
                                                            <AddOrEditExamTest type={"edit"}
                                                                               refresh={handlerQuestionList}
                                                                               level={lessonLevelList[activeFolder]}
                                                                               question={value1}

                                                            />
                                                            <DeleteExamTestModal refresh={handlerQuestionList}
                                                                                 id={value1.id}/>

                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :<Typography>
                                    Test mavjud emas
                                </Typography>
                            }
                        </Box>


                    }
                </div>
            }
        </>
    );
}