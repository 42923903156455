import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import {checkAllApi} from "../../api/DefaultApi";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import {Button, TextField, InputLabel, MenuItem, Select} from "@mui/material";
import Typography from "@mui/material/Typography";
import GET from "../../api/GET";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};


export const days=["Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba", "Yakshanba"]




export default function AddScheduleModal({handlerGetGroupSchedule, groupId, rooms}){
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {


        const newData={...data, group_id:groupId};
        const res=await POST.addClassTime(newData);
        checkAllApi(res);
        if (res.success){
            handlerGetGroupSchedule()
            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }




    return (
        <>

            <Box>
                <Button variant={"contained"} color={"success"} onClick={handleOpen}>
                    Dars qo`shish
                </Button>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box width={"100%"}>
                            <InputLabel id="demo-multiple-name-label">Kun tanlang</InputLabel>
                            <Select sx={{width:"100%"}}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    MenuProps={MenuProps}
                                    {...register("day", {required:"Kunni tanlash kerak"})}
                            >
                                {days.map((value) => (
                                    <MenuItem
                                        key={value}
                                        value={value}
                                    >
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.day&&<Typography color={"error"}>{errors.day.message}</Typography>}
                        </Box>


                        <Box>
                            <Typography>Dars boshlanish vaqtini kiriting:</Typography>
                            <TextField
                                fullWidth type={"time"}
                                {...register("time",
                                    {required:"Vaqti bo`sh bo`lishi mumkin emas."})}
                            />

                            {errors.time&&<Typography color={"error"}>{errors.time.message}</Typography>}

                        </Box>

                        <Box>
                            <Typography>Dars tugash vaqtini kiriting:</Typography>
                            <TextField
                                fullWidth type={"time"}
                                {...register("end_time",
                                    {required:"Vaqti bo`sh bo`lishi mumkin emas."})}
                            />

                            {errors.time&&<Typography color={"error"}>{errors.time.message}</Typography>}

                        </Box>

                        <Box width={"100%"}>
                            <InputLabel id="demo-multiple-name-label">Xonani tanlang</InputLabel>
                            <Select sx={{width:"100%"}}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    MenuProps={MenuProps}
                                    {...register("room_id", {required:"Xonani tanlash shart"})}
                            >
                                {rooms.map((value) => (
                                    <MenuItem
                                        key={value.id}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.day&&<Typography color={"error"}>{errors.day.message}</Typography>}
                        </Box>



                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                            {/*<Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>*/}
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}