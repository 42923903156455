import {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UsersForAdmin from "../../components/UsersForAdmin";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function UserPageForAdmin(){


    const [searchParams, setSearchParams] = useSearchParams();

    const tab=searchParams.get("tab")



    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchParams({tab:newValue})
    };



    const roles=useSelector(state => state.otherInfoList.roles);

    useEffect(()=>{
        if (tab!=="null"){
            setValue(parseInt(tab));
        }
        if (tab==="null"||tab===null){
            setValue(0);
        }
    }, []);



    return (
        <>
            <Box sx={{ maxWidth: { xs: "320px", sm: "100%" }, bgcolor: 'background.paper' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >

                    {roles.map((role, index)=>
                        <Tab label={role.display_name} {...a11yProps(index)} />
                    )}

                    {/*<Tab label="Adminlar" {...a11yProps(0)} />*/}
                    {/*<Tab label="O`qituvchilar" {...a11yProps(1)} />*/}
                    {/*<Tab label="Talabalar" {...a11yProps(2)} />*/}
                </Tabs>

                {roles.map((role, index)=>
                    <TabPanel value={value} index={index}>
                        <Box>
                            <UsersForAdmin role={role} handlerStatus={"active"} statusFilter={true}/>
                        </Box>
                    </TabPanel>
                )}

                {/*<TabPanel value={value} index={0}>*/}
                {/*    <Box>*/}
                {/*        <UsersForAdmin role={"admin"}/>*/}
                {/*    </Box>*/}
                {/*</TabPanel>*/}



                {/*<TabPanel value={value} index={1}>*/}
                {/*    <Box>*/}
                {/*        <UsersForAdmin role={"teacher"}/>*/}
                {/*    </Box>*/}
                {/*</TabPanel>*/}

                {/*<TabPanel value={value} index={2}>*/}
                {/*    <Box>*/}
                {/*        <UsersForAdmin role={"user"}/>*/}
                {/*    </Box>*/}
                {/*</TabPanel>*/}
            </Box>

        </>
    );
}