import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import AddFileModal from "../../components/modals/AddFileModal";
import FileTab from "../../components/FileTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const types = [
  { id: 0, name: "Books" },
  { id: 1, name: "Audio" },
  { id: 2, name: "Videos" },
  { id: 3, name: "Movies" },
  { id: 4, name: "Podcasts" },
  { id: 5, name: "Cartoons" },
];


export default function Files() {

  const params=useParams();
  console.log(params.id);

  let [searchParams, setSearchParams] = useSearchParams();
  const level_name=searchParams.get("name");

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loader, setLoader] = useState(false);

  useEffect(() => {}, []);

  return (
    <Box>
      {loader ? (
        <LoadingBeatLoader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>{level_name}</Typography>
            {(localStorage.getItem("role") === "admin" ||
              localStorage.getItem("role") === "superadmin") && (
              <AddFileModal types={types} typeActive={types[value].name} />
            )}
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {types.map((value1, index) => (
                <Tab
                  key={value1.id}
                  label={value1.name}
                  {...a11yProps(value1.id)}
                />
              ))}
            </Tabs>
          </Box>

          <TabPanel value={value} index={value}>
            <FileTab type={types[value].name} level={params.id} />
          </TabPanel>
        </Box>
      )}
    </Box>
  );
}
