import axios from "axios";
import { BaseUrl } from "./DefaultApi";
import Schedule from "../../page/Schedule";

const token = localStorage.getItem("token");

const headers = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Bearer ${token}`,
  },
};

const getAllGroupsByTeacherId = BaseUrl + "/api/teacher/groups"; // Barcha guruhlarni olish
const getByIdGroup = BaseUrl + "/api/teacher/group"; // Bitta guruhni olish
const getByIdLesson = BaseUrl + "/api/teacher/lesson"; // Bitta guruhni olish
const getAllGroups = BaseUrl + "/api/teacher/group/all_groups"; // Bitta guruhni olish
const getAllRooms = BaseUrl + "/api/teacher/rooms"; // Bitta guruhni olish
const getAllChats = BaseUrl + "/api/teacher/chat"; // Hamma chatni olish
const getChatById = BaseUrl + "/api/teacher/chat"; // Bitta chatni olish
const getUserInfo = BaseUrl + "/api/profile/user-info"; // Bitta chatni olish
const getFiles = BaseUrl + "/api/teacher/uploaded/get_files"; // Bitta chatni olish
const getMainLessons = BaseUrl + "/api/teacher/mainlessons"; // Bitta chatni olish
const getMainLesson = BaseUrl + "/api/teacher/get-mainlesson?mainlesson_id="; // Bitta chatni olish
const getByStudentDiscount = BaseUrl + "/api/teacher/discounts/student?student_id="; // Bitta chatni olish
const getByIdNotification = BaseUrl + "/api/teacher/notifications-in-group?group_id="; // Bitta chatni olish
const getByUserPractise = BaseUrl + "/api/teacher/practice/student?student_id="; // Bitta chatni olish
const getByUserExercise = BaseUrl + "/api/teacher/exercise/student?student_id="; // Bitta chatni olish
const getAllUserForAdmin = BaseUrl + "/api/admin/students"; // Admin sifatida barcha userlarni olish
const getAllGroupForNotification = BaseUrl + "/api/admin/all-groups"; // Admin sifatida barcha userlarni olish
const getAllNewsForAdmin = BaseUrl + "/api/admin/all-news?page="; // Admin sifatida barcha userlarni olish
const getAllNotification = BaseUrl + "/api/admin/all-notifications?page="; // Admin sifatida barcha userlarni olish
const getAllScheduleForGroups=BaseUrl+"/api/admin/schedules?page=";
const getImportantNotes=BaseUrl+"/api/teacher/important-notes";
const getAllDiscountsForAdmin=BaseUrl+"/api/teacher/all-discounts";
const getAllRoles=BaseUrl+"/api/profile/get-roles";
const getAllAdminDashboard=BaseUrl+"/api/admin/dashboard";
const getAllGroupsForAdmin=BaseUrl+"/api/admin/get-groups?page=";
const checkNumberOrEmail=BaseUrl+"/api/profile/validatsiya";
const getAllUserForAdminByRole =BaseUrl+"/api/profile/all-users";
const getAllTeachers =BaseUrl+"/api/admin/teachers";
const getUserById=BaseUrl+"/api/profile/user-info-by-id";
const getRoomStatus=BaseUrl+"/api/admin/dashboard/schedules";
const getPaymentsType=BaseUrl+"/api/admin/payments";
const userIdPayments=BaseUrl+"/api/admin/get-payment/user";
const getAllPayments=BaseUrl+"/api/admin/orders";
const getBattleInfoByGroupId=BaseUrl+"/api/student/group-battles"
const getAllUsers=BaseUrl+"/api/admin/all-users";
const getAllSMS=BaseUrl+"/api/admin/all-sms?page=";
const getAllOutputType=BaseUrl+ "/api/admin/all-expense";
const getAllOutput=BaseUrl+"/api/admin/all-output";
const getAllProfession=BaseUrl+"/api/salary/profession";
const getAllSalaryType=BaseUrl+"/api/salary/user-items/calc-salary-type";
const getAllSalaryUserItems=BaseUrl+"/api/salary/user-items"
const getAllUsersOtherStudent=BaseUrl+"/api/salary/users"   // studentdan boshqa userlarni olish uchun
const getAllWorkerBonus=BaseUrl+"/api/salary/bonuses";
const getAllWorkerAdvances=BaseUrl+"/api/salary/advances";
const getUserSalary=BaseUrl+"/api/salary/user-salary"; // joriy oydagi oyligini ko`radi
const getUserSalaryHistory=BaseUrl+"/api/salary/salaries"; // oyliklarini tarixini ko`radi
const getAllNewOrderForStudent=BaseUrl+"/api/admin/students/order"; // oyliklarini tarixini ko`radi
const getOrderPayments=BaseUrl+"/api/admin/order"; // oyliklarini tarixini ko`radi
const getAllLessonLevel=BaseUrl+"/api/admin/lesson/all-level"; // oyliklarini tarixini ko`radi
const getAllMainLessonByLevelId=BaseUrl+"/api/admin/lesson/level"; // oyliklarini tarixini ko`radi
const getAllMoneyInformation=BaseUrl+"/api/salary/revenues"; // oyliklarini tarixini ko`radi
const getByIdOutput=BaseUrl+"/api/admin/output"; // oyliklarini tarixini ko`radi
const getUserMotions=BaseUrl+"/api/user-motion/motions";
const getSalaryItemsByUserId=BaseUrl+"/api/salary/user-items/user";
const getSalaryIdSalaryMoney=BaseUrl+"/api/salary/user-items/statistics";
const getCardList=BaseUrl+"/api/salary/cards";
const getInfoGroups=BaseUrl+"/api/admin/filter/groups"
const getStudentsByStatus=BaseUrl+"/api/admin/get-students?status="
const getDiscountsLevel=BaseUrl+"/api/admin/battle-discounts";
const getStudentsForGroup=BaseUrl+"/api/teacher/group/get-students/";
const getAllLessonStatusStudent =BaseUrl+"/api/teacher/discounts-for-lessons";
const getAllLessonStatusStudentList =BaseUrl+"/api/teacher/all/lesson-students/";
const getAllQuestionByLevel=BaseUrl+"/api/exam/test/questions";
const getAllExam=BaseUrl+"/api/exam/all";
const getAllExamId=BaseUrl+"/api/exam/";
const getAllExamGroup=BaseUrl+"/api/exam/all-exams/groups";
const getAllSMSMessage=BaseUrl+"/api/teacher/all-sms/to-parents?lesson_id="
const getAllPaymentsType=BaseUrl+"/api/admin/payment-transactions";




const GET = {
  getAllGroupsByTeacherId: async () => {
    try {
      const res = await axios.get(getAllGroupsByTeacherId, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getStudentsForGroup: async (id) => {
    try {
      const res = await axios.get(getStudentsForGroup+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllSMSMessage: async (id) => {
    try {
      const res = await axios.get(getAllSMSMessage+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllExamId: async (id) => {
    try {
      const res = await axios.get(getAllExamId+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllExam: async () => {
    try {
      const res = await axios.get(getAllExam, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllExamGroup: async () => {
    try {
      const res = await axios.get(getAllExamGroup, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllQuestionByLevel: async (levelId) => {
    try {
      const res = await axios.get(getAllQuestionByLevel+(levelId?"?level_id="+levelId:""), headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllLessonStatusStudentList: async (id) => {
    try {
      const res = await axios.get(getAllLessonStatusStudentList+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllLessonStatusStudent: async () => {
    try {
      const res = await axios.get(getAllLessonStatusStudent, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getSalaryIdSalaryMoney: async (id) => {
    try {
      const res = await axios.get(getSalaryIdSalaryMoney+"/"+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getDiscountsLevel: async (id) => {
    try {
      const res = await axios.get(getDiscountsLevel, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getStudentsByStatus: async (status) => {
    try {
      const res = await axios.get(getStudentsByStatus+status, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getCardList: async () => {
    try {
      const res = await axios.get(getCardList, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getBattleInfoByGroupId: async (id, page) => {
    try {
      const res = await axios.get(getBattleInfoByGroupId+"/"+id+"?page="+page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getSalaryItemsByUserId: async (id) => {
    try {
      const res = await axios.get(getSalaryItemsByUserId+"/"+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getUserMotions: async (param, value) => {
    try {
      const res = await axios.get(getUserMotions+`?${param}=`+value, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getByIdOutput: async (id) => {
    try {
      const res = await axios.get(getByIdOutput+"/"+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllMainLessonByLevelId: async (id, page) => {
    try {
      const res = await axios.get(getAllMainLessonByLevelId+"/"+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllMoneyInformation: async (params) => {
    try {
      const res = await axios.get(getAllMoneyInformation, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllGroups: async () => {
    try {
      const res = await axios.get(getAllGroups, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllLessonLevel: async () => {
    try {
      const res = await axios.get(getAllLessonLevel, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },



  getAllUsersOtherStudent: async () => {
    try {
      const res = await axios.get(getAllUsersOtherStudent, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllUsers: async () => {
    try {
      const res = await axios.get(getAllUsers, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllProfession: async () => {
    try {
      const res = await axios.get(getAllProfession, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getRoomStatus: async () => {
    try {
      const res = await axios.get(getRoomStatus, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getOrderPayments: async (id) => {
    try {
      const res = await axios.get(getOrderPayments+"/"+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllRooms: async () => {
    try {
      const res = await axios.get(getAllRooms, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getUserInfo: async () => {
    try {
      const res = await axios.get(getUserInfo, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getByIdGroup: async (id) => {
    try {
      const res = await axios.get(getByIdGroup + "/" + id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getByIdLesson: async (id) => {
    try {
      const res = await axios.get(getByIdLesson + "/" + id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllChats: async () => {
    try {
      const res = await axios.get(getAllChats, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllOutputType: async () => {
    try {
      const res = await axios.get(getAllOutputType, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllSalaryUserItems: async () => {
    try {
      const res = await axios.get(getAllSalaryUserItems, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getPaymentsType: async () => {
    try {
      const res = await axios.get(getPaymentsType, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getChatById: async (id) => {
    try {
      const res = await axios.get(getChatById + "/" + id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getFiles: async (level, type) => {
    try {
      const res = await axios.get(getFiles + "?level_id=" + level + "&type=" + type, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getByStudentDiscount: async (id, page) => {
    try {
      const res = await axios.get(getByStudentDiscount + id + "&page=" + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getByUserPractise: async (id, page) => {
    try {
      const res = await axios.get(getByUserPractise + id + "&page=" + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getByUserExercise: async (id, page) => {
    try {
      const res = await axios.get(getByUserExercise + id + "&page=" + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getByIdNotification: async (id, page) => {
    try {
      const res = await axios.get(getByIdNotification + id + "&page=" + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getMainLesson: async (id) => {
    try {
      const res = await axios.get(getMainLesson + id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getMainLessons: async (params) => {
    try {
      const res = await axios.get(getMainLessons, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllUserForAdmin: async () => {
    try {
      const res = await axios.get(getAllUserForAdmin, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllGroupForNotification: async () => {
    try {
      const res = await axios.get(getAllGroupForNotification, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllRoles: async () => {
    try {
      const res = await axios.get(getAllRoles, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllNewsForAdmin: async (id) => {
    try {
      const res = await axios.get(getAllNewsForAdmin + id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllNotification: async (page) => {
    try {
      const res = await axios.get(getAllNotification + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllSMS: async (page) => {
    try {
      const res = await axios.get(getAllSMS + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllScheduleForGroups: async (page) => {
    try {
      const res = await axios.get(getAllScheduleForGroups + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getImportantNotes: async (id, page) => {
    try {
      const res = await axios.get(getImportantNotes + "/" + id + "?page=" + page, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllDiscountsForAdmin: async (params) => {
    try {
      const res = await axios.get(getAllDiscountsForAdmin, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllAdminDashboard: async () => {
    try {
      const res = await axios.get(getAllAdminDashboard, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllGroupsForAdmin: async (page, text) => {
    try {
      const res = await axios.get(getAllGroupsForAdmin+page+"&search="+text, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },



  checkNumberOrEmail: async (type, value, id) => {
    try {
      const res = await axios.get(checkNumberOrEmail+"?"+type+"="+value+"&id="+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllTeachers: async () => {
    try {
      const res = await axios.get(getAllTeachers, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getUserById: async (id) => {
    try {
      const res = await axios.get(getUserById+"/"+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getUserByIdPayment: async (id) => {
    try {
      const res = await axios.get(userIdPayments+"/"+id, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllSalaryType: async () => {
    try {
      const res = await axios.get(getAllSalaryType, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllUserForAdminByRole: async (params) => {
    try {
      const res = await axios.get(getAllUserForAdminByRole, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllOutput: async (params) => {
    try {
      const res = await axios.get(getAllOutput, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllNewOrderForStudent: async (params) => {
    try {
      const res = await axios.get(getAllNewOrderForStudent, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllPayments: async (params) => {
    try {
      const res = await axios.get(getAllPayments, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
  getAllPaymentsType: async (params) => {
    try {
      const res = await axios.get(getAllPaymentsType, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllWorkerBonus: async (params) => {
    try {
      const res = await axios.get(getAllWorkerBonus, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getAllWorkerAdvances: async (params) => {
    try {
      const res = await axios.get(getAllWorkerAdvances, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getInfoGroups: async (params) => {
    try {
      const res = await axios.get(getInfoGroups, {
        params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        }
      });
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getUserSalary: async () => {
    try {
      const res = await axios.get(getUserSalary, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },

  getUserSalaryHistory: async () => {
    try {
      const res = await axios.get(getUserSalaryHistory, headers);
      return {success: true, res: res};
    } catch (e) {
      return {success: false, res: e};
    }
  },
}


export default GET;