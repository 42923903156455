import React, {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {Pagination, TextField, Tooltip} from "@mui/material";
import {getDateInfo} from "../Chat";
import EditDiscountModal from "../../components/modals/EditDiscountModal";
import AddDiscountModal from "../../components/modals/AddDiscountModal";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {formatPrice, functionEndDate, functionStartDate} from "../../components/Other/useWindowSize";
import DeleteDiscountModal from "../../components/modals/DeleteModal/DeleteDiscountModal";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {ClearOutlined} from "@mui/icons-material";
import { PrimeReactProvider } from 'primereact/api';
import { AutoComplete } from 'primereact/autocomplete';
import EditDiscountsLevelModal from "../../components/modals/EditDiscountsLevelModal";


export default function DiscountLevel(){


    const [discounts, setDiscounts]=useState([]);


    const [loader, setLoader]=useState(true);






    const handlerGetDiscountLevels=async ()=>{
        const res=await GET.getDiscountsLevel();
        if (res.success){
            setDiscounts(res.res.data.batle_discount);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }




    useEffect(()=>{
        handlerGetDiscountLevels();
    }, [])




    return (
        <>

            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} marginBottom={"8px"}>
                <Typography>
                    Mukofot va jarima turlari
                </Typography>
            </Box>



            {loader?<LoadingBeatLoader/>:
                <Box>
                    {discounts!==undefined&&discounts.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:"grey"}}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Nomi</TableCell>
                                            <TableCell align={"center"}>Turi</TableCell>
                                            <TableCell align={"center"}>Miqdor</TableCell>
                                            <TableCell align={"center"}>Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discounts&&discounts.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                    ":hover": {backgroundColor: "#cbcbcb"}
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {value1.title}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {value1.description}
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    {formatPrice(value1.sum)}
                                                </TableCell>
                                                <TableCell align={"right"}>
                                                    <EditDiscountsLevelModal sum={value1.sum} id={value1.id} handlerGetDiscountLevels={handlerGetDiscountLevels} />

                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>Bonus/jarimalar mavjud emas</Typography>
                        </Box>
                    }
                </Box>}
        </>
    );
}