import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import { BaseUrl, checkAllApi, FileBaseUrl } from "../api/DefaultApi";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import DELETE from "../api/DELETE";
import { useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import POST from "../api/POST";
import DownloadIcon from "@mui/icons-material/Download";
import ReactPlayer from "react-player";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { types } from "../../page/Files";
import axios from "axios";
import { handleGetFile } from "../FileTab";
import DeleteFileModal from "../modals/DeleteModal/DeleteFileModal";
import {useSelector} from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export default function FileTabId({ file, index, level, typeFile }) {

  const lavels=useSelector(state => state.otherInfoList.levels);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const newData = {
      ...data,
      id: file.id,
      size: fileSize,
      file_type: fileInfo.type,
      name_path: fileInfo.image,
    };
    const res = await POST.addFile(newData);
    checkAllApi(res);
    if (res.success) {
      toast.success("Tahrirlandi.");
      reset();
      handleClose();
      handleGetFile(level, typeFile);
    }
    if (!res.success) {
      toast.error("Xatolik");
    }
  };

  const refresh = () => {
    reset();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fileInfo, setFileInfo] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [fileSize, setFileSize] = useState(0);

  const handleUploadFile = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    try {
      const res = await axios.post(
        BaseUrl + "/api/teacher/practice/file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      setFileInfo(res.data);
      setFileSize(event.target.files[0].size);
    } catch (e) {
      toast("Xatolik");
    }
  };

  watch((data) => {
    const typeWatch = data.type;
    handlerTypeEdit(typeWatch);
  });

  const handlerTypeEdit = (typeWatch) => {
    if (typeWatch === "Books") {
      setType("application/pdf");
    } else if (
      typeWatch === "Videos" ||
      typeWatch === "Movies" ||
      typeWatch === "Cartoons"
    ) {
      setType("video/*");
    } else if (typeWatch === "Audio") {
      setType("audio/*");
    } else if (typeWatch === "Podcasts") {
      setType("video/*, audio/*");
    }
  };

  const handlerDownload = (name_path) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = FileBaseUrl + name_path;
    downloadLink.download = name_path;
    downloadLink.target = "_blank";

    // Append the link to the DOM (this is required for the download to work in some browsers)
    document.body.appendChild(downloadLink);

    // Click the link to start the download
    downloadLink.click();

    // Remove the link (it's not needed anymore)
    document.body.removeChild(downloadLink);
  };

  const [type, setType] = useState(undefined);

  useEffect(() => {
    setFileInfo({ type: file.file_type, image: file.name_path });
    handlerTypeEdit(file.type);
  }, [file]);

  const getSize = (size) => {
    if (size > 1024 && 1048576 > size) {
      return parseInt(size / 1024) + " Kb";
    }
    if (size > 1048576) {
      return parseInt(size / 1024 / 1024) + " Mb";
    }
  };

  return (
    <>
      <TableRow
        key={file.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell align={"center"}>{file.name}</TableCell>
        <TableCell align={"center"}>{file.author}</TableCell>
        <TableCell align={"center"}>
          {file.file_type == 0 && (
            <Box>
              <img
                style={{ objectFit: "contain" }}
                width={"300px"}
                height={"200px"}
                src={FileBaseUrl + file.name_path}
              />
            </Box>
          )}
          {file.file_type == 1 && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ReactPlayer
                width={"300px"}
                height={"200px"}
                controls={true}
                url={FileBaseUrl + file.name_path}
              />
            </Box>
          )}
          {file.file_type == 2 && (
            <Box>
              <PictureAsPdfIcon />

              {/*<a href={*/}
              {/*    // FileBaseUrl+*/}
              {/*    file.name_path}*/}
              {/*   // download*/}
              {/*   style={{display:"flex", justifyContent:"center"}}>*/}
              {/*    <img src={"/assets/icon/file.svg"} width={"50px"}/>*/}
              {/*</a>*/}
            </Box>
          )}
          {file.file_type == 3 && (
            <Box>
              <audio controls={true} src={FileBaseUrl + file.name_path} />
            </Box>
          )}
        </TableCell>

        <TableCell align={"center"} width={"150px"}>
          <Tooltip title={"Yuklab olish " + getSize(file.size)}>
            <IconButton
              color={"info"}
              onClick={() => handlerDownload(file.name_path)}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          {(localStorage.getItem("role") === "admin" ||
            localStorage.getItem("role") === "superadmin") && (
            <>
              <Tooltip title={"Tahrirlash"}>
                <IconButton color={"warning"} onClick={handleOpen}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <DeleteFileModal id={file.id} level={level} refresh={handleGetFile} typeFile={typeFile}/>
            </>
          )}
        </TableCell>
      </TableRow>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography>Nomini kiriting:</Typography>
              <TextField
                fullWidth
                defaultValue={file.name}
                {...register("name")}
              />

              {errors.name && (
                <Typography color={"name"}>{errors.name.message}</Typography>
              )}
            </Box>

            <Box>
              <Typography>Muallifini kiriting:</Typography>
              <TextField
                fullWidth
                defaultValue={file.author}
                {...register("author")}
              />
              {errors.name && (
                <Typography color={"author"}>{errors.name.message}</Typography>
              )}
            </Box>

            <Box width={"100%"}>
              <InputLabel id="demo-multiple-name-label">
                Darajasini tanlang
              </InputLabel>
              <Select
                sx={{ width: "100%" }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                MenuProps={MenuProps}
                defaultValue={file.level_id}
                {...register("level_id", { required: "Tanlash kerak" })}
              >
                {lavels.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box width={"100%"}>
              <InputLabel id="demo-multiple-name-label">
                Turini tanlang
              </InputLabel>
              <Select
                sx={{ width: "100%" }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                MenuProps={MenuProps}
                defaultValue={file.type}
                {...register("type", { required: "Tanlash kerak" })}
              >
                {types.map((value) => (
                  <MenuItem key={value.name} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {fileInfo === undefined && type !== undefined && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#d9dbe0",
                  padding: "8px",
                  justifyContent: "center",
                }}
              >
                <label>
                  <input
                    style={{ display: "none" }}
                    type={"file"}
                    onChange={handleUploadFile}
                    accept={type}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={"/assets/icon/upload.svg"}
                      width={"24px"}
                      height={"24px"}
                    />
                    <span>Faylni yuklang</span>
                  </div>
                </label>
              </Box>
            )}

            <Box width={"100%"}>
              {progress > 0 && 100 > progress && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      backgroundColor: "gray",
                      width: progress + "%",
                      height: "4px",
                    }}
                  ></div>
                  <span>{progress}%</span>
                </Box>
              )}
            </Box>

            {fileInfo !== undefined && (
              <Box sx={{ alignSelf: "center" }}>
                {fileInfo.type == 0 && (
                  <Box>
                    <img
                      style={{ objectFit: "contain" }}
                      width={"300px"}
                      height={"200px"}
                      src={FileBaseUrl + fileInfo.image}
                    />
                  </Box>
                )}
                {fileInfo.type == 1 && (
                  <Box>
                    <ReactPlayer
                      width={"300px"}
                      height={"200px"}
                      controls={true}
                      url={FileBaseUrl + fileInfo.image}
                    />
                  </Box>
                )}
                {fileInfo.type == 2 && (
                  <Box>
                    <a
                      href={FileBaseUrl + fileInfo.image}
                      download
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img src={"/assets/icon/file.svg"} width={"50px"} />
                    </a>
                  </Box>
                )}
                {fileInfo.type == 3 && (
                  <Box>
                    <audio controls={true} src={FileBaseUrl + fileInfo.image} />
                  </Box>
                )}
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    onClick={() => {
                      setFileInfo(undefined);
                    }}
                    color={"error"}
                    variant={"contained"}
                    sx={{ marginTop: "4px" }}
                  >
                    O`chirish
                  </Button>
                </Box>
              </Box>
            )}

            <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
              <Button type={"submit"} color={"success"} variant={"contained"}>
                Saqlash
              </Button>
              {/*<Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>*/}
              <Button
                onClick={handleClose}
                color={"error"}
                variant={"contained"}
              >
                Yopish
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
