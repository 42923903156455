import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {Button, TextField, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DELETE from "../../components/api/DELETE";
import React, {useState} from "react";
import {handleGetGroups} from "../../components/group/GroupsMenu";
import toast from "react-hot-toast";
import {checkAllApi, getTeacherId} from "../../components/api/DefaultApi";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import {useForm} from "react-hook-form";
import POST from "../../components/api/POST";





export default function Home(){


    const groups=useSelector(state => state.items.groups);

    const navigate=useNavigate();





    return (
      <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography>Guruhlar</Typography>
            </Box>

            <Box sx={{display:"flex", flexDirection:"column", gap:"4px", marginY:"4px"}}>
                {groups.map((value, index)=> (
                    <Box sx={{width:"100%", background:"#C8C8C8", padding:"8px", borderRadius:"4px",
                        display:"flex", alignItems:"center", justifyContent:"space-between", flexWrap:"wrap"}}>
                        <Typography>{(++index)}{". "+value.name} </Typography>
                        <Box display={"flex"} gap={"4px"} sm={{flexDirection:"column", flexWrap:"wrap"}}>
                            <Button variant={"contained"} onClick={()=>navigate("group/?groupId="+value.id)}>Ko`rish</Button>
                        </Box>
                    </Box>

                ))}
            </Box>




      </>
    );

}