import Dashboard from "./components/Dashboard";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import GroupTabs from "./page/GroupTabs";
import Home from "./page/Home";
import Sort3 from "./components/Sort3";
import Lesson from "./page/Lesson";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import NetWorkError from "./page/NetWorkError";
import Loader from "./page/Loader";
import GET from "./components/api/GET";
import { checkAllApi } from "./components/api/DefaultApi";
import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Rooms from "./page/Rooms";
import Chats from "./page/Chats";
import Chat from "./page/Chat";
import Login from "./page/Login";
import { setIsLoading, setIsLogin } from "./redux/action";
import File from "./page/File";
import Files from "./page/Files";
import MainLessons from "./page/MainLessons";
import MainLesson from "./page/MainLesson";
import User from "./page/User";
import UsersForAdmin from "./components/UsersForAdmin";
import ListItemCheckbox from "./components/ListItemCheckbox";
import AddNotification from "./page/AddNotification";
import Notification from "./page/Notification";
import News from "./page/News";
import HomePageForAdmin from "./page/HomePageForAdmin";
import Schedule from "./page/Schedule";
import Discount from "./page/Discount";
import Autocomplect from "./page/Autocomplect";
import UserPageForAdmin from "./page/UserPageForAdmin";
import Groups from "./page/Groups";
import RoomStatus from "./page/RoomStatus";
import PaymentType from "./page/PaymentType";
import Payments from "./page/Payments";
import Profile from "./page/Profile";
import AddSMS from "./page/AddSMS";
import SMS from "./page/SMS";
import OutputType from "./page/OutputType";
import AddOutput from "./page/AddOutput";
import Output from "./page/Output";
import Profession from "./page/Profession";
import SalaryItem from "./page/SalaryItem";
import WorkerBonuses from "./page/WorkerBonuses";
import WorkerAdvance from "./page/WorkerAdvances";
import Exit from "./page/Exit";
import MySalary from "./page/MySalary";
import AddDebtor from "./page/AddDebtor";
import Inputs from "./components/Inputs";
import LoginPage2 from "./page/LoginPage2";
import Students from "./page/Students";
import LessonLevel from "./page/LessonLevel";
import StatisticMoney from "./page/StatisticMoney";
import SalaryItemUserId from "./page/SalaryItemUserId";
import {
  handlerGetProfissions, handlerGetRoles,
  handlerGetSalaryType,
  handlerGetStudents,
  handlerGetWorkers, handlerLevels, handlerRooms, handlerTeachers
} from "./redux/functions";
import Arxiv from "./page/Arxiv";
import Card from "./page/Card";
import InfoGroups from "./page/InfoGroups";
import {handleGetGroups} from "./components/group/GroupsMenu";
import AddSMSByStatus from "./page/AddSMSByStatus";
import DiscountLevel from "./page/DiscountLevel";
import PassTheLesson from "./page/PassTheLesson";
import ExamTest from "./page/ExamTest";
import ExamType from "./page/ExamType";
import ExamTypeId from "./page/ExamTypeId";
import Exams from "./page/Exams";
import PaymentsType from "./page/PaymentsType";

function App() {
  const { isLogin, isLoading, errorNetwork } = useSelector(
    (state) => state.main
  );

  useEffect(() => {
    if (isLogin){
      setTimeout(()=>{
        handlerGetWorkers();
        handlerGetStudents();
        handlerGetProfissions();
        handlerGetSalaryType();
        handlerGetRoles();
        handlerLevels();
        handlerTeachers();
        handlerRooms();
        handleGetGroups();
      }, 1000)
    }
    setIsLoading(false);
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("role") === null ||
      localStorage.getItem("id") === null
    ) {
      setIsLogin(false);
    }
    // handleGetGroup();
  }, []);

  // const navigate=useNavigate();
  // useEffect(()=>{
  //     if (!isLogin){
  //         navigate("/")
  //     }
  // }, [isLogin])

  return (
    <div style={{ height: "100vh" }}>
      {isLoading && !errorNetwork && <Loader />}
      {errorNetwork && <NetWorkError />}

      <BrowserRouter>
        <Routes>
          {!isLogin && <Route path={"*"} element={<LoginPage2 />}></Route>}
          {!isLogin && <Route path={"/login"} element={<LoginPage2 />}></Route>}

          {!isLoading && isLogin && !errorNetwork && (
            <Route path={"/"} element={<Dashboard />}>
              {localStorage.getItem("role") === "teacher" && (
                <Route path={"/"} element={<Home />} />
              )}
              {(localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "superadmin") && (
                <Route path={"/"} element={<HomePageForAdmin />} />
              )}
              {(localStorage.getItem("role") === "admin" ||
                  localStorage.getItem("role") === "superadmin") && (
                  <Route path={"/discount-level"} element={<DiscountLevel />} />
              )}
              {(localStorage.getItem("role") === "superadmin") && (
                  <Route path={"/statistic-money"} element={<StatisticMoney />} />
              )}
              <Route path={"/rooms"} element={<Rooms />} />
              <Route path={"/pass-the-lesson/:id"} element={<PassTheLesson />} />
              <Route path={"/group"} element={<GroupTabs />} />
              <Route path={"/exam-test"} element={<ExamTest />} />
              <Route path={"/exam-type"} element={<ExamType />} />
              <Route path={"/exam-type/:id"} element={<ExamTypeId />} />
              <Route path={"/exam-type/add"} element={<ExamTypeId />} />
              <Route path={"/exams"} element={<Exams />} />
              <Route path={"/files"} element={<File />} />
              <Route path={"/files/:id"} element={<Files />} />
              <Route path={"/lesson"} element={<Lesson />} />
              <Route path={"/sort"} element={<Sort3 />} />
              {localStorage.getItem("role") === "teacher" && (
                <Route path={"/chats"} element={<Chats />} />
              )}
              {localStorage.getItem("role") === "teacher" && (
                <Route path={"/chat/:chatId"} element={<Chat />} />
              )}
              {(localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "superadmin") && (
                <Route path={"/payment-type"} element={<PaymentType />} />
              )}

              {(localStorage.getItem("role") === "admin" ||
                  localStorage.getItem("role") === "superadmin") && (
                  <Route path={"/payment-type/card"} element={<Card />} />
              )}

              {(localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "superadmin") && (
                <Route path={"/payments"} element={<Payments status={1} />} />
              )}

              {(localStorage.getItem("role") === "admin" ||
                  localStorage.getItem("role") === "superadmin") && (
                  <Route path={"/payments-type"} element={<PaymentsType />} />
              )}

              {(localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "superadmin") && (
                <Route
                  path={"/no-payments"}
                  element={<Payments status={0} />}
                />
              )}

              {(localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "superadmin") && (
                <Route path={"/students"} element={<Students />} />
              )}

              {(localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "superadmin") && (
                <Route path={"/lesson-level"} element={<LessonLevel />} />
              )}

              <Route path={"/info-groups"} element={<InfoGroups />} />
              <Route path={"/main-lessons"} element={<MainLessons />} />
              <Route path={"/group-list"} element={<Groups />} />
              <Route path={"/main-lesson/:id"} element={<MainLesson />} />
              <Route path={"/user-group/:id"} element={<User />} />
              <Route path={"/users"} element={<UserPageForAdmin />} />
              <Route path={"/notification"} element={<Notification />} />
              <Route path={"/notification/add"} element={<AddNotification />} />
              <Route path={"/news"} element={<News />} />
              <Route path={"/schedule"} element={<Schedule />} />
              <Route path={"/discount"} element={<Discount />} />
              <Route path={"/test"} element={<Autocomplect />} />
              <Route path={"/room-status"} element={<RoomStatus />} />
              <Route path={"/settings"} element={<Profile />} />
              <Route path={"/sms"} element={<SMS />} />
              <Route path={"/sms/add"} element={<AddSMS />} />
              <Route path={"/sms/add-students"} element={<AddSMSByStatus />} />
              <Route path={"/output-type"} element={<OutputType />} />
              <Route path={"/profession"} element={<Profession />} />
              <Route path={"/worker"} element={<SalaryItem />} />
              <Route path={"/worker/:id"} element={<SalaryItemUserId />} />
              <Route path={"/worker-bonuses"} element={<WorkerBonuses />} />
              <Route path={"/worker-advances"} element={<WorkerAdvance />} />
              <Route path={"/output"} element={<Output />} />
              <Route path={"/chiqim/add"} element={<AddOutput />} />
              <Route path={"/my-salary/:id"} element={<MySalary />} />
              <Route path={"/add-debtor"} element={<AddDebtor />} />
              <Route path={"/exit"} element={<Exit />} />
              <Route path={"/input"} element={<Inputs />} />
              <Route path={"/arxiv"} element={<Arxiv />} />
              <Route path={"*"} element={<p>404</p>} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
      <Toaster position={"top-right"} />
    </div>
  );
}

export default App;
