import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GET from "../../components/api/GET";
import React, {useEffect, useState} from "react";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import toast from "react-hot-toast";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../components/api/DELETE";
import AddOrEditLessonLevel from "../../components/modals/AddOrEditLessonLevel";
import DeleteLessonTypeModal from "../../components/modals/DeleteModal/DeleteLessonTypeModal";


export default function LessonLevel(){


    const [loader, setLoader]=useState(true);

    const [lessonLevelList, setLessonLevelList]=useState([]);

    const handlerGetAllLessonLevel=async ()=>{
        const res=await GET.getAllLessonLevel();
        if (res.success){
            setLessonLevelList(res.res.data.levels);
            setLoader(false);
        }else if (!res.success){
            toast.error("Guruhlarni olishda xatolik");
            setLoader(false);
        }
    }



    useEffect(()=>{
        handlerGetAllLessonLevel();
    }, [])







    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} alignItems={"center"}>
                <Typography>Dars darajalari</Typography>
                <AddOrEditLessonLevel  type={"add"} handlerGetAllLessonLevel={handlerGetAllLessonLevel}/>
            </Box>


            {loader?<LoadingBeatLoader/>:
                <Box>
                    {lessonLevelList.length>0?
                        <>
                            <TableContainer component={Paper} sx={{marginY:2}}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:"grey", }}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Nomi</TableCell>
                                            <TableCell align={"center"}>Rangi</TableCell>
                                            <TableCell align={"center"}>Tavsifi</TableCell>
                                            <TableCell width={"100px"} align={"center"} >Yaratilgan vaqti</TableCell>
                                            <TableCell width={"100px"} align={"center"} >Tahrirlangan vaqti</TableCell>
                                            <TableCell width={"100px"} >Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lessonLevelList.map((row, index) =>
                                            <TableRow sx={{
                                                ":hover": {cursor: "pointer", backgroundColor: "#cbcbcb"}
                                            }}>
                                                <TableCell width={"30px"} align={"center"}>{(index + 1)}</TableCell>
                                                <TableCell align={"center"}>{row.name}</TableCell>
                                                <TableCell align={"center"} sx={{display:"flex", alignItems:"center", gap:"4px", justifyContent:"center"}}>
                                                    <p style={{color: row.color}}>
                                                        {row.color}
                                                    </p>

                                                    <div style={{backgroundColor: row.color, width:"40px", height:"40px", borderRadius:"100%"}}>

                                                    </div>
                                                </TableCell>
                                                <TableCell align={"center"}>{row.description}</TableCell>
                                                <TableCell align={"center"}>{row.created_at}</TableCell>
                                                <TableCell align={"center"}>{row.updated_at}</TableCell>
                                                <TableCell width={"150px"} align={"center"} sx={{display:"flex", alignItems:"center"}}>
                                                    <AddOrEditLessonLevel type={"edit"} lessonLevel={row} handlerGetAllLessonLevel={handlerGetAllLessonLevel}/>

                                                    <DeleteLessonTypeModal id={row.id} refresh={handlerGetAllLessonLevel}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        :
                        <Box>
                            <Typography>Dars darajalari mavjud emas</Typography>
                        </Box>
                    }
                </Box>
            }
        </>
    );
}