import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import MenuIcon from '@mui/icons-material/Menu';


const list=[
    {id:1, title:"Order 1", order:1},
    {id:2, title:"Order 2", order:2},
    {id:3, title:"Order 3", order:5},
    {id:4, title:"Order 4", order:3},
    {id:5, title:"Order 5", order:4},
    {id:6, title:"Order 6", order:6},
    {id:7, title:"Order 7", order:7},
    {id:8, title:"Order 8", order:8},
    {id:11, title:"Order 9", order:8},
    {id:10, title:"Order 10", order:10},
];


export default function Sort3() {


    const [orderList, setOrderList]=useState(list.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)));
    // console.log(orderList);

    useEffect(()=>{
        // setOrderList()
    },[]);

    const onDragEnd = (param) => {
        const srcI = param.source.index;
        const desI = param.destination?.index;


    };
    return (
        <div className="App">
            <DragDropContext
                onDragEnd={(param) => {
                    const srcI = param.source.index;
                    const desI = param.destination?.index;
                    const handleOrderList = [...orderList];
                    handleOrderList.splice(desI, 0, handleOrderList.splice(srcI, 1)[0])
                    handleOrderList.map((value, index) => value.order=index)
                    handleOrderList.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
                    if (desI) {
                        setOrderList(handleOrderList);
                    }
                }}
            >
                <Box>
                    <h1>The List</h1>
                    <Droppable droppableId="droppable-1">
                        {(provided, _) => (
                            <Box sx={{display:'flex', flexDirection:"column", gap:"4px"}} ref={provided.innerRef} {...provided.droppableProps}>
                                {orderList.map((item, i) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={"draggable-" + item.id}
                                        index={i}
                                    >
                                        {(provided, snapshot) => (
                                            <Box sx={{display:"flex", gap:"4px", alignItems:"center"}}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                // style={{
                                                //     ...provided.draggableProps.style,
                                                //     boxShadow: snapshot.isDragging
                                                //         ? "0 0 .4rem #666"
                                                //         : "none",
                                                // }}
                                            >
                                                <img src={"assets/icon/cursor.svg"} {...provided.dragHandleProps} />
                                                <span>{item.title}</span>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </Box>
            </DragDropContext>
        </div>
    );
}