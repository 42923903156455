import {useEffect} from "react";
import {setIsLogin} from "../../redux/action";
import {useNavigate} from "react-router-dom";


export default function Exit(){


    const navigate=useNavigate();

    useEffect(() => {
        setIsLogin(false);
             localStorage.removeItem("token");
                navigate("/");
    }, []);

    return (
        <></>
    );
}