import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddImportantNotes from "../../modals/AddImportantNotes";
import {useEffect, useState} from "react";
import GET from "../../api/GET";
import {useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Pagination, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {getDateInfo} from "../../../page/Chat";
import DELETE from "../../api/DELETE";
import EditImportantNotes from "../../modals/EditImportantNotes";


export default function GroupImportantNotes(){
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");





    const [importantNotes, setImportantNotes]=useState([]);


    const [loader, setLoader]=useState(true);


    const [lastPage, setLastPage]=useState(1);

    const [page, setPage]=useState(0);



    const handlerImportantNotes=async ()=>{
        const res=await GET.getImportantNotes(groupId, page);

        if (res.success){
            setImportantNotes(res.res.data.importantNotes.data);
            setLastPage(res.res.data.importantNotes.last_page);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");

            setLoader(false);
        }

    }

    const handlerDeleteImportantNews=async (id)=>{
        const res=await DELETE.deleteImportantNote(id);
        if (res.success){
            toast.success("O`chirildi");
            return;
        }else if (!res.success){
            toast.error("Siz so`ragan Muhim xabar topilmadi");
            return;
        }
    }

    useEffect(()=>{
        handlerImportantNotes();
    }, []);

    useEffect(()=>{
        handlerImportantNotes();
    }, [groupId]);

    return (
        <>
            <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <Typography>Muhim xabarlar</Typography>
                {localStorage.getItem("role")==="teacher"&&
                    <AddImportantNotes handlerImportantNotes={handlerImportantNotes} page={page}/>
                }
            </Box>

            <Box width={"100%"}>
                <Box sx={{marginY:"4px"}}>
                    {importantNotes!==undefined&&importantNotes.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Title</TableCell>
                                            <TableCell align={"center"}>Description</TableCell>
                                            <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                            {localStorage.getItem("role")==="teacher"&&
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {importantNotes&&importantNotes.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.title}</Typography>
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    <Typography
                                                        dangerouslySetInnerHTML={{__html: value1.description?value1.description:"Mavjud emas"}}
                                                    >
                                                        {/*{lesson.description?lesson.description:"Aniqlanmadi"}*/}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                {localStorage.getItem("role")==="teacher"&&
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>

                                                            <EditImportantNotes handlerImportantNotes={handlerImportantNotes} page={page} value={value1}/>
                                                            <Tooltip title={"O`chirish"}>
                                                                <IconButton color={"error"}
                                                                            onClick={()=>handlerDeleteImportantNews(value1.id)}
                                                                >
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Tooltip>

                                                        </Box>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>News mavjud emas</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    );
}