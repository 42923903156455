import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, TextField, InputLabel, MenuItem, Select} from "@mui/material";
import {useForm} from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import GET from "../../api/GET";
import {checkAllApi} from "../../api/DefaultApi";
import LoadingBeatLoader from "../../LoadingBeatLoader";
import {setGroupsById} from "../../../redux/action";
import {useSelector} from "react-redux";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import GroupsClassTimeId from "../GroupsClassTimeId";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import RoomId from "../../RoomId";
import TableContainer from "@mui/material/TableContainer";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};


const days=["Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba", "Yakshanba"]




export default function GroupClassTime(){
    const {group, lessons}=useSelector(state => state.items.group_id);
    const [isLoader, setIsLoader]=useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");

    const [rooms, setRooms]=useState([]);

    useEffect(()=>{
        const res=GET.getAllRooms().then((res)=>
        {
            setRooms(res.res.data.data)
            return;
        }        ).catch(()=>
            toast.error("Xonalarni olib bo`lmadi")
        );
    }, []);

    useEffect(()=>{
        setIsLoader(true)
        setGroupsById({})
        handleGetByIdGroup(groupId).then(value => {
           value===false&&setGroupsById({});
            setIsLoader(false)
        });
    }, [groupId]);

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {


        const newData={...data, group_id:groupId};
        const res=await POST.addClassTime(newData);
        checkAllApi(res);
        if (res.success){
            handleGetByIdGroup(groupId);
            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    return (
        <>
            {isLoader?
                <LoadingBeatLoader/>
                :<Box>
                    {group!==undefined?
                        <Box>
                            <Box >
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Typography>
                                        Darslar
                                    </Typography>

                                    {/*<Button onClick={handleOpen} variant={"contained"} color={"success"}>*/}
                                    {/*    Qo`shish*/}
                                    {/*</Button>*/}
                                </Box>
                            </Box>

                            {group.schedules.length>0&&
                             <Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Nomi</TableCell>
                                                <TableCell align={"center"}>Dars boshalanish vaqti</TableCell>
                                                <TableCell align={"center"}>Dars tugash vaqti</TableCell>
                                                <TableCell align={"center"}>Xona</TableCell>
                                                {/*<TableCell width={"100px"} align={"center"}>Amallar</TableCell>*/}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {group.schedules.map((row, index) =>
                                                <GroupsClassTimeId index={index} schedule={row} days={days} rooms={rooms}/>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>}

                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                slots={{ backdrop: Backdrop }}
                                slotProps={{
                                    backdrop: {
                                        timeout: 500,
                                    },
                                }}
                            >
                                <Fade in={open}>
                                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                                        <Box width={"100%"}>
                                                <InputLabel id="demo-multiple-name-label">Kun tanlang</InputLabel>
                                                <Select sx={{width:"100%"}}
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        MenuProps={MenuProps}
                                                        {...register("day", {required:"Kunni tanlash kerak"})}
                                                >
                                                    {days.map((value) => (
                                                        <MenuItem
                                                            key={value}
                                                            value={value}
                                                        >
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            {errors.day&&<Typography color={"error"}>{errors.day.message}</Typography>}
                                        </Box>


                                        <Box>
                                            <Typography>Vaqtini kiriting:</Typography>
                                            <TextField
                                                       fullWidth type={"time"}
                                                       {...register("time",
                                                           {required:"Vaqti bo`sh bo`lishi mumkin emas."})}
                                            />

                                            {errors.time&&<Typography color={"error"}>{errors.time.message}</Typography>}

                                        </Box>

                                        <Box width={"100%"}>
                                            <InputLabel id="demo-multiple-name-label">Xonani tanlang</InputLabel>
                                            <Select sx={{width:"100%"}}
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    MenuProps={MenuProps}
                                                    {...register("room_id", {required:"Xonani tanlash shart"})}
                                            >
                                                {rooms.map((value) => (
                                                    <MenuItem
                                                        key={value.id}
                                                        value={value.id}
                                                    >
                                                        {value.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.day&&<Typography color={"error"}>{errors.day.message}</Typography>}
                                        </Box>



                                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                                            {/*<Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>*/}
                                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                        </Box>
                                    </Box>
                                </Fade>
                            </Modal>
                        </Box>
                        :
                        <Typography>Siz so`ragan guruh mavjud emas</Typography>
                    }
                </Box>

            }
        </>
    );


}