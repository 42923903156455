import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {Button, Pagination, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {getDateInfo} from "../Chat";
import EditDiscountModal from "../../components/modals/EditDiscountModal";
import AddDiscountModal from "../../components/modals/AddDiscountModal";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import DELETE from "../../components/api/DELETE";
import {formatPrice} from "../../components/Other/useWindowSize";
import AddOrEditUserSalaryItemModal from "../../components/modals/AddOrEditUserSalaryItemModal";
import DeleteSalaryItemModal from "../../components/modals/DeleteModal/DeleteSalaryItemModal";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function SalaryItem(){


    const [userSalaryItems, setUserSalaryItems]=useState([]);
    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);


    const [loader, setLoader]=useState(true);



    const salaryTypeList=useSelector((state)=>state.otherInfoList.salaryType);
    const users=useSelector(state => state.otherInfoList.workers);
    const professionList=useSelector(state => state.otherInfoList.professions);

    const navigate=useNavigate();



    const handlerGetSalaryItems=async ()=>{
        const res=await GET.getAllSalaryUserItems();
        if (res.success){
            setUserSalaryItems(res.res.data.userItemsSalary);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }


    useEffect(()=>{
        handlerGetSalaryItems();
    }, [])


    useEffect(()=>{
        handlerGetSalaryItems();
    }, [page]);







    return (
        <>

            {loader?<LoadingBeatLoader/>:
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} marginBottom={"8px"}>
                    <Typography>
                        Ishchilar
                    </Typography>

                    <Box display={"flex"} gap={"8px"}>
                        <AddOrEditUserSalaryItemModal type={"add"}
                                                      handlerGetSalaryItems={handlerGetSalaryItems}
                                                      users={users}
                                                      salaryTypeList={salaryTypeList}
                                                      professionList={professionList}
                                                      userItemsList={userSalaryItems}
                        />
                    </Box>
                </Box>}

            {loader?<LoadingBeatLoader/>:
                <Box>
                    {userSalaryItems!==undefined&&userSalaryItems.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>F.I.O.</TableCell>
                                            <TableCell align={"center"}>Kasbi</TableCell>
                                            <TableCell align={"center"}>Oylik turi</TableCell>
                                            <TableCell align={"center"}>Miqdori %</TableCell>
                                            <TableCell align={"center"}>Miqdori</TableCell>
                                            <TableCell align={"center"}>Berilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Holati</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userSalaryItems&&userSalaryItems.map((value1, index) =>
                                            <TableRow onClick={()=>navigate("/worker/"+value1.user_id+"?name="+value1.name+" "+value1.lastname)}
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {((page-1)*20)+index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {value1.name} {value1.lastname&&value1.lastname}
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    <Typography
                                                    >
                                                        {value1.profession_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    <Typography>
                                                        {value1.type_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"right"}>{formatPrice(value1.protsent)}</TableCell>
                                                <TableCell align={"right"}>{formatPrice(value1.amount)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>
                                                    <Button color={value1.status===0?"error":"success"} variant={"contained"}>
                                                        {value1.status===0?"Ishdan bo`shagan":"Ishlayapti"}
                                                    </Button>
                                                </TableCell>
                                                {/*<TableCell align={"center"} >*/}
                                                {/*    <Box sx={{display:"flex"}}>*/}
                                                {/*        <AddOrEditUserSalaryItemModal type={"edit"}*/}
                                                {/*                                      handlerGetSalaryItems={handlerGetSalaryItems}*/}
                                                {/*                                      salaryItem={value1}*/}
                                                {/*                                      users={users}*/}
                                                {/*                                      salaryTypeList={salaryTypeList}*/}
                                                {/*                                      professionList={professionList}/>*/}
                                                {/*        <DeleteSalaryItemModal id={value1.id} refresh={handlerGetSalaryItems}/>*/}

                                                {/*    </Box>*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>Ishchilar mavjud emas</Typography>
                        </Box>
                    }
                </Box>}
        </>
    );
}