import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../api/DELETE";
import VisibilityIcon from "@mui/icons-material/Visibility";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function AddOrEditExamTest({type, level, refresh, question}){


    const { register,
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
        setValue
    } = useForm();
    const onSubmit = async (data) => {
        let validateCheck=validate();
        if (validateCheck){

            let sendData=type=="add"
                ? {
                    question:data.question,
                    level_id:level.id,
                    questionOption:options
                   }
                :   {
                    id:question.id,
                    question:data.question,
                    level_id:level.id,
                    questionOption:options
                    }

            const res=await POST.addOrEditExamTest(sendData);
            if (res.success){
                toast.success(type==="add"?"Test qo'shild":"Test tahrirlandi");
                handleClose();
                refresh();
                options.map((value)=>{
                    if (value.delete){
                        DELETE.deleteExampOption(value.id);
                    }
                })

            }
            if (!res.success){
                toast.error("Xatolik");
            }
            refreshData();

        }

    };

    const validate=()=>{
        let checkOption=false;
        let countCheck=0;
        let activeCount=0;
        options.map((value)=>{

            if (!value.delete){
                activeCount=activeCount+1;
            }
            if (value.type==true){
                checkOption=true;

            }

            if (value.value!=null&&value.value.length>0&&!value.delete) {
                countCheck=countCheck+1;
            }
        });
        if (!checkOption){
            toast.error("To'g'ri javobni tanlang");
            return false;
        }
        if (activeCount<1){
            toast.error("Test javob variantlari 1 ta dan ko'p bo'lishi kerak");
        }

        if (countCheck!=activeCount){
            toast.error("Javoblardagi kamchiliklarni to'ldiring");
            return false;
        }

        return true;
    }
    const refreshData=()=>{
        reset();
        setOptions([]);
    }


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [options, setOptions]=useState([]);
    useEffect(() => {
        if (type=="edit" || type=="show"){
            if (question){
                setValue("question", question.question);
                const handlerData=[...question.test_question_option];
                handlerData.map((value, index) =>{
                    if (value){
                        if (value.type==1){
                            value.type=true;
                        }else if (value.type==0) {
                            value.type=false;
                        }
                    }
                });
                console.log(handlerData);
                setOptions(handlerData);
            }
        }
    }, [question]);



    return (
        <>

            <Box>
                {type==="add"?
                    <Tooltip title={"Test yaratish"}>
                        <IconButton onClick={handleOpen}>
                            <AddCircleOutlineOutlinedIcon color={"success"} variant={"contained"}/>
                        </IconButton>
                    </Tooltip>
                    :type=="edit"?
                        <Tooltip title={"Testni tahrirlash"}>
                            <IconButton onClick={handleOpen}>
                                <ModeEditOutlineIcon color={"warning"} variant={"contained"}/>
                            </IconButton>
                        </Tooltip>
                        : <Tooltip title={"Testni tahrirlash"}>
                            <IconButton onClick={handleOpen}>
                                <VisibilityIcon color={"primary"} variant={"contained"}/>
                            </IconButton>
                        </Tooltip>


                }
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Typography align={"center"}>
                            {level.name} darajasi uchun savol qo'shayapsiz
                        </Typography>
                        <Box >
                            <Typography>
                                Test savolini kiriting:
                            </Typography>
                            <TextField fullWidth
                                {...register("question", {required:"Savol bo'sh bo'lishi mumkin emas"})}
                            />
                            <Typography>
                                {errors.question&&<Typography color={"error"}>{errors.question.message}</Typography>}
                            </Typography>
                        </Box>



                        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            {options.map((option, index)=>
                                    <Box sx={{display:"flex", alignItems:"center", gap:"8px", opacity:option.delete?"0.5":"1"}}>
                                        <input style={{width:"20px", height:"20px"}}
                                               type={"radio"}
                                               name={"type"}
                                               checked={option.type}
                                               value={option.type}
                                               disabled={option.delete}
                                               onChange={(event)=>{
                                            const handlerData=[...options];
                                            handlerData.map((value, indexOption)=>{
                                                if (index==indexOption){
                                                    value.type=true;
                                                }else {
                                                    value.type=false;
                                                }
                                            })
                                            setOptions(handlerData);
                                        }}/>
                                        <TextField value={option.value}
                                                   disabled={option.delete}
                                                   fullWidth
                                                   onChange={(event)=>{
                                            const handlerData=[...options];
                                            handlerData[index].value=event.target.value;
                                            setOptions(handlerData);
                                        }}/>
                                        {!option.delete&&<Tooltip title={"O'chirish"}>
                                            <IconButton color={"error"} onClick={()=>{
                                                const handlerData=[...options];
                                                handlerData[index].type=false;
                                                handlerData[index].delete=true;
                                                setOptions(handlerData);
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </Box>
                            )}
                        </Box>

                        <Box display={"flex"} justifyContent={"flex-end"}>
                            <Tooltip title={"Javob variantini qo'shing"}>
                                <IconButton onClick={()=>{
                                    const handlerOption=[...options];
                                    handlerOption.push({type:false, value:null});
                                    setOptions(handlerOption);
                                }} color={"primary"}>
                                    <AddCircleOutlineOutlinedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>


                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            {type!="show"&&
                                <Button type={"submit"} color={"success"} variant={"contained"}>{type==="add"?"Saqlash":"Tahrirlash"}</Button>
                            }
                            {type=="add"&&
                                <Button color={"warning"} variant={"contained"} onClick={refreshData}>Tozalash</Button>
                            }
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}