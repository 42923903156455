// import HomeIcon from "@mui/icons-material/Home";
// import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
// import DiscountIcon from "@mui/icons-material/Discount";
// import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
// import {FileOpen, Money, Sms, Soap, VerifiedUser} from "@mui/icons-material";
// import NewspaperIcon from "@mui/icons-material/Newspaper";
// import LogoutIcon from "@mui/icons-material/Logout";
// import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
// import PaidIcon from '@mui/icons-material/Paid';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import PaymentsIcon from '@mui/icons-material/Payments';
// import TuneIcon from '@mui/icons-material/Tune';
// import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
//
//
// export const MenuData=[
//     {name:"Rooms", path:"/rooms", role:["teacher", "admin"], icon:<MeetingRoomIcon/>},
//     {name:"Discount", path:"/discount", role:["admin"], icon:<DiscountIcon/>},
//     {name:"Notification", path:"/notification", role:["admin", "teacher"], icon:<NotificationImportantIcon/>},
//     {name:"SMS", path:"/sms", role:["admin", "teacher"], icon:<Sms/>},
//     {name:"Schedule", path:"/schedule", role:["admin"], icon:<InsertInvitationIcon/>},
//     {name:"News", path:"/news", role:["admin"], icon:<NewspaperIcon/>},
//     {name:"Users", path:"/users", role:["admin", "superadmin"], icon:<VerifiedUser/>},
//     {name:"Groups", path:"/group-list", role:["admin"], icon:<Diversity3Icon/>},
//     {name:"Payment type", path:"/payment-type", role:["admin", "superadmin"], icon:<PaymentsIcon/>},
//     {name:"Payments", path:"/payments", role:["admin", "superadmin"], icon:<PaidIcon/>},
//     {name:"Debtors", path:"/no-payments", role:["admin", "superadmin"], icon:<MoneyOffIcon/>},
//     {name:"Files", path:"/files", role:["teacher", "admin", "superadmin"], icon:<FileOpen/>},
//     {name:"Output Type", path:"/output-type", role:["admin", "superadmin"], icon:""},
//     {name:"Output", path:"/output", role:["admin", "superadmin"], icon:""},
//     {name:"Profession", path:"/profession", role:["admin", "superadmin"], icon:""},
//     {name:"Worker", path:"/worker", role:["admin", "superadmin"], icon:""},
//     {name:"Worker Bonuses", path:"/worker-bonuses", role:["admin", "superadmin"], icon:""},
//     {name:"Worker Advances", path:"/worker-advances", role:["superadmin", "admin"], icon:""},
//     {name:"Chats", path:"/chats", role:["/chats"], icon:""},
//     {name:"Main Lessons", path:"/main-lessons", role:["admin", "teacher"], icon:<LibraryBooksIcon/>},
//     {name:"Settings", path:"/settings", role:["taecher", "admin", "superadmin"], icon:<TuneIcon/>},
//     {name:"My Salary", path:"/my-salary", role:["admin", "teacher", "superadmin"], icon:<Money/>},
//     {name:"Exit", path:"/exit", role:["admin", "teacher", "superadmin", "student"], icon:<LogoutIcon/>},
// ]


import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import DiscountIcon from "@mui/icons-material/Discount";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import {FileOpen, Money, Sms, Soap, VerifiedUser} from "@mui/icons-material";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LogoutIcon from "@mui/icons-material/Logout";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PaidIcon from '@mui/icons-material/Paid';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaymentsIcon from '@mui/icons-material/Payments';
import TuneIcon from '@mui/icons-material/Tune';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FeedIcon from '@mui/icons-material/Feed';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderIcon from '@mui/icons-material/Folder';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Groups3Icon from '@mui/icons-material/Groups3';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddCardIcon from '@mui/icons-material/AddCard';


export const MenuData=[
    {name:"Guruhlar tezkor", path:"/info-groups", role:["admin", "superadmin"], icon:<EventNoteIcon/>},
    {name:"Xonalar", path:"/rooms", role:["teacher", "admin", "superadmin"], icon:<MeetingRoomIcon/>},
    {name:"O’quvchilarga bonus va jarimalar", path:"/discount", role:["admin", "superadmin"], icon:< PriceChangeIcon/>},
    {name:"Bonus darajalari", path:"/discount-level", role:["admin", "superadmin"], icon:< PriceChangeIcon/>},
{name:"Eslatmalar", path:"/notification", role:["admin", "teacher", "superadmin"], icon:<NotificationImportantIcon/>},
{name:"SMS", path:"/sms", role:["admin", "teacher", "superadmin"], icon:<Sms/>},
{name:"Dars jadvali", path:"/schedule", role:["admin", "superadmin"], icon:< EventNoteIcon/>},
    {name:"Yangiliklar", path:"/news", role:["admin","superadmin"], icon:< FeedIcon/>},
    {name:"Talabalar", path:"/students", role:["admin", "superadmin"], icon:< GroupIcon/>},
    {name:"Foydalanuvchilar", path:"/users", role:["admin", "superadmin"], icon:< GroupIcon/>},
    {name:"Arxivdagi foydalanuvchilar", path:"/arxiv", role:["admin", "superadmin"], icon:< GroupIcon/>},
    {name:"Guruhlar", path:"/group-list", role:["admin", "superadmin"], icon:< GroupsIcon/>},
    {name:"Imtihon testlari", path:"/exam-test", role:["admin", "superadmin", "teacher"], icon:< GroupsIcon/>},
    {name:"Imtihon turlari", path:"/exam-type", role:["admin", "superadmin", "teacher"], icon:< GroupsIcon/>},
    {name:"Imtihonlar", path:"/exams", role:["admin", "superadmin", "teacher"], icon:< GroupsIcon/>},
    {name:"To’lov turlari", path:"/payment-type", role:["admin", "superadmin"], icon:<PaymentsIcon/>},
        {name:"To'lovlar", path:"/payments", role:["admin", "superadmin"], icon:<PaidIcon/>},
        {name:"To'lov turlari bo'yicha to'lovlar", path:"/payments-type", role:["admin", "superadmin"], icon:<PaidIcon/>},
            {name:"Qarzdorlar", path:"/no-payments", role:["admin", "superadmin"], icon:<MoneyOffIcon/>},
            {name:"Fayllar", path:"/files", role:["teacher", "admin", "superadmin"], icon:< FolderIcon/>},
            {name:"Pul harakatlari", path:"/statistic-money", role:["superadmin"], icon:< CurrencyExchangeIcon/>},
            {name:"Harajat turlari", path:"/output-type", role:["superadmin"], icon:< CurrencyExchangeIcon/>},
                {name:"Harajatlar", path:"/output", role:["superadmin"], icon:< ReceiptLongIcon/>},
                {name:"Kasblar", path:"/profession", role:["superadmin"], icon:< Groups3Icon/>},
                {name:"Hodimlar", path:"/worker", role:["superadmin"], icon:<EngineeringIcon/>},
                {name:"Hodimlarga bonus va jarimalar", path:"/worker-bonuses", role:["superadmin"], icon:<PriceChangeIcon/>},
                {name:"Hodimlarga avans ", path:"/worker-advances", role:["superadmin"], icon:""},
                    {name:"Yozishmalar", path:"/chats", role:["teacher"], icon:<AddCardIcon/>},
                    {name:"Darslar", path:"/main-lessons", role:["admin", "teacher", "superadmin"], icon:<LibraryBooksIcon/>},
                    {name:"Dars darajalari", path:"/lesson-level", role:["admin", "teacher", "superadmin"], icon:<LibraryBooksIcon/>},
                    {name:"Sozlamalar", path:"/settings", role:["taecher", "admin", "superadmin"], icon:<TuneIcon/>},
                    {name:"Mening oyligim", path:"/my-salary/"+localStorage.getItem("id"), role:["admin", "teacher", "superadmin"], icon:<Money/>},
                        {name:"Chiqish", path:"/exit", role:["admin", "teacher", "superadmin", "student", "user"], icon:<LogoutIcon/>},
                    ]