import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {formatPrice} from "../Other/useWindowSize";
import Box from "@mui/material/Box";
import AddPaymentModal from "../modals/AddPaymentModal";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import {Button, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import GET from "../api/GET";
import toast from "react-hot-toast";
import LoadingBeatLoader from "../LoadingBeatLoader";


export default function PaymentId({status,index, payment, handlerGetPayments, page}){


    const [isOpen, setIsOpen]=useState(false);

    const [orderPaymentList, setOrderPaymentList]=useState(undefined);


    const [isLoader, setLoader]=useState(true);


    const handlerGetOrderPayments=async ()=>{
        setLoader(true)
        const res=await GET.getOrderPayments(payment.order_id);
        if (res.success){
            setOrderPaymentList(res.res.data.order_payments);
            setLoader(false);
        }else {
            toast.error("Xatolik");
        }
    }

    useEffect(()=>{
        if (isOpen===true){
            handlerGetOrderPayments();
        }
    }, [isOpen]);

    useEffect(()=>{
        setIsOpen(false)
    }, [status])

    return (
        <>
            <TableRow
                key={payment.order_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                    ":hover": {cursor: "pointer", backgroundColor: "#cbcbcb"}
                }}
            >
                <TableCell component="th" scope="row">
                    {((page-1)*30)+(index+1)}
                </TableCell>
                <TableCell align={"center"}>
                    <Typography>{payment.name+" "+(payment.lastname?payment.lastname:"")}</Typography>
                </TableCell>
                <TableCell align={"center"} >
                    {payment.group_name}
                </TableCell>
                <TableCell align={"center"} >
                    {formatPrice(payment.sum)}
                </TableCell>
                {status===0&&
                    <TableCell align={"center"} >
                        {formatPrice(payment.price)}
                    </TableCell>
                }
                <TableCell align={"center"}>{payment.date}</TableCell>
                <TableCell align={"center"} >
                    <Typography sx={{color:"white", backgroundColor:payment.status===1?"#2E7D32":"#EB4C42", borderRadius:"8px"}}>
                        {payment.status?"To`langan":"To`lanmagan"}
                    </Typography>
                </TableCell>
                <TableCell align={"center"}>{payment.phone}</TableCell>
                <TableCell align={"center"} >
                    <Box sx={{display:"flex"}}>
                        <Tooltip title={"Batafsil"}>
                            <IconButton color={"info"} onClick={()=>setIsOpen(!isOpen)}>
                                {!isOpen?
                                    <ArrowDownwardIcon/>
                                    :<ArrowUpwardIcon/>
                                }
                            </IconButton>
                        </Tooltip>
                        {status===0&&
                            <AddPaymentModal handlerGetOrderPayments={handlerGetOrderPayments} detail={payment} id={payment.order_id} handlerGetOrdersByUserId={handlerGetPayments}/>
                        }
                    </Box>
                </TableCell>
            </TableRow>

            {isOpen===true&&
                <tr>
                    <th colSpan={status?8:9}>
                        {isLoader?<LoadingBeatLoader/>
                            :
                            <Box margin={2}>
                                {
                                    orderPaymentList.length>0?
                                        <TableContainer component={Paper} sx={{marginY:4}}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead sx={{backgroundColor:"grey"}}>
                                                    <TableRow>
                                                        <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                        <TableCell align={"center"}>Qaysi to`lov turidan</TableCell>
                                                        <TableCell align={"center"}>Miqdori</TableCell>
                                                        <TableCell align={"center"}>Sanasi</TableCell>
                                                        <TableCell align={"center"}>Holati</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {orderPaymentList&&orderPaymentList.map((value1, index) =>
                                                        <TableRow
                                                            key={value1.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {index+1}
                                                            </TableCell>
                                                            <TableCell align={"center"}>
                                                                <Typography>{value1.pay_method}</Typography>
                                                            </TableCell>
                                                            <TableCell align={"right"}>
                                                                {formatPrice(value1.amount)}
                                                            </TableCell>
                                                            <TableCell align={"center"}>
                                                                {value1.date_add}
                                                            </TableCell>

                                                            <TableCell align={"center"}>
                                                                {value1.status==="0"?
                                                                    <Button color={"error"} variant={"contained"}>
                                                                        to`lanmagan
                                                                    </Button>
                                                                    :
                                                                    <Button color={"success"} variant={"contained"}>
                                                                        to`langan
                                                                    </Button>

                                                                }
                                                            </TableCell>

                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography>
                                            To`lovlari mavjud emas
                                        </Typography>
                                }
                            </Box>
                        }
                    </th>
                </tr>
            }
        </>
    );
}