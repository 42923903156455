import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


export default function NetWorkError(){

    return (
        <Box sx={{display:"flex", alignItems:'center', justifyContent:"center", height:'100vh'}}>
            <Typography color={"error"} variant={"h2"} align={"center"}>
                Ulanishda xatolik.
            </Typography>
        </Box>
    );
}


