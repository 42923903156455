import React, { useEffect, useState } from "react";
import GET from "../../components/api/GET";
import { checkAllApi } from "../../components/api/DefaultApi";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Chats() {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();
  const handlerChats = async () => {
    const response = await GET.getAllChats();
    checkAllApi(response);

    if (response.success) {
      setChats(response.res.data.groups);
    }
  };

  useEffect(() => {
    handlerChats();
  }, []);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          marginY: "4px",
        }}
      >
        {chats.map((item, index) => (
          <Box
            sx={{
              width: "100%",
              background: "#C8C8C8",
              padding: "8px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
                }}
                key={index}
          >
            <Typography>
              {++index}
              {". " + item.name}{" "}
            </Typography>
            <Box
              display={"flex"}
              gap={"4px"}
              sm={{ flexDirection: "column", flexWrap: "wrap" }}
            >
              <Button
                variant={"contained"}
                onClick={() => navigate("/chat/" + item.id)}
              >
                Ko`rish
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
}
