import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import {Button, MenuItem, Pagination, Select, TextField, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {getDateInfo} from "../Chat";
import EditIcon from "@mui/icons-material/Edit";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import toast from "react-hot-toast";
import DELETE from "../../components/api/DELETE";
import {formatPrice, functionEndDate, functionStartDate} from "../../components/Other/useWindowSize";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutputModal from "../../components/modals/DeleteModal/DeleteOutputModal";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Output(){


    const [outputList, setOutputList]=useState([]);

    const [total, setTotal]=useState(0);

    const [page, setPage]=useState(1);

    const [lastPage, setLastPage]=useState(1);

    const [loader, setLoader]=useState(true);


    const [outputTypeList, setOutputTypeList]=useState([]);


    const navigate=useNavigate();

    const handlerGetOutputType=async ()=>{
        setLoader(true);
        const res=await GET.getAllOutputType();
        if (res.success){

            const handlerOutputType=res.res.data.expenses;
            setOutputTypeList(handlerOutputType);

        }else if (!res.success){
            toast.error("Chiqim turlarini olishda xatolik");
            setLoader(false);
        }
    }


    const handlerGetAllOutput=async ()=>{
        const res=await GET.getAllOutput({start_date:startDate, type:type, end_date:endDate, user_id:userId, expense_name:outputType, page:page});
        if (res.success){
            setOutputList(res.res.data.outputs.data);
            setLastPage(res.res.data.outputs.last_page);
            setTotal(res.res.data.total_price);
            setLoader(false);
        }else if (!res.success){
            toast.error("Chiqimlarni olishda xatolik.");
            setLoader(false);
        }
    }

    const [users, setUsers]=useState([]);

    const handlerGetAllUsersOtherStudent=async ()=>{
        const res=await GET.getAllUsersOtherStudent();
        if (res.success){
            // console.log(res.res);
            setUsers(res.res.data.users);
        }else if (!res.success){
            toast.error("Userlarni olishda xatolik");
        }
    }





    useEffect(()=>{
        handlerGetOutputType();
        handlerGetAllOutput();
        handlerGetAllUsersOtherStudent();
    }, [])

    useEffect(()=>{
        handlerGetAllOutput();
    }, [page])




    const [type, setType]=useState(null);
    const [startDate, setStartDate]=useState(functionStartDate);
    const [endDate, setEndDate]=useState(functionEndDate);
    const [outputType, setOutputType]=useState(null);
    const [userId, setUserId]=useState(null);


    return (
        <>
            <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <Typography>
                    Chiqimlar
                </Typography>

                <Link to={"/chiqim/add?type=add"}>
                    <Button color={"success"} variant={"contained"}>
                        Qo`shish
                    </Button>
                </Link>
            </Box>


            <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} gap={"4px"} justifyContent={"space-between"}>
                    <Box>
                        <Typography>
                            Nomini kiriting:
                        </Typography>
                        <TextField fullWidth/>
                    </Box>
                    <Box >
                        <Typography>
                            Turini tanlang
                        </Typography>
                        <Select sx={{width:"350px"}}
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                MenuProps={MenuProps}
                                value={outputType}
                                onChange={event => setOutputType(event.target.value)}
                        >
                            {outputTypeList.map((value) => (
                                <MenuItem
                                    key={value.id}
                                    value={value.name}
                                >
                                    {value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                   <Box>
                       <Typography>
                           Boshlanish sanasi:
                       </Typography>
                       <TextField
                           type={"date"}
                           fullWidth
                           value={startDate}
                           onChange={event => setStartDate(event.target.value)}
                       />
                   </Box>
                    <Box>
                        <Typography>
                            Tugash sanasi:
                        </Typography>
                        <TextField
                            fullWidth
                            type={"date"}
                            value={endDate}
                            onChange={event => setEndDate(event.target.value)}
                        />
                    </Box>

                    <Box >
                        <Typography>
                            Kim chiqim qilgan
                        </Typography>
                        <Select sx={{width:"250px"}}
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                MenuProps={MenuProps}
                                value={userId}
                                onChange={event => setUserId(event.target.value)}
                        >
                            {users.map((value) => (
                                <MenuItem
                                    key={value.id}
                                    value={value.id}
                                >
                                    {value.name} {value.lastname&&" "+value.lastname}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>


                    <Box alignSelf={"end"}>
                        <Tooltip title={"Qidirish"} >
                            <IconButton color={"info"} onClick={handlerGetAllOutput}>
                                <SearchIcon/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"O`chirish"} >
                            <IconButton color={"error"} onClick={()=>{
                                setEndDate("");
                                setStartDate("");
                                setType(null);
                                setUserId(null);
                                setOutputType(null);
                                handlerGetAllOutput();
                            }}>
                                <HighlightOffIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
            </Box>

            {loader?
                <LoadingBeatLoader/>
                :
                <Box width={"100%"}>
                    <Box sx={{marginY:"4px"}}>
                        {outputList!==undefined&&outputList.length>0?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead sx={{backgroundColor:"grey"}}>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Sanasi</TableCell>
                                                <TableCell align={"center"}>Qiymati</TableCell>
                                                <TableCell align={"center"}>Type</TableCell>
                                                <TableCell align={"center"}>Description</TableCell>
                                                <TableCell align={"center"}>Turi</TableCell>
                                                <TableCell align={"center"}>Foydalanuvchi</TableCell>
                                                <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                                <TableCell align={"center"}>O`zgartirilgan vaqti</TableCell>
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {outputList&&outputList.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                    "&:hover":{
                                                        backgroundColor:"#cbcbcb"
                                                    }
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {((page-1)*10)+index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"} sx={{width:"150px"}}>
                                                        <Typography>{value1.date}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"right"} >
                                                        <Typography sx={{width:"150px"}}>
                                                            {formatPrice(value1.amount)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Typography>
                                                            {value1.type}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Typography>
                                                            {value1.description}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {value1.expense_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {value1.user_name}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {getDateInfo(value1.created_at)}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {getDateInfo(value1.updated_at)}
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>
                                                            {/*<ShowNotification notification={value1}/>*/}
                                                            <Tooltip title={"Tahrirlash"}>
                                                                <IconButton color={"warning"} onClick={()=>navigate("/chiqim/add?type=edit&id="+value1.id)}>
                                                                    <EditIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <DeleteOutputModal id={value1.id} refresh={handlerGetAllOutput}/>

                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )}

                                            <TableRow>
                                                <TableCell>

                                                </TableCell>
                                                <TableCell align={"center"} sx={{fontSize:"18px", fontWeight:"bold"}}>
                                                    Jami:
                                                </TableCell>
                                                <TableCell align={"right"} sx={{fontSize:"18px"}}>
                                                    {formatPrice(total)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                    <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                                </Box>
                            </>
                            :
                            <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                                <Typography>Chiqimlar mavjud emas</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </>
    );
}