import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {Button, Pagination} from "@mui/material";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {getDateInfo} from "../Chat";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import toast from "react-hot-toast";
import ShowSMSPhone from "../../components/modals/ShowSMSPhone";


export default function SMS(){


    const [SMSList, setSMSList]=useState([]);


    const [page, setPage]=useState(1);

    const [lastPage, setLastPage]=useState(1);

    const [loader, setLoader]=useState(true);

    const handlerGetSendSMS=async ()=>{
        const res=await GET.getAllSMS(page);
        if (res.success){
            setSMSList(res.res.data.sms.data);
            setLastPage(res.res.data.sms.last_page);
            setLoader(false);
        }else if (!res.success){
            toast.error("Jo`natilgan sms larni olishda xatolik.");
            setLoader(false);
        }
    }

    // console.log(SMSList);

    useEffect(()=>{
        handlerGetSendSMS();
    }, [])

    useEffect(()=>{
        handlerGetSendSMS();
    }, [page])


    return (
        <>
            <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <Typography>
                    SMS yoborish qismi
                </Typography>

                <Box display={"flex"} gap={"4px"}>
                    <Link to={"add-students"}>
                        <Button color={"success"} variant={"contained"}>
                            Arxiv foydalanuvchilarga yuborish
                        </Button>
                    </Link>
                    <Link to={"add"}>
                        <Button color={"success"} variant={"contained"}>
                            Qo`shish
                        </Button>
                    </Link>
                </Box>
            </Box>



            {loader?
                <LoadingBeatLoader/>
                :
                <Box width={"100%"}>
                    <Box sx={{marginY:"4px"}}>
                        {SMSList!==undefined&&SMSList.length>0?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Message</TableCell>
                                                <TableCell align={"center"}>Description</TableCell>
                                                <TableCell align={"center"}>Kim tomonidan jo`natilgan</TableCell>
                                                <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                                <TableCell align={"center"}>O`zgartirilgan vaqti</TableCell>
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {SMSList&&SMSList.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {((page-1)*10)+index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.text}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Typography
                                                            dangerouslySetInnerHTML={{__html: value1.description?value1.description:"Mavjud emas"}}
                                                        >
                                                            {/*{lesson.description?lesson.description:"Aniqlanmadi"}*/}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {value1.name}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {getDateInfo(value1.created_at)}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {getDateInfo(value1.updated_at)}
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>
                                                            <ShowSMSPhone phone={value1.phones} />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                    <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                                </Box>


                            </>
                            :
                            <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                                <Typography>Yuborilgan SMS lar mavjud emas</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </>
    );
}