import jwtDecode from "jwt-decode";
import {setIsErrorNetwork, setIsLoading, setIsLogin} from "../../redux/action";
import toast from "react-hot-toast";



export const BaseUrl="https://api.wisdomlc.uz";

export const FileBaseUrl=BaseUrl+"/storage/images/";
export const AvatarBaseUrl=BaseUrl+"/storage/";

export const getTeacherId=()=>{
    let item = localStorage.getItem("token");
    if (item!==null){
        let decode = jwtDecode(item);
        localStorage.setItem("id", decode.sub);
        return decode.sub;
    }
    return null;
}



export const checkAllApi=(res)=>{
    // console.log(res);
    if (!res.success){
        if (res.res.code==="ERR_NETWORK"){
            setIsErrorNetwork(true);
        }
        if (res.res.status===401 || res.res.message==="Request failed with status code 401"){
            setIsLogin(false);
            setIsLoading(false);
            // localStorage.removeItem("token")
            toast.error("Iltimos qaytadan kiring");
        }
    }

    if (res.res.message==="Request failed with status code 403"){
        toast.error("Sizga huquq yo`q");
        return false;
    }

    if (res.res.message==="Request failed with status code 500"){
        
        return false;
    }

    // if (res.success){
        setIsLoading(false);
    // }

    return true;


}