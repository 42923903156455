import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import Test from "../Test";
import LessonMainContent from "../../components/group/LessonMainContent";
import GET from "../../components/api/GET";
import {setLessonID} from "../../redux/action";
import {Link, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const handleGetLesson=async (lessonId)=>{
    const res=await GET.getByIdLesson(lessonId);
    if (res.success){
        setLessonID(res.res.data.lesson);
        return true;
    }
    return false;
}
export default function Lesson(){

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const lesson=useSelector(state => state.items.lesson_id);

    const [searchParams, setSearchParams] = useSearchParams();
    const lessonId=searchParams.get("lessonId");
    const groupId=searchParams.get("groupId");

    const [loader, setLoader]=useState(true);


    useEffect(()=>{
        handleGetLesson(lessonId).then((res)=>{
            res&&setLoader(false);
        });
    }, [])


    return (
        <Box>
            <Link to={"/group/?groupId="+groupId} style={{textDecoration:"none", padding:"8px", backgroundColor:"#1976D2", borderRadius:"4px", color:"white"}}>
                Guruhga qaytish
            </Link>

            {loader?
                <LoadingBeatLoader/>
                :
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Asosiy ma`lumotlar" {...a11yProps(0)} />
                            <Tab label="Dars yuzasidan testlar" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <LessonMainContent lesson={lesson}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Test/>
                    </TabPanel>
                </Box>}

        </Box>
    );
}