import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import {Link, useNavigate} from "react-router-dom";
import {days} from "../../components/modals/AddScheduleModal";
import {useSelector} from "react-redux";
import {MenuItem, Select, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {Clear} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {MultiSelect} from "react-multi-select-component";

const selectDays=days.map(value=>{
    return { label:value, value:value};
});



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const times=[
    { label:"Ertalabgi", value:0},
    { label:"Tushgi", value:1},
    { label:"Kechgi", value:2}
]

export default function InfoGroups(){

    const [data, setData]=useState([]);


    const getSelectDay=(day)=>{
       const handlerData=[];
       day.map(value=>{
           handlerData.push(value.value);
       });

       return handlerData.join(", ");
    }


    const handlerInfoGroups=async ()=>{
        const res=await GET.getInfoGroups({teacher_id:getSelectDay(teacherId), level_id:getSelectDay(levelId), day:getSelectDay(day), room_id:getSelectDay(roomId), time:getSelectDay(timeId), group_id:getSelectDay(groupId)});
        if (res.success){
            setData(res.res.data.groups);
        }else {
            toast.error("Xatolik");
        }
    }

    const handlerInfoGroupsRefresh=async ()=>{
        const res=await GET.getInfoGroups();
        if (res.success){
            setData(res.res.data.groups);
        }else {
            toast.error("Xatolik");
        }
    }

    const navigate=useNavigate();




    const levels=useSelector(state => state.otherInfoList.levels);
    const teachers=useSelector(state => state.otherInfoList.teachers);
    const rooms = useSelector(state => state.otherInfoList.rooms);
    const groups = useSelector(state => state.items.groups);


    const parsDataForMultiSelect=(info, type)=>{
        if (type==="teacher"){
            const handlerInfo=info.map(value=>{
                return { label:(value.name+" "+value.lastname), value:value.id}
            });
            return handlerInfo;
        }else {
            const handlerInfo=info.map(value=>{
                return { label:value.name, value:value.id}
            });
            return handlerInfo;
        }
    }




    useEffect(() => {
        handlerInfoGroups();
    }, []);


    const [levelId, setLevelId]=useState([]);
    const [teacherId, setTeacherId]=useState([]);
    const [day, setDay]=useState([]);
    const [roomId, setRoomId]=useState([]);
    const [timeId, setTimeId]=useState([]);
    const [groupId, setGroupId]=useState([]);



    const getDaysTime=(str)=>{
        if (str!==null&&str!==undefined){
            let array = str.split(",");
            return <div>
                {array.map(value=>{
                    return <Typography>{value}</Typography>
                })}
            </div>
        }
        return "";
    }


    return <>
            <div>


                {(data&&data.length>0)?
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                    <TableCell align={"center"}>Guruh nomi</TableCell>
                                    <TableCell align={"center"}>Darajasi</TableCell>
                                    <TableCell align={"center"}>O`qituvchi</TableCell>
                                    <TableCell align={"center"}>Dars kunlar</TableCell>
                                    <TableCell align={"center"}>Dars vaqtlari</TableCell>
                                    <TableCell align={"center"}>Xonasi</TableCell>
                                    <TableCell align={"center"}>O`quvchi soni</TableCell>
                                    <TableCell align={"center"}>To`lagan</TableCell>
                                    <TableCell align={"center"}>To`lamagan</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={"30px"} align={"center"}>Filter</TableCell>
                                    <TableCell align={"center"}>
                                        <Box width={"200px"}>
                                            <MultiSelect className={"selectcustom"}
                                                         options={parsDataForMultiSelect(groups, "")}
                                                         value={groupId}
                                                         onChange={setGroupId}
                                                         labelledBy="Select"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <Box width={"150px"}>
                                            <MultiSelect className={"selectcustom"}
                                                         options={parsDataForMultiSelect(levels, "")}
                                                         value={levelId}
                                                         onChange={setLevelId}
                                                         labelledBy="Select"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"center"} sx={{width:"300px"}}>
                                        <Box width={"200px"}>
                                            <MultiSelect className={"selectcustom"}
                                                         options={parsDataForMultiSelect(teachers, "teacher")}
                                                         value={teacherId}
                                                         onChange={setTeacherId}
                                                         labelledBy="Select"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <Box width={"130px"}>
                                            <MultiSelect className={"selectcustom"}
                                                         options={selectDays}
                                                         value={day}
                                                         onChange={setDay}
                                                         labelledBy="Select"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <Box width={"130px"}>
                                            <MultiSelect className={"selectcustom"}
                                                         options={times}
                                                         value={timeId}
                                                         onChange={setTimeId}
                                                         labelledBy="Select"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <Box width={"130px"}>
                                            <MultiSelect className={"selectcustom"}
                                                         options={parsDataForMultiSelect(rooms, "")}
                                                         value={roomId}
                                                         onChange={setRoomId}
                                                         labelledBy="Select"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"center"}></TableCell>
                                    <TableCell align={"center"}>
                                        <Tooltip title={"Qidirish"}>
                                            <IconButton color={"info"} onClick={handlerInfoGroups}>
                                                <SearchIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <Tooltip title={"Tozalash"}>
                                            <IconButton color={"error"} onClick={()=>{
                                                setTimeId([]);
                                                setDay([]);
                                                setRoomId([]);
                                                setLevelId([]);
                                                setTeacherId([]);
                                                setGroupId([])
                                                handlerInfoGroupsRefresh();
                                            }}>
                                                <Clear/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data&&data.map((value1, index) =>
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {index+1}
                                        </TableCell>
                                        <TableCell align={"center"} sx={{cursor:"pointer"}}>
                                            <Link to={"/group?groupId="+value1.group_id}>
                                                {value1.group_name}
                                            </Link>
                                        </TableCell>
                                        <TableCell align={"center"} >
                                            {value1.level_name}
                                        </TableCell>
                                        <TableCell align={"center"} >
                                            {value1.teacher_name}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.schedules_day.length>0&&value1.schedules_day.map(value=><Typography>{value}</Typography>)}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {getDaysTime(value1.time)}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.room_name}
                                        </TableCell>
                                        <TableCell align={"center"} >
                                            {value1.student_count}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.paid}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            {value1.unpaid}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :<div>
                        Ma`lumotlar mavjud emas
                    </div>
                }
            </div>
    </>
}