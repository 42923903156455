import {Button, FormControlLabel, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {checkAllApi, FileBaseUrl} from "../../components/api/DefaultApi";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useForm} from "react-hook-form";
import POST from "../../components/api/POST";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GET from "../../components/api/GET";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {useNavigate} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};



const statusList=[
    {value:"delete", name:"O`chirilgan"},
    {value: "active", name: "Aktiv"},
    {value: "inactive", name: "Aktiv emas"}
]

export default function AddSMSByStatus(){



    const [students, setStudents]=useState([]);

    const [loader, setLoader]=useState(true);

    const navigate=useNavigate();

    const [checkedAll, setCheckedAll]=useState(true);


    const allChecked=()=>{
        students.map((value, index)=>editedValue(index, checkedAll));
        setCheckedAll(!checkedAll);
        checkAmount();
    }

    const [status, setStatus]=useState("delete");


    const getStudentsByStatus=async ()=>{
        setLoader(true);
        const res=await GET.getStudentsByStatus(status);

        if (res.success){
            setStudents(res.res.data.students);
            setLoader(false);
        }
    }


    useEffect(()=>{
        getStudentsByStatus();
    }, []);

    useEffect(()=>{
        getStudentsByStatus();
    }, [status]);




    const editedValue=(index, checked, type)=>{
        let handlerValues=[...students];
        handlerValues[index].checked=checked;
        setStudents(handlerValues);
        if (type==="all"){
            checkAmount();
        }
    }


    const getValue=(index)=>{
       return students[index].checked?students[index].checked:false;
    }







    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {

        const handlerPhoneList=[...phoneList]

        students.map(user => {
            if (user.checked===true){
                handlerPhoneList.push(user.phone);
            }
        })



        const res=await POST.addSMS({...data, phone_numbers:handlerPhoneList, phone_list:JSON.stringify(handlerPhoneList)});
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast.error("Xatolik");
            return;
        }

        if (res.success){
            toast.success("Yaratildi.");
            reset();
            navigate("/sms");
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
        getStudentsByStatus();

    }




    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [phoneList, setPhoneList]=useState([])


    const [phoneNumber, setPhoneNumber]=useState();
    const addPhoneNumber = (event) => {
        event.preventDefault();
        const handlerPhoneNumberList=[...phoneList];
        handlerPhoneNumberList.push(phoneNumber);
        setPhoneList(handlerPhoneNumberList);
        setPhoneNumber(null);
        handleClose();
        checkAmount();
    };

    const checkAmount=()=>{
        let count=0;
        students.map(value => {
            if (value.checked===true){
                count++
            }
        })

        count+=phoneList.length;
        if (count>50){
            toast.success("Siz 50 tadan ortiq raqamni tanladingiz. ");
        }
    }


    const deletePhoneNumber=(item)=>{
        const handlerPhoneList=[...phoneList];
        let findIndex = handlerPhoneList.findIndex(value => value===item);
        handlerPhoneList.splice(findIndex, 1);
        setPhoneList(handlerPhoneList);
    }




    return (
        <>
            <Box>
                <Typography>
                    Foydalanuvchi holatini tanlang
                </Typography>
                <Select sx={{width:"100%"}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        MenuProps={MenuProps}
                        value={status}
                        label={""}
                        onChange={(event)=>setStatus(event.target.value)}

                >
                    {statusList.map((value) => (
                        <MenuItem
                            key={value.value}
                            value={value.value}
                        >
                            {value.name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>


            {loader?
                <LoadingBeatLoader/>:
                <Box component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"} sx={{display:'flex', flexDirection:"column", gap:"8px"}}>
                    <Box sx={{display:"flex", flexDirection:"column", width:"100%", gap:"12px"}}>
                        <Box sx={{display:"flex", flexDirection:"column", gap:"12px"}}>
                            <Box>
                                <Typography>SMS matnini kiriting:</Typography>
                                <textarea rows={5} style={{width:"100%"}}
                                          {...register("message")}
                                />
                            </Box>
                            <Box>
                                <Typography>Tavsifini kiriting:</Typography>
                                <textarea rows={5} style={{width:"100%"}}
                                          {...register("description")}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Button onClick={()=>allChecked()} color={checkedAll?"success":"error"} variant={"contained"}>
                        {checkedAll?"Hammasini belgilash":"Barchasini bekor qilish"}
                    </Button>


                    <Box>
                            <Box>
                                {
                                    students.map((value, index) =>
                                    <Box key={index} sx={{display:"flex", gap:"4px"}}>
                                        <Checkbox
                                            checked={getValue(index)}
                                            onChange={(event, checked)=> editedValue(index, checked)}
                                        />
                                        <Typography>
                                            {value.phone} {value.full_name}
                                        </Typography>
                                    </Box>)
                                }
                            </Box>
                    </Box>




                    <Box>
                        <Box>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"} marginY={"8px"}>
                                <Typography variant={"h4"} component={"h4"}>Siz sms jo`natmoqchi bo`lgan qo`shimcha telifon raqamlar:</Typography>
                                <Button variant={"contained"} color={"success"} onClick={handleOpen}>
                                    Qo`shish
                                </Button>
                            </Box>

                            <Box display={"flex"} flexWrap={"wrap"} gap={"4px"}>
                                {phoneList.length>0&&phoneList.map((value)=>
                                    <Box display={"flex"} alignItems={"center"} sx={{backgroundColor:"#1976D2", padding:"2px", borderRadius:"8px"}}>
                                        <Typography color={"white"}>{value}</Typography>
                                        <Tooltip title={"O`chirish"}>
                                            <IconButton color={"error"} onClick={()=>deletePhoneNumber(value)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                        <Button type={"submit"} color={"success"} variant={"contained"} >Saqlash</Button>
                        <Button color={"warning"} variant={"contained"} onClick={handleClose}>Yopish</Button>
                    </Box>
                </Box>
            }


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={addPhoneNumber}>

                        <PhoneInput
                            defaultCountry="UZ"
                            value={phoneNumber}
                            onChange={(value)=>{setPhoneNumber(value)}} />

                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Jo`natish</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}