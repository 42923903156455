import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {formatPrice} from "../../Other/useWindowSize";
import {Link, useNavigate} from "react-router-dom";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function PaymentsForDashboard({total}){

    const [data, setData]=useState(total?total.paid.data:[]);
    
    const navigate=useNavigate();


    return (
        <>
            <Box>
                {data.length>0
                    ?
                    <Box>
                        <Link style={{display:"flex", justifyContent:"right", textDecorationLine:"none"}} to={"/payments"}>
                            <Typography >Barcha to`lovlarni ko`rish</Typography>
                        </Link>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={"center"}>T/R</StyledTableCell>
                                        <StyledTableCell align={"left"}>O`quvchi</StyledTableCell>
                                        <StyledTableCell align={"right"}>Miqdori</StyledTableCell>
                                        <StyledTableCell align={"center"}>Guruhi</StyledTableCell>
                                        <StyledTableCell align={"center"}>Qarz hisoblangan sanasi</StyledTableCell>
                                        <StyledTableCell align={"center"}>Telifon raqami</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, index) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell align={"center"} width={"30px"} component="th" scope="row">
                                                {(index+1)}
                                            </StyledTableCell>
                                            <StyledTableCell width={"200px"} align={"start"}>{row.name}</StyledTableCell>
                                            <StyledTableCell align={"right"} width={"150px"} sx={{color:"green"}}>{formatPrice(row.sum)}</StyledTableCell>
                                            <StyledTableCell >{row.group_name}</StyledTableCell>
                                            <StyledTableCell align={"center"}>{row.date}</StyledTableCell>
                                            <StyledTableCell align={"right"}>{row.phone!==null?row.phone:"Telifon raqami mavjud emas"}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>





                    </Box>
                    :<Typography>To`laganlarni barchasini ko`rish</Typography>
                }
            </Box>

        </>
    );
}