import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {formatPrice} from "../../components/Other/useWindowSize";
import "./index.scss";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import {FormControl, MenuItem, Select} from "@mui/material";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import GroupsListForDashboard from "../../components/AdminHomePage/GroupsListForDashboard";
import Debtors from "../../components/AdminHomePage/Debtors";
import TopTeacherForDashboard from "../../components/AdminHomePage/TopTeacherForDashboard";
import TopStudentsForDashboard from "../../components/AdminHomePage/TopStudentsForDashboard";
import PaymentsForDashboard from "../../components/AdminHomePage/PaymentsForDashboard";
import {useNavigate} from "react-router-dom";
import LinearChart from "../../components/AdminHomePage/LinearChart";
import ChartStatistic from "../../components/ChartStatistic";
import ChartjsLine from "../../components/ChartList/ChartjsLine";


const style={
    border:"1px solid gray",
    backgroundColor:"#F5F5F5",
    padding:"12px",
    borderRadius:"8px",
    display:"flex",
    minHeight:"100px",
    justifyContent:"space-between",
    alignItems:"center",
}

const imgStyle={
    width:"70px",
    borderRadius: "50%"
}

export default function HomePageForAdmin(){


    const [adminAmount, setAdminAmount]=useState(0);
    const [studentAmount, setStudentAmount]=useState(0);
    const [teacherAmount, setTeacherAmount]=useState(0);
    const [groupAmount, setGroupAmount]=useState(0);
    const [roomAmount, setRoomAmount]=useState(0);
    const [normalUserCount, setNormalUserCount]=useState(0);

    const [groups, setGroups]=useState();
    const [lastMonth, setLastMonth]=useState();
    const [topStudents, setTopStudents]=useState();
    const [topTeachers, setTopTeachers]=useState();
    const [total, setTotal]=useState();

    const handlerGetDashboard=async ()=>{
        const res=await GET.getAllAdminDashboard();
        if (res.success){
            // console.log(res.res.data.dashboard);
            setTeacherAmount(res.res.data.dashboard.teacherAmount);
            setAdminAmount(res.res.data.dashboard.adminAmount);
            setGroupAmount(res.res.data.dashboard.groupAmount);
            setStudentAmount(res.res.data.dashboard.studentAmount);
            setNormalUserCount(res.res.data.dashboard.normalUserCount);
            setGroups(res.res.data.dashboard.groups);
            setLastMonth(res.res.data.dashboard.lastMonth);
            setTopStudents(res.res.data.dashboard.topStudents);
            setTopTeachers(res.res.data.dashboard.topTeachers);
            setRoomAmount(res.res.data.dashboard.roomAmount);
            setTotal(res.res.data.dashboard.total);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }

    const [revenueList, setRevenueList]=useState([]);
    const [revenueSum, setRevenueSum]=useState(0)
    const [expenseList, setExpenseList]=useState([]);
    const [expenseSum, setExpenseSum]=useState(0)

    const handlerGetMoneyInformation=async ()=>{
        const res=await GET.getAllMoneyInformation();
        if (res.success){
            setRevenueList(res.res.data.revenueList);
            setRevenueSum(res.res.data.revenueSum);
            setExpenseList(res.res.data.expenseList);
            setExpenseSum(res.res.data.expenseSum);
        }else if (!res.success) {
            toast.error("Xatolik");
        }
    }


    useEffect(()=>{
        handlerGetDashboard();
        handlerGetMoneyInformation();
    }, [])


    const [page, setPage]=useState(0);



    const [loader, setLoader]=useState(true);

    const navigate=useNavigate();

    return (
        <div>
            {loader?
                <LoadingBeatLoader/>
                :
            <Box>
                <Box className={"home"}>
                    <Box sx={style} onClick={()=>navigate("/students?tab=null&page=1")}>
                        <Box>
                            <Typography variant={"h4"}>{studentAmount}</Typography>
                            <Typography>Jami Talabalar soni</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/student.png"} />
                    </Box>

                    <Box sx={style} onClick={()=>navigate("/users?tab=2&page=1")} >
                        <Box>
                            <Typography variant={"h4"}>{teacherAmount}</Typography>
                            <Typography>Jami O`qituvchilar soni</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/teacher.png"} width={"50px"}/>

                    </Box>

                    <Box sx={style} onClick={()=>navigate("/group-list")}>
                        <Box>
                            <Typography variant={"h4"}>{groupAmount}</Typography>
                            <Typography>Jami guruhlar soni</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/group.png"} width={"70px"}/>
                    </Box>

                    <Box sx={style} onClick={()=>navigate("/worker")}>
                        <Box>
                            <Typography variant={"h4"}>{normalUserCount}</Typography>
                            <Typography>Ishchilar soni</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/worker.png"} width={"70px"}/>
                    </Box>

                    <Box sx={style} className={"money"} onClick={()=>navigate("/payments")}>
                        <Box>
                            <Typography variant={"h5"}>{formatPrice(revenueSum)}    </Typography>
                            <Typography>Jami tushum (so`mda)</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/money.png"} width={"50px"}/>
                    </Box>

                    <Box sx={style} className={"money"} onClick={()=>navigate("/output")}>
                        <Box>
                            <Typography variant={"h5"}>{formatPrice(expenseSum)}</Typography>
                            <Typography>Jami chiqim (so`mda)</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/money.png"} width={"50px"}/>
                    </Box>

                    <Box sx={style}
                        // onClick={()=>navigate("/rooms")}
                    >
                        <Box>
                            <Typography variant={"h5"} color={(revenueSum-expenseSum)>0?"success":"error"}>{formatPrice(revenueSum-expenseSum)}</Typography>
                            <Typography>Joriy oydagi foyda</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/money3d.jpg"} width={"70px"}/>
                    </Box>

                    <Box sx={style} onClick={()=>navigate("/users?tab=0&page=1")}>
                        <Box>
                            <Typography variant={"h4"}>{adminAmount}</Typography>
                            <Typography>Adminlar soni</Typography>

                        </Box>
                        <img style={imgStyle} src={"/assets/image/admin.png"} width={"70px"}/>
                    </Box>

                    {/*<Box sx={style} onClick={()=>navigate("/rooms")}>*/}
                    {/*    <Box>*/}
                    {/*        <Typography variant={"h4"}>{roomAmount}</Typography>*/}
                    {/*        <Typography>Jami Xonalar soni</Typography>*/}

                    {/*    </Box>*/}
                    {/*    <img style={imgStyle} src={"/assets/image/room.png"} width={"70px"}/>*/}
                    {/*</Box>*/}


                </Box>



                <ChartjsLine expenseList={expenseList} revenueList={revenueList}/>


                <Box display={"flex"} justifyContent={"flex-end"}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <Select sx={{width:"200px"}}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={page}
                                onChange={(event)=>setPage(event.target.value)}
                                label="Age"
                        >
                            <MenuItem value={0}>Guruhlar</MenuItem>
                            <MenuItem value={1}>To`laganlar</MenuItem>
                            <MenuItem value={2}>Qarzdorlar</MenuItem>
                            <MenuItem value={3}>Top O`qituvchilar</MenuItem>
                            <MenuItem value={4}>Top O`quvchilar</MenuItem>
                        </Select>
                    </FormControl>
                </Box>


                {page===0&&<GroupsListForDashboard groups={groups}/>}
                {page===1&&<PaymentsForDashboard total={total}/>}
                {page===2&&<Debtors total={total}/>}
                {page===3&&<TopTeacherForDashboard topTeachers={topTeachers}/>}
                {page===4&&<TopStudentsForDashboard topStudents={topStudents}/>}

            </Box>}

        </div>
    );
}