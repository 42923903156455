import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Card} from "@mui/material";
import {FileBaseUrl} from "../../api/DefaultApi";
import ReactPlayer from "react-player";
import LessonEditModal from "../../modals/LessonEditModal";


export default function LessonMainContent({lesson}){
    return (
        <div >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginBottom={"4px"}>
                <Typography>Dars haqida ma`lumot</Typography>
                <LessonEditModal lesson={lesson}/>
            </Box>
            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        Dars nomi:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", flex:1}}>
                    <Typography>
                        {lesson.name}
                    </Typography>
                </Box>
            </Box>

            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        Yaratilgan sana:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", flex:1}}>
                    <Typography>
                        {lesson.created_at?lesson.created_at:"Aniqlanmadi"}
                    </Typography>
                </Box>
            </Box>

            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        Tahrirlangan sana:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", flex:1}}>
                    <Typography>
                        {lesson.updated_at?lesson.updated_at:"Aniqlanmadi"}
                    </Typography>
                </Box>
            </Box>

            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        Tavsifi:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", flex:1}}>
                    <Typography
                        dangerouslySetInnerHTML={{__html: lesson.description?lesson.description:"Aniqlanmadi"}}
                    >
                        {/*{lesson.description?lesson.description:"Aniqlanmadi"}*/}
                    </Typography>
                </Box>
            </Box>

            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        Fayl:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", flex:1}}>
                        {lesson.file?
                            <ReactPlayer url={FileBaseUrl+lesson.file} width={"300px"} height={"200px"} controls={true}/>
                            :
                            <Typography>"Aniqlanmadi"</Typography>
                        }

                </Box>
            </Box>

            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        Testlar soni:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", flex:1}}>
                    <Typography>
                        {lesson.questions?
                            lesson.questions.length
                            :"Aniqlanmadi"}
                    </Typography>
                </Box>
            </Box>

            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        O`qishga ruxsat berilganmi:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"70%"}}>
                    <Typography>
                        {lesson.ready===0?
                            "Ruxsat berilmagan"
                            :"Ruxsat berilgan"}
                    </Typography>
                </Box>
            </Box>

            <Box display={"flex"}>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"30%"}}>
                    <Typography>
                        Takrorlashga ruxsat berilganmi:
                    </Typography>
                </Box>
                <Box sx={{border:"1px solid gray", padding:"4px", width:"70%"}}>
                    <Typography>
                        {lesson.repeat===0?
                            "Ruxsat berilmagan"
                            :"Ruxsat berilgan"}
                    </Typography>
                </Box>
            </Box>



            

        </div>
    );
}