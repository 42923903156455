import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {useSelector} from "react-redux";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function File(){

    const navigate=useNavigate();

    const lessonLevelList=useSelector(state => state.otherInfoList.levels);

    return (
        <>
            <Typography>
                Darajani tanlang:
            </Typography>


            <Box>
                        {lessonLevelList.map(value =>
                            <Box
                                onClick={()=>navigate("/files/"+value.id+"?name="+value.name)}
                                sx={{padding:"8px",
                                    border:"1px solid #5A84BB",
                                    marginY:"4px",
                                    backgroundColor:"#E0E9F5",
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    "&:hover": {
                                        cursor: "pointer", backgroundColor:"#9badcb"
                                    }
                                }}>
                                <Typography align={"center"}>
                                    {value.name}
                                </Typography>
                            </Box>
                        )}
            </Box>
        </>
    );
}