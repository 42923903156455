import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import toast from "react-hot-toast";
import React, {useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ParentLessonEditModal({lesson, groupId, lessonLevelList}){
    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {

        const newData={...lesson, name:data.name, level_id:data.level_id};
        const res=await POST.addLesson(newData);
        // checkAllApi(res);
        if (res.success){
            handleGetByIdGroup(groupId);

            toast.success("Tahrirlandi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Box>
                <Tooltip title="Tahrirlash">
                    <IconButton color={"warning"} onClick={handleOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box >
                            <TextField label={"Dars nomini kiriting:"}
                                       fullWidth
                                       defaultValue={lesson.name}
                                       {...register("name",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />

                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>

                        <Box>
                            <Box width={"100%"}>
                                <InputLabel id="demo-multiple-name-label">Darsni tanlang</InputLabel>
                                <Select sx={{width:"100%"}}
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        MenuProps={MenuProps}
                                        error={errors.level_id}
                                        defaultValue={lesson.level_id}
                                        {...register("level_id",{required:"Tanlash shart"})}
                                >
                                    {lessonLevelList.map((value) => (
                                        <MenuItem
                                            key={value.id}
                                            value={value.id}
                                        >
                                            {value.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            {errors.level_id&&<Typography color={"error"}>{errors.level_id.message}</Typography>}
                        </Box>


                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                            <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>

        </>
    );
}