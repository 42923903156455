import React, {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {MenuItem, Pagination, Select, TextField, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {getDateInfo} from "../Chat";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {formatPrice, functionEndDate, functionStartDate} from "../../components/Other/useWindowSize";
import AddOrEditWorkerBonusModal from "../../components/modals/AddOrEditWorkerBonusModal";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteWorkerBonusesModal from "../../components/modals/DeleteModal/DeleteWorkerBonusesModal";
import {useSelector} from "react-redux";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function WorkerBonuses(){


    const [userSalaryItems, setUserSalaryItems]=useState([]);
    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);
    const [totalPrice, setTotalPrice]=useState(0);


    const [loader, setLoader]=useState(true);







    const users=useSelector(state => state.otherInfoList.workers);




    const handlerGetWorkerBonus=async ()=>{
        const res=await GET.getAllWorkerBonus({page:page, start_date:startDate, end_date:endDate, user_id:userId});
        if (res.success){
            setUserSalaryItems(res.res.data.bonuses.data);
            setLastPage(res.res.data.bonuses.last_page);
            setTotalPrice(res.res.data.total_price);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }


    useEffect(()=>{
        handlerGetWorkerBonus();
    }, [])


    useEffect(()=>{
        handlerGetWorkerBonus();
    }, [page]);


    const [userId, setUserId]=useState(null);
    const [startDate, setStartDate]=useState(functionStartDate);
    const [endDate, setEndDate]=useState(functionEndDate);






    return (
        <>

            {loader?<LoadingBeatLoader/>:
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} marginBottom={"8px"}>
                    <Typography>
                        Ishchilarga bonus
                    </Typography>

                    <Box display={"flex"} gap={"8px"}>
                        <AddOrEditWorkerBonusModal type={"add"}
                                                   handlerGetWorkerBonus={handlerGetWorkerBonus}
                                                   users={users}

                        />
                    </Box>
                </Box>}

            {loader?<LoadingBeatLoader/>:
                <Box>
                    <Box display={'flex'} alignItems={"center"} justifyContent={"space-between"}>
                        <Box sx={{display:'flex', flexWrap:'wrap', marginY:2, gap:"4px"}}>
                            <Box>
                                <Typography>
                                    Boshlanish sanasi
                                </Typography >
                                <TextField
                                    type={"date"}
                                    value={startDate}
                                    onChange={event => setStartDate(event.target.value)}
                                />
                            </Box>

                            <Box>
                                <Typography>
                                    Tugash sanasi
                                </Typography>
                                <TextField
                                    type={"date"}
                                    value={endDate}
                                    onChange={event => setEndDate(event.target.value)}
                                />
                            </Box>


                            <Box>
                                <Typography>
                                    Ishchini tanlang
                                </Typography>
                                <Select sx={{minWidth:"200px"}}
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        MenuProps={MenuProps}
                                        value={userId}
                                        onChange={event => setUserId(event.target.value)}
                                >
                                    {users.map((value) => (
                                        <MenuItem
                                            key={value.id}
                                            value={value.id}
                                        >
                                            {value.name} {value.lastname&&" "+value.lastname}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                        <Box>
                            <Tooltip title={"Qidirish"}>
                                <IconButton color={"info"} onClick={()=>handlerGetWorkerBonus()}>
                                    <SearchIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Tozalash"}>
                                <IconButton color={"error"} onClick={()=>{
                                    setUserId(null);
                                    setStartDate("");
                                    setEndDate("")
                                }}>
                                    <HighlightOffIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>


                    {userSalaryItems!==undefined&&userSalaryItems.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:"grey"}}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>FIO</TableCell>
                                            <TableCell align={"center"}>Miqdori</TableCell>
                                            <TableCell align={"center"}>Sanasi</TableCell>
                                            <TableCell align={"center"}>Kim tomonidan berilgan</TableCell>
                                            <TableCell align={"center"}>Berilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                            <TableCell align={"center"}>Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userSalaryItems&&userSalaryItems.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                    "&:hover":{ backgroundColor:"#cbcbcb" }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {((page-1)*20)+index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.user_name}</Typography>
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    <Typography color={value1.sum>0?"green":"error"}>
                                                        {formatPrice(value1.sum)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.date}</Typography>
                                                </TableCell>
                                                <TableCell align={"right"}>{value1.admin_name}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                <TableCell align={"center"} >
                                                    <Box sx={{display:"flex"}}>
                                                        <AddOrEditWorkerBonusModal type={"edit"}
                                                                                   handlerGetWorkerBonus={handlerGetWorkerBonus}
                                                                                      salaryItem={value1}
                                                                                      users={users}
                                                        />
                                                        <DeleteWorkerBonusesModal id={value1.id} refresh={handlerGetWorkerBonus}/>

                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        <TableRow>
                                            <TableCell align={"center"} colSpan={2}>
                                                Jami:
                                            </TableCell>
                                            <TableCell sx={{fontSize:"18px"}} align={"right"} colSpan={1}>
                                                {formatPrice(totalPrice)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>Bonus/Jarimalar mavjud emas</Typography>
                        </Box>
                    }
                </Box>}
        </>
    );
}