import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import EditIcon from "@mui/icons-material/Edit";
import {StringToDouble} from "../../Other/useWindowSize";
import CurrencyInput from "react-currency-input-field";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function EditDiscountsLevelModal({id, sum, handlerGetDiscountLevels}){


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        // console.log(data);

        const handlerSum=StringToDouble(data.value);



        const res=await POST.editDiscountsLevel(id, {sum: handlerSum});
        checkAllApi(res);
        if (res.success){
            toast.success(sum>0?"Mukofot darajasi tahrirlandi":"Jarima darajasi tahrirlandi");
            reset();
            handleClose();
            handlerGetDiscountLevels();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>

            <Box>
                {sum>0?
                    <Tooltip title={"Mukofotni tahrirlash"}>
                        <IconButton onClick={handleOpen}>
                            <EditIcon color={"warning"} variant={"contained"}/>
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={"Jarimani tahrirlash"}>
                        <IconButton onClick={handleOpen}>
                            <EditIcon color={"warning"} variant={"contained"}/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box >
                            <Typography>
                                Miqdorini tahrirlang:
                            </Typography>
                            <Box sx={{display:"flex", alignItems:"center", border:"1px solid black", borderRadius:"4px"}}>
                                <AttachMoneyOutlinedIcon/>
                                <CurrencyInput
                                        defaultValue={Math.abs(sum)}
                                        placeholder={(sum>0?"Bonus":"Jarima")+" miqdorni kiriting:"}
                                               style={{width:"100%", padding:"12px 12px", fontSize:"16px", border:"none", outline:"none"}}
                                               groupSeparator={" "}
                                               decimalSeparator={"."}
                                               {...register("value",
                                                   {required:"Bo`sh bo`lishi mumkin emas."})}
                                />

                            </Box>
                            {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                        </Box>



                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>{sum>0?"Bunusni tahrirlash":"Jarimani tahrirlash"}</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}