import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import {setGroupsById, setLessonID} from "../../redux/action";
import {useSearchParams} from "react-router-dom";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import GroupMain from "../../components/group/GroupMain";
import GroupLessons from "../../components/group/GroupLessons";
import GroupUsers from "../../components/group/GroupUsers";
import GroupClassTime from "../../components/group/GroupClassTime";
import {checkAllApi} from "../../components/api/DefaultApi";
import useWindowSize from "../../components/Other/useWindowSize";
import GroupBattleInfo from "../../components/group/GroupBattleInfo";
import Notification from "../../components/Notification";
import AddImportantNotes from "../../components/modals/AddImportantNotes";
import GroupImportantNotes from "../../components/group/GroupImportantNotes";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const handleGetLesson=async (lessonId)=>{
    const res=await GET.getByIdLesson(lessonId);
    if (res.success){
        setLessonID(res.res.data.lesson);
        return true;
    }
    return false;
}


export const handleGetByIdGroup=async (groupId)=>{
    const res=await GET.getByIdGroup(groupId);
    let api = checkAllApi(res);
    if(!api){
        return false;
    }
    if (res.success){
        res.res.data.lessons.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
        res.res.data.lessons.map((value)=>{
            value.children.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
        })
        setGroupsById(res.res.data);
        return true;

    }
}



export default function GroupTabs(){



    const [value, setValue] = useState(2);
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");
    const paramsTab=searchParams.get("tab")

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchParams({tab:newValue, groupId:groupId});
    };

    const [isLoader, setIsLoader]=useState(true);






    const [loader, setLoader]=useState(true);


    useEffect(()=>{
        handleGetByIdGroup(groupId).then((res)=>{
            res&&setLoader(false);
        });
        paramsTab&&setValue(parseInt(paramsTab));
    }, [groupId])


    // const [orientation, setOrientation]=useState(useWindowSize().width>600?"horizontal":"vertical");



    return (
        <Box>

            {loader?
                <LoadingBeatLoader/>
                :

                <Box sx={{ maxWidth: { xs: "320px", sm: "100%" }, bgcolor: 'background.paper' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                    >
                        <Tab label="Asosiy ma`lumotlar" {...a11yProps(0)} />
                        <Tab label="Guruh darslari" {...a11yProps(1)} />
                        <Tab label="O`quvchilar" {...a11yProps(2)} />
                        <Tab label="Scheduling" {...a11yProps(3)} />
                        <Tab label="Battle info" {...a11yProps(4)} />
                        <Tab label="Notification" {...a11yProps(5)} />
                        <Tab label="Muhim xabarlar" {...a11yProps(6)} />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <GroupMain/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <GroupLessons/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <GroupUsers groupId={groupId}/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <GroupClassTime/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <GroupBattleInfo/>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <Notification/>
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <Box>
                            <GroupImportantNotes/>
                        </Box>
                    </TabPanel>
                </Box>
            }

        </Box>
    );
}