import Box from "@mui/material/Box";
import {Pagination} from "@mui/material";
import Typography from '@mui/material/Typography';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useEffect, useState} from "react";
import LoadingBeatLoader from "../../LoadingBeatLoader";
import {useParams} from "react-router-dom";
import GET from "../../api/GET";
import {getDateInfo} from "../../../page/Chat";


export default function UserExercise(){

    const params=useParams();


    const [data, setData]=useState(undefined);
    const [page, setPage]=useState(0);

    const [lastPage, setLastPage]=useState(1);

    const handlerGetUserExercise=async ()=>{
        const res=await GET.getByUserExercise(params.id, page);
        if (res.success){
            setData(res.res.data.exercises.data);
            setLastPage(res.res.data.exercises.last_page);
        }
        res&&setLoader(false);
    }

    useEffect(()=>{
        handlerGetUserExercise();
    }, [page])


    const [loader, setLoader]=useState(true);

    return (
        <>
            {loader===true?
                <LoadingBeatLoader/>
                :
                <Box width={"100%"}>
                    {/*<Box>*/}
                    {/*    User Discount*/}
                    {/*</Box>*/}

                    <Box sx={{marginY:"4px"}}>
                        {data!==undefined&&data.length>0?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Qaysi dars</TableCell>
                                                <TableCell align={"center"}>Davomiyligi</TableCell>
                                                <TableCell align={"center"}>Bali</TableCell>
                                                <TableCell align={"center"}>Bonus</TableCell>
                                                <TableCell align={"center"}>Boshlanish vaqti</TableCell>
                                                <TableCell align={"center"}>Tugash vaqti</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data&&data.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.lesson&&value1.lesson.name}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"} >{value1.time}</TableCell>
                                                    <TableCell align={"center"} >{value1.ball}</TableCell>
                                                    {
                                                        value1.discount!==null?
                                                            <TableCell align={"center"} >{value1.discount.sum}</TableCell>
                                                            :<TableCell align={"center"}>0</TableCell>
                                                    }

                                                    <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                    <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                    <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                                </Box>
                            </>
                            :
                            <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                                <Typography>Ushbu foydalanuvchining Exercise mavjud emas</Typography>
                            </Box>
                        }




                    </Box>





                </Box>
            }
        </>
    );
}