import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";

import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";

import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function ShowNotification({notification}){

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>

            <Box>
                <Tooltip title={"Ko`rish"}>
                    <IconButton onClick={handleOpen} color={"info"}>
                        <VisibilityIcon/>
                    </IconButton>
                </Tooltip>
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>

                        <Box>
                            <Typography>Jo`natilgan guruhlar</Typography>
                            <Box>
                                {notification.groups.map(value=><Link style={{backgroundColor:"#1976D2", padding:"2px", margin:"2px", color:"white", textDecorationLine:"none", borderRadius:"4px"}} >
                                    {value.name}
                                </Link>)}

                            </Box>
                        </Box>

                        <Box>
                            <Typography>Jo`natilgan foydalanuvchilar</Typography>
                            <Box sx={{width:"300px"}}>
                                {notification.students.map(value=><Link style={{backgroundColor:"#1976D2", padding:"2px", margin:"2px", color:"white", textDecorationLine:"none", borderRadius:"4px"}} >
                                    {value.name}
                                </Link>)}

                            </Box>
                        </Box>

                        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}