import { useEffect, useState } from "react";
import POST from "../api/POST";

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== "undefined") {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}
export default useWindowSize;



export const formatPrice = (num) => {
    let formated = (Math.round(num * 100) / 100).toFixed(2);
    return formated?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}


export const formatPhone = (phone) => {
    let b = phone.startsWith("998");
    if (b){
        const code=phone.substring(0, 3);
        const codeRegion=phone.substring(3, 5);
        const phoneOld=phone.substring(5, 8);
        const phoneMiddle=phone.substring(8, 10);
        const phoneEnd=phone.substring(10, 12);


        return  "+"+code+"-"+codeRegion+"-"+phoneOld+"-"+phoneMiddle+"-"+phoneEnd;
    }
    return phone;
}



export const generateUuid = function(){
    return Array
        .from(Array(16))
        .map(e => Math.floor(Math.random() * 255)
            .toString(16)
            .padStart(2,"0"))
        .join('')
        .match(/.{1,4}/g)
        .join('-')
}

export const StringToDouble=(value)=>{
    if (value!==undefined){
        let replaceAll = value.replaceAll(" ", "");
        return parseFloat(replaceAll);
    }
    return 0;
}


export const addUserMotion=async (data)=>{
    const res=await POST.addUserMotion(data);
}

const getNumber=(number)=>{
    let number1 = parseInt(number);

    if (number1>10){
        return number1;
    }else {
        return "0"+number1;
    }
}
let date = new Date();

export const functionStartDate=()=>{
    return (date.getFullYear()+"-"+getNumber(date.getMonth()+1)+"-01");
}

export const functionEndDate=()=>{
    return (date.getFullYear()+"-"+getNumber(date.getMonth()+1)+"-"+getNumber(date.getDate()));
}