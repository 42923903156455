import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {formatPrice} from "../Other/useWindowSize";
import {getDateInfo} from "../../page/Chat";
import Box from "@mui/material/Box";
import AddOrEditWorkerAdvancesModal from "../modals/AddOrEditWorkerAdvancesModal";
import DeleteWorkerAdvanceModal from "../modals/DeleteModal/DeleteWorkerAdvanceModal";
import {Pagination} from "@mui/material";
import GET from "../api/GET";


export default function MyAdvance(){
    const params=useParams();


    const [workerAdvancesList, setWorkerAdvancesList]=useState([]);
    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);


    const [loader, setLoader]=useState(true);
    const [totalPrice, setTotalPrice]=useState(0);

    const handlerGetWorkerAdvances=async ()=>{
        const res=await GET.getAllWorkerAdvances({page:page, user_id:params.id});
        if (res.success){
            setWorkerAdvancesList(res.res.data.advances.data);
            setLastPage(res.res.data.advances.last_page);
            setTotalPrice(res.res.data.total_price);
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }


    useEffect(()=>{
        handlerGetWorkerAdvances();
    }, [])



    return (<>
                <div>

                    {workerAdvancesList!==undefined&&workerAdvancesList.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:'grey'}}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>FIO</TableCell>
                                            <TableCell align={"center"}>Miqdori</TableCell>
                                            <TableCell align={"center"}>Berilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Kim tomonidan berilgan</TableCell>
                                            <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {workerAdvancesList&&workerAdvancesList.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                    "&:hover":{
                                                        backgroundColor:"#cbcbcb"
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {((page-1)*20)+index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.user_name}</Typography>
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    <Typography color={value1.sum>0?"success":"error"}>
                                                        {formatPrice(value1.sum)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    <Typography
                                                    >
                                                        {value1.date}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align={"right"}>{value1.admin_name}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                <TableCell align={"center"} >
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"} sx={{fontSize:"18px"}}>
                                                Jami:
                                            </TableCell>
                                            <TableCell align={"right"} sx={{fontSize:"18px"}}>
                                                {formatPrice(totalPrice)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>Avanslar mavjud emas</Typography>
                        </Box>
                    }
                </div>
            </>);
}