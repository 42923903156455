import TableCell from "@mui/material/TableCell";
import {Avatar, Tooltip} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BonusModal from "../../modals/BonusModal";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import {AvatarBaseUrl} from "../../api/DefaultApi";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {formatPhone} from "../../Other/useWindowSize";
import EditUserModal from "../../modals/EditUserModal";
import PasswordRecoveryModal from "../../modals/PasswordRecoveryModal";
import DeleteUserModal from "../../modals/DeleteModal/DeleteUserModal";
import DeleteUserByGroupModal from "../../modals/DeleteModal/DeleteUserByGroupModal";


export default function GroupUserId({user, index, groups,role, handlerGetUsers, userTypePayments, groupId}){

    const navigate=useNavigate();

    const handlerGetUserDiscounts=()=>{
        // console.log("Bonus berildi");
    }


    return (
        <>
            <TableRow
                key={user.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index+1}
                </TableCell>
                <TableCell align={"center"}>
                    <Link to={"/user-group/"+user.id}>
                        <Box sx={{display:'flex', flexDirection:'row', alignItems:"center", gap:"4px"}}>
                            <Avatar src={AvatarBaseUrl+user.avatar}/>
                            <Typography>
                                {user.name} {user.lastname&&" "+user.lastname}
                            </Typography>
                        </Box>
                    </Link>
                </TableCell>
                <TableCell align={"center"}>{formatPhone(user.phone)}</TableCell>
                {/*<TableCell align={"center"}>{user.phone?user.phone:"Telifon raqami mavjud emas"}</TableCell>*/}

                <TableCell align={"center"} width={"150px"} >
                    <Box display={"flex"} alignItems={"center"}>
                        {/*<Tooltip title={"To`liq ko`rish"}>*/}
                        {/*    <IconButton color={"info"} onClick={()=>navigate("/user-group/"+user.id+"?groupId="+groupId)}>*/}
                        {/*        <VisibilityIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        <BonusModal type={"add"} id={user.id} handlerGetUserDiscounts={handlerGetUserDiscounts}/>
                        <AttachMoneyOutlinedIcon/>
                        <BonusModal type={"remove"} id={user.id} handlerGetUserDiscounts={handlerGetUserDiscounts}/>

                    </Box>
                </TableCell>
                <TableCell>
                    {role&&
                        <EditUserModal
                            user={user}
                            handlerGetUsers={handlerGetUsers}
                            role={role}
                            userTypePayments={userTypePayments}
                            groups={groups}
                            groupId={groupId}
                        />
                    }

                    <PasswordRecoveryModal user={user}/>
                    <DeleteUserByGroupModal user={user} refresh={"refresh"}/>


                </TableCell>
            </TableRow>
        </>
    );
}