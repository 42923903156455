import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserInfo from "../../components/UserInfo";
import UserDiscount from "../../components/users/UserDiscount";
import UserPractise from "../../components/users/UserPractise";
import UserExercise from "../../components/users/UserExercise";
import UserIdPayments from "../../components/UserIdPayments";
import UserStatistic from "../../components/UsersForAdmin/UserStatistic";
import Typography from "@mui/material/Typography";
import UserIdSalaryMoney from "../../components/UserIdSalaryMoney";
import UserIdSalaryItems from "../../components/UserIdSalaryItems";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


export default function SalaryItemUserId(){

    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = useState(0);
    const tab = searchParams.get("tab");

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchParams({ tab: newValue });
    };

    useEffect(() => {
        tab && setValue(parseInt(tab));
    }, []);


    return (
        <>

            <Box
                sx={{
                    maxWidth: { xs: "320px", sm: "100%" },
                    bgcolor: "background.paper",
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Oylik ma`lumotlari" {...a11yProps(0)} />
                    <Tab label="Xodim tarixi" {...a11yProps(1)} />

                </Tabs>

                <TabPanel value={value} index={0}>
                    <UserIdSalaryMoney />
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <UserIdSalaryItems />
                </TabPanel>
            </Box>




            {/*<UserIdSalaryItems/>*/}
            {/*<UserIdSalaryMoney/>*/}
        </>
    );
}