import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import EditIcon from '@mui/icons-material/Edit';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function EditBattleTimeModal({groupId, time}){


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        // console.log(data);



        const res=await POST.editBattleTime({group_id:groupId, battle_time:data.value});
        checkAllApi(res);
        if (res.success){
            toast.success("Tahrirlandi");
            handleGetByIdGroup(groupId);
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>

            <Box>
                    <Tooltip title={"Mukofot berish"}>
                        <IconButton onClick={handleOpen}>
                            <EditIcon color={"warning"} variant={"contained"}/>
                        </IconButton>
                    </Tooltip>
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box >
                            <TextField label={"Vaqtni kiriting:"}
                                       defaultValue={time}
                                       type={"time"}
                                       fullWidth
                                       {...register("value",
                                           {required:"Bo`sh bo`lishi mumkin emas."})}
                            />

                            {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                        </Box>


                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}