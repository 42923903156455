import Box from "@mui/material/Box";
import {Button, InputLabel, Select, MenuItem, TextField, Tooltip} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import GET from "../../api/GET";
import CheckIcon from '@mui/icons-material/Check';
import IconButton from "@mui/material/IconButton";
import {addUserMotion, generateUuid, StringToDouble} from "../../Other/useWindowSize";
import CurrencyInput from "react-currency-input-field";
import {handleGetByIdGroup} from "../../../page/GroupTabs";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    padding:"10px",
    overflow:'auto',
    height:'60%',
    minWidth:"50%",
    display:'flex',
    gap:1,
    flexDirection:'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function AddUserModal({role, handlerGetUsers, groups, userTypePayments, groupId}) {

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();



    const onSubmit = async (data) => {
        // console.log(data);
        const res=await POST.addUserForAdmin({...data, price:StringToDouble(data.priceHandler), role_id:role.id, usertype:"0", status:"active", email:generateUuid()+"@wisdomlc.uz"});
        checkAllApi(res);

        if (res.success){
            toast.success("Yaratildi.");
            reset();
            if (handlerGetUsers==="refresh"){
                handleGetByIdGroup(groupId)
            }else {
                handlerGetUsers();
            }
            handleClose();
            addUserMotion({
                user_id: 1, type: "delete", success:true,
                description: "User qo`shildi"
            });
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }







    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [checkPhoneNumber, setCheckPhoneNumber]=useState(undefined);
    const [checkEmail, setCheckEmail]=useState(undefined);

    const getCheckNumberOrEmail=async (type, value)=>{
        const res=await GET.checkNumberOrEmail(type, value);
        if (res.success){
            type==="phone"?setCheckPhoneNumber(true):setCheckEmail(true);
        }else if (!res.success){
            if (res.res.response.status==409){
                type==="phone"?setCheckPhoneNumber(false):setCheckEmail(false);
            }
        }
    }




    return (
        <Box>
            {<Box>
                    {<Button variant={"contained"}
                                                 onClick={handleOpen}  color={"success"}>{role.display_name} qo`shish</Button>}

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>

                                <Box >
                                    <Typography>
                                        Ismini kiriting:
                                    </Typography>
                                    <TextField
                                        // label={"FIO ni kiriting:"}
                                        fullWidth
                                        {...register("name", {required:"Bo`sh bo`lishi mumkin emas"})}
                                        error={errors.name}
                                    />
                                    {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                                </Box>

                                <Box>
                                    <Typography>Familiyani kiriting: </Typography>
                                    <TextField
                                        // label={"Email ni kiriting:"}
                                        fullWidth
                                        {...register("lastname")}
                                    />
                                    {errors.lastname&&<Typography color={"error"}>{errors.lastname.message}</Typography>}
                                </Box>

                                <Box>
                                    <Typography>Otasining ismini kiriting: </Typography>
                                    <TextField
                                        // label={"Email ni kiriting:"}
                                        fullWidth
                                        {...register("fatherName")}
                                    />
                                    {errors.lastname&&<Typography color={"error"}>{errors.lastname.message}</Typography>}
                                </Box>

                                <Box>
                                    <Typography>Onasining ismini kiriting: </Typography>
                                    <TextField
                                        // label={"Email ni kiriting:"}
                                        fullWidth
                                        {...register("matherName")}
                                    />
                                    {errors.lastname&&<Typography color={"error"}>{errors.lastname.message}</Typography>}
                                </Box>


                                <Box >
                                    <Typography>Telifon raqamni kiriting: </Typography>
                                    <TextField
                                        defaultValue={"+998"}
                                        InputProps={{
                                            endAdornment:
                                                <Tooltip title={"Telifon mavjudligini tekshirish"}>
                                                    <IconButton position="end"
                                                                onClick={()=>getCheckNumberOrEmail("phone", watch().phone)}
                                                                color={checkPhoneNumber?"success":"error"}>
                                                        <CheckIcon/>
                                                    </IconButton>
                                                </Tooltip>,
                                        }}
                                        fullWidth
                                        {...register("phone", {required:"Bo`sh bo`lishi mumkin emas"})}
                                    />
                                    {checkPhoneNumber===false&&<Typography color={"error"}>Bunday telifon raqamli foydalanuvchi mavjud</Typography>}
                                    {errors.phone&&<Typography color={"error"}>{errors.phone.message}</Typography>}
                                </Box>

                                <Box >
                                    <Typography>Tug`ilgan kunini kiriting: </Typography>
                                    <TextField
                                        defaultValue={"+998"}
                                        error={errors.birthday}
                                        fullWidth
                                        type={"date"}
                                        {...register("birthday")}
                                    />
                                    {errors.birthday&&<Typography color={"error"}>{errors.birthday.message}</Typography>}
                                </Box>

                                <Box >
                                    <Typography>Otasining telifon raqamini kiriting: </Typography>
                                    <TextField
                                        defaultValue={"+998"}
                                        error={errors.fatherPhone}
                                        fullWidth
                                        {...register("fatherPhone")}
                                    />
                                    {errors.fatherPhone&&<Typography color={"error"}>{errors.fatherPhone.message}</Typography>}
                                </Box>

                                <Box >
                                    <Typography>Onasining telifon raqamini kiriting: </Typography>
                                    <TextField
                                        defaultValue={"+998"}
                                        error={errors.matherPhone}
                                        fullWidth
                                        {...register("matherPhone")}
                                    />
                                    {errors.matherPhone&&<Typography color={"error"}>{errors.matherPhone.message}</Typography>}
                                </Box>

                                <Box >
                                    <Typography>Manzilni kiriting: </Typography>
                                    <TextField
                                        error={errors.address}
                                        fullWidth
                                        {...register("address")}
                                    />
                                    {errors.address&&<Typography color={"error"}>{errors.address.message}</Typography>}
                                </Box>




                                <Box >
                                    <Typography>
                                        Parolni kiriting:
                                    </Typography>
                                    <TextField
                                        // label={"Telifon raqamini kiriting:"}
                                        fullWidth
                                        {...register("password", {required:"Bo`sh bo`lishi mumkin emas"})}
                                        error={errors.name}
                                    />
                                    {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                                </Box>

                                {role.name==="student"&&
                                    <Box width={"100%"}>
                                        <InputLabel id="demo-multiple-name-label">Guruhni tanlang</InputLabel>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                MenuProps={MenuProps}
                                                defaultValue={groupId}
                                                {...register("group_id")}
                                        >
                                            {groups.map((value) => (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.day&&<Typography color={"error"}>{errors.day.message}</Typography>}
                                    </Box>
                                }

                                {role.name==="student"&&
                                    <Box width={"100%"}>
                                        <InputLabel id="demo-multiple-name-label">To`lovni tanlang. </InputLabel>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                MenuProps={MenuProps}
                                                {...register("type_payment")}
                                        >
                                            {userTypePayments.map((value) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}
                                                >
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.day&&<Typography color={"error"}>{errors.day.message}</Typography>}
                                    </Box>
                                }

                                {
                                    role.name==="student"&&
                                    <Box >
                                        <Typography>
                                            Miqdorini kiriting:
                                        </Typography>
                                        <CurrencyInput
                                            groupSeparator={" "}
                                            decimalSeparator={"."}
                                            style={{padding:"12px", width:"100%", fontSize:"16px"}}
                                            fullWidth
                                            {...register("priceHandler")}
                                            defaultValue={0}
                                        />
                                    </Box>
                                }






                                <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                    <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </Box>}
        </Box>
    );
}