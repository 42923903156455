import Box from "@mui/material/Box";
import {Button, InputLabel, Select, MenuItem, Icon, Tooltip, TextField} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import GET from "../../api/GET";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {StringToDouble} from "../../Other/useWindowSize";
import CurrencyInput from "react-currency-input-field";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function AddOrEditWorkerAdvancesModal({type, salaryItem, handlerGetWorkerAdvances, users }) {

    const { register, setValue, handleSubmit, control, watch, reset, formState: { errors },} = useForm();

    const [isDiscount, setIsDiscount]=useState(true);

    const onSubmit = async (data) => {
        let newData;
        const sum=StringToDouble(data.priceHandler);
        if (sum<0){
            toast.error("Siz manfiy son kiritdingiz");
            return;
        }
        if (type==="add"){
            newData={...data};
        }else {
            newData={...data, id:salaryItem.id}
        }
        const res=await POST.addSalaryWorkerAdvances({...newData, sum });
        checkAllApi(res);

        if (res.success){
            handlerGetWorkerAdvances();
            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    

    const refresh=()=>{
        reset();
    }



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    return (
        <Box>
            {<Box>
                    <Box>
                        {type==="add"?
                            <Button variant={"contained"}
                                    onClick={handleOpen}  color={"success"}>
                                Qo`shish
                            </Button>
                            :
                        <Tooltip title={"Tahrirlash"}>
                            <IconButton onClick={handleOpen} color={"warning"}>
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>}
                    </Box>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                                <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                    <Box width={"100%"}>
                                        <Typography>
                                            Foydalanuvchini tanlang.
                                        </Typography>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                defaultValue={salaryItem?salaryItem.user_id:null}
                                                id="demo-multiple-name"
                                                MenuProps={MenuProps}
                                                error={errors.user_id}
                                                {...register("user_id", {required:"Tanlash shart"})}
                                        >
                                            {users.map((value) => (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    {errors.user_id&&<Typography color={"error"}>{errors.user_id.message}</Typography>}
                                </Box>


                                <Box>
                                    <Box>
                                        <Typography>Miqdorini kiriting </Typography>
                                        <CurrencyInput
                                            groupSeparator={" "}
                                            decimalSeparator={"."}
                                            style={{padding:"12px", width:"100%", fontSize:"16px"}}
                                            fullWidth
                                            {...register("priceHandler")}
                                            defaultValue={salaryItem?salaryItem.sum:null}
                                        />
                                        {errors.priceHandler&&<Typography color={"error"}>{errors.priceHandler.message}</Typography>}
                                    </Box>
                                </Box>

                                <Box>
                                    <Box>
                                        <Typography>Sanasini kiriting </Typography>
                                        <TextField
                                            fullWidth
                                            defaultValue={type==="edit"?salaryItem?salaryItem.date:null:new Date().toISOString().substr(0, 10)}
                                            type={"date"}
                                            {...register("date", {required:"Sanani tanlash shart"})}
                                        />
                                        {errors.date&&<Typography color={"error"}>{errors.date.message}</Typography>}
                                    </Box>
                                </Box>


                                <Box>
                                    <Typography>Tavsifini kiriting:</Typography>
                                    <textarea rows={5} style={{width:"100%"}}
                                              defaultValue={salaryItem?salaryItem.description:null}

                                              {...register("description")}
                                    />
                                </Box>





                                <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                    <Button type={"submit"} color={"success"} variant={"contained"}>{type==="add"?"Qo`shish":"Tahrirlash"}</Button>
                                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </Box>}
        </Box>
    );
}