import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import AddUserDebtor from "../../components/AddUserDebtor";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import GET from "../../components/api/GET";
import {checkAllApi} from "../../components/api/DefaultApi";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function AddDebtor(){

    const [studentAndGroup, setStudentAndGroup]=useState(null);


    const [groupId, setGroupId]=useState(null);
    const [groupIdError, setGroupIdError]=useState(false);
    const [text, setText]=useState(null);
    const [textError, setTextError]=useState(false);



    const changeGroupOrUser=(item)=>{
        setTextError(false);
        setGroupIdError(false);
        if (item===true){
            setText(null);
        }else if(item===false){
            setGroupId(null);
        }
        setUserNewOrderList(null)
        setStudentAndGroup(item)
    }



    const [groupList, setGroupList]=useState([]);

    const handleGetAllGroups=async ()=>{
        const res=await GET.getAllGroups();
        let checkAllApi1 = checkAllApi(res);
        if (res.success){
            setGroupList(res.res.data.all_groups);
        }
    }



    useEffect(()=>{
        if (studentAndGroup===true){
            handleGetAllGroups();
        }
    }, [studentAndGroup]);



    const [userNewOrderList, setUserNewOrderList]=useState(null);


    const submit=async (event)=>{
        event.preventDefault();


        if (studentAndGroup===true && groupId===null){
            setGroupIdError(true);
        }

        if (studentAndGroup===false && text===null){
            setTextError(true);
        }

        if (studentAndGroup!==null){
            handlerStudentNewOrder();
        }
    }


    const handlerStudentNewOrder=async ()=>{

        const res=await GET.getAllNewOrderForStudent({group_id:groupId, text:text});
        if (res.success){
            setUserNewOrderList(res.res.data);
        }else if (!res.success){
            toast.error("Foydalanuvchi topilmadi");
        }
    }



    return (
        <>
            <Box>
                Siz bu yerdan order yaratishingiz mumkin.
                Iltimos guruh yoki studentni tanglang.
            </Box>

            <Box sx={{width:"100%", display:"flex", marginY:2}}>
                <Button
                    fullWidth
                    variant={studentAndGroup&&"contained"}
                    color={studentAndGroup===true?"info":"inherit"}
                    onClick={()=>changeGroupOrUser(true)}
                >
                    Guruh
                </Button>

                <Button fullWidth color={"inherit"}
                        variant={studentAndGroup===false&&"contained"}
                        onClick={()=>changeGroupOrUser(false)}
                        color={studentAndGroup===false?"info":"inherit"}
                >
                    User
                </Button>
            </Box>

            {studentAndGroup===true&&
                <InputLabel sx={{marginY:1}} id="demo-multiple-name-label">Guruhni tanlang</InputLabel>
            }

            <Box display={'flex'} alignItems={"center"} component={"form"} onSubmit={submit}>
                {studentAndGroup===true&&
                    <Box width={"100%"}>
                        <Select sx={{width:"100%"}}
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                MenuProps={MenuProps}
                                placeholder={"Tanlang"}
                                onChange={(event)=>{
                                    setGroupIdError(false);
                                    setGroupId(event.target.value);
                                }}
                        >
                            {groupList.map((value) => (
                                <MenuItem
                                    key={value.id}
                                    value={value.id}
                                >
                                    {value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                }



                {studentAndGroup===false&&
                    <TextField fullWidth
                               label={"O`quvchining ismi, familiyasi yoki telifon raqamini kiriting"}
                               value={text}
                               onChange={event => {
                                   setText(event.target.value);
                                    event.target.value.length===0&&setText(null);
                                    setTextError(false);
                               }} />
                }


                {studentAndGroup!==null&&
                    <Box>
                        <Tooltip title={"Qidirish"} >
                            <IconButton color={"info"} type={"sumbit"}>
                                <SearchIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                }

            </Box>


            <Box>
                {(studentAndGroup===true&&groupIdError)&&
                    <Typography color={"error"}>Guruhni tanlang</Typography>
                }
                {(studentAndGroup===false&&textError)&&
                    <Typography color={"error"}>Matn kiriting</Typography>
                }
            </Box>


            {userNewOrderList!==null&&
                <Box>
                    {userNewOrderList.length===0&&
                        <Box>
                            <Typography>
                                Ma`lumot topilmadi
                            </Typography>
                        </Box>
                    }

                    {
                        userNewOrderList.length>0&&
                        <AddUserDebtor
                            userNewOrderList={userNewOrderList}
                            handlerStudentNewOrder={handlerStudentNewOrder}
                        />
                    }
                </Box>
            }

        </>
    );
}