import {dispatch} from "./story";
import {
    ERROR_NETWORK,
    GET_GROUP_ID,
    GET_GROUPS,
    GET_LESSON_ID,
    SET_FILES,
    SET_LEVEL,
    SET_MESSAGE,
    SET_PROFESSIONS,
    SET_ROLES, SET_ROOMS,
    SET_SALARY_TYPE,
    SET_STUDENTS,
    SET_TEACHERS,
    SET_WORKERS,
    TOGGLE_LOADING,
    TOGGLE_LOGIN
} from "./type";
export const setIsLogin=(value)=>{
    dispatch({type:TOGGLE_LOGIN, payload:value})
}
export const setIsLoading=(value)=>{
    dispatch({type:TOGGLE_LOADING, payload:value})
}
export const setIsErrorNetwork=(value)=>{
    dispatch({type:ERROR_NETWORK, payload:value})
}

export const setLessonID=(value)=>{
    dispatch({type:GET_LESSON_ID, payload:value})
}
export const setGroups=(value)=>{
    dispatch({type:GET_GROUPS, payload:value})
}
export const setGroupsById=(value)=>{
    dispatch({type:GET_GROUP_ID, payload:value})
}

export const setFiles=(value)=>{
    dispatch({type:SET_FILES, payload:value})
}

export const setMessageList=(value)=>{
    dispatch({type:SET_MESSAGE, payload:value})
}

export const setWorkers=(value)=>{
    dispatch({type:SET_WORKERS, payload:value})
}

export const setProfessions=(value)=>{
    dispatch({type:SET_PROFESSIONS, payload:value})
}

export const setSalaryType=(value)=>{
    dispatch({type:SET_SALARY_TYPE, payload:value})
}

export const setRoles=(value)=>{
    dispatch({type:SET_ROLES, payload:value})
}

export const setStudents=(value)=>{
    dispatch({type:SET_STUDENTS, payload:value})
}

export const setLevel=(value)=>{
    dispatch({type:SET_LEVEL, payload:value})
}

export const setTeachers=(value)=>{
    dispatch({type:SET_TEACHERS, payload:value})
}

export const setRooms=(value)=>{
    dispatch({type:SET_ROOMS, payload:value})
}


