import {useEffect, useState} from "react";
import {LinearProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import GET from "../api/GET";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {formatPrice} from "../Other/useWindowSize";
import {getDateInfo} from "../../page/Chat";



export default function UserIdSalaryMoney(){

    const params=useParams();

    const [loader, setLoader]=useState(true);

    const [data, setData]=useState();

    const handlerUserIdMoneySalary=async ()=>{
        const res=await GET.getSalaryIdSalaryMoney(params.id);
        if (res.success){
            setData(res.res.data);
            setLoader(false);
        }else {
            setLoader(false);

        }
    }


    useEffect(() => {
        handlerUserIdMoneySalary();
    }, []);




    return (<>
                <div>
                    {loader?
                        <LinearProgress color="success" />:
                        <div>
                            {/*{JSON.stringify(data)}*/}
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:'grey'}}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Davr</TableCell>
                                            <TableCell align={"center"}>Bonus</TableCell>
                                            <TableCell align={"center"}>Jarima</TableCell>
                                            <TableCell align={"center"}>Oylik miqdori</TableCell>
                                            <TableCell align={"center"}>Avanslar</TableCell>
                                            <TableCell align={"center"}>Qoldiq</TableCell>
                                            <TableCell align={"center"}>Tavsifi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data&&data.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                    "&:hover":{
                                                        backgroundColor:"#cbcbcb"
                                                    }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>{value1.period}</Typography>
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    <Typography >
                                                        {formatPrice(value1.bonuses)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    <Typography >
                                                        {formatPrice(value1.jarima)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    <Typography
                                                    >
                                                        {formatPrice(value1.salary)}
                                                    </Typography>

                                                </TableCell>

                                                <TableCell align={"right"}>
                                                    <Typography>
                                                        {formatPrice(value1.advances)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"right"}>
                                                    {formatPrice(value1.salary-value1.advances)}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <Typography>
                                                        {value1.protsent}
                                                    </Typography>
                                                </TableCell>
                                                {/*<TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>*/}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                </div>
            </>);
}