import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {Button, TextField, InputLabel, Select, MenuItem} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import {useSearchParams} from "react-router-dom";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import {checkAllApi, getTeacherId} from "../../api/DefaultApi";
import GET from "../../api/GET";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function ImportLessonOtherGroup() {

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();

    const [groupList, setGroupList]=useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");


    const handleGetAllGroups=async ()=>{
        const res=await GET.getAllGroups();
        let checkAllApi1 = checkAllApi(res);
        if (res.success){
            setGroupList(res.res.data.all_groups);
        }
    }

    useEffect(()=>{
        handleGetAllGroups();
    }, []);


    const onSubmit = async (data) => {
        const newData={...data, id:parseInt(groupId), teacher_id:parseInt(getTeacherId())};
        // console.log(newData);
        const res=await POST.importLessons(newData);
        checkAllApi(res);

        if (res.success){
            handleGetByIdGroup(groupId);
            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }


    const {group, lessons}=useSelector(state => state.items.group_id);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);




    return (
        <Box>
            {lessons.length===0&&
                <Box>
                    {lessons.length===0&&<Button startIcon={<VerticalAlignBottomIcon/>} sx={{backgroundColor:"#BEBEBE"}} variant={"contained"}
                                                 onClick={handleOpen}  color={"secondary"}>Guruhdan darslarni import qilish</Button>}

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>


                                <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                    <Box width={"100%"}>
                                        <InputLabel id="demo-multiple-name-label">Guruhni tanlang</InputLabel>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                MenuProps={MenuProps}
                                                error={errors.group_id}
                                                {...register("group_id", {required:"Tanlash kerak"})}
                                        >
                                            {groupList.map((value) => (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    {errors.group_id&&<Typography color={"error"}>{errors.group_id.message}</Typography>}
                                </Box>



                                <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                    <Button type={"submit"} color={"success"} variant={"contained"}>Import qilish</Button>
                                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </Box>}
        </Box>
    );
}