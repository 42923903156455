import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {BaseUrl, checkAllApi, FileBaseUrl} from "../../api/DefaultApi";
import ReactPlayer from "react-player";
import toast from "react-hot-toast";
import axios from "axios";
import POST from "../../api/POST";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {handleGetLesson} from "../../../page/Lesson";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    padding:"10px",
    overflow:'auto',
    height:'50%',
    minWidth:"70%",
    display:'flex',
    gap:1,
    flexDirection:'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function LessonEditModal({lesson}){
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");

    useEffect(()=>{
        lesson&&setDescription(lesson.description)
        setFileInfo({type:1, image:lesson.file})
    }, [lesson])

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();


    const onSubmit = async (data) => {
        // console.log(data);
        let newData;

        fileInfo!==undefined?
            newData={...lesson, file:fileInfo.image, name:data.name, description:description}
            :
            newData={...lesson, description:description, name:data.name}

        const res=await POST.addLesson({...newData,  id:lesson.id});
        checkAllApi(res);

        if (res.success){
            handleGetLesson(lesson.id);
            toast.success("Tahrirlandi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [description, setDescription]=useState("");

    const [fileInfo, setFileInfo]=useState(undefined);
    const [progress, setProgress] = useState(0);

    const handleUploadFile = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("image", event.target.files[0]);
        let substring = event.target.files[0]&&event.target.files[0].type.substring(0, 5);
        if (substring!="video"){
            toast.error("Siz mp4 formatdagi vedio tanlamadiz.");
            return;
        }
        try {
            const res=await axios.post(BaseUrl+"/api/teacher/practice/file", formData, {
                headers: {
                    "Content-Type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            })
            setFileInfo(res.data);
        }catch (e) {
            toast("Xatolik");
        }
    };

    // const { quill, quillRef } = useQuill();
    // useEffect(() => {
    //     if (quill) {
    //         quill.on("text-change", () => {
    //             setDescription(quillRef.current.firstChild.innerHTML);
    //         });
    //     }
    // }, [quill]);





    return (
        <>
            <Box>
                <Button onClick={handleOpen} variant={"contained"} color={"warning"}>Tahrirlash</Button>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>

                        <Box>
                            <TextField label={"Dars nomini kiriting:"}
                                       fullWidth
                                       defaultValue={lesson.name}
                                       {...register("name",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>
                        {/*<div*/}
                        {/*    style={{ width: "100%", height: "300px", color:"black", marginBottom:"100px" }}*/}
                        {/*>*/}
                        {/*    <div ref={quillRef} />*/}
                        {/*</div>*/}



                        <CKEditor
                            editor={ ClassicEditor }
                            data={description}

                            // onReady={ editor => {
                            //     // You can store the "editor" and use when it is needed.
                            //     console.log( 'Editor is ready to use!', editor.data );
                            // } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDescription(data);
                            } }
                            // onBlur={ ( event, editor ) => {
                            //     console.log( 'Blur.', editor );
                            // } }
                            // onFocus={ ( event, editor ) => {
                            //     console.log( 'Focus.', editor );
                            // } }
                        />

                        {fileInfo===undefined&&
                            <Box sx={{display:"flex", width:"100%", backgroundColor:"#d9dbe0", padding:"8px", justifyContent:"center"}}>
                                <label>
                                    <input
                                        // style={{display:"none"}}
                                        type={"file"} onChange={handleUploadFile} accept={"video/mp4,video/x-m4v,video/*"} />
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                        <img src={"/assets/icon/upload.svg"} width={"24px"} height={"24px"}/>
                                        <span>Videoni yuklang</span>
                                    </div>
                                </label>
                            </Box>
                        }


                        <Box width={"100%"}>
                            {(progress>0 && 100>progress)&&
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <div style={{backgroundColor:"gray", width:progress+"%", height:"4px"}}>
                                    </div>
                                    <span>
                                        {progress}%
                                    </span>
                                </Box>
                            }
                        </Box>

                        {fileInfo!==undefined&&
                            <Box sx={{alignSelf:"center"}}>
                                {fileInfo.type===0&&
                                    <Box>
                                        <img style={{objectFit: "contain"}} width={"300px"} height={"200px"} src={FileBaseUrl+fileInfo.image}/>
                                    </Box>
                                }
                                {fileInfo.type===1&&
                                    <Box>
                                        <ReactPlayer width={"300px"}
                                                     height={"200px"}
                                                     controls={true}
                                                     url={FileBaseUrl+fileInfo.image}/>
                                    </Box>
                                }
                                {fileInfo.type===2&&
                                    <Box>
                                        <a href={FileBaseUrl+fileInfo.image} download style={{display:"flex", justifyContent:"center"}}>
                                            <img src={"/assets/icon/file.svg"} width={"50px"}/>
                                        </a>
                                    </Box>
                                }
                                <Box display={"flex"} justifyContent={"center"} >
                                    <Button onClick={()=>setFileInfo(undefined)} color={"error"} variant={"contained"} sx={{marginTop:"4px"}}>
                                        O`chirish
                                    </Button>
                                </Box>
                            </Box>
                        }


                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>


        </>
    );
}