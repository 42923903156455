import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import GET from "../../components/api/GET";


export default function Card(){



    const [data, setData]=useState();

    const handlerCardList=async ()=>{
        const res=await GET.getCardList();
        console.log(res);
    }

    useEffect(() => {
        handlerCardList();
    }, []);




    return (<>
        <Box>
            <Box>

            </Box>
        </Box>
    </>)
}