import axios from "axios";
import {BaseUrl} from "./DefaultApi";



const token = localStorage.getItem("token");






const headers = {
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": `Bearer ${token}`
    }
}


const deleteTest=BaseUrl+"/api/teacher/questions/delete";             // test o`chirish
const deleteLesson=BaseUrl+"/api/teacher/lesson/delete";              // Lesson o`chirish
const deleteGroup=BaseUrl+"/api/teacher/group/delete";                // Guruh o`chirish
const deleteRoom=BaseUrl+"/api/teacher/rooms";                // Guruh o`chirish
const deleteSchedules=BaseUrl+"/api/admin/schedules";                // Guruh o`chirish
const deleteMessage=BaseUrl+"/api/student/message";                // Guruh o`chirish
const deleteFile=BaseUrl+"/api/teacher/uploaded/delete";                // Guruh o`chirish
const deleteNotification=BaseUrl+"/api/teacher/notification/delete";                // Guruh o`chirish
const deleteMainLesson=BaseUrl+"/api/teacher/mainlesson/delete";                // Guruh o`chirish
const deleteDiscount=BaseUrl+"/api/student/discount/delete";                // Guruh o`chirish
const deleteNews=BaseUrl+"/api/admin/new/delete";                // Guruh o`chirish
const deleteImportantNote=BaseUrl+"/api/teacher/important-note/delete";                // Guruh o`chirish
const deleteDiscountForAdmin =BaseUrl+"/api/teacher/discount/delete";
const deleteUser=BaseUrl+"/api/admin/student/delete";
const deleteOutputType=BaseUrl+"/api/admin/expense/delete";
const deleteOutput=BaseUrl+"/api/admin/output/delete";
const deleteProfession=BaseUrl+"/api/salary/profession/delete";
const deleteBonuses=BaseUrl+"/api/salary/bonuses/delete";
const deleteAdvances=BaseUrl+"/api/salary/advances/delete";
const deleteLessonLevel=BaseUrl+"/api/admin/lesson/level/delete";
const deleteUserItem=BaseUrl+"/api/salary/user-items/delete"
const deleteUserByGroup=BaseUrl+"/api/admin/remove-user-from-group"
const deleteExampOption=BaseUrl+"/api/exam/question-option/delete/"
const deleteExampQuestion=BaseUrl+"/api/exam/question/delete/";
const deleteExam=BaseUrl+"/api/exam/delete/";


const DELETE={
    deleteTest: async (id) => {
        try {
            const res = await axios.delete(deleteTest+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteExampOption: async (id) => {
        try {
            const res = await axios.delete(deleteExampOption+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteExam: async (id) => {
        try {
            const res = await axios.delete(deleteExam+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteExampQuestion: async (id) => {
        try {
            const res = await axios.delete(deleteExampQuestion+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },


    deleteBonuses: async (id) => {
        try {
            const res = await axios.delete(deleteBonuses+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteUserByGroup: async (id) => {
        try {
            const res = await axios.delete(deleteUserByGroup+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteAdvances: async (id) => {
        try {
            const res = await axios.delete(deleteAdvances+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteLesson: async (id) => {
        try {
            const res = await axios.delete(deleteLesson+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteLessonLevel: async (id) => {
        try {
            const res = await axios.delete(deleteLessonLevel+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteSchedules: async (id) => {
        try {
            const res = await axios.delete(deleteSchedules+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteProfession: async (id) => {
        try {
            const res = await axios.delete(deleteProfession+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteOutput: async (id) => {
        try {
            const res = await axios.delete(deleteOutput+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteRoom: async (id) => {
        try {
            const res = await axios.delete(deleteRoom+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteGroup: async (id) => {
        try {
            const res = await axios.delete(deleteGroup+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteMessage: async (id) => {
        try {
            const res = await axios.delete(deleteMessage+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },

    deleteFile: async (id) => {
        try {
            const res = await axios.delete(deleteFile+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteNotification: async (id) => {
        try {
            const res = await axios.delete(deleteNotification+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteMainLesson: async (id) => {
        try {
            const res = await axios.delete(deleteMainLesson+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteDiscount: async (id) => {
        try {
            const res = await axios.delete(deleteDiscount+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteNews: async (id) => {
        try {
            const res = await axios.delete(deleteNews+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteImportantNote: async (id) => {
        try {
            const res = await axios.delete(deleteImportantNote+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteDiscountForAdmin: async (id) => {
        try {
            const res = await axios.delete(deleteDiscountForAdmin+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteUser: async (id) => {
        try {
            const res = await axios.delete(deleteUser+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteOutputType: async (id) => {
        try {
            const res = await axios.delete(deleteOutputType+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },
    deleteUserItem: async (id) => {
        try {
            const res = await axios.delete(deleteUserItem+"/"+id,  headers);
            return {success:true, res:res}
        }catch (e){
            return {success: false, res:e}
        }
    },


}

export default DELETE;