import {Button, FormControlLabel, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {BaseUrl, checkAllApi, FileBaseUrl} from "../../components/api/DefaultApi";
import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import axios from "axios";
import {useForm} from "react-hook-form";
import POST from "../../components/api/POST";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GET from "../../components/api/GET";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {useNavigate} from "react-router-dom";


export default function AddNotification(){

    const [values, setValues]=useState([]);

    const [data, setData]=useState([]);

    const [loader, setLoader]=useState(true);

    const navigate=useNavigate();


    const setHandlerValues=async ()=>{
        const res=await GET.getAllGroupForNotification();
        if (res.success){
            setData(res.res.data.groups);
            const handlerData=[];
            res.res.data.groups.map((value)=>{
                handlerData.push({id: value.id, type: "group", value: false})
                value.students_group.map((value1)=>{
                    handlerData.push({id: value1.id, type: "user", value: false, groupId:value.id})
                })
            });
            setValues(handlerData);
            setLoader(false);
        }
    }


    useEffect(()=>{
        setHandlerValues();
    }, []);



    const editedValue=(id, checked, type, parentId)=>{
        let handlerValues=[...values];
        let groupValues=[];

        handlerValues.map((value)=> {
            if (value.id===id&&value.type===type){
                value.value=checked;
            }
            if (type==="group"){
                if (value.groupId===id){
                    value.value=checked;
                }
            }
            if (type==="user"&&value.groupId===parentId){
                groupValues.push(value.value);
            }
        });

        let count=0;

        groupValues.map((value)=>value===checked&&count++)


        if (count===groupValues.length){
            handlerValues.map((value)=>{
                if (value.type==="group"&&value.id===parentId){
                    value.value=checked;
                }
            })
        }

        if (count!==groupValues.length){
            if (!checked){
                handlerValues.map((value)=>{
                    if (value.type==="group"&&value.id===parentId){
                        value.value=checked;
                    }
                })
            }
        }
        setValues(handlerValues);
    }


    const [checked, setChecked]=useState(false);


    const allChecked=(handlerChecked)=>{
        const handlerValue=[...values];

        handlerValue.map(value => value.value=handlerChecked);
        // console.log(handlerValue);
        setValues(handlerValue);
    }


    const getValue=(id, type, parentId)=>{
        if (type==="group"){
            const find=values.find(value => value.id==id&&value.type==type);
            return find?find.value:false;
        }
        if (type==="user"){
            const find=values.find(value => value.id==id&&value.type=="user");
            return find?find.value:false;
        }
    }

    const [fileInfo, setFileInfo]=useState(undefined);
    const [progress, setProgress] = useState(0);

    const handleUploadFile = async (event) => {
        event.preventDefault();


        const formData = new FormData();
        formData.append("image", event.target.files[0]);

        try {
            const res=await axios.post(BaseUrl+"/api/teacher/practice/file", formData, {
                headers: {
                    "Content-Type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            })
            setFileInfo(res.data);
        }catch (e) {
            toast("Xatolik");
        }
    };




    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {

        const groups=[];
        const users=[];

        values.map((value)=>{
            (value.type=="group"&&value.value)&&groups.push(value.id);
        });

        values.map(user => {
            if (groups.length>0){
                if (user.type==="user"&&user.value){
                    let includes = groups.includes(user.groupId);
                    if (!includes){
                        users.push(user.id);
                    }
                }
            }else {
                if (user.type==="user"&&user.value){
                    users.push(user.id);
                }
            }
        })



        let newData={...data};

            fileInfo!==undefined?
                newData={...data, file:fileInfo.image, type:fileInfo.type, groups_id:groups, students_id:users}
                :
                newData={...data, groups_id:groups, students_id:users}

        const res=await POST.addGroupOrStudentsNotification(newData);
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast.error("Xatolik");
            return;
        }

        if (res.success){
            toast.success("Yaratildi.");
            setFileInfo(undefined);
            reset();
            navigate("/notification");
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
        allChecked(false);
    }

    return (
        <>
            {loader?
                <LoadingBeatLoader/>:
                <Box component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"} sx={{display:'flex', flexDirection:"column", gap:"8px"}}>
                    <Box sx={{display:"flex", flexDirection:"column", width:"100%", gap:"12px"}}>
                        <Box sx={{display:"flex", flexDirection:"column", gap:"12px"}}>
                            <Box sx={{width:"100%"}}>
                                <TextField label={"Titleni kiriting:"}
                                           fullWidth
                                           error={errors.title}
                                           {...register("title",
                                               {required:"Bo`sh bo`lishi mumkin emas.",
                                                   minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                                   maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                                />
                                {errors.title&&<Typography color={"error"}>{errors.title.message}</Typography>}
                            </Box>

                            <Box>
                                <Typography>Tavsifini kiriting:</Typography>
                                <textarea rows={5} style={{width:"100%"}}
                                          {...register("description")}
                                />
                            </Box>
                        </Box>

                        <Box sx={{alignSelf:"center"}}>
                            {fileInfo===undefined&&
                                <Box sx={{display:"flex", width:"100%", backgroundColor:"#d9dbe0", padding:"8px", justifyContent:"center"}}>
                                    <label>
                                        <input
                                            style={{display:"none"}}
                                            type={"file"} onChange={handleUploadFile} accept={"video/*, image/*"} />
                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                            <img src={"/assets/icon/upload.svg"} width={"24px"} height={"24px"}/>
                                            <span>Rasmni yuklang</span>
                                        </div>
                                    </label>
                                </Box>
                            }


                            <Box width={"100%"}>
                                {(progress>0 && 100>progress)&&
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <div style={{backgroundColor:"gray", width:progress+"%", height:"4px"}}>
                                        </div>
                                        <span>
                                        {progress}%
                                    </span>
                                    </Box>
                                }
                            </Box>

                            {fileInfo!==undefined&&
                                <Box sx={{alignSelf:"center"}} witdh={"100%"}>
                                    {fileInfo.type===0&&
                                        <Box>
                                            <img style={{objectFit: "contain"}} width={"300px"} height={"200px"} src={FileBaseUrl+fileInfo.image}/>
                                        </Box>
                                    }
                                    {fileInfo.type===1&&
                                        <Box>
                                            <ReactPlayer width={"300px"}
                                                         height={"200px"}
                                                         controls={true}
                                                         url={FileBaseUrl+fileInfo.image}/>
                                        </Box>
                                    }
                                    {fileInfo.type===2&&
                                        <Box>
                                            <a href={FileBaseUrl+fileInfo.image} download style={{display:"flex", justifyContent:"center"}}>
                                                <img src={"/assets/icon/file.svg"} width={"50px"}/>
                                            </a>
                                        </Box>
                                    }
                                    <Box display={"flex"} justifyContent={"center"} >
                                        <Button onClick={()=>setFileInfo(undefined)} color={"error"} variant={"contained"} sx={{marginTop:"4px"}}>
                                            O`chirish
                                        </Button>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>

                    <Button color={"success"} variant={"contained"} onClick={()=>{
                        allChecked(!checked);
                        setChecked(!checked);
                    }}>{checked?"Barchasini o`chir  ish":"Xammasini belgilash"}</Button>
                    <Box >
                        {data.map(value =>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    disableRipple={false}
                                >
                                    <FormControlLabel
                                        label={value.name}
                                        control={
                                            <Checkbox
                                                checked={getValue(value.id, "group")}
                                                onChange={(event, checked)=> editedValue(value.id, checked, "group" )}
                                            />
                                        }
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        value.students_group.map(value1 =>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                                <FormControlLabel
                                                    label={value1.name}
                                                    control={<Checkbox
                                                        checked={getValue(value1.id, "user", value.id)}
                                                        onChange={(event, checked)=> editedValue(value1.id, checked, "user", value.id)}
                                                    />}
                                                />
                                            </Box>
                                        )
                                    }
                                </AccordionDetails>

                            </Accordion>
                        )}
                    </Box>

                    <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                        <Button type={"submit"} color={"success"} variant={"contained"} disabled={(progress>0 && progress<100)&&true}>Jo`natish</Button>
                        <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                    </Box>
                </Box>
            }
        </>
    );
}