import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {formatPhone, formatPrice} from "../Other/useWindowSize";
import GenerataOrderModal from "../modals/GenerataOrderModal";


export default function AddUserDebtor({userNewOrderList, handlerStudentNewOrder}){




    return (
        <>
            <TableContainer component={Paper} sx={{marginY:4}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                            <TableCell align={"center"}>Nomi</TableCell>
                            <TableCell align={"center"}>Guruhi</TableCell>
                            <TableCell align={"center"}>Telifon raqami</TableCell>
                            <TableCell align={"center"}>Bonuslari</TableCell>
                            <TableCell align={"center"}>Oylik to`lovi</TableCell>
                            <TableCell align={"center"}>To`lov summasi</TableCell>
                            <TableCell align={"center"}>Amallar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userNewOrderList&&userNewOrderList.map((value1, index) =>
                            <TableRow
                                key={value1.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                            >
                                <TableCell component="th" scope="row">
                                    {index+1}
                                </TableCell>
                                <TableCell align={"center"}>
                                    <Typography>{value1.name+" "+value1.lastname}</Typography>
                                </TableCell>
                                <TableCell>
                                    {value1.group_name}
                                </TableCell>
                                <TableCell>
                                    {formatPhone(value1.phone)}
                                </TableCell>
                                <TableCell align={"center"} >
                                    {formatPrice(value1.discount)}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {formatPrice(value1.sum)}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {formatPrice(value1.total_amount)}
                                </TableCell>

                                <TableCell align={"center"} >
                                    <Box sx={{display:"flex"}}>
                                        <GenerataOrderModal type={"add"} item={value1}
                                                            handlerStudentNewOrder={handlerStudentNewOrder}/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

}