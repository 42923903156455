import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button,Tooltip} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../../api/DELETE";
import Typography from "@mui/material/Typography";
import {addUserMotion} from "../../../Other/useWindowSize";
import {handleGetByIdGroup} from "../../../../page/GroupTabs";
import {useSearchParams} from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function DeleteUserModal({user, refresh}){
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");

    const [description, setDescription]=useState("");

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDeleteUser=async ()=>{
        if (description.length<3){
            toast.error("O`chirish sababi kitilmagan");
            return;
        }

        const res=await DELETE.deleteUser(user.id);
        if (res.success){
            toast.success("O`chirildi");
            handleClose();
            if (refresh==="refresh") {
                handleGetByIdGroup(groupId)
            }else {
                refresh();
            }
            await addUserMotion({
                user_id: user.id, type: "delete", status:true, description: description
            })
        }else if (!res.success){
            toast.error("User mavjud emas yoki sizda huquq yo`q");
            await addUserMotion({
                user_id: user.id, type:"delete", status:false,
                description: description
            })
        }
    }


    return (
        <>

            <Box>
                <Tooltip title={"O`chirish"}>
                    <IconButton color={"error"} onClick={handleOpen} disabled={user.status==="delete"}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"}>
                        <Box>
                            Rostdan ham o`chirmoqchimisiz
                        </Box>

                        <Box>
                            <Typography>
                                O`chirish sababini kiriting. (majburiy)
                            </Typography>
                            <textarea style={{width:"100%"}}
                                      value={description}
                                      onChange={(event)=>setDescription(event.target.value)}
                                      rows={4}
                            />
                        </Box>

                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            <Button color={"success"} variant={"contained"} onClick={handleDeleteUser}>Ha, O`chirmoqchiman</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Bekor qilish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
