import {MenuItem, Popover, Tooltip} from "@mui/material";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Link} from "react-router-dom";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {addUserMotion} from "../../Other/useWindowSize";



export default function StudentActiveMore({row, handlerGetUsers}){

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const handlerStatusChange=async (status)=>{
        const res=await POST.userStatusChange({user_id: row.id, status});
        if (res.success){
            toast.success("Bajarildi");
            handlerGetUsers();
            addUserMotion({
                user_id: row.id, type: "change status", success:true,
                description: "User tahrirlandi.\n"+JSON.stringify({status:status})
            });
        }else {
            toast.error("Xatolik");
            addUserMotion({
                user_id: row.id, type: "change status", success:false,
                description: "User tahrirlandi.\n"+JSON.stringify({status:status})
            });
        }
    }




    return (

        <>
            <Tooltip title={"Ko`proq"}>
                <IconButton aria-describedby={id} variant="contained" onClick={handleClick} >
                    <MoreVertIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                            // border:"1px solid black"
                        },
                        boxShadow:"1px",
                    },
                }}
            >
                {row.status!=="active"&&
                    <MenuItem sx={{color:"#ff9800", fontWeight:"bold"}} onClick={()=>handlerStatusChange("active")}>
                        aktivlashtirish
                    </MenuItem>
                }
                {row.status!=="inactive"&&
                    <MenuItem sx={{color:"#ff9800", fontWeight:"bold"}} onClick={()=>handlerStatusChange('inactive')}>
                        aktivligini o`chirish
                    </MenuItem>
                }
            </Popover>
        </>
    );


}