import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useForm} from "react-hook-form";
import LoadingBeatLoader from "../../LoadingBeatLoader";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import GroupUserId from "../GroupUserId";
import AddUserModal from "../../modals/AddUserModal";
import GET from "../../api/GET";
import {checkAllApi} from "../../api/DefaultApi";
import {handleGetByIdGroup} from "../../../page/GroupTabs";


export default function GroupUsers({groupId}){
    const {group, lessons}=useSelector(state => state.items.group_id);
    const [isLoader, setIsLoader]=useState(false);

    const [role, setRole]=useState(null);
    const [groupList, setGroupList]=useState([]);

    const [userTypePayments, setUserTypePayments]=useState([]);


    const handlerGetRoles=async ()=>{
        const res=await GET.getAllRoles();
        checkAllApi(res);
        if (res.success){
            let roles = res.res.data.roles;
            let find = roles.find(item => item.name==="student");
            setRole(find);
        }else if (!res.success){
            toast.error("Role haqidagi ma`lumotlarni olib bo`lamadi");
        }
    }

    const handlerGetGroups=async ()=>{
            const res=await GET.getAllGroups();
            setGroupList(res.res.data.all_groups);
    }


    const handlerGetStudentsTypePayments=async ()=>{
            const res=await GET.getAllUserForAdmin();
            if (res.success){
                setUserTypePayments(res.res.data.type_payments);
            }else if (!res.success){
                toast.error("Foydalanuvchilarni olishda xatolik yuz berdi. ");
            }
    }


    useEffect(() => {
        handlerGetRoles();
        handlerGetGroups();
        handlerGetStudentsTypePayments();
    }, []);

    return (
        <Box>
            {role&&
                <Box>
                    <AddUserModal role={role}
                                  groups={groupList}
                                  userTypePayments={userTypePayments}
                                  handlerGetUsers={"refresh"}
                                  groupId={groupId}/>
                </Box>
            }


            {isLoader?
                <LoadingBeatLoader/>
                :<Box>
                    {group!==undefined?
                        <Box>
                            <Box>
                                {group.students_group.length>0?
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                    <TableCell align={"center"}>F.I.SH</TableCell>
                                                    <TableCell align={"center"}>Telifon raqami</TableCell>
                                                    {/*<TableCell align={"center"}>Telifon raqami</TableCell>*/}
                                                    <TableCell width={"100px"} align={"center"}>Bonus/Jarima</TableCell>
                                                    <TableCell width={"100px"} align={"center"}>Amallar</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {group.students_group.map((row, index) =>
                                                    <GroupUserId index={index}
                                                                 user={row}
                                                                 role={role}
                                                                 groups={groupList}
                                                                 userTypePayments={userTypePayments}
                                                                 handlerGetUsers={"refresh"}
                                                                 groupId={groupId}
                                                    />
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                <Typography>Talabalar mavjud emas</Typography>}
                            </Box>
                        </Box>:
                        <Typography>Siz so`ragan guruh mavjud emas</Typography>
                    }
                </Box>

            }
        </Box>
    );


}