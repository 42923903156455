import Box from "@mui/material/Box";
import {Button, Select, MenuItem, TextField} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import CurrencyInput from "react-currency-input-field";
import {StringToDouble} from "../../Other/useWindowSize";
import {useSelector} from "react-redux";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function AddOrEditUserSalaryItemModal({type, salaryItem, handlerGetSalaryItems, userItemsList }) {

    const { register,handleSubmit, watch, reset, formState: { errors }} = useForm();


    const users=useSelector(state => state.otherInfoList.workers);
    const salaryTypeList=useSelector(state => state.otherInfoList.salaryType);
    const professionList=useSelector(state => state.otherInfoList.professions);

    const onSubmit = async (data) => {
        console.log(data);
        let newData;
        if (type==="add"){
            newData={...data, amount:StringToDouble(data.priceHandler), protsent:StringToDouble(data.protsent), status:1};
        }else {
            newData={...data, amount:StringToDouble(data.priceHandler), protsent:StringToDouble(data.protsent), status:1}
        }
        const res=await POST.addSalaryUserItem(newData);
        checkAllApi(res);

        if (res.success){
            handlerGetSalaryItems();
            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    

    const refresh=()=>{
        reset();
    }



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [user, setUser]=useState(null);

    watch((data)=>{
        let isUser=false;
        userItemsList.map(value=>{
            if (value.user_id===data.user_id){
                setUser(value);
                isUser=true;
            }
        });

        if (!isUser){
            setUser(null);
        }
        console.log(data.user_id);
    });

    return (
        <Box>
            {<Box>
                    <Box>
                        {type==="add"?
                            <Button variant={"contained"}
                                    onClick={handleOpen}  color={"success"}>
                                Qo`shish
                            </Button>
                            :
                        <Button onClick={handleOpen} color={"warning"} variant={"contained"}>
                            Tahrirlash
                        </Button>}
                    </Box>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>

                                <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                    <Box width={"100%"}>
                                        <Typography>
                                            Foydalanuvchini tanlang.
                                        </Typography>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                defaultValue={salaryItem?salaryItem.user_id:null}
                                                id="demo-multiple-name"
                                                MenuProps={MenuProps}
                                                error={errors.user_id}
                                                {...register("user_id", {required:"Tanlash shart"})}
                                        >
                                            {users.map((value) => (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    {errors.user_id&&<Typography color={"error"}>{errors.user_id.message}</Typography>}
                                </Box>

                                {user&&<Box>
                                    <Typography>
                                        Bu hodimni oyligi bor
                                    </Typography>

                                    <Typography>
                                        Kasbi: {user.profession_name}
                                    </Typography>
                                    <Typography>
                                        Oylik turi : {user.type_name}
                                    </Typography>

                                    <Typography>
                                        Miqdori: {user.amount}
                                    </Typography>

                                    <Typography>
                                        Miqdori: {user.protsent}
                                    </Typography>
                                </Box>}

                                <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                    <Box width={"100%"}>
                                        <Typography>
                                            Oylik turini tanlang.
                                        </Typography>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                defaultValue={salaryItem?salaryItem.type:null}
                                                MenuProps={MenuProps}
                                                error={errors.type}
                                                {...register("type", {required:true})}
                                        >
                                            {salaryTypeList.map((value) => (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    {errors.type&&<Typography color={"error"}>{errors.type.message}</Typography>}
                                </Box>

                                <Box>
                                    <Typography>
                                        Vaqtini kiriting:
                                    </Typography>

                                    <TextField
                                        fullWidth
                                        type={"date"}
                                        defaultValue={salaryItem?salaryItem.date:null}
                                        {...register("date")}
                                    />
                                </Box>


                                <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                    <Box width={"100%"}>
                                        <Typography>
                                            Kasbini tanlang.
                                        </Typography>
                                        <Select sx={{width:"100%"}}
                                                labelId="demo-multiple-name-label"
                                                defaultValue={salaryItem?salaryItem.profession_id:null}
                                                id="demo-multiple-name"
                                                MenuProps={MenuProps}
                                                error={errors.profession_id}
                                                {...register("profession_id")}
                                        >
                                            {professionList.map((value) => (
                                                <MenuItem
                                                    key={value.id}
                                                    value={value.id}
                                                >
                                                    {value.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    {errors.profession_id&&<Typography color={"error"}>{errors.profession_id.message}</Typography>}
                                </Box>


                                <Box>
                                    <Box>
                                        <Typography>Miqdorini % kiriting </Typography>
                                        <TextField
                                            fullWidth
                                            defaultValue={salaryItem?salaryItem.protsent:0}
                                            {...register("protsent")}
                                        />
                                        {errors.protsent&&<Typography color={"error"}>{errors.protsent.message}</Typography>}
                                    </Box>
                                </Box>

                                <Box>
                                    <Box>
                                        <Typography>Miqdorini so`mda kiriting </Typography>
                                        <CurrencyInput
                                            groupSeparator={" "}
                                            decimalSeparator={"."}
                                            style={{padding:"12px", width:"100%", fontSize:"16px"}}
                                            fullWidth
                                            {...register("priceHandler")}
                                            defaultValue={salaryItem?salaryItem.amount:null}

                                        />
                                        {errors.priceHandler&&<Typography color={"error"}>{errors.priceHandler.message}</Typography>}
                                    </Box>
                                </Box>





                                <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                                    <Button type={"submit"} color={"success"} variant={"contained"}>{type==="add"?"Qo`shish":"Tahrirlash"}</Button>
                                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </Box>}
        </Box>
    );
}