import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {formatPrice} from "../../Other/useWindowSize";
import {Link, useNavigate} from "react-router-dom";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function TopTeacherForDashboard({topTeachers}){

    const [data, setData]=useState(topTeachers?topTeachers:[]);
    
    const navigate=useNavigate();


    return (
        <>
            <Box>
                {data.length>0
                    ?
                    <Box>
                        {/*<Link style={{display:"flex", justifyContent:"right", textDecorationLine:"none"}} to={"/group-list"}>*/}
                        {/*    <Typography >Barchasi guruhlarni ko`rish</Typography>*/}
                        {/*</Link>*/}
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={"center"}>T/R</StyledTableCell>
                                        <StyledTableCell align={"left"}>F.I.O.</StyledTableCell>
                                        <StyledTableCell align={"center"}>Guruh soni</StyledTableCell>
                                        <StyledTableCell align={"center"}>O`quvchilar soni</StyledTableCell>
                                        <StyledTableCell align={"center"}>Telifon raqami</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, index) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell align={"center"} width={"30px"} component="th" scope="row">
                                                {(index+1)}
                                            </StyledTableCell>
                                            <StyledTableCell width={"200px"} align={"start"}>{row.name}</StyledTableCell>
                                            <StyledTableCell width={"200px"} align={"center"}>{row.group_count}</StyledTableCell>
                                            <StyledTableCell align={"center"} width={"150px"}>{row.student_count}</StyledTableCell>
                                            <StyledTableCell align={"center"}>{row.phone}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>





                    </Box>
                    :<Typography>Top o`qituvchilar mavjud emas</Typography>
                }
            </Box>

        </>
    );
}