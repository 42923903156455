import Box from "@mui/material/Box";
import AddMainLessonModal from "../../components/modals/AddMainLessonModal";
import Typography from "@mui/material/Typography";
import GET from "../../components/api/GET";
import React, {useEffect, useState} from "react";
import MainLessonAccordion from "../../components/mainlesson/MainLessonAccordion";
import toast from "react-hot-toast";
import {InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {useSelector} from "react-redux";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MainLessons(){


    const [mainLessons, setMainLessons]=useState(undefined);
    const [searchParams, setSearchParams] = useSearchParams();

    const [loader, setLoader]=useState(true);


    const lessonLevelList=useSelector(state => state.otherInfoList.levels);
    const [levelId, setLevelId]=useState(undefined);
    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);




    const handlerGetMainLessons=async ()=>{
        setLoader(true);
        const res=await GET.getMainLessons({page:1, level_id:levelId});
        // console.log(res);
        if (res.success){
            setMainLessons(res.res.data.mainlessons.data);
            setLoader(false);
        }else {
            setLoader(false);
        }
    }

    useEffect(()=>{
        handlerGetMainLessons();
    }, [])

    useEffect(()=>{
        handlerGetMainLessons();
    }, [levelId, page])

    return (
        <>
            <Box sx={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
                <Typography>Asosiy darslar</Typography>
                <AddMainLessonModal lessonLevelList={lessonLevelList} type={"main"} length={10} parentLessonId={1} handlerGetMainLessons={handlerGetMainLessons}/>
            </Box>


            <Box>
                <Box width={"100%"}>
                    <InputLabel id="demo-multiple-name-label">Dars darajasini tanlang</InputLabel>
                    <Select sx={{width:"100%"}}
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            MenuProps={MenuProps}
                            value={levelId}
                            onChange={(event)=>setLevelId(event.target.value)}
                    >
                        {lessonLevelList.map((value) => (
                            <MenuItem
                                key={value.id}
                                value={value.id}
                            >
                                {value.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>








            {loader
                ?
                <LoadingBeatLoader/>
                :
            <div>
                {mainLessons!==undefined&&
                    <MainLessonAccordion lessonLevelList={lessonLevelList} mainLessons={mainLessons} handlerGetMainLessons={handlerGetMainLessons}/>
                }


                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                    <Pagination count={lastPage} defaultPage={page} onChange={(event)=>{
                        setSearchParams({page:event.currentTarget.textContent})
                        setPage(event.currentTarget.textContent);
                    }} color="primary" />
                </Box>
            </div>}
        </>
    );
}