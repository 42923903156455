import {useParams, useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";
import GET from "../../components/api/GET";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import './index.css';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {getDateInfo} from "../Chat";
import TableContainer from "@mui/material/TableContainer";
import AddPassTheLessonStudentModal from "../../components/modals/AddPassTheLessonStudentModal";
import {Button, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import POST from "../../components/api/POST";
import BonusModal from "../../components/modals/BonusModal";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Paper from "@mui/material/Paper";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PassTheLesson() {

    const params =useParams();
    const id=params.id;
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("group_id");

    const [students, setStudents]=useState([]);
    const [bonuses, setBonuses]=useState([]);
    const [lessonStudents, setLessonStudents]=useState([]);

    const getAllLessonStatus = async () => {
      const res=await GET.getAllLessonStatusStudent();
      if (res.success){
          setBonuses(res.res.data.battle_discounts);
      }else {
          toast.error("Bonuslarni olishda xatolik");
      }
    }

    const handlerGetAllLessonStatusStudent = async () => {
        const res=await GET.getAllLessonStatusStudentList(id);
        if (res.success){
            setLessonStudents(res.res.data.lessonStudents);
        }else {
            toast.error("Bonuslarni olishda xatolik");
        }
    }

    const getAllStudentByGroupId = async () => {
      const res=await GET.getStudentsForGroup(groupId);
      if (res.success){
          setStudents(res.res.data.students);
      }else {
          toast.error("Talabalarni olishda xatolik");
      }
    }

    const getActiveValue=(type, studentId)=>{
        let find = lessonStudents.find((value)=>value.student_id==studentId && value.battle_discount_type==type);
        if (find){
            return find.battle_discount_id;
        }else {
            return null;
        }
    }

    const getActiveValueId=(type, studentId)=>{
        let find = lessonStudents.find((value)=>value.student_id==studentId && value.battle_discount_type==type);
        if (find){
            return find.id;
        }else {
            return null;
        }
    }

    const [smsMessageList, setSMSMessageList]=useState([]);

    const addOrEditSMSMessage=(student_id,message)=>{
        const handlerSMSMessageList=[...smsMessageList];

        let check=false;
        handlerSMSMessageList.map((value)=>{
            if (value.student_id == student_id) {
                check=true;
                value.messages=message;
            }
        })
        if (!check){
            handlerSMSMessageList.push({student_id:student_id, messages:message})
        }

        setSMSMessageList(handlerSMSMessageList);
    }


    const changeStatus=async (student_id, bonus_id, type)=>{
        let activeValueId = getActiveValueId(type, student_id);
        let data;
        if (activeValueId){
            data={lesson_id:params.id,
                student_id:student_id,
                group_id:groupId,
                battle_discount_id:bonus_id,
                id :activeValueId
            }
        }else {
            data={lesson_id:params.id,
                student_id:student_id,
                group_id:groupId,
                battle_discount_id:bonus_id
            }
        }
        const res=await POST.addForLessonDiscount(data);
        if (res.success){
            addOrEditSMSMessage(student_id, res.res.data.message);
            handlerGetAllLessonStatusStudent();
        }else {
            toast.error("Xatolik");
        }
    }

    const getAllSMSMessage=async ()=>{
        const res=await GET.getAllSMSMessage(params.id);
        if (res.success){
            setSMSMessageList(res.res.data.messagesToParents);
        }
    }


    const sendMessage=async (student_id, student_name)=>{
        let messageId = getMessageId(student_id);
        if (messageId){
            toast.error("SMS junatilgan");
            return;
        }
        const res= await POST.sendSMSLessonInfo({student_id, lesson_id:params.id, message:getValueMessage(student_id)});
        if (res.success){
            toast.success(student_name + "ga SMS jo'natildi");
            const smsList=[...smsMessageList];
            smsList.map((value)=>{
                if (value.student_id==student_id){
                    value.id= res.res.data.message.id;
                }
            })
            setSMSMessageList(smsList);

        }else {
            if (res.res.response.status==403){
                toast.error(student_name+ "ga SMS jo'natilgan");
                return;
            }
            toast.error("Xatolik");
        }
    }


    console.log(smsMessageList)









    useEffect(()=>{
        getAllStudentByGroupId();
        getAllLessonStatus();
        handlerGetAllLessonStatusStudent();
        getAllSMSMessage();
    }, [])

    const getValueMessage = (id) => {
        let find = smsMessageList.find((value)=>value.student_id==id);
        if (find){
            return find.messages;
        }else {
            return "";
        }
    };
    const getMessageId = (id) => {
        let find = smsMessageList.find((value)=>value.student_id==id);
        if (find){
            return find.id;
        }else {
            return null;
        }
    };

    const getValueMessageLength = (id) => {
        let find = smsMessageList.find((value)=>value.student_id==id);
        if (find){
            return find.messages?find.messages.length:0;
        }else {
            return 0;
        }
    };

    const sendAllSMS = () => {
        students.map((value)=>{
            if (!value.id){
                sendMessage(value.id, value.name);
            }
        });
    }

    return(
        <Box>
            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginY:"10px"}}>
                <Typography component={"h4"} variant={"h4"}>
                    Dars davomati
                </Typography>

                <Box>
                    <Button onClick={sendAllSMS} variant={"contained"} color={"primary"} sx={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:"10px"}}>
                        <SendIcon/>
                        <Typography>
                            Barchaga SMS jo'natish
                        </Typography>
                    </Button>
                    <Typography color={"error"} sx={{fontSize:"14px"}}>
                        Holatlar o'zgarganda sms matni o'zgaradi
                    </Typography>
                </Box>
            </Box>

            <Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{backgroundColor:"#89918d"}}>
                            <TableRow>
                                <TableCell style={{color:"white"}} align={"center"}>
                                    {/*<svg color={"white"} width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*    <path*/}
                                    {/*        d="M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8L8.11 7.06L5 5.5L12 2L19 5.5V10.5H18V6L15.89 7.06L16 8ZM12 14C16.42 14 20 15.79 20 18V20H4V18C4 15.79 7.58 14 12 14Z"*/}
                                    {/*        fill="#0063A4"/>*/}
                                    {/*</svg>*/}
                                    Talabalar
                                </TableCell>
                                <TableCell style={{color:"white"}} align={"center"}>
                                    {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*    <path*/}
                                    {/*        d="M3 7H6V4H8V7H11V9H8V12H6V9H3V7ZM13 15H21V17H13V15ZM16.04 3H18.35L7.96 21H5.65L16.04 3Z"*/}
                                    {/*        fill="#0063A4"/>*/}
                                    {/*</svg>*/}
                                    Davomat
                                </TableCell>
                                <TableCell style={{color:"white"}} align={"center"}>
                                    {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*    <path*/}
                                    {/*        d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3ZM21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14ZM19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z"*/}
                                    {/*        fill="#0063A4"/>*/}
                                    {/*</svg>*/}
                                    Uyga vazifa
                                </TableCell>
                                <TableCell style={{color:"white"}} align={"center"}>
                                    {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*    <path*/}
                                    {/*        d="M9 5C10.0609 5 11.0783 5.42143 11.8284 6.17157C12.5786 6.92172 13 7.93913 13 9C13 10.0609 12.5786 11.0783 11.8284 11.8284C11.0783 12.5786 10.0609 13 9 13C7.93913 13 6.92172 12.5786 6.17157 11.8284C5.42143 11.0783 5 10.0609 5 9C5 7.93913 5.42143 6.92172 6.17157 6.17157C6.92172 5.42143 7.93913 5 9 5ZM9 15C11.67 15 17 16.34 17 19V21H1V19C1 16.34 6.33 15 9 15ZM16.76 5.36C18.78 7.56 18.78 10.61 16.76 12.63L15.08 10.94C15.92 9.76 15.92 8.23 15.08 7.05L16.76 5.36ZM20.07 2C24 6.05 23.97 12.11 20.07 16L18.44 14.37C21.21 11.19 21.21 6.65 18.44 3.63L20.07 2Z"*/}
                                    {/*        fill="#0063A4"/>*/}
                                    {/*</svg>*/}
                                    Darsga faollik
                                </TableCell>
                                <TableCell>
                                    SMS matni
                                </TableCell>
                                <TableCell style={{color:"white"}} align={"center"}>
                                    {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*    <path*/}
                                    {/*        d="M20 2H4C2.89 2 2 2.89 2 4V16C2 17.11 2.9 18 4 18H8V21C8 21.55 8.45 22 9 22H9.5C9.75 22 10 21.9 10.2 21.71L13.9 18H20C21.1 18 22 17.1 22 16V4C22 2.89 21.1 2 20 2ZM9.08 15H7V12.91L13.17 6.72L15.24 8.8L9.08 15ZM16.84 7.2L15.83 8.21L13.76 6.18L14.77 5.16C14.97 4.95 15.31 4.94 15.55 5.16L16.84 6.41C17.05 6.62 17.06 6.96 16.84 7.2Z"*/}
                                    {/*        fill="#0063A4"/>*/}
                                    {/*</svg>*/}
                                    Amallar
                                </TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>
                            {students.map((value, index) =>
                                <TableRow>
                                    <TableCell>
                                        {index+1}. {value.name} {value.lastname&&value.lastname}
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{width:"300px"}}>
                                            <Box width={"100%"}>
                                                <Select sx={{width:"100%"}}
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        MenuProps={MenuProps}
                                                        onChange={(event)=>{changeStatus(value.id, event.target.value, "0")}}
                                                        value={getActiveValue("0", value.id)}
                                                >
                                                    {bonuses.map((value) => value.type==0&&
                                                        <MenuItem
                                                            key={value.id}
                                                            value={value.id}
                                                        >
                                                            {value.title}
                                                        </MenuItem>

                                                    )}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{width:"300px"}}>
                                            <Box width={"100%"}>
                                                <Select sx={{width:"100%"}}
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        MenuProps={MenuProps}
                                                        onChange={(event)=>{changeStatus(value.id, event.target.value, "1")}}
                                                        value={getActiveValue("1", value.id)}
                                                >
                                                    {bonuses.map((value) => value.type==1&&
                                                        <MenuItem
                                                            key={value.id}
                                                            value={value.id}
                                                        >
                                                            {value.title}
                                                        </MenuItem>

                                                    )}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{width:"300px"}}>
                                            <Box width={"100%"}>
                                                <Select sx={{width:"100%"}}
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        MenuProps={MenuProps}
                                                        onChange={(event)=>{changeStatus(value.id, event.target.value, "2")}}
                                                        value={getActiveValue("2", value.id)}
                                                >
                                                    {bonuses.map((value) => value.type==2&&
                                                        <MenuItem
                                                            key={value.id}
                                                            value={value.id}
                                                        >
                                                            {value.title}
                                                        </MenuItem>

                                                    )}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <textarea disabled={getMessageId(value.id)?true:false} cols={20} rows={4} value={getValueMessage(value.id)} onChange={(event)=>addOrEditSMSMessage(value.id, event.target.value)}/>
                                        <Typography>
                                            Matn uzunligi: {getValueMessageLength(value.id)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Box style={{display:"flex", alignItems:"center"}}>
                                            <BonusModal type={"add"} id={value.id} handlerGetUserDiscounts={handlerGetAllLessonStatusStudent}/>
                                            <AttachMoneyOutlinedIcon/>
                                            <BonusModal type={"remove"} id={value.id} handlerGetUserDiscounts={handlerGetAllLessonStatusStudent}/>
                                        </Box>
                                        <Box sx={{display:"flex", justifyContent:"center"}}>
                                            <Tooltip title={"SMS jo'natish"}>
                                                <IconButton color={"primary"} onClick={()=>sendMessage(value.id, value.name)} disabled={getMessageId(value.id)?true:false}>
                                                    <SendIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>

            </Box>


            <Box>
                {/*<TableContainer component={Paper}>*/}
                {/*    <Table sx={{ minWidth: 650 }} aria-label="simple table">*/}
                {/*        <TableHead sx={{backgroundColor:'grey'}}>*/}
                {/*            <TableRow>*/}
                {/*                <TableCell width={"30px"} align={"center"}>T/R</TableCell>*/}
                {/*                <TableCell align={"center"} width={"250px"}>FIO</TableCell>*/}
                {/*                <TableCell align={"center"} width={"400px"}>Darsga qatnashganligi belgilash</TableCell>*/}
                {/*                <TableCell align={"center"}>Xolati</TableCell>*/}
                {/*                <TableCell align={"center"}>Amallar</TableCell>*/}
                {/*            </TableRow>*/}
                {/*        </TableHead>*/}
                {/*        <TableBody>*/}
                {/*            {students&&students.map((student, index) =>*/}
                {/*                <TableRow*/}
                {/*                    key={index}*/}
                {/*                    sx={{ '&:last-child td, &:last-child th': { border: 0 },*/}
                {/*                        "&:hover":{*/}
                {/*                            backgroundColor:"#cbcbcb"*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <TableCell component="th" scope="row">*/}
                {/*                        {index+1}*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align={"center"}>*/}
                {/*                        <Typography> {student.name} {student.lastname?student.lastname:""}</Typography>*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align={"right"} sx={{display:"flex", gap:"8px", flexWrap:"wrap", alignItems:"center", justifyContent:"center"}}>*/}
                {/*                        {bonuses.map((value)=><AddPassTheLessonStudentModal lessonId={id}*/}
                {/*                                                                            student={student}*/}
                {/*                                                                            bonus={value}*/}
                {/*                                                                            group_id={groupId}*/}
                {/*                                                                            refresh={handlerGetAllLessonStatusStudent} />)}*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align={"center"} >*/}
                {/*                        {lessonStudents.map((value)=>{*/}
                {/*                            if (value.student_id==student.id){*/}
                {/*                                return <Typography title={getDateInfo(value.created_at)}>*/}
                {/*                                    {value.title}*/}
                {/*                                </Typography>*/}
                {/*                            }*/}
                {/*                        })}*/}
                {/*                    </TableCell>*/}

                {/*                    <TableCell align={"right"}>{student.admin_name}</TableCell>*/}

                {/*                </TableRow>*/}
                {/*            )}*/}
                {/*        </TableBody>*/}
                {/*    </Table>*/}
                {/*</TableContainer>*/}
            </Box>



        </Box>
    )
}