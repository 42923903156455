import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import EditIcon from "@mui/icons-material/Edit";
import GET from "../../api/GET";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockResetIcon from "@mui/icons-material/LockReset";
import {formatPhone} from "../../Other/useWindowSize";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function PasswordRecoveryModal({user}){


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();






    const onSubmit = async (data) => {
        const res=await POST.passwordRecovery({id:user.id, password:data.password});
        checkAllApi(res);
        if (res.success){
            toast.success("Parol tahrirlandi");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isPassword, setIsPassword]=useState("password");


    return (
        <>

            <Box>
                <Tooltip title={"Parolni tiklash"}>
                    <IconButton color={"warning"}
                        onClick={handleOpen}
                    >
                        <LockResetIcon/>
                    </IconButton>
                </Tooltip>
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box>
                            <Typography>
                                Foydalanuvchining ismi: {user.name+" "+user.lastname}
                            </Typography>
                            <Typography>
                                Foydalanuvchining telifon raqami: <br/> {formatPhone(user.phone)}
                            </Typography>
                        </Box>

                        <Box >
                            <TextField label={"Parolni kiriting"}
                                       InputProps={{
                                           endAdornment: <IconButton position="end">
                                               {isPassword==="password"?<VisibilityIcon onClick={()=>{setIsPassword(isPassword==="password"?"text":"password")}}/>
                                                   :<VisibilityOffIcon onClick={()=>{setIsPassword(isPassword==="password"?"text":"password")}}/>}
                                           </IconButton>,
                                       }}
                                       fullWidth
                                       type={isPassword}
                                       {...register("password",
                                           {required:"Bo`sh bo`lishi mumkin emas."})}
                            />
                            {errors.password&&<Typography color={"error"}>{errors.password.message}</Typography>}
                        </Box>


                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Parolni tiklash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}