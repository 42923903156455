import {useNavigate, useParams} from "react-router-dom";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import Loader from "../Loader";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {Button, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddOrEditExamTest from "../../components/modals/AddOrEditExamTest";
import DeleteIcon from "@mui/icons-material/Delete";
import {useForm} from "react-hook-form";
import POST from "../../components/api/POST";


export default function ExamTypeId(){

    const { register,
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
        setValue
    } = useForm();

    const navigate=useNavigate();

    const onSubmit = async (data) => {
        let question_ids=[];
        selectQuestionList.map((value)=>{
            question_ids.push(value.id);
        });

        if (data.count>question_ids.length){
            toast.error("Siz imtihonga tushish kerak bo'ladigan soncha test tanlamagansiz");
            return;
        }


        const handlerData=exam?{question_ids:question_ids, ...data, id:exam.id}:{question_ids:question_ids, ...data}
            const res=await POST.addOrEditExam(handlerData);
            if (res.success){
                toast.success(exam?"Imtihon tahrirlandi":"Imtihon saqlandi");
                navigate('/exam-type');
            }
            if (!res.success){
                toast.error("Xatolik");
            }

    };



    const params=useParams();
    const [exam, setExam]=useState(undefined);
    const [loader, setLoader]=useState(true);
    const [isMoreButton, setIsMoreButton]=useState(true);
    const [isDisabled, setIsDisabled]=useState(true);

    const getExamId=async ()=>{
        setLoader(true);
        const res=await GET.getAllExamId(params.id);
        if (res.success){
            let examData=res.res.data.exam;
            setValue("title", examData.title);
            setValue("descriptin", examData.descriptin);
            setValue("count", examData.count);
            setValue("pass_score", examData.pass_score);
            setExam(examData);
            setLoader(false);

        }else {
            toast.error("Imtihon turini olishda xatolik");
        }
    }
    const [questionList, setQuestionList]=useState([]);


    const lessonLevelList=useSelector(state => state.otherInfoList.levels);

    const getAllQuestion=async ()=>{
        const res=await GET.getAllQuestionByLevel();
        if (res.success){
            setQuestionList(res.res.data.examQuestions);
        }else {
            toast.error("Test savollarini olishda xatolik");

        }
    }

    const setQuestion=()=>{
            if (exam){
                let questionExam=JSON.parse(exam.question_ids);
                console.log(questionExam);
                const selectQuestionListHandler=[];
                const noSelectQuestionListHandler=[];

                questionList.map((question)=>{
                    questionExam.includes(question.id)?selectQuestionListHandler.push(question):noSelectQuestionListHandler.push(question);
                })
                setSelectQuestionList(selectQuestionListHandler);
                setNoQuestionList(noSelectQuestionListHandler);
            }else {
                setNoQuestionList(questionList);
            }
    }


    useEffect(() => {
        if (params.id){
            getExamId();
            setIsDisabled(true);
        }else {
            setIsDisabled(false)
            setLoader(false);
        }

        getAllQuestion();
    }, []);


    const [noQuestionList, setNoQuestionList]=useState([]);
    const [selectQuestionList, setSelectQuestionList]=useState([]);

    const addQuestion=(index, question)=>{
        const handlerSelectQuestionList=[...selectQuestionList];
        const handlerNoSelectQuestionList=[...noQuestionList];

        handlerNoSelectQuestionList.splice(index, 1);
        handlerSelectQuestionList.push(question);
        setSelectQuestionList(handlerSelectQuestionList);
        setNoQuestionList(handlerNoSelectQuestionList);
    }

    const deleteQuestion=(index, question)=>{
        const handlerSelectQuestionList=[...selectQuestionList];
        const handlerNoSelectQuestionList=[...noQuestionList];

        handlerSelectQuestionList.splice(index, 1);
        handlerNoSelectQuestionList.push(question)

        setSelectQuestionList(handlerSelectQuestionList);
        setNoQuestionList(handlerNoSelectQuestionList);
    }



    return (
        <>
            <div>
                {loader?
                    <Loader/>
                    :
                    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <p>
                            {JSON.stringify(exam)}
                        </p>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography>
                                {exam?exam.title:"Yangi kiritilayotgan imtihon"}
                            </Typography>

                            <Box style={{display:"flex", gap:"8px", alignItems:"center"}}>
                                {exam&&
                                    <div >
                                        {isDisabled?
                                            <Button onClick={()=>setIsDisabled(false)} variant={"contained"} color={"warning"}>
                                                Tahrirlash
                                            </Button>:
                                            <Button onClick={()=>setIsDisabled(true)} variant={"contained"} color={"error"}>
                                                Bekor qilish
                                            </Button>
                                        }
                                    </div>
                                }
                                {!isDisabled&&
                                    <Button type={"submit"} variant={"contained"} color={"success"}>
                                        Saqlash
                                    </Button>
                                }
                            </Box>
                        </Box>




                        <Box sx={{display:"flex", flexGrow:"grow", gap:"8px"}}>
                            <Box>
                                <Typography>
                                    Imtihon nomini kiriting:
                                </Typography>
                                <TextField
                                    type={"string"}
                                    disabled={isDisabled}
                                    {...register("title", {required:"Imtihon nomi bo'sh bo'lishi mumkin emas"})}/>
                                {errors.title&&<Typography color={"error"}>{errors.title.message}</Typography>}
                            </Box>

                            <Box>
                                <Typography>
                                    Imtihon savollari sonini kiriting:
                                </Typography>
                                <TextField
                                    type={"number"}
                                    disabled={isDisabled}
                                    {...register("count", {required:"Savollar soni bo'sh bo'lishi mumkin emas", min:{value:5, message:"5 ta savoldan kam bo'lishi mumkin emas"}})}/>
                                {errors.count&&<Typography color={"error"}>{errors.count.message}</Typography>}
                            </Box>

                            <Box>
                                <Typography>
                                    Imtihon o'tish balini kiriting:
                                </Typography>
                                <TextField
                                    type={"number"}
                                    disabled={isDisabled}
                                    {...register("pass_score", {required:"O'tish bali bo'lishi shart", min:{value:5, message:"5 balldan kam bo'lishi mumkin emas"}, max:{value:100, message:"100 baldan ortiq bo'lishi mumkin emas."}})} />
                                {errors.pass_score&&<Typography color={"error"}>{errors.pass_score.message}</Typography>}
                            </Box>
                        </Box>

                        <Box>
                            <Typography>
                                Imtihon tavsifini kiriting:
                            </Typography>
                            <TextField
                                fullWidth {...register("descriptin")}
                                disabled={isDisabled}
                            />

                        </Box>



                        {isMoreButton &&
                            <Button onClick={() => {
                                setQuestion();
                                setIsMoreButton(false);
                            }}>
                                Testlarni ko'rish
                            </Button>
                        }


                        {!isMoreButton &&
                            <Box sx={{display:"flex", flexDirection:"column", gap:"8px"}}>
                                {lessonLevelList.map((level) =>

                                    <Box>
                                        <Typography align={"center"}>
                                            {level.name}
                                        </Typography>
                                        <Box sx={{display: "flex", gap:"32px"}}>
                                            <Box sx={{width: "300px",
                                                overflowY:"scroll",
                                                height:"400px",
                                                border:"1px solid gray",
                                                borderRadius:"8px",
                                                gap:"8px",
                                                display:"flex",
                                                flexDirection:"column",
                                                padding:"4px",
                                            }}>
                                                <Typography>
                                                    Tanlanmagan savollar
                                                </Typography>
                                                {noQuestionList.map((question, index) => question.level_id==level.id&&
                                                    <div  style={{                                                    border:"1px solid gray",
                                                        borderRadius:"8px",
                                                        display:"flex",
                                                        flexDirection:"column",
                                                        justifyContent:"space-between",
                                                        padding:"4px",
                                                        gap:"8px",
                                                        backgroundColor: "#d5cdcd"
                                                    }}>
                                                        <Typography title={question.question} className={"line2"}>
                                                            {question.question}
                                                        </Typography>
                                                        <Box style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                                                            <AddOrEditExamTest level={level}
                                                                               type={"show"}
                                                                               question={question}
                                                                               refresh={getExamId}
                                                            />
                                                            <Tooltip title={"Ko'chirish"}>
                                                                <IconButton color={"primary"} disabled={isDisabled} onClick={()=>{addQuestion(index, question)}}>
                                                                    <ArrowForwardIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </div>
                                                )}
                                            </Box>

                                            <Box sx={{width: "300px",
                                                overflowY:"scroll",
                                                height:"400px",
                                                border:"1px solid gray",
                                                borderRadius:"8px",
                                                gap:"8px",
                                                display:"flex",
                                                flexDirection:"column",
                                                padding:"4px"
                                            }}>
                                                <Typography>
                                                    Tanlangan savollar
                                                </Typography>
                                                {selectQuestionList.map((question, index) => question.level_id == level.id &&
                                                    <div style={{
                                                        border: "1px solid gray",
                                                        borderRadius: "8px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        padding: "4px",
                                                        gap: "8px",
                                                        backgroundColor: "#d5cdcd"
                                                    }}>
                                                        <Typography title={question.question} className={"line2"}>
                                                            {question.question}
                                                        </Typography>
                                                        <Box style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%"
                                                        }}>
                                                            <AddOrEditExamTest level={level}
                                                                               type={"show"}
                                                                               question={question}
                                                                               refresh={getExamId}
                                                            />
                                                            <Tooltip title={"Ko'chirish"}>
                                                                <IconButton color={"error"}
                                                                            disabled={isDisabled}
                                                                            onClick={()=>{deleteQuestion(index, question)}}>
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </div>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        }


                    </Box>
                }
            </div>
        </>
    );

}