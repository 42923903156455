import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useForm} from "react-hook-form";
import GET from "../../api/GET";
import {checkAllApi} from "../../api/DefaultApi";
import LoadingBeatLoader from "../../LoadingBeatLoader";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {setGroupsById} from "../../../redux/action";
import {useSelector} from "react-redux";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import EditBattleTimeModal from "../../modals/EditBattleTimeModal";
import EditExamTimeModal from "../../modals/EditExamTimeModal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};



export default function GroupMain(){
    const { quill, quillRef } = useQuill();
    const {group, lessons}=useSelector(state => state.items.group_id);
    const [isLoader, setIsLoader]=useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");

    useEffect(()=>{
        setIsLoader(true)
        setGroupsById({})
        handleGetByIdGroup(groupId).then(value => {
           value===false&&setGroupsById({});
            setIsLoader(false)
        });
    }, [groupId]);

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        // console.log(data);

        const newData={...data, group_id:groupId, order:lessons.length};
        const res=await POST.addLesson(newData);
        checkAllApi(res);
        if (res.success){
            handleGetByIdGroup(groupId);

            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    return (
        <Box>
            {isLoader?
                <LoadingBeatLoader/>
                :<Box>
                    {group!==undefined?
                        <Box>
                            {group&&
                            <Box>
                                <Box sx={{display:"flex", alignItems:"center"}}>
                                    <Typography>Guruh nomi: {group.name}</Typography>
                                </Box>
                                <Typography>Boshlanish vaqti: {group.start_date}</Typography>
                                <Box sx={{display:"flex", alignItems:"center"}}>
                                    <Typography>Batle vaqti: {group.battle_time}</Typography>
                                    <EditBattleTimeModal time={group.battle_time} groupId={groupId}/>
                                </Box>
                                <Typography>Darslar soni: {lessons.length} ta</Typography>
                                <Typography>Talabalar soni: {(group.students_group.length)} ta</Typography>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Typography>
                                        Imtihon sanasi : {group.examp}
                                    </Typography>
                                    <EditExamTimeModal groupId={groupId} time={group.examp}/>
                                </Box>
                                <Typography>Oxirgi o`tilgan dars : {10} ta</Typography>
                                <Typography>Boshqa ma`lumotlar yozilishi mumkin. </Typography>
                            </Box>
                            }
                        </Box>:
                        <Typography>Siz so`ragan guruh mavjud emas</Typography>
                    }
                </Box>

            }
        </Box>
    );


}