import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import POST from "../../components/api/POST";
import Checkbox from "@mui/material/Checkbox";
import toast from "react-hot-toast";
import axios from "axios";
import { BaseUrl, FileBaseUrl } from "../../components/api/DefaultApi";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { handleGetLesson } from "../Lesson";
import TestId from "../../components/TestId";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export default function Test() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const lesson = useSelector((state) => state.items.lesson_id);
  // console.log(lesson);

  const onSubmit = async (data) => {
    // console.log(data);

    const newData = {
      ...data,
      lesson_id: lesson_id,
      type: fileInfo && fileInfo.type,
      file: fileInfo && fileInfo.image
    };
    // console.log(newData);
    const res = await POST.addTest(newData);
    if (res.success) {
      toast.success("Qo`shildi");
      refresh();
      handleGetLesson(lesson_id);
      handleClose();
    }
  };

  const refresh = () => {
    reset();
    setFileInfo(undefined);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const lesson_id = searchParams.get("lessonId");

  const [fileInfo, setFileInfo] = useState(undefined); //type, file   berib yuboramiz.
  const [progress, setProgress] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUploadFile = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    try {
      const res = await axios.post(
        BaseUrl + "/api/teacher/practice/file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );
      setFileInfo(res.data);
    } catch (e) {
      toast("Xatolik");
    }
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Box>
          <Button variant={"contained"} color={"success"} onClick={handleOpen}>
            Test qo`shish
          </Button>
        </Box>
      </Box>

      <Box>
        {lesson &&
          lesson.questions.map((value, index) => {
            return <TestId test={value} index={index} />;
          })}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <TextField
                label={"Testni kiriting:"}
                fullWidth
                {...register("value", {
                  required: "Bo`sh bo`lishi mumkin emas.",
                  minLength: {
                    value: 2,
                    message: " 2 ta belgidan kam bo`lmasligi kerak",
                  },
                  maxLength: {
                    value: 101,
                    message: "100 ta belgidan ortiq bo`lishi mumkin emas",
                  },
                })}
              />
              {errors.value && (
                <Typography color={"error"}>{errors.value.message}</Typography>
              )}
            </Box>

            <Box>
              <TextField
                label={"Testni(o`zbek tilida) kiriting:"}
                fullWidth
                {...register("value_uz")}
              />
            </Box>

            <Box>
              <TextField
                label={"Testni(rus tilida) kiriting:"}
                fullWidth
                {...register("value_ru")}
              />
            </Box>

            {fileInfo === undefined && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#d9dbe0",
                  padding: "8px",
                  justifyContent: "center",
                }}
              >
                <label>
                  <input
                    style={{ display: "none" }}
                    type={"file"}
                    onChange={handleUploadFile}
                    accept={"video/*, image/*"}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={"/assets/icon/upload.svg"}
                      width={"24px"}
                      height={"24px"}
                    />
                    <span>Faylni yuklang</span>
                  </div>
                </label>
              </Box>
            )}

            <Box width={"100%"}>
              {progress > 0 && 100 > progress && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      backgroundColor: "gray",
                      width: progress + "%",
                      height: "4px",
                    }}
                  ></div>
                  <span>{progress}%</span>
                </Box>
              )}
            </Box>

            {fileInfo !== undefined && (
              <Box>
                {fileInfo.type === 0 && (
                  <Box>
                    <img
                      style={{ objectFit: "contain" }}
                      width={"100%"}
                      height={"200px"}
                      src={FileBaseUrl + fileInfo.image}
                    />
                  </Box>
                )}
                {fileInfo.type === 1 && (
                  <Box>
                    <ReactPlayer
                      width={"100%"}
                      height={"200px"}
                      controls={true}
                      url={FileBaseUrl + fileInfo.image}
                    />
                  </Box>
                )}
                {fileInfo.type === 2 && (
                  <Box>
                    <a
                      href={FileBaseUrl + fileInfo.image}
                      download
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img src={"/assets/icon/file.svg"} width={"50px"} />
                    </a>
                  </Box>
                )}
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    onClick={() => setFileInfo(undefined)}
                    color={"error"}
                    variant={"contained"}
                    sx={{ marginTop: "4px" }}
                  >
                    O`chirish
                  </Button>
                </Box>
              </Box>
            )}

            {/*<Box display={"flex"} alignItems={"center"}>*/}
            {/*  <Checkbox {...label} {...register("random")} />*/}
            {/*  <Typography>Random bo`lsinmi</Typography>*/}
            {/*</Box>*/}

            <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
              <Button
                type={"submit"}
                color={"success"}
                variant={"contained"}
                disabled={progress > 0 && progress < 100 && true}
              >
                Saqlash
              </Button>
              <Button color={"warning"} variant={"contained"} onClick={refresh}>
                Tozalash
              </Button>
              <Button
                onClick={handleClose}
                color={"error"}
                variant={"contained"}
              >
                Yopish
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
