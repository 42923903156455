import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button,Tooltip} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import {checkAllApi} from "../../../api/DefaultApi";
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../../api/DELETE";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function DeleteNotificationModal({id, refresh}){

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handlerDeleteNotification = async () => {
        const res=await DELETE.deleteNotification(id);
        if (res.success){
            toast.success("O`chirildi");
            refresh();
            handleClose();
        }else if (!res.success){
            toast.error("Notification mavjud emas");
        }
    };


    return (
        <>

            <Box>
                <Tooltip title={"O`chirish"}>
                    <IconButton color={"error"} onClick={handleOpen}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"}>
                        <Box>
                            Rostdan ham o`chirmoqchimisiz
                        </Box>
                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            <Button color={"success"} variant={"contained"} onClick={handlerDeleteNotification}>Ha, O`chirmoqchiman</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Bekor qilish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}