import GET from "../../api/GET";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {getDateInfo} from "../../../page/Chat";
import {Pagination, Tooltip} from "@mui/material";
import {formatPrice} from "../../Other/useWindowSize";


export default function UserStatistic(){

    const params=useParams();


    const [data, setData]=useState([]);
    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);


    const handlerGetUserMotions=async ()=>{

        const res=await GET.getUserMotions("user_id", params.id);
        if (res.success){
            setData(res.res.data.user_motions.data);
            setLastPage(res.res.data.user_motions.last_page);
        }
    }

    useEffect(() => {
        handlerGetUserMotions();
    }, []);


    return (
        <>

            <Box>
                {(data&&data.length>0)?
                    <Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                        <TableCell align={"center"}>Amal va natija</TableCell>
                                        <TableCell align={"center"}>Guruh</TableCell>
                                        <TableCell align={"center"}>Tavsifi</TableCell>
                                        <TableCell align={"center"}>Vaqti</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data&&data.map((value1, index) =>
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index+1}
                                            </TableCell>
                                            <TableCell align={"center"}>
                                               {value1.type}
                                            </TableCell>
                                            <TableCell>
                                                guruh nomi
                                            </TableCell>
                                            <TableCell align={"center"} >
                                                <Box sx={{overflow:'auto', width:"300px"}}>
                                                    {value1.description}
                                                </Box>
                                            </TableCell>
                                            <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                            <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                        </Box>
                    </Box>
                    :
                    <Box>
                        Siz so`ragan ma`lumotlar mavjud emas
                    </Box>

                }
            </Box>

        </>
    );
}