import React, { useEffect, useState } from "react";
import DELETE from "../../api/DELETE";
import { checkAllApi, getTeacherId } from "../../api/DefaultApi";
import { handleGetGroups } from "../../group/GroupsMenu";
import toast from "react-hot-toast";
import POST from "../../api/POST";
import { useForm } from "react-hook-form";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import GET from "../../api/GET";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export default function AddAndEditOutputTypeModal({
  type,
  outputType,
  handlerGetOutputType,
}) {
  const [loader, setLoader] = useState(true);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let newData;
    if (type === "add") {
      newData = { ...data };
    }
    if (type === "edit") {
      newData = { ...data, id: outputType.id };
    }
    const res = await POST.addOutputType(newData);
    checkAllApi(res);
    if (res.success) {
      toast.success("Yaratildi.");
      reset();
      handleClose();
      handlerGetOutputType();
    }
    if (!res.success) {
      toast.error("Xatolik");
    }
  };

  useEffect(() => {
    if (type === "edit") {
      setValue("name", outputType.name);
      setValue("description", outputType.description);
    }
  }, []);

  const refresh = () => {
    reset();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        {type === "add" && (
          <Box>
            {localStorage.getItem("role") === "superadmin" && (
              <Button
                variant={"contained"}
                color={"success"}
                onClick={handleOpen}
              >
                Qo`shish
              </Button>
            )}
          </Box>
        )}
        {type === "edit" && (
          <Box>
            {(localStorage.getItem("role") === "admin" ||
              localStorage.getItem("role") === "superadmin") && (
              <Tooltip title={"Tahrirlash"}>
                <IconButton onClick={handleOpen} color={"warning"}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography>Guruh nomini kiriting:</Typography>
              <TextField
                fullWidth
                {...register("name", {
                  required: "Bo`sh bo`lishi mumkin emas.",
                  minLength: {
                    value: 2,
                    message: " 2 ta belgidan kam bo`lmasligi kerak",
                  },
                  maxLength: {
                    value: 101,
                    message: "100 ta belgidan ortiq bo`lishi mumkin emas",
                  },
                })}
              />

              {errors.name && (
                <Typography color={"error"}>{errors.name.message}</Typography>
              )}
            </Box>

            <Box>
              <Typography>Tavsifini kiriting:</Typography>
              <textarea
                rows={5}
                style={{ width: "100%" }}
                {...register("description")}
              />
            </Box>

            <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
              <Button type={"submit"} color={"success"} variant={"contained"}>
                Saqlash
              </Button>
              <Button color={"warning"} variant={"contained"} onClick={refresh}>
                Tozalash
              </Button>
              <Button
                onClick={handleClose}
                color={"error"}
                variant={"contained"}
              >
                Yopish
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
