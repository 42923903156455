import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import {checkAllApi} from "../../api/DefaultApi";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRow from "@mui/material/TableRow";
import React, {useState} from "react";
import DELETE from "../../api/DELETE";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import {useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function GroupsClassTimeId({schedule, index, days, rooms}){

    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");
    const handleDeleteSchedule = async (id) => {
        const res=await DELETE.deleteSchedules(id);
        if (res.success){
            toast.success("O`chirildi");
            handleGetByIdGroup(groupId);
            return;
        }

        toast.error("Xatolik")
    }


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        const newData={...data, group_id:groupId, id:schedule.id};
        const res=await POST.addClassTime(newData);
        checkAllApi(res);
        if (res.success){
            handleGetByIdGroup(groupId);
            toast.success("Tahrirlandi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }


    const getDays=(id)=>{
        let number = parseInt(id);
        return days[number];
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            <TableRow
                key={schedule.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index+1}
                </TableCell>
                <TableCell align={"center"}>{schedule.day}</TableCell>
                <TableCell align={"center"}>{schedule.time}</TableCell>
                <TableCell align={"center"}>{schedule.end_time}</TableCell>
                <TableCell align={"center"}>{schedule.room.name}</TableCell>

                {/*<TableCell align={"center"} width={"150px"}>*/}
                {/*    <Tooltip title={"Tahrirlash"}>*/}
                {/*        <IconButton color={"warning"}*/}
                {/*                    onClick={handleOpen}*/}
                {/*        >*/}
                {/*            <EditIcon/>*/}
                {/*        </IconButton>*/}
                {/*    </Tooltip>*/}
                {/*    <Tooltip title={"O`chirish"}>*/}
                {/*        <IconButton color={"error"}*/}
                {/*                    onClick={()=>handleDeleteSchedule(schedule.id)}*/}
                {/*        >*/}
                {/*            <DeleteIcon/>*/}
                {/*        </IconButton>*/}
                {/*    </Tooltip>*/}
                {/*</TableCell>*/}
            </TableRow>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box width={"100%"}>
                            <InputLabel id="demo-multiple-name-label">Kun tanlang</InputLabel>
                            <Select sx={{width:"100%"}}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    MenuProps={MenuProps}
                                    defaultValue={schedule.day}
                                    {...register("day", {required:"Tanlash kerak"})}
                            >
                                {days.map((value) => (
                                    <MenuItem
                                        key={value}
                                        value={value}
                                    >
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>


                        <Box>
                            <Typography>Vaqtni kiriting:</Typography>
                            <TextField
                                       fullWidth type={"time"}
                                       defaultValue={schedule.time}
                                       {...register("time",
                                           {required:"Bo`sh bo`lishi mumkin emas."})}
                            />

                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}

                        </Box>

                        <Box width={"100%"}>
                            <InputLabel id="demo-multiple-name-label">Xonani tanlang</InputLabel>
                            <Select sx={{width:"100%"}}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    MenuProps={MenuProps}
                                    defaultValue={schedule.room.id}
                                    {...register("room_id", {required:"Tanlash kerak"})}
                            >
                                {rooms.map((value) => (
                                    <MenuItem
                                        key={value.id}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>



                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                            {/*<Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>*/}
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}