import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Button, InputLabel, MenuItem, Pagination, Select, TextField, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import GET from "../api/GET";
import DELETE from "../api/DELETE";
import {getDateInfo} from "../../page/Chat";
import LockResetIcon from '@mui/icons-material/LockReset';
import AddUserModal from "../modals/AddUserModal";
import LoadingBeatLoader from "../LoadingBeatLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Link, useLocation, useNavigate, useParams, useRoutes, useSearchParams} from "react-router-dom";
import EditUserModal from "../modals/EditUserModal";
import {formatPhone} from "../Other/useWindowSize";
import PasswordRecoveryModal from "../modals/PasswordRecoveryModal";
import StudentActiveMore from "../more/StudentActiveMore";
import DeleteUserModal from "../modals/DeleteModal/DeleteUserModal";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const statusList=[
    {name:"Aktiv", id:"active" },
    {name:"Aktiv emas",id:"inactive"},
    {name:"O`chirilgan",id:"delete"},
    {name:"Barchasi", id:""}
];

export default function UsersForAdmin({role, handlerStatus, statusFilter}){
    const [searchParams, setSearchParams] = useSearchParams();

    const tab=searchParams.get("tab");
    const paramPage=searchParams.get("page");
    const [status, setStatus]=useState(handlerStatus);

    const [page, setPage]=useState(1);
    const [loader, setLoader]=useState(false);
    const [lastPage, setLastPage]=useState(1);




    const [searchText, setSearchText]=useState("");



    const [groupList, setGroupList]=useState([]);

    const [userTypePayments, setUserTypePayments]=useState([]);





    const handlerGetGroups=async ()=>{
        if (role.name==="student"){
            const res=await GET.getAllGroups();
            setGroupList(res.res.data.all_groups);
        }
    }





    const handlerGetStudentsTypePayments=async ()=>{
        if (role.name==='student'){
            const res=await GET.getAllUserForAdmin();
            if (res.success){
                setUserTypePayments(res.res.data.type_payments);
            }else if (!res.success){
                toast.error("Foydalanuvchilarni olishda xatolik yuz berdi. ");
            }
        }
    }




    const [data, setData]=useState(undefined);

    const handlerGetUsers=async ()=>{
        const res=await GET.getAllUserForAdminByRole(
            {role:role.name, page:page, size:30, text:searchText, status:status});
        if (res.success){
            setData(res.res.data.users.data);
            setLastPage(res.res.data.users.last_page);
            setLoader(false);
        }
    }


    useEffect(()=>{
        setLoader(true)
        handlerGetUsers();
    }, [page, status])

    useEffect(() => {
        setSearchParams({tab:tab, page:1})
        if(searchText.length>3 || searchText.length===0){
            setLoader(true)
            handlerGetUsers();
        }
    }, [searchText]);

    useEffect(()=>{
        handlerGetUsers();
        handlerGetGroups();
        handlerGetStudentsTypePayments();
        paramPage&&setPage(parseInt(paramPage));
    }, [])





    const getColorByStatus=(status)=>{
        if (status==="active"){
            return "#2E7D32";
        }else if (status==="inactive"){
            return "#ED6C02";
        }else if (status==="delete"){
            return "#D32F2F";
        }
    }


    return (
        <>

            <Box sx={{display:'flex',justifyContent:"end"}}>
                <AddUserModal role={role} groups={groupList}
                              handlerGetUsers={handlerGetUsers}
                              page={page}
                              userTypePayments={userTypePayments}
                />
            </Box>

            <Box  sx={{marginY:"8px", display:"flex", gap:"4px", justifyContent:"center"}} onSubmit={handlerGetUsers}>
                <TextField placeholder={"Foydalanuvchining ismini, familiyasini yoki telifon raqami yordamida qidiring: "}
                           style={{width:"100%"}}
                           value={searchText}
                           onChange={(event)=>{setSearchText(event.target.value)}}/>

                {statusFilter===true&&
                    <Box width={"400px"}>
                        <Select sx={{width:"100%"}}
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                MenuProps={MenuProps}
                                defaultValue={status}
                                onChange={(event)=>setStatus(event.target.value)}

                        >
                            {statusList.map((value) => (
                                <MenuItem
                                    key={value.id}
                                    value={value.id}
                                >
                                    {value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                }

            </Box>










            {loader?
                <LoadingBeatLoader/>
                :
                <Box width={"100%"}>
                    <Box sx={{marginY:"4px"}}>
                        {data!==undefined&&data.length>0?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>F.I.O.</TableCell>
                                                <TableCell align={"center"}>Telifon raqami</TableCell>
                                                {role.name=="student"&&
                                                    <TableCell align={"center"}>Guruhi</TableCell>
                                                }
                                                <TableCell align={"center"}>Yozilgan vaqti vaqti</TableCell>
                                                <TableCell align={"center"}>O`zgartirilgan vaqti</TableCell>
                                                <TableCell align={"center"}>Holati</TableCell>
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data&&data.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {((page-1)*30)+index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Link to={"/user-group/"+value1.id}>
                                                            {value1.name} {value1.lastname&&value1.lastname}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {value1.phone?formatPhone(value1.phone):"Telifon raqami mavjud emas"}
                                                    </TableCell>
                                                    {role.name==="student"&&
                                                        <TableCell align={"center"}>
                                                            {(value1.student_groups&&value1.student_groups.length>0)&&
                                                                <Link to={"/group?groupId="+value1.student_groups[0].id} sx={{textDecorationLine:"none"}}>
                                                                    {value1.student_groups[0].name}
                                                                </Link>
                                                            }
                                                        </TableCell>
                                                    }
                                                    <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                    <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                    <TableCell align={"center"} sx={{display:"flex"}}>
                                                        <Typography sx={{backgroundColor:getColorByStatus(value1.status), color:"white", padding:"4px", borderRadius:"8px"}}>
                                                            {value1.status}
                                                        </Typography>
                                                        <StudentActiveMore row={value1} handlerGetUsers={handlerGetUsers}/>
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>
                                                            {/*<Tooltip title={"Batafsil ko`rish"} onClick={()=>navigate("/user-group/"+value1.id)}>*/}
                                                            {/*    <IconButton color={"info"}>*/}
                                                            {/*        <VisibilityIcon/>*/}
                                                            {/*    </IconButton>*/}
                                                            {/*</Tooltip>*/}
                                                            <EditUserModal groups={groupList}
                                                                           handlerGetUsers={handlerGetUsers}
                                                                           user={value1}
                                                                           role={role}
                                                                           userTypePayments={userTypePayments}
                                                            />
                                                            <PasswordRecoveryModal user={value1}/>

                                                            <DeleteUserModal user={value1} refresh={handlerGetUsers}/>


                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                    <Pagination count={lastPage} defaultPage={page} onChange={(event)=>{
                                        setSearchParams({tab:tab, page:event.currentTarget.textContent})
                                        setPage(event.currentTarget.textContent);
                                    }} color="primary" />
                                </Box>
                            </>
                            :
                            <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                                <Typography>{role.display_name} mavjud emas</Typography>
                            </Box>
                        }
                    </Box>
                </Box>}
        </>
    );
}