import {Button, FormControlLabel, InputLabel, MenuItem, Select, TextareaAutosize, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {checkAllApi} from "../../components/api/DefaultApi";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useForm} from "react-hook-form";
import POST from "../../components/api/POST";
import GET from "../../components/api/GET";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {useNavigate, useSearchParams} from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import {StringToDouble} from "../../components/Other/useWindowSize";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function AddOutput(){

    const [outputTypeList, setOutputTypeList]=useState([]);


    const [searchParams, setSearchParams] = useSearchParams();
    const type=searchParams.get("type");
    const id=searchParams.get("id");



    const [loader, setLoader]=useState(true);

    const navigate=useNavigate();


    const handlerGetOutputType=async ()=>{
        setLoader(true);
        const res=await GET.getAllOutputType();
        if (res.success){
            setOutputTypeList(res.res.data.expenses);
        }else if (!res.success){
            toast.error("Chiqim turlarini olishda xatolik");
        }
    }

    const [output, setOutput]=useState();


    const handlerGetByIdOutput=async ()=>{
        const res=await GET.getByIdOutput(id);
        if (res.success){
            setOutput(res.res.data.output[0]);
            setValue("date", res.res.data.output[0].date)
            setLoader(false);
        }
    }



    useEffect(() => {
        setLoader(true)
        handlerGetOutputType();

        if (type==="add"){
            setValue("date", new Date().toISOString().substr(0, 10));
            setLoader(false)
        }


        if (type==="edit"){
            handlerGetByIdOutput();
        }
    }, []);


    const { register,setValue, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {

        let sendData;
        if (type==="add"){
            sendData={...data, amount:StringToDouble(data.price)}
        }else if (type==="edit"){
            sendData={...data, amount:StringToDouble(data.price), id:id}
        }


        const res=await POST.addOutput(sendData);
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast.error("Xatolik");
            return;
        }

        if (res.success){
            toast.success("Yaratildi.");
            reset();
            navigate("/output");
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    return (
        <>
            {loader?
                <LoadingBeatLoader/>:
                <Box component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"} sx={{display:'flex', flexDirection:"column", gap:"8px"}}>
                    <Box sx={{display:"flex", flexDirection:"column", width:"100%", gap:"12px"}}>
                        <Box sx={{display:"flex", flexDirection:"column", gap:"12px"}}>

                            <Box width={"100%"} display={"flex"} gap={2} flexDirection={"column"}>
                                <Box width={"100%"}>
                                    <InputLabel id="demo-multiple-name-label">Xarajat turini tanlang</InputLabel>
                                    <Select sx={{width:"30%"}}
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            defaultValue={output&&output.expense_id}
                                            MenuProps={MenuProps}
                                            {...register("expense_id")}
                                            error={errors.expense_id}
                                    >
                                        {outputTypeList.map((value) => (
                                            <MenuItem
                                                key={value.id}
                                                value={value.id}
                                            >
                                                {value.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                {errors.expense_id&&<Typography color={"error"}>{errors.expense_id.message}</Typography>}
                            </Box>

                            <Box sx={{width:"100%"}}>
                                <Typography>
                                    Miqdorini kiriting:
                                </Typography>
                                <CurrencyInput
                                    style={{padding:"10px", width:"30%", fontSize:"24px"}}
                                           // fullWidth
                                    groupSeparator={" "}
                                    decimalSeparator={"."}
                                            defaultValue={output&&output.amount}
                                           error={errors.price}
                                           {...register("price",
                                               {required:"Bo`sh bo`lishi mumkin emas.",
                                                   minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                                   maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                                />
                                {errors.price&&<Typography color={"error"}>{errors.price.message}</Typography>}
                            </Box>

                            <Box sx={{width:"100%"}}>
                                <Typography>
                                    Sanasini kiriting:
                                </Typography>
                                <TextField
                                    // fullWidth
                                    sx={{width:"30%"}}
                                           error={errors.date}
                                           type={"date"}
                                           {...register("date",
                                               {required:"Bo`sh bo`lishi mumkin emas.",
                                                   minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                                   maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                                />
                                {errors.date&&<Typography color={"error"}>{errors.date.message}</Typography>}
                            </Box>

                            <Box>
                                <Typography>Turlarini kiriting:</Typography>
                                <textarea rows={5} style={{width:"50%"}}
                                          defaultValue={output&&output.type}
                                          {...register("type")}
                                />
                            </Box>

                            <Box>
                                <Typography>Tavsifini kiriting:</Typography>
                                <textarea rows={5} style={{width:"50%"}}
                                          defaultValue={output&&output.description}
                                          {...register("description")}
                                />
                            </Box>
                        </Box>

                        <Box sx={{alignSelf:"center"}}>

                        </Box>
                    </Box>

                    <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                        <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                        <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                    </Box>
                </Box>
            }
        </>
    );
}