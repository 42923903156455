import React, { useEffect, useState } from "react";
import DELETE from "../../api/DELETE";
import { checkAllApi, getTeacherId } from "../../api/DefaultApi";
import { handleGetGroups } from "../../group/GroupsMenu";
import toast from "react-hot-toast";
import POST from "../../api/POST";
import { useForm } from "react-hook-form";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import GET from "../../api/GET";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CurrencyInput from "react-currency-input-field";
import {StringToDouble} from "../../Other/useWindowSize";
import {useSelector} from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export default function EditGroupModal({ handlerGetGroups, group,  teacherList}) {
  const [loader, setLoader] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    // console.log(data);

    const newData = { ...data, id: group.id, price:StringToDouble(data.priceHandler) };
    const res = await POST.addGroup(newData);
    checkAllApi(res);
    if (res.success) {
      handleGetGroups();

      toast.success("Yaratildi.");
      reset();
      handleClose();
      handlerGetGroups();
    }
    if (!res.success) {
      toast.error("Xatolik");
    }
  };

  const levels=useSelector(state => state.otherInfoList.levels);



  const refresh = () => {
    reset();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        {(localStorage.getItem("role") === "admin" ||
          localStorage.getItem("role") === "superadmin") && (
          <Tooltip title={"Tahrirlash"}>
            <IconButton
              onClick={(event) => handleOpen(event)}
              color={"warning"}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography>Guruh nomini kiriting:</Typography>
              <TextField
                fullWidth
                defaultValue={group.name}
                {...register("name", {
                  required: "Bo`sh bo`lishi mumkin emas.",
                  minLength: {
                    value: 2,
                    message: " 2 ta belgidan kam bo`lmasligi kerak",
                  },
                  maxLength: {
                    value: 101,
                    message: "100 ta belgidan ortiq bo`lishi mumkin emas",
                  },
                })}
              />

              {errors.name && (
                <Typography color={"error"}>{errors.name.message}</Typography>
              )}
            </Box>

            <Box>
              <Typography>Guruh boshlanish sanasini kiriting:</Typography>

              <TextField
                fullWidth
                defaultValue={group.start_date}
                type={"date"}
                {...register("start_date")}
              />
            </Box>

            <Box>
              <Typography>Oylik pulini kiriting:</Typography>

              <CurrencyInput
                  defaultValue={group.price}
                  groupSeparator={" "}
                  decimalSeparator={"."}
                  style={{padding:"12px", width:"100%", fontSize:"16px"}}
                  fullWidth
                  {...register("priceHandler")}
              />
            </Box>

            <Box width={"100%"}>
              <InputLabel id="demo-multiple-name-label">
                Darajasini tanlang
              </InputLabel>
              <Select
                  sx={{ width: "100%" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  MenuProps={MenuProps}
                  defaultValue={group.level_id}
                  {...register("level_id", {required:"Guruhning darajasini tanlang. "})}
              >
                {levels.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                ))}
              </Select>
              {errors.level_id && (
                  <Typography color={"error"}>
                    {errors.level_id.message}
                  </Typography>
              )}
            </Box>

            <Box width={"100%"}>
              <InputLabel id="demo-multiple-name-label">
                O`qituvchini tanlang
              </InputLabel>
              <Select
                sx={{ width: "100%" }}
                defaultValue={group.teacher_id}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                MenuProps={MenuProps}
                {...register("teacher_id")}
              >
                {teacherList.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.teacher_id && (
                <Typography color={"error"}>
                  {errors.teacher_id.message}
                </Typography>
              )}
            </Box>

            <Box width={"100%"}>
              <InputLabel id="demo-multiple-name-label">
                Xolatini tanlang
              </InputLabel>
              <Select
                sx={{ width: "100%" }}
                defaultValue={group.status}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                MenuProps={MenuProps}
                {...register("status")}
              >
                <MenuItem value={0}>Aktiv emas</MenuItem>

                <MenuItem value={1}>Aktiv</MenuItem>
                <MenuItem value={2}>To`lmoqda</MenuItem>
              </Select>
              {errors.status && (
                <Typography color={"error"}>{errors.status.message}</Typography>
              )}
            </Box>

            <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
              <Button type={"submit"} color={"success"} variant={"contained"}>
                Saqlash
              </Button>
              <Button color={"warning"} variant={"contained"} onClick={refresh}>
                Tozalash
              </Button>
              <Button
                onClick={handleClose}
                color={"error"}
                variant={"contained"}
              >
                Yopish
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
