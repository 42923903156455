
import "./index.css";
import {MenuItem, Select, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatPhone, formatPrice} from "../Other/useWindowSize";
import GenerataOrderModal from "../modals/GenerataOrderModal";
import {AllInbox} from "@mui/icons-material";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const userNewOrderList=[
    {id:1, name:"Salom 1"},
    {id:2, name:"Salom 2"},
    {id:3, name:"Salom 3"},
    {id:4, name:"Salom 4"}
]
export default function Inputs(){


    const [startDate, setStartDate]=useState(null);
    const [endDate, setEndDate]=useState(null);
    const [userId, setUserId]=useState(null);


    const [isOpen, setIsOpen]=useState(false);

    return (
        <>
            <div style={{display:'flex', justifyContent:"space-between", gap:"4px", flexWrap:"wrap"}}>
            </div>
            <Box display={'flex'} alignItems={"center"}>
                <Box sx={{display:'flex', flexWrap:'wrap', marginY:2, gap:"4px"}}>
                    <Box>
                        <Typography>
                            Boshlanish sanasi
                        </Typography >
                        <TextField type={"date"}/>
                    </Box>

                    <Box>
                        <Typography>
                            Tugash sanasi
                        </Typography>
                        <TextField type={"date"}/>
                    </Box>


                    <Box>
                        <Typography>
                            Ishchini tanlang
                        </Typography>
                        <Select sx={{minWidth:"200px"}}
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                MenuProps={MenuProps}
                        >
                            {/*{groupList.map((value) => (*/}
                                <MenuItem
                                    // key={value.id}
                                    // value={value.id}
                                >
                                    Salom
                                    {/*{value.name}*/}
                                </MenuItem>
                            {/*))}*/}
                        </Select>
                    </Box>
                </Box>
                <Tooltip title={"Qidirish"}>
                    <IconButton color={"info"}>
                        <SearchIcon/>
                    </IconButton>
                </Tooltip>
            </Box>


            <TableContainer component={Paper} sx={{marginY:4}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                            <TableCell align={"center"}>Nomi</TableCell>
                            <TableCell align={"center"}>Guruhi</TableCell>
                            <TableCell align={"center"}>Telifon raqami</TableCell>
                            <TableCell align={"center"}>Bonuslari</TableCell>
                            <TableCell align={"center"}>Oylik to`lovi</TableCell>
                            <TableCell align={"center"}>To`lov summasi</TableCell>
                            <TableCell align={"center"}>Amallar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userNewOrderList&&userNewOrderList.map((value1, index) =>
                            <>
                                <TableRow
                                    key={value1.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                >
                                    <TableCell component="th" scope="row">
                                        {index+1}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <Typography>{value1.name+" "+value1.lastname}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {value1.group_name}
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell align={"center"} >
                                    </TableCell>
                                    <TableCell align={"center"}>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                    </TableCell>

                                    <TableCell align={"center"} >
                                        <Tooltip title={"Ko`proq"}>
                                            <IconButton onClick={()=>setIsOpen(!isOpen)}>
                                                <AllInbox/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                {isOpen&&
                                    <tr style={{width:"100%"}}>
                                        <th colSpan={8}>
                                            <Box margin={2}>
                                                <TableContainer component={Paper} sx={{marginY:4}}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead sx={{backgroundColor:"grey"}}>
                                                            <TableRow>
                                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                                <TableCell align={"center"}>Qaysi to`lov turidan</TableCell>
                                                                <TableCell align={"center"}>Miqdori</TableCell>
                                                                <TableCell align={"center"}>Sanasi</TableCell>
                                                                <TableCell align={"center"}>Miqdori</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {userNewOrderList&&userNewOrderList.map((value1, index) =>
                                                                <TableRow
                                                                    key={value1.id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {index+1}
                                                                    </TableCell>
                                                                    <TableCell align={"center"}>
                                                                        <Typography>{value1.pay_method}</Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {value1.amount}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {value1.status}
                                                                    </TableCell>

                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>

                                        </th>
                                    </tr>
                                }
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>



        </>
    );
}