import React, {useRef} from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import GET from "../../components/api/GET";
import {checkAllApi} from "../../components/api/DefaultApi";
import { useState } from "react";
import Box from "@mui/material/Box";
import {Avatar, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SendIcon from '@mui/icons-material/Send';
import {useForm} from "react-hook-form";
import POST from "../../components/api/POST";
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../components/api/DELETE";
import toast from "react-hot-toast";

export const getDateInfo=(date)=>{
    const year=date.substring(0,4);
    const month=date.substring(5,7);
    const day=date.substring(8,10);
    const vaqt=date.substring(11, 19);

    return date&&day+"-"+month+"-"+year+" "+vaqt;
}
export default function Chat() {

  const params=useParams();


  const socket = new WebSocket(
    "wss://wisdom.exadot.io/ws/messages/?token="+localStorage.getItem("token")
  );

  const [messageList, setMessageList]=useState([]);


    socket.onmessage = (event) => {
        let newVar = event!==undefined&&JSON.parse(event.data);
        newVar&&newVar.message.group_id==params.chatId&&setMessageList((prevState)=> [...prevState, newVar.message]);
    };


  // console.log(messageList);
  const handlerGetMessage=async ()=>{
    const res=await GET.getChatById(params.chatId);
    checkAllApi(res)
    if (res.success){
      setMessageList(res.res.data.group.messages);
    }
  }


  const handlerDeleteMessage=async (id)=>{
      const res=await DELETE.deleteMessage(id);
      if (res.success){
          toast.success("O`chirildi");
          handlerGetMessage();
      }
    }

  useEffect(()=>{
      handlerGetMessage();
  }, [params.chatId])


    // useEffect(() => {
    //     // chatRefBox.current.scrollIntoView({before: "smooth"})
    // }, [])

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        // console.log(data);
        const res=await POST.sendMessage({group_id: params.chatId, message: data.text});
        if (res.success){
            setMessageList([...messageList, {...res.res.data.message, student:null }]);
            refresh();
        }
    };

    const refresh=()=>{
        reset();
    }






  return (
    <Box sx={{boxShadow:"4px 4px 4px 4px #D7E4FA"}}>
      <Box sx={{
          border:"1px solid #D7E4FA",
          padding:"8px",
          boxShadow:"10px 10px 10px 10px #D7E4FA",
          height:"300px",
          overflow:"auto"
        }}
      >
          <Box display={"flex"} flexDirection={"column"}>
              {messageList.length>0&&messageList.map((value, index) => {
                  let item = localStorage.getItem("id");
                  let endOrStart=value.user?value.user.id==item?"end":"start":"start";
                return (
                    <Box display={'flex'} alignItems={"center"} gap={"8px"} sx={{boxShadow:"1px 1px 1px 1px #D7E4FA",  marginY:"8px", padding:"4px",
                        alignSelf:endOrStart}} width={"80%"}>
                        <Avatar src={value.src}/>
                        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography fontSize={"16px"}>{value.user?value.user.name:"Aniqlanmandi"}</Typography>
                                <Typography fontSize={"14px"}>{
                                    getDateInfo(value.created_at)
                                }</Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography>{value.message}</Typography>
                                <Tooltip title={"O`chirish"} >
                                    <IconButton color={"error"} sx={{alignItems:"end"}} onClick={()=>handlerDeleteMessage(value.id)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                )
              })}

              {messageList.length===0&&<Typography>Chat bo`sh</Typography>}
          </Box>
      </Box>

        <Box component={"form"} onSubmit={handleSubmit(onSubmit)} sx={{display:"flex", alignItems:"center", marginTop:"20px"}} >
            <Box width={"100%"} gap={"8px"}>
                <TextField onKeyDown={onSubmit}
                           fullWidth
                           {...register("text",
                               {required:"Bo`sh bo`lishi mumkin emas."})}
                />

                {errors.text&&<Typography color={"error"}>{errors.text.message}</Typography>}
            </Box>


            <Tooltip title={"Jo`natish"}>
                <IconButton type={"submit"}>
                    <SendIcon color={"primary"}/>
                </IconButton>
            </Tooltip>
        </Box>
    </Box>
  );
}
