import Box from "@mui/material/Box";
import GET from "../api/GET";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import LoadingBeatLoader from "../LoadingBeatLoader";
import toast from "react-hot-toast";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {formatPrice} from "../Other/useWindowSize";
import {getDateInfo} from "../../page/Chat";
import AddPaymentModal from "../modals/AddPaymentModal";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function UserIdPayments(){

    const params=useParams();


    const [loader, setLoader]=useState(true);

    const [data, setData]=useState([]);


    const handlerGetOrdersByUserId=async ()=>{
        const res=await GET.getUserByIdPayment(params.id);
        if (res.success) {
            setData(res.res.data.payments);
            setLoader(false);
        }else if (res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }


    useEffect(()=>{
        handlerGetOrdersByUserId()
    }, [])



    return (
        <>
            <Box>
                {loader?<LoadingBeatLoader/>
                    :
                    <>
                        {data.length>0?
                            <Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Foydalanuvchi</TableCell>
                                                <TableCell align={"center"}>Guruhi</TableCell>
                                                <TableCell align={"center"}>Miqdori</TableCell>
                                                <TableCell align={"center"}>Qoldiq miqdori</TableCell>
                                                <TableCell align={"center"}>Sanasi</TableCell>
                                                <TableCell align={"center"}>Holati</TableCell>
                                                <TableCell align={"center"}>Telifon raqami</TableCell>
                                                <TableCell align={"center"}>To`langan vaqti</TableCell>
                                                <TableCell align={"center"}>Qanday to`langan</TableCell>
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.name+" "+(value1.lastname?value1.lastname:"")}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        {value1.group_name}
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        {formatPrice(value1.sum)}
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        {formatPrice(value1.price)}
                                                    </TableCell>
                                                    <TableCell align={"center"}>{value1.date}</TableCell>
                                                    <TableCell align={"center"} >
                                                        <Typography sx={{color:"white", backgroundColor:value1.status==1?"#2E7D32":"#EB4C42", borderRadius:"8px"}}>
                                                            {value1.status?"To`langan":"To`lanmagan"}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"}>{value1.phone}</TableCell>
                                                    <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                    <TableCell align={"center"}>{value1.payment_name!==null?value1.payment_name:"To`lanmagan"}</TableCell>
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>
                                                            <AddPaymentModal detail={value1} id={value1.order_id} handlerGetOrderPayments={handlerGetOrdersByUserId} handlerGetOrdersByUserId={handlerGetOrdersByUserId}/>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>:
                            <Box>
                                <Typography>Ushbu foydalanuvchining to`lovlari mavjud emas</Typography>
                            </Box>}

                    </>
                }
            </Box>
        </>
    );
}