import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { TextareaAutosize, TextField, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import DELETE from "../../api/DELETE";
import { checkAllApi, FileBaseUrl } from "../../api/DefaultApi";
import toast from "react-hot-toast";
import POST from "../../api/POST";
import "quill/dist/quill.snow.css";
import AddMainLessonModal from "../../modals/AddMainLessonModal";
import DeleteMainLessonModal from "../../modals/DeleteModal/DeleteMainLessonModal";

const style = {
  padding: "10px",
  overflow: "auto",
  height: "50%",
  minWidth: "70%",
  display: "flex",
  gap: 1,
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function MainLessonChildAccordionDetail({
  child,
  parent_id,
  handlerGetMainLessons,
}) {
  const navigate = useNavigate();

  const [childLesson, setChildLesson] = useState(child ? child : []);

  const [length, setLength] = useState(0);

  useEffect(() => {
    let sort = child.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    setChildLesson(sort);
    setLength(child.length);
  }, [child]);


  const [description, setDescription] = useState("");

  return (
    <div className="App">
      {childLesson ? (
        <DragDropContext
          onDragEnd={(param) => {
            if (localStorage.getItem("role") === "admin"||localStorage.getItem("role") === "superadmin") {
              const srcI = param.source.index;
              const desI = param.destination?.index;
              const handleOrderList = [...childLesson];
              handleOrderList.splice(
                desI,
                0,
                handleOrderList.splice(srcI, 1)[0]
              );
              handleOrderList.map((value, index) => (value.order = index));

              let data = [];
              handleOrderList.map((value, index) => {
                data.push({ id: value.id, order: value.order });
              });
              // console.log(childLesson);
              setChildLesson(handleOrderList);

              // if (desI) {
                POST.sortMainLesson(data).then((res) => {
                  if (res.success) {
                    // handlerGetMainLessons();
                    toast.success("Qayta tartiblandi. ");
                  }
                });
              // }
            } else {
              toast.error("Sizda huquq yo`q");
            }
          }}
        >
          <Box>
            <Droppable droppableId="droppable-1">
              {(provided, _) => (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {childLesson.map((item, i) => (
                    <Draggable
                      key={item.id}
                      draggableId={"draggable-" + item.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#EFF1F5",
                            padding: "4px",
                          }}
                          width={"100%"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          // style={{
                          //     ...provided.draggableProps.style,
                          //     boxShadow: snapshot.isDragging
                          //         ? "0 0 .4rem #666"
                          //         : "none",
                          // }}
                        >
                          <Box display={"flex"} gap={"4px"}>
                            <img
                              src={"/assets/icon/cursor.svg"}
                              {...provided.dragHandleProps}
                            />
                            <span>
                              {i + 1 + ". "}
                              {item.name}
                            </span>
                          </Box>
                          <Box>
                            <Tooltip title={"Tahrirlash"}>
                              <IconButton
                                color={"warning"}
                                onClick={() =>
                                  navigate("/main-lesson/" + item.id)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            {(localStorage.getItem("role") === "admin" ||
                              localStorage.getItem("role") ===
                                "superadmin") && (
                              <DeleteMainLessonModal id={item.id} refresh={handlerGetMainLessons}/>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        </DragDropContext>
      ) : (
        <Typography>Darslar mavjud emas</Typography>
      )}

      <Box display={"flex"} justifyContent={"flex-end"}>
        <AddMainLessonModal
          parentLessonId={parent_id}
          type={"child"}
          length={length}
          handlerGetMainLessons={handlerGetMainLessons}
        />
      </Box>
    </div>
  );
}
