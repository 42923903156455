import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useState} from "react";
import {set, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {BaseUrl, checkAllApi, FileBaseUrl} from "../../api/DefaultApi";
import axios from "axios";
import ReactPlayer from "react-player";
import POST from "../../api/POST";
import {useParams} from "react-router-dom";
import {handleGetFile} from "../../FileTab";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function AddFileModal({types, typeActive}){

    const params=useParams();


    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        // console.log(data);

       if (fileInfo===undefined){
           toast.error("Fayl yuklanmagan");
           return;
       }

        const newData={...data, level_id:params.id, size:fileSize, file_type:fileInfo.type, name_path:fileInfo.image};

        const res=await POST.addFile(newData);
        checkAllApi(res);
        if (res.success){
            toast.success("Fayl qo`shildi");
            reset();
            setFileInfo(undefined);
            setType(undefined);
            handleClose();
            handleGetFile(params.name, typeActive);
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };


    watch((data)=>{
        const typeWatch=data.type;
        if (typeWatch==="Books"){
            setType("application/pdf");
        }else if (typeWatch==="Videos" || typeWatch==="Movies" || typeWatch==="Cartoons"){
            setType("video/*");
        }else if (typeWatch==="Audio"){
            setType("audio/*")
        }else if (typeWatch==="Podcasts"){
            setType("video/*, audio/*")
        }
    })

    const refresh=()=>{
        reset();
    }


    const [type, setType]=useState(undefined)

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [fileInfo, setFileInfo]=useState(undefined);
    const [progress, setProgress] = useState(0);
    const [fileSize, setFileSize]=useState(0);

    const handleUploadFile = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("image", event.target.files[0]);

        // let substring = event.target.files[0]&&event.target.files[0].type.substring(0, 5);
        // // if (substring!="video"){
        // //     toast.error("Siz mp4 formatdagi vedio tanlamadiz.");
        // //     return;
        // // }
        try {
            const res=await axios.post(BaseUrl+"/api/teacher/practice/file", formData, {
                headers: {
                    "Content-Type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            })
            setFileInfo(res.data);
            setFileSize(event.target.files[0].size);
        }catch (e) {
            toast("Xatolik");
        }
    };


    return (
        <>

            <Box>
                <Button onClick={handleOpen} variant={"contained"} color={"success"}>
                    Fayl qo`shish
                </Button>
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box >
                            <TextField
                                label={"Nomini kiriting."}
                                       fullWidth
                                       {...register("name")}
                            />

                            {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                        </Box>

                        <Box >
                            <TextField
                                label={"Muallifini kiriting:"}
                                fullWidth
                                {...register("author")}
                            />

                            {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                        </Box>

                        <Box width={"100%"}>
                            <InputLabel id="demo-multiple-name-label">Turini tanlang</InputLabel>
                            <Select sx={{width:"100%"}}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    MenuProps={MenuProps}
                                    {...register("type", {required:"Xonani tanlash shart"})}
                            >
                                {types.map((value) => (
                                    <MenuItem
                                        key={value.name}
                                        value={value.name}
                                    >
                                        {value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.day&&<Typography color={"error"}>{errors.day.message}</Typography>}
                        </Box>

                        {(fileInfo===undefined && type!==undefined)&&
                            <Box sx={{display:"flex", width:"100%", backgroundColor:"#d9dbe0", padding:"8px", justifyContent:"center"}}>
                                <label>
                                    <input
                                        style={{display:"none"}}
                                        type={"file"} onChange={handleUploadFile} accept={type} />
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                        <img src={"/assets/icon/upload.svg"} width={"24px"} height={"24px"}/>
                                        <span>Faylni yuklang</span>
                                    </div>
                                </label>
                            </Box>
                        }

                        <Box width={"100%"}>
                            {(progress>0 && 100>progress)&&
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <div style={{backgroundColor:"gray", width:progress+"%", height:"4px"}}>
                                    </div>
                                    <span>
                                        {progress}%
                                    </span>
                                </Box>
                            }
                        </Box>

                        {fileInfo!==undefined&&
                            <Box sx={{alignSelf:"center"}}>
                                {fileInfo.type===0&&
                                    <Box sx={{display:"flex", justifyContent:"center"}}>
                                        <img style={{objectFit: "contain"}} width={"300px"} height={"200px"} src={FileBaseUrl+fileInfo.image}/>
                                    </Box>
                                }
                                {fileInfo.type===1&&
                                    <Box sx={{display:"flex", justifyContent:"center"}}>
                                        <ReactPlayer width={"300px"}
                                                     height={"200px"}
                                                     controls={true}
                                                     url={FileBaseUrl+fileInfo.image}/>
                                    </Box>
                                }
                                {fileInfo.type===2&&
                                    <Box sx={{display:"flex", justifyContent:"center"}}>
                                        <a href={FileBaseUrl+fileInfo.image} download style={{display:"flex", justifyContent:"center"}}>
                                            <img src={"/assets/icon/file.svg"} width={"50px"}/>
                                        </a>
                                    </Box>
                                }
                                {fileInfo.type===3&&
                                    <Box sx={{display:"flex", justifyContent:"center"}}>
                                        <audio
                                                     controls={true}
                                                     src={FileBaseUrl+fileInfo.image}/>
                                    </Box>
                                }
                                <Box display={"flex"} justifyContent={"center"} >
                                    <Button onClick={()=>setFileInfo(undefined)} color={"error"} variant={"contained"} sx={{marginTop:"4px"}}>
                                        O`chirish
                                    </Button>
                                </Box>
                            </Box>
                        }


                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Qo`shish</Button>
                            <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}