import GET from "../components/api/GET";
import toast from "react-hot-toast";
import {
    setLevel,
    setProfessions,
    setRoles,
    setRooms,
    setSalaryType,
    setStudents,
    setTeachers,
    setWorkers
} from "./action";
import {checkAllApi} from "../components/api/DefaultApi";


export const handlerGetWorkers=async ()=>{
    const res=await GET.getAllUsersOtherStudent();
    if (res.success){
        setWorkers(res.res.data.users);
    }else if (!res.success){
        // toast.error("Ishchilarni olishda xatolik");
    }
}

export const handlerGetStudents=async ()=>{
    const res=await GET.getAllUserForAdmin();
    if (res.success){
        setStudents(res.res.data.students);
    }else if (!res.success){
        // toast.error("Foydalanuvchilarni olishda xatolik yuz berdi. ");
    }
}

export const handlerGetProfissions=async () =>{
    const res=await GET.getAllProfession();
    if (res.success){
        setProfessions(res.res.data.permissions);
    }else if (!res.success){
        // toast.error("Userlarni olishda xatolik");
    }
}

export const handlerGetSalaryType=async ()=>{
    const res=await GET.getAllSalaryType();
    if (res.success){
        setSalaryType(res.res.data.calcSalaryType);
    }else if (!res.success){
        // toast.error("Oylik turlarini olishda xatolik");
    }
}


export const handlerGetRoles=async ()=>{
    const res=await GET.getAllRoles();
    checkAllApi(res);
    if (res.success){
        setRoles(res.res.data.roles);
    }else if (!res.success){
        // toast.error("Role haqidagi ma`lumotlarni olib bo`lamadi");
    }
}

export const handlerLevels=async ()=>{
    const res=await GET.getAllLessonLevel();
    if (res.success){
        setLevel(res.res.data.levels);
    }else if (!res.success){
        // toast.error("Guruhlarni olishda xatolik");
    }
}

export const handlerTeachers=async ()=>{
    const res = await GET.getAllTeachers();
    if (res.success){
        setTeachers(res.res.data.teachers);
    }else if (!res.success){
        // toast.error("O`qituvchilarni olishda xatolik");
    }
}

export const handlerRooms=async ()=>{
    const res = await GET.getAllRooms();
    if (res.success) {
        setRooms(res.res.data.data);
    }
    if (!res.success) {
        // toast.error("Xonalarni olishda xatolik");
    }
}

