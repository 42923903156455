import React, { useState } from "react";
import { checkAllApi } from "../../api/DefaultApi";
import { handleGetGroups } from "../../group/GroupsMenu";
import toast from "react-hot-toast";
import POST from "../../api/POST";
import { useForm } from "react-hook-form";
import { Button, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export default function AddOrEditLessonLevel({
  type,
  lessonLevel,
  handlerGetAllLessonLevel,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let sendData;

    if (type === "add") {
      sendData = { ...data };
    } else {
      sendData = { ...data, id: lessonLevel.id };
    }

    const res = await POST.addLessonLevel(sendData);
    checkAllApi(res);
    if (res.success) {
      toast.success("Yaratildi.");
      reset();
      handleClose();
      handlerGetAllLessonLevel();
    }
    if (!res.success) {
      toast.error("Xatolik");
    }
  };

  const refresh = () => {
    reset();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        {(localStorage.getItem("role") === "admin" ||
          localStorage.getItem("role") === "superadmin") && (
          <Box>
            {type === "add" ? (
              <Button
                variant={"contained"}
                color={"success"}
                onClick={handleOpen}
              >
                Qo`shish
              </Button>
            ) : (
              <Tooltip title={"Tahrirlash"}>
                <IconButton color={"warning"} onClick={handleOpen}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography>Dars darajasi nomini kiriting:</Typography>
              <TextField
                fullWidth
                defaultValue={type === "edit" ? lessonLevel.name : ""}
                {...register("name", {
                  required: "Bo`sh bo`lishi mumkin emas.",
                  minLength: {
                    value: 2,
                    message: " 2 ta belgidan kam bo`lmasligi kerak",
                  },
                  maxLength: {
                    value: 101,
                    message: "100 ta belgidan ortiq bo`lishi mumkin emas",
                  },
                })}
              />

              {errors.name && (
                <Typography color={"error"}>{errors.name.message}</Typography>
              )}
            </Box>

            <Box>
              <Typography>Daraja rangini kiriting:</Typography>

              <TextField
                defaultValue={type === "edit" ? lessonLevel.color : "#00000"}
                fullWidth
                type={"color"}
                {...register("color")}
              />
            </Box>

            <Box>
              <Typography>Tavsifini kiriting:</Typography>

              <textarea
                style={{ width: "100%" }}
                rows={5}
                defaultValue={type === "edit" ? lessonLevel.description : ""}
                {...register("description")}
              />
            </Box>

            <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
              <Button type={"submit"} color={"success"} variant={"contained"}>
                Saqlash
              </Button>
              {type === "admin" && (
                <Button
                  color={"warning"}
                  variant={"contained"}
                  onClick={refresh}
                >
                  Tozalash
                </Button>
              )}
              <Button
                onClick={handleClose}
                color={"error"}
                variant={"contained"}
              >
                Yopish
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
