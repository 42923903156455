import UsersForAdmin from "../../components/UsersForAdmin";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import {checkAllApi} from "../../components/api/DefaultApi";
import toast from "react-hot-toast";


export default function Students(){
    const [role, setRole]=useState(undefined);

    const handlerGetRoles=async ()=>{
        const res=await GET.getAllRoles();
        checkAllApi(res);
        if (res.success){
            let roles = res.res.data.roles;
            let find = roles.find(item => item.name==="student");
            setRole(find);
        }else if (!res.success){
            toast.error("Role haqidagi ma`lumotlarni olib bo`lamadi");
        }
    }



    useEffect(()=>{
        handlerGetRoles();
    }, []);

    return (
        <>
            {role&&
                <UsersForAdmin role={role} statusFilter={true} handlerStatus={"active"}/>
            }
        </>
    );

}