import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {Button, Pagination, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {FileBaseUrl} from "../../components/api/DefaultApi";
import ReactPlayer from "react-player";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {getDateInfo} from "../Chat";
import EditIcon from "@mui/icons-material/Edit";
import ShowNotification from "../../components/modals/ShowNotification";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import toast from "react-hot-toast";
import DELETE from "../../components/api/DELETE";
import DeleteNotificationModal from "../../components/modals/DeleteModal/DeleteNotificationModal";


export default function Notification(){


    const [notifications, setNotifications]=useState([]);


    const [page, setPage]=useState(1);

    const [lastPage, setLastPage]=useState(1);

    const [loader, setLoader]=useState(true);

    const handlerGetNotifications=async ()=>{
        const res=await GET.getAllNotification(page);
        if (res.success){
            setNotifications(res.res.data.data);
            setLastPage(res.res.data.last_page);
            setLoader(false);
        }else if (!res.success){
            toast.error("Notificationda xatolik.");
            setLoader(false);
        }
    }

    useEffect(()=>{
        handlerGetNotifications();
    }, [])

    useEffect(()=>{
        handlerGetNotifications();
    }, [page])



    return (
        <>
            <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <Typography>
                    Notification
                </Typography>

                <Link to={"/notification/add"}>
                    <Button color={"success"} variant={"contained"}>
                        Qo`shish
                    </Button>
                </Link>
            </Box>



            {loader?
                <LoadingBeatLoader/>
                :
                <Box width={"100%"}>
                    <Box sx={{marginY:"4px"}}>
                        {notifications!==undefined&&notifications.length>0?
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Title</TableCell>
                                                <TableCell align={"center"}>Description</TableCell>
                                                <TableCell align={"center"}>Fayl</TableCell>
                                                <TableCell align={"center"}>Yaratilgan vaqti</TableCell>
                                                <TableCell align={"center"}>O`zgartirilgan vaqti</TableCell>
                                                <TableCell align={"center"}>Amallar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {notifications&&notifications.map((value1, index) =>
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {((page-1)*10)+index+1}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <Typography>{value1.title}</Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Typography
                                                            dangerouslySetInnerHTML={{__html: value1.description?value1.description:"Mavjud emas"}}
                                                        >
                                                            {/*{lesson.description?lesson.description:"Aniqlanmadi"}*/}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {value1.type==0&&
                                                            <Box>
                                                                <img style={{objectFit: "contain"}} width={"100%"} height={"200px"} src={FileBaseUrl+value1.file}/>
                                                            </Box>
                                                        }
                                                        {value1.type==1&&
                                                            <Box>
                                                                <ReactPlayer width={"100%"}
                                                                             height={"200px"}
                                                                             controls={true}
                                                                             url={FileBaseUrl+value1.file}/>
                                                            </Box>
                                                        }
                                                        {value1.file===null&&<Typography>Mavjud emas</Typography>}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {getDateInfo(value1.created_at)}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {getDateInfo(value1.updated_at)}
                                                    </TableCell>
                                                    <TableCell align={"center"} >
                                                        <Box sx={{display:"flex"}}>
                                                            <ShowNotification notification={value1}/>
                                                            <Tooltip title={"Tahrirlash"}>
                                                                <IconButton color={"warning"} >
                                                                    <EditIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <DeleteNotificationModal id={value1.id} refresh={handlerGetNotifications}/>

                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                    <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                                </Box>
                            </>
                            :
                            <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                                <Typography>Notification mavjud emas</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </>
    );
}