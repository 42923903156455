import {useParams} from "react-router-dom";
import GET from "../api/GET";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {getDateInfo} from "../../page/Chat";
import EditUserModal from "../modals/EditUserModal";


const typographyStyle={
    border:"1px solid black", width:"30%", padding:"4px 8px", borderCollapse:"collapse", minWidth:"150px"
}

const boxStyle={
    display:"flex", flexWrap:"wrap"

}

export default function UserInfo(){

    const params=useParams();


    const [user, setUser]=useState(undefined);
    const handlerByIdUserInfo=async ()=>{
        const res=await GET.getUserById(params.id);
        setUser(res.res.data.user);
    }

    const getColorByStatus=(status)=>{
        if (status==="active"){
            return "#2E7D32";
        }else if (status==="inactive"){
            return "#ED6C02";
        }else if (status==="delete"){
            return "#D32F2F";
        }
    }


    useEffect(()=>{
        handlerByIdUserInfo();
    }, [])

    return(
        <>
            {user?
                <Box>
                    {user&&
                        <Box>
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Ismi:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.name}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Familiyasi:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.lastname}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Telifon raqami:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.phone}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Email:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.email}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Manzili:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.address}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Tug`ilgan kuni:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.birthday}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Yaratilgan sanasi:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {getDateInfo(user.created_at)}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Yaratilgan sanasi:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {getDateInfo(user.updated_at)}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Otasinig ismi:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.fatherName?user.fatherName:"Tizimda yo`q"}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Onasining ismi:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.matherName?user.matherName:"Tizimda yo`q"}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Otasining telifon raqami:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.fatherPhone?user.fatherPhone:"Tizimda yo`q"}
                                </Typography>
                            </Box>


                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Onasining telifon raqami:
                                </Typography>
                                <Typography sx={typographyStyle}>
                                    {user.matherPhone?user.matherPhone:"Tizimda yo`q"}
                                </Typography>
                            </Box>

                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Holati:
                                </Typography>
                                <Box sx={typographyStyle} >
                                    <Typography style={{color: "white", backgroundColor:getColorByStatus(user.status), borderRadius:"8px"}} align={"center"} width={"100px"}>
                                        {user.status}
                                    </Typography>
                                </Box>
                            </Box>


                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Role:
                                </Typography>
                                <Typography sx={typographyStyle} >
                                    {user.role?user.role.display_name:"Role yo`q"}
                                </Typography>
                            </Box>
                        </Box>}
                </Box>
                :
            <Box>
                <Typography>Siz so`ragan foydalanuvchi mavjud emas yoki sizga ruxsat yo`q</Typography>
            </Box>}


        </>
    );
}