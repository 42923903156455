export const TOGGLE_LOGIN="TOGGLE_LOGIN";
export const TOGGLE_LOADING="TOGGLE_LOADING";
export const ERROR_NETWORK="ERROR_NETWORK";
export const GET_GROUPS="GET_GROUPS";

export const GET_LESSON_ID="GET_LESSON_ID";
export const GET_GROUP_ID="GET_GROUP_ID";

export const GET_QUESTIONS="GET_QUESTIONS";

export const SET_FILES="SET_FILES";

export const SET_MESSAGE="SET_MESSAGE";


export const SET_WORKERS="SET_WORKERS";
export const SET_STUDENTS="SET_STUDENTS";
export const SET_PROFESSIONS="SET_PROFESSIONS";
export const SET_SALARY_TYPE="SET_SALARY_TYPE";
export const SET_ROLES="SET_ROLES";

export const SET_LEVEL="SET_LEVEL";

export const SET_TEACHERS="SET_TEACHERS";
export const SET_ROOMS="SET_ROOMS";

// export const SET_GROUPS="SET_GROUPS";