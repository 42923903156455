import {createStore} from "redux";
import reducer from "./reducer";




const story=createStore(reducer);

const dispatch=story.dispatch;
export {dispatch};

export default story;