import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import {useForm} from "react-hook-form";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {checkAllApi} from "../../api/DefaultApi";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../../api/DELETE";
import VisibilityIcon from "@mui/icons-material/Visibility";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AddOrEditExamGroupModal({type, exam, refresh, statusList, groups, examTypeList}){


    const { register,
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
        setValue
    } = useForm();

    const onSubmit = async (data) => {
        const handlerData=type=="add"?{...data}:{...data, id:exam.id};

            const res=await POST.addOrEditExamGroup(handlerData);
            if (res.success){
                toast.success(type==="add"?"Q'shild":"Tahrirlandi");
                handleClose();
                refresh();
            }
            if (!res.success){
                toast.error("Xatolik");
            }

        }





    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    useState(()=>{
        if (type=="edit"){
            setValue("date", exam.date);
            setValue("time", exam.time);
            setValue("lifetime", exam.lifetime);
        }
    }, [exam])





    return (
        <>

            <Box>
                {type==="add"?
                    <Tooltip title={"Test yaratish"}>
                        <IconButton onClick={handleOpen}>
                            <AddCircleOutlineOutlinedIcon color={"success"} variant={"contained"}/>
                        </IconButton>
                    </Tooltip>
                    :type=="edit"?
                        <Tooltip title={"Testni tahrirlash"}>
                            <IconButton onClick={handleOpen}>
                                <ModeEditOutlineIcon color={"warning"} variant={"contained"}/>
                            </IconButton>
                        </Tooltip>
                        : <Tooltip title={"Testni tahrirlash"}>
                            <IconButton onClick={handleOpen}>
                                <VisibilityIcon color={"primary"} variant={"contained"}/>
                            </IconButton>
                        </Tooltip>


                }
            </Box>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box >
                            <Typography>
                                Kunini kiriting:
                            </Typography>
                            <TextField fullWidth
                                       type={"date"}
                                {...register("date", {required:"Kunini kiriting"})}
                            />
                            <Typography>
                                {errors.date&&<Typography color={"error"}>{errors.date.message}</Typography>}
                            </Typography>
                        </Box>

                        <Box >
                            <Typography>
                                Vaqtini kiriting:
                            </Typography>
                            <TextField fullWidth
                                       type={"time"}
                                       {...register("time", {required:"Kunini kiriting"})}
                            />
                            <Typography>
                                {errors.date&&<Typography color={"error"}>{errors.date.message}</Typography>}
                            </Typography>
                        </Box>

                        <Box >
                            <Typography>
                                Davomiyligini kiriting:
                            </Typography>
                            <TextField fullWidth
                                       type={"time"}
                                       {...register("lifetime", {required:"Kunini kiriting"})}
                            />
                            <Typography>
                                {errors.date&&<Typography color={"error"}>{errors.date.message}</Typography>}
                            </Typography>
                        </Box>

                        <Box>
                            <Box width={"100%"}>
                                <InputLabel id="demo-multiple-name-label">Guruhni tanlang</InputLabel>
                                <Select sx={{width:"100%"}}
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        MenuProps={MenuProps}
                                        error={errors.group_id}
                                        defaultValue={exam?exam.group_id:null}
                                        {...register("group_id",{required:"Tanlash shart"})}
                                >
                                    {groups.map((value) => (
                                        <MenuItem
                                            key={value.id}
                                            value={value.id}
                                        >
                                            {value.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            {errors.group_id&&<Typography color={"error"}>{errors.group_id.message}</Typography>}
                        </Box>

                        <Box>
                            <Box width={"100%"}>
                                <InputLabel id="demo-multiple-name-label">Imtihon turini tanlang</InputLabel>
                                <Select sx={{width:"100%"}}
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        MenuProps={MenuProps}
                                        error={errors.exam_id}
                                        defaultValue={exam?exam.exam.id:null}
                                        {...register("exam_id",{required:"Tanlash shart"})}
                                >
                                    {examTypeList.map((value) => (
                                        <MenuItem
                                            key={value.id}
                                            value={value.id}
                                        >
                                            {value.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            {errors.exam_id&&<Typography color={"error"}>{errors.exam_id.message}</Typography>}
                        </Box>

                        <Box>
                            <Box width={"100%"}>
                                <InputLabel id="demo-multiple-name-label">Holatini tanlang</InputLabel>
                                <Select sx={{width:"100%"}}
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        MenuProps={MenuProps}
                                        error={errors.status}
                                        defaultValue={exam?exam.status:null}
                                        {...register("status",{required:"Tanlash shart"})}
                                >
                                    {statusList.map((value) => (
                                        <MenuItem
                                            key={value.id}
                                            value={value.id}
                                        >
                                            {value.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            {errors.status&&<Typography color={"error"}>{errors.status.message}</Typography>}
                        </Box>







                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            {type!="show"&&
                                <Button type={"submit"} color={"success"} variant={"contained"}>{type==="add"?"Saqlash":"Tahrirlash"}</Button>
                            }
                            {type=="add"&&
                                <Button color={"warning"} variant={"contained"} onClick={reset}>Tozalash</Button>
                            }
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}