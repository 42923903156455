import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import FileTabId from "../FileTabId";
import Typography from "@mui/material/Typography";
import GET from "../api/GET";
import Loader from "../LoadingBeatLoader";
import {useSelector} from "react-redux";
import {setFiles} from "../../redux/action";


export const handleGetFile=async (level, type)=>{
    const res=await GET.getFiles(level, type);
    if (res.success){
        setFiles(res.res.data.files);
    }
    return res&&false;
}
export default function FileTab({level, type}){

    const files=useSelector(state => state.items.files);


    useEffect(()=>{
        handleGetFile(level, type).then((res)=>setLoader(false));
    }, [level, type])



    const [loader, setLoader]=useState(true);


    return (
        <>
            <Box>
                {loader
                    ?<Loader/>
                    :<Box>
                        {files.length>0?
                            <Box>


                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                                <TableCell align={"center"}>Nomi</TableCell>
                                                <TableCell align={"center"}>Muallifi</TableCell>
                                                <TableCell align={"center"}>Fayl</TableCell>
                                                <TableCell width={"160px"} align={"center"}>Amallar</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {files.map((row, index) =>
                                                <FileTabId index={index} file={row} level={level} typeFile={type}/>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            :
                            <Box>
                                <Typography>Fayllar mavjud emas</Typography>
                            </Box>
                        }
                    </Box>
                }
            </Box>
        </>
    );
}