import {Button, FormControlLabel, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {checkAllApi, FileBaseUrl} from "../../components/api/DefaultApi";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useForm} from "react-hook-form";
import POST from "../../components/api/POST";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GET from "../../components/api/GET";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {useNavigate} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function AddSMS(){

    const [values, setValues]=useState([]);

    const [data, setData]=useState([]);

    const [loader, setLoader]=useState(true);

    const navigate=useNavigate();

    const [checkedAll, setCheckedAll]=useState(true);


    const allChecked=()=>{
        const handlerValue=[...values];

        handlerValue.map(value => value.value=checkedAll);
        // console.log(handlerValue);
        setValues(handlerValue);
        setCheckedAll(!checkedAll);
        checkAmount();
    }


    const setHandlerValues=async ()=>{
        const res=await GET.getAllGroupForNotification();

        if (res.success){
            setData(res.res.data.groups);
            const handlerData=[];
            res.res.data.groups.map((value)=>{
                handlerData.push({id: value.id, type: "group", value: false})
                value.students_group.map((value1)=>{
                    handlerData.push({id: value1.id, type: "user", value: false, groupId:value.id, phone:value1.phone})
                })
            });
            setValues(handlerData);
            setLoader(false);
        }
    }


    useEffect(()=>{
        setHandlerValues();
    }, []);




    const editedValue=(id, checked, type, parentId)=>{
        let handlerValues=[...values];
        let groupValues=[];

        handlerValues.map((value)=> {
            if (value.id===id&&value.type===type){
                value.value=checked;
            }
            if (type==="group"){
                if (value.groupId===id){
                    value.value=checked;
                }
            }
            if (type==="user"&&value.groupId===parentId){
                groupValues.push(value.value);
            }
        });

        let count=0;

        groupValues.map((value)=>value===checked&&count++);




        if (count===groupValues.length){
            handlerValues.map((value)=>{
                if (value.type==="group"&&value.id===parentId){
                    value.value=checked;
                }
            })
        }

        if (count!==groupValues.length){
            if (!checked){
                handlerValues.map((value)=>{
                    if (value.type==="group"&&value.id===parentId){
                        value.value=checked;
                    }
                })
            }
        }
        setValues(handlerValues);
        checkAmount();

    }


    const getValue=(id, type, parentId)=>{
        if (type==="group"){
            const find=values.find(value => value.id==id&&value.type==type);
            return find?find.value:false;
        }
        if (type==="user"){
            const find=values.find(value => value.id==id&&value.type=="user");
            return find?find.value:false;
        }
    }

    const checkAmount=()=>{
        let count=0;
        values.map(value => {
            if (value.value===true){
                count++
            }
        })

        count+=phoneList.length;
        if (count>50){
            toast.success("Siz 50 tadan ortiq raqamni tanladingiz. ");
        }
    }







    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {

        const handlerPhoneList=[...phoneList]

        values.map(user => {
            if (user.type==="user"&&user.value===true){
                handlerPhoneList.push(user.phone);
            }
        })

        setPhoneList(handlerPhoneList);


        const res=await POST.addSMS({...data, phone_numbers:handlerPhoneList, phone_list:JSON.stringify(handlerPhoneList)});
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast.error("Xatolik");
            return;
        }

        if (res.success){
            toast.success("Yaratildi.");
            reset();
            navigate("/sms");
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
        setHandlerValues();
    }




    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [phoneList, setPhoneList]=useState([])


    const [phoneNumber, setPhoneNumber]=useState();
    const addPhoneNumber = (event) => {
        event.preventDefault();
        const handlerPhoneNumberList=[...phoneList];
        handlerPhoneNumberList.push(phoneNumber);
        setPhoneList(handlerPhoneNumberList);
        setPhoneNumber(null);
        handleClose();
        checkAmount();
    };


    const deletePhoneNumber=(item)=>{
        const handlerPhoneList=[...phoneList];
        let findIndex = handlerPhoneList.findIndex(value => value===item);
        handlerPhoneList.splice(findIndex, 1);
        setPhoneList(handlerPhoneList);
    }


    return (
        <>
            {loader?
                <LoadingBeatLoader/>:
                <Box component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"} sx={{display:'flex', flexDirection:"column", gap:"8px"}}>
                    <Box sx={{display:"flex", flexDirection:"column", width:"100%", gap:"12px"}}>
                        <Box sx={{display:"flex", flexDirection:"column", gap:"12px"}}>
                            <Box>
                                <Typography>SMS matnini kiriting:</Typography>
                                <textarea rows={5} style={{width:"100%"}}
                                          {...register("message")}
                                />
                            </Box>
                            <Box>
                                <Typography>Tavsifini kiriting:</Typography>
                                <textarea rows={5} style={{width:"100%"}}
                                          {...register("description")}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Button onClick={()=>allChecked()} color={checkedAll?"success":"error"} variant={"contained"}>
                        {checkedAll?"Hammasini belgilash":"Barchasini bekor qilish"}
                    </Button>

                    <Box >
                        {data.map(value =>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <FormControlLabel
                                        label={value.name}
                                        control={
                                            <Checkbox
                                                checked={getValue(value.id, "group")}
                                                onChange={(event, checked)=> editedValue(value.id, checked, "group" )}
                                            />
                                        }
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        value.students_group.map(value1 =>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                                <FormControlLabel
                                                    label={value1.name+"\t     "+value1.phone}
                                                    control={<Checkbox
                                                        checked={getValue(value1.id, "user", value.id)}
                                                        onChange={(event, checked)=> editedValue(value1.id, checked, "user", value.id)}
                                                    />}
                                                />
                                            </Box>
                                        )
                                    }
                                </AccordionDetails>

                            </Accordion>
                        )}
                    </Box>

                    <Box>
                        <Box>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"} marginY={"8px"}>
                                <Typography variant={"h4"} component={"h4"}>Siz sms jo`natmoqchi bo`lgan qo`shimcha telifon raqamlar:</Typography>
                                <Button variant={"contained"} color={"success"} onClick={handleOpen}>
                                    Qo`shish
                                </Button>
                            </Box>

                            <Box display={"flex"} flexWrap={"wrap"} gap={"4px"}>
                                {phoneList.length>0&&phoneList.map((value)=>
                                    <Box display={"flex"} alignItems={"center"} sx={{backgroundColor:"#1976D2", padding:"2px", borderRadius:"8px"}}>
                                        <Typography color={"white"}>{value}</Typography>
                                        <Tooltip title={"O`chirish"}>
                                            <IconButton color={"error"} onClick={()=>deletePhoneNumber(value)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                        <Button type={"submit"} color={"success"} variant={"contained"} >Saqlash</Button>
                        <Button color={"warning"} variant={"contained"} onClick={handleClose}>Yopish</Button>
                    </Box>
                </Box>
            }


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={addPhoneNumber}>

                        <PhoneInput
                            defaultCountry="UZ"
                            value={phoneNumber}
                            onChange={(value)=>{setPhoneNumber(value)}} />

                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                            <Button type={"submit"} color={"success"} variant={"contained"}>Jo`natish</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}