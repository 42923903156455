import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import {useParams} from "react-router-dom";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import MainLessonMainContent from "../../components/mainlesson/MainLessonMainContent";
import MainLessonTest from "../../components/mainlesson/MainLessonTest";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function MainLesson(){

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [lesson, setLesson]=useState(undefined);
    const handleGetMainLesson=async (id)=>{
        const res=await GET.getMainLesson(id);
        if (res.success){
           setLesson(res.res.data.mainlesson);
        }
        res&&setLoader(false);
        return false;
    }

    const params=useParams();
    const id=params.id;

    const [loader, setLoader]=useState(true);

    useEffect(()=>{
        handleGetMainLesson(id);
    }, [id, value])


    return (
        <Box>

            {loader?
                <LoadingBeatLoader/>
                :
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Asosiy ma`lumotlar" {...a11yProps(0)} />
                            <Tab label="Dars yuzasidan testlar" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <MainLessonMainContent lesson={lesson} handleGetMainLesson={handleGetMainLesson}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MainLessonTest lesson={lesson} handleGetMainLesson={handleGetMainLesson}/>
                    </TabPanel>
                </Box>}

        </Box>
    );
}