import {Button, Switch, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {BaseUrl, checkAllApi, FileBaseUrl} from "../../api/DefaultApi";
import ReactPlayer from "react-player";
import React, {useState} from "react";
import toast from "react-hot-toast";
import axios from "axios";
import {useForm} from "react-hook-form";
import POST from "../../api/POST";


const label = { inputProps: { 'aria-label': 'Switch demo' } };

const styleChild = {
    padding:"10px",
    overflow:'auto',
    height:'50%',
    minWidth:"70%",
    display:'flex',
    gap:1,
    flexDirection:'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function AddNewsModal({handlerGetNews, page}){


    const [open, setOpen] = useState(false);


    const handleOpen = () => {
        setOpen(true)
    };


    const handleClose = () => setOpen(false);


    const [fileInfo, setFileInfo]=useState(undefined);
    const [progress, setProgress] = useState(0);

    const handleUploadFile = async (event) => {
        event.preventDefault();


        const formData = new FormData();
        formData.append("image", event.target.files[0]);

        try {
            const res=await axios.post(BaseUrl+"/api/teacher/practice/file", formData, {
                headers: {
                    "Content-Type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            })
            setFileInfo(res.data);
        }catch (e) {
            toast("Xatolik");
        }
    };




    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        // console.log(data);
        let newData={...data};

            fileInfo!==undefined?
                newData={...data,  file:fileInfo.image, type:fileInfo.type}
                :
                newData={...data}

        const res=await POST.addNews(newData);
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast.error("Xatolik");
            return;
        }

        if (res.success){
            toast.success("Yaratildi.");
            setFileInfo(undefined);
            reset();
            handleClose();
            handlerGetNews(page);
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    return (
        <>



            <Box>
                <Button onClick={handleOpen} variant={"contained"} color={"success"}>
                    Qo`shish
                </Button>

            </Box>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={styleChild} component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"}>
                        <Box>
                            <Typography>Titleni kiriting:</Typography>
                            <TextField
                                       fullWidth
                                       {...register("title",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>








                            <Box>
                                <Typography>Tavsifini kiriting:</Typography>
                                <textarea style={{width:"100%"}} rows={5}
                                label={"Tavsifini kiriting: "} sx={{marginBottom:"4px"}}
                                {...register("description")}
                                    fullWidth/>

                                {fileInfo===undefined&&
                                    <Box sx={{display:"flex", width:"100%", backgroundColor:"#d9dbe0", padding:"8px", justifyContent:"center"}}>
                                        <label>
                                            <input
                                                style={{display:"none"}}
                                                type={"file"} onChange={handleUploadFile} accept={"video/*, image/*"} />
                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                                <img src={"/assets/icon/upload.svg"} width={"24px"} height={"24px"}/>
                                                <span>Faynli yuklang</span>
                                            </div>
                                        </label>
                                    </Box>
                                }


                                <Box width={"100%"}>
                                    {(progress>0 && 100>progress)&&
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <div style={{backgroundColor:"gray", width:progress+"%", height:"4px"}}>
                                            </div>
                                            <span>
                                        {progress}%
                                    </span>
                                        </Box>
                                    }
                                </Box>

                                {fileInfo!==undefined&&
                                    <Box sx={{alignSelf:"center"}} witdh={"100%"}>
                                        {fileInfo.type===0&&
                                            <Box sx={{display:"flex", justifyContent:"center"}}>
                                                <img style={{objectFit: "contain"}} width={"300px"} height={"200px"} src={FileBaseUrl+fileInfo.image}/>
                                            </Box>
                                        }
                                        {fileInfo.type===1&&
                                            <Box sx={{display:"flex", justifyContent:"center"}}>
                                                <ReactPlayer width={"300px"}
                                                             height={"200px"}
                                                             controls={true}
                                                             url={FileBaseUrl+fileInfo.image}/>
                                            </Box>
                                        }
                                        {fileInfo.type===2&&
                                            <Box sx={{display:"flex", justifyContent:"center"}}>
                                                <a href={FileBaseUrl+fileInfo.image} download style={{display:"flex", justifyContent:"center"}}>
                                                    <img src={"/assets/icon/file.svg"} width={"50px"}/>
                                                </a>
                                            </Box>
                                        }
                                        <Box display={"flex"} justifyContent={"center"} >
                                            <Button onClick={()=>setFileInfo(undefined)} color={"error"} variant={"contained"} sx={{marginTop:"4px"}}>
                                                O`chirish
                                            </Button>
                                        </Box>
                                    </Box>
                                }
                            </Box>


                        <Box>
                            <Typography>
                                Telifon raqamini kiriting:
                            </Typography>
                            <TextField
                                       fullWidth
                                       {...register("phone",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>

                        <Box>
                            <Typography>
                                Emailni kiriting:
                            </Typography>
                            <TextField label={"Emailni kiriting:"}
                                       fullWidth
                                       {...register("email",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>

                        <Box>
                            <Typography>
                                Boshlanish vaqtini kiriting:
                            </Typography>
                            <TextField
                                       fullWidth type={"time"}
                                       {...register("start_time",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>

                        <Box>
                            <Typography>
                                Tugash vaqtini kiriting:
                            </Typography>
                            <TextField
                                       fullWidth type={"time"}
                                       {...register("end_time",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                        </Box>


                        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <Typography>Aktiv emas</Typography>
                            <Switch {...label} color={"success"}
                                    {...register("status")}
                            />
                            <Typography>Aktiv</Typography>
                        </Box>




                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"} disabled={(progress>0 && progress<100)&&true}>Saqlash</Button>
                            <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>


        </>
    );
}