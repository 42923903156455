import { useEffect } from "react";
import Box from "@mui/material/Box";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import DELETE from "../../api/DELETE";
import { checkAllApi } from "../../api/DefaultApi";
import toast from "react-hot-toast";
import POST from "../../api/POST";
import MainLessonChildAccordionDetail from "../MainLessonChildAccordionDetail";
import EditMainLessonModal from "../../modals/EditMainLessonModal";
import DeleteMainLessonModal from "../../modals/DeleteModal/DeleteMainLessonModal";

export default function MainLessonAccordion({
  mainLessons,
  lessonLevelList,
  handlerGetMainLessons,
}) {


  const handleEditLesson = async (data) => {
    // const res=await POST.addLesson(data);
    // let checkAllApi1 = checkAllApi(res);
    // if (!checkAllApi1){
    //     toast("Xatolik")
    //     return;
    // }
    // if (res.success){
    //     toast.success("Tahrirlandi ");
    //     handleGetByIdGroup(groupId)
    //     return;
    // }
    // if (!res.success){
    //     toast.error("Xatolik");
    // }
  };

  useEffect(() => {
    mainLessons.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
  }, []);

  return (
    <div>
      <div>
        {mainLessons && (
          <DragDropContext
            onDragEnd={(param) => {
              if (localStorage.getItem("role") === "admin"||localStorage.getItem("role") === "superadmin") {
                const srcI = param.source.index;
                const desI = param.destination?.index;
                mainLessons.splice(desI, 0, mainLessons.splice(srcI, 1)[0]);
                mainLessons.map((value, index) => (value.order = index));
                let data = [];
                mainLessons.map((value, index) => {
                  data.push({ id: value.id, order: value.order });
                });

                // if (desI) {
                  POST.sortMainLesson(data).then((res) => {
                    if (res.success) {
                      // handlerGetMainLessons();
                      toast.success("Qayta tartiblandi. ");
                    }
                  });
                // }
              } else {
                toast.error("Sizda huquq yo`q");
              }
            }}
          >
            <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
              <Droppable droppableId="droppable-1">
                {(provided, _) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {mainLessons.map((item, i) => (
                      <Draggable
                        key={item.id}
                        draggableId={"draggable-" + item.id}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                              width: "100%",
                              backgroundColor: "#EFF1F5",
                              padding: "4px",
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // style={{
                            //     ...provided.draggableProps.style,
                            //     boxShadow: snapshot.isDragging
                            //         ? "0 0 .4rem #666"
                            //         : "none",
                            // }}
                          >
                            <img
                              src={"/assets/icon/cursor.svg"}
                              {...provided.dragHandleProps}
                            />
                            <Accordion sx={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Box
                                  display={"flex"}
                                  width={"100%"}
                                  justifyContent={"space-between"}
                                >
                                  <Typography>
                                    {i + 1 + ". "}
                                    {item.name}
                                  </Typography>
                                  {item.level && (
                                    <Typography
                                      style={{ color: item.level.color }}
                                    >
                                      {item.level.name}
                                    </Typography>
                                  )}
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box>
                                  <MainLessonChildAccordionDetail
                                    child={item.children}
                                    parent_id={item.id}
                                    handlerGetMainLessons={
                                      handlerGetMainLessons
                                    }
                                  />
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                            <Box display={"flex"}>
                              {/*<Tooltip title={item.ready===0?"Darsni ochish":"Darsni yopish"}>*/}
                              {/*    <IconButton color={item.ready===1?"success":"error"} onClick={()=>handleEditLesson({...item,ready:item.ready===0?1:0},item.ready===0?"Dars ochildi":"Dars yopildi" )}>*/}
                              {/*        {item.ready===1?<VisibilityIcon/>:<VisibilityOffIcon/>}*/}
                              {/*    </IconButton>*/}
                              {/*</Tooltip>*/}

                              {/*<Tooltip title={item.repeat===0?"Takrorlash ochish":"Takrorlash yopish"}>*/}
                              {/*    <IconButton color={item.repeat===0?"error":"success"} onClick={()=>handleEditLesson({...item,repeat:item.repeat===0?1:0}, item.repeat===0?"Takrorlash ochildi":"Takrorlash yopildi")}>*/}
                              {/*        <AutorenewIcon />*/}
                              {/*    </IconButton>*/}
                              {/*</Tooltip>*/}

                              {/*<ParentLessonEditModal lesson={item} groupId={groupId}/>*/}

                              {(localStorage.getItem("role") === "admin" ||
                                localStorage.getItem("role") ===
                                  "superadmin") && (
                                <EditMainLessonModal
                                  lessonLevelList={lessonLevelList}
                                  handlerGetMainLessons={handlerGetMainLessons}
                                  type={"main"}
                                  length={mainLessons.length}
                                  lesson={item}
                                />
                              )}
                              {(localStorage.getItem("role") === "admin" ||
                                localStorage.getItem("role") ===
                                  "superadmin") && (
                                <DeleteMainLessonModal id={item.id} refresh={handlerGetMainLessons}/>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          </DragDropContext>
        )}
      </div>
    </div>
  );
}
