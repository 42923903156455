import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {formatPrice} from "../../Other/useWindowSize";
import {Link, useNavigate} from "react-router-dom";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&':{
        cursor:"pointer"
    }
}));

export default function GroupsListForDashboard({groups}){

    const [data, setData]=useState(groups?groups.data:[]);
    
    const navigate=useNavigate();


    return (
        <>
            <Box>
                {data.length>0
                    ?
                    <Box>
                        <Link style={{display:"flex", justifyContent:"right", textDecorationLine:"none"}} to={"/group-list"}>
                            <Typography >Barchasi guruhlarni ko`rish</Typography>
                        </Link>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={"center"}>T/R</StyledTableCell>
                                        <StyledTableCell align={"left"}>Nomi</StyledTableCell>
                                        <StyledTableCell align={"right"}>Narxi</StyledTableCell>
                                        <StyledTableCell align={"center"}>Holati</StyledTableCell>
                                        <StyledTableCell align={"center"}>Ochilgan sanasi</StyledTableCell>
                                        <StyledTableCell align={"center"}>O`qituvchi</StyledTableCell>
                                        <StyledTableCell align={"center"}>Dars vaqtlari</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, index) => (
                                        <StyledTableRow key={row.id}
                                                        onClick={()=>navigate("/group?groupId="+row.id)}
                                        >
                                            <StyledTableCell align={"center"} width={"30px"} component="th" scope="row">
                                                {(index+1)}
                                            </StyledTableCell>
                                            <StyledTableCell width={"150px"} align={"start"}>{row.name}</StyledTableCell>
                                            <StyledTableCell align={"right"} width={"150px"}>{formatPrice(row.price)}</StyledTableCell>
                                            {row.status==0&&
                                                <StyledTableCell  align={"center"}>
                                                    <Typography sx={{backgroundColor:"#FF0000", width:"objectFit", color:"white", borderRadius:"50px"}} >
                                                        yopilgan
                                                    </Typography>
                                                </StyledTableCell>

                                            }
                                            {row.status==1&&
                                                <StyledTableCell  align={"center"}>
                                                    <Typography sx={{backgroundColor:"#2E7D32", width:"objectFit", color:"white", borderRadius:"50px"}} >
                                                        aktive
                                                    </Typography>
                                                </StyledTableCell>
                                            }
                                            {row.status==2&&
                                                <StyledTableCell  align={"center"}>
                                                    <Typography sx={{backgroundColor:"#ED6C02", width:"objectFit", color:"white", borderRadius:"50px"}} >
                                                        to`lmoqda
                                                    </Typography>
                                                </StyledTableCell>}
                                            <StyledTableCell align={"center"}>{row.start_date}</StyledTableCell>
                                            <StyledTableCell >{row.teacher_name}</StyledTableCell>
                                            <StyledTableCell align={"center"}>
                                                {row.schedules.map((value, index1) =>
                                                <Typography>
                                                    {value.day}: {value.time} - {value.end_time},  {value.room&&value.room.name+"-xona"}
                                                </Typography>)}

                                                {row.schedules.length===0&&
                                                <Typography>
                                                    Darslar mavjud emas
                                                </Typography>}

                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>





                    </Box>
                    :<Typography>Guruh mavjud emas</Typography>
                }
            </Box>

        </>
    );
}