import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserIdSalaryMoney from "../../components/UserIdSalaryMoney";
import UserIdSalaryItems from "../../components/UserIdSalaryItems";
import MyBonus from "../../components/MyBonus";
import MyAdvance from "../../components/MyAdvance";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export default function MySalary(){

    const [value, setValue] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    const tab=searchParams.get("tab")

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchParams({tab:newValue})

    };

    useEffect(()=>{
        if (tab!=="null"){
            setValue(parseInt(tab));
        }else {
            setValue(0);
        }
    }, []);


    return (
        <>

            <Box sx={{ maxWidth: { xs: "320px", sm: "100%" }, bgcolor: 'background.paper' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >

                    <Tab label="Oyliklarim tarixi" {...a11yProps(0)} />
                    <Tab label="Mening tarixim" {...a11yProps(1)} />
                    <Tab label="Maning avanslarim" {...a11yProps(2)} />
                    <Tab label="Maning bonuslarim" {...a11yProps(3)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Box>
                        <UserIdSalaryMoney />
                    </Box>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <Box>
                        <UserIdSalaryItems />
                    </Box>
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <Box>
                        <MyAdvance />
                    </Box>
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <Box>
                        <MyBonus />
                    </Box>
                </TabPanel>


            </Box>



        </>
    );
}