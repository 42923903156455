import React, {useEffect, useState} from "react";
import GET from "../../components/api/GET";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {Pagination, TextField, Tooltip} from "@mui/material";
import {getDateInfo} from "../Chat";
import EditDiscountModal from "../../components/modals/EditDiscountModal";
import AddDiscountModal from "../../components/modals/AddDiscountModal";
import LoadingBeatLoader from "../../components/LoadingBeatLoader";
import {formatPrice, functionEndDate, functionStartDate} from "../../components/Other/useWindowSize";
import DeleteDiscountModal from "../../components/modals/DeleteModal/DeleteDiscountModal";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {ClearOutlined} from "@mui/icons-material";
import { PrimeReactProvider } from 'primereact/api';
import { AutoComplete } from 'primereact/autocomplete';


export default function Discount(){


    const [discounts, setDiscounts]=useState([]);
    const [page, setPage]=useState(1);
    const [lastPage, setLastPage]=useState(1);


    const [loader, setLoader]=useState(true);

    const [startDate, setStartDate]=useState(functionStartDate);
    const [endDate, setEndDate]=useState(functionEndDate);
    const [student, setStudent]=useState("");



    const [users, setUsers]=useState([]);
    const [totalPrice, setTotalPrice]=useState();



    const handlerGetDiscounts=async ()=>{
        const res=await GET.getAllDiscountsForAdmin({page, user_id:student.value, end_date:endDate, start_date:startDate});
        if (res.success){
            setDiscounts(res.res.data.discounts.data);
            setLastPage(res.res.data.discounts.last_page);
            setTotalPrice(res.res.data.total_price)
            setLoader(false);
        }else if (!res.success){
            toast.error("Ma`lumot olishda xatolik");
            setLoader(false);
        }
    }

    const [selectUsers, setSelectUsers]=useState([]);

    const handlerGetStudents=async ()=>{
        const res=await GET.getAllUserForAdmin();
        if (res.success){
            let students = res.res.data.students;
            const data=students.map(value => {
                return {label:value.full_name, value:value.id}
            })
            setUsers(data);
            setSelectUsers(data);
        }else if (!res.success){
            toast.error("Foydalanuvchilarni olishda xatolik yuz berdi. ");
        }
    }


    useEffect(()=>{
        handlerGetDiscounts();
        handlerGetStudents();

    }, [])


    useEffect(()=>{
        handlerGetDiscounts();
    }, [page]);

    useEffect(() => {
        if (student){
            if (startDate.length===0&&endDate.length===0&&student.length===0){
                handlerGetDiscounts();
            }
        }
    }, [startDate, endDate, student]);


    const search = (event) => {
        const data=[];
        users.map((value)=>{
            if (value.label.toLowerCase().includes(event.query.toLowerCase())){
                data.push(value);
            }
        })
        setSelectUsers(data);
    }


    return (
        <>

            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} marginBottom={"8px"}>
                <Typography>
                    Discount
                </Typography>

                <Box display={"flex"} gap={"8px"}>
                    <AddDiscountModal users={users} handlerGetDiscounts={handlerGetDiscounts} sum={1}/>
                    <AddDiscountModal users={users} handlerGetDiscounts={handlerGetDiscounts} sum={-1}/>
                </Box>
            </Box>


            <Box sx={{display:"flex", gap:"16px", marginY:"8px", alignItems:"center"}}>
                <Box>
                    <Typography>
                        Boshlanish sanasi:
                    </Typography>
                    <TextField value={startDate} onChange={(event)=>setStartDate(event.target.value)} type={"date"}/>
                </Box>
                <Box>
                    <Typography>
                        Tugash sanasi:
                    </Typography>
                    <TextField value={endDate} onChange={(event)=>setEndDate(event.target.value)} type={"date"} />
                </Box>
                <Box width={"100%"}>
                    <Typography>
                        Foydalanuvchini tanlang.
                    </Typography>
                    {/*<Autocomplete*/}
                    {/*    value={student}*/}
                    {/*    onChange={(event, newValue) => {*/}
                    {/*        setStudent(newValue);*/}
                    {/*    }}*/}
                    {/*    filterSelectedOptions={true}*/}
                    {/*    disablePortal*/}
                    {/*    options={users}*/}
                    {/*    sx={{ width: "100%" }}*/}
                    {/*    renderInput={(params) =>*/}
                    {/*        <TextField {...params}  />}*/}
                    {/*/>*/}
                    <PrimeReactProvider>
                        <AutoComplete value={student} field={"label"} suggestions={selectUsers} completeMethod={search} onChange={(e) => setStudent(e.value)} dropdown />
                    </PrimeReactProvider>
                </Box>
                <Box sx={{display:"flex", alignSelf:"bottom"}}>
                    <Tooltip title={"Qidirish"}>
                        <IconButton color={"info"} onClick={()=>{handlerGetDiscounts()}}>
                            <SearchIcon/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={"Tozalash"}>
                        <IconButton color={"error"} onClick={()=>{
                            setStartDate("")
                            setEndDate("");
                            setStudent("");
                            handlerGetDiscounts();
                        }}>
                            <ClearOutlined/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            {loader?<LoadingBeatLoader/>:
                <Box>
                    {discounts!==undefined&&discounts.length>0?
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead sx={{backgroundColor:"grey"}}>
                                        <TableRow>
                                            <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                            <TableCell align={"center"}>Student</TableCell>
                                            <TableCell align={"center"}>Type</TableCell>
                                            <TableCell align={"center"}>Bonus</TableCell>
                                            <TableCell align={"center"}>Tavsifi</TableCell>
                                            <TableCell align={"center"}>Ishlatilgani qismi</TableCell>
                                            <TableCell align={"center"}>Kim bergan</TableCell>
                                            <TableCell align={"center"}>Berilgan vaqti</TableCell>
                                            <TableCell align={"center"}>Tahrirlangan vaqti</TableCell>
                                            <TableCell align={"center"}>Amallar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discounts&&discounts.map((value1, index) =>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                    ":hover": {backgroundColor: "#cbcbcb"}
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {((page-1)*20)+index+1}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {value1.student&&value1.student.name} {value1.student&&value1.student.lastname}
                                                </TableCell>
                                                <TableCell align={"center"} >
                                                    <Typography
                                                    >
                                                        {value1.type}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={"right"} >
                                                    <Typography
                                                        color={value1.sum>0?"green":"red"}
                                                        // color={"error"}
                                                    >
                                                        {formatPrice(value1.sum)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {value1.description}
                                                </TableCell>
                                                <TableCell align={"right"}>{value1.used==0?"Ishlatilmagan":"Ishlatilgan"}</TableCell>
                                                <TableCell align={"center"}>{value1.author!==null?value1.author.name+" "+value1.author.lastname:"Tizim"}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.created_at)}</TableCell>
                                                <TableCell align={"center"}>{getDateInfo(value1.updated_at)}</TableCell>
                                                <TableCell align={"center"} >
                                                    <Box sx={{display:"flex"}}>
                                                        <EditDiscountModal description={value1.description} sum={value1.sum} id={value1.id} handlerGetDiscounts={handlerGetDiscounts}/>
                                                        <DeleteDiscountModal id={value1.id} refresh={handlerGetDiscounts}/>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={2} align={"center"}>
                                                Jami:
                                            </TableCell>

                                            <TableCell align={"right"} colSpan={2} sx={{fontSize:"18px"}}>
                                                {formatPrice(totalPrice)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display={"flex"} justifyContent={"center"} sx={{marginTop:"12px"}}>
                                <Pagination count={lastPage} onChange={(event)=>{setPage(event.currentTarget.textContent);}} color="primary" />
                            </Box>
                        </>
                        :
                        <Box sx={{display:"flex", justifyContnet:"center", width:"100%"}}>
                            <Typography>Bonus/jarimalar mavjud emas</Typography>
                        </Box>
                    }
                </Box>}
        </>
    );
}