import GET from "../../components/api/GET";
import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {getDateInfo} from "../Chat";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Button, Tooltip} from "@mui/material";
import DeleteExamModal from "../../components/modals/DeleteModal/DeleteExamModal";
import Typography from "@mui/material/Typography";


export default function ExamType(){

    const [examList, setExamList]=useState([]);
    const handlerGetExam=async ()=>{
        const res=await GET.getAllExam();
        if (res.success){
            setExamList(res.res.data.exams);
        }else {

        }
    }

    const navigate=useNavigate();

    useEffect(() => {
        handlerGetExam();
    }, []);

    return (
        <>
            <div>
                <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center", margin:"8px 0"}}>
                    <Typography>
                        Imtihon turlari
                    </Typography>
                    <Button onClick={()=>navigate("add")} variant={"contained"} color={"success"}>
                        Qo'shish
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{backgroundColor:"grey"}}>
                            <TableRow>
                                <TableCell width={"30px"} align={"center"}>T/R</TableCell>
                                <TableCell align={"center"}>Title</TableCell>
                                <TableCell align={"center"}>Tavsifi</TableCell>
                                <TableCell align={"center"}>Testlar soni</TableCell>
                                <TableCell align={"center"}>O'tish foizi</TableCell>
                                <TableCell align={"center"}>Yaratilgan sanasi</TableCell>
                                <TableCell align={"center"}>Amallar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {examList&&examList.map((value1, index) =>
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                        "&:hover":{ backgroundColor:"#cbcbcb" }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index+1}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {value1.title}
                                    </TableCell>
                                    <TableCell align={"left"} >
                                        {value1.descriptin}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {value1.count}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {value1.pass_score}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {getDateInfo(value1.created_at)}
                                    </TableCell>
                                    <TableCell align={"center"} >
                                        <Box sx={{display:"flex", justifyContent:"end"}}>
                                            <Tooltip title={"Batafsil ko'rish"}>
                                                <IconButton color={"primary"} onClick={()=>navigate("/exam-type/"+value1.id)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {/*<AddOrEditExamTest type={"edit"}*/}
                                            {/*                   refresh={handlerQuestionList}*/}
                                            {/*                   level={lessonLevelList[activeFolder]}*/}
                                            {/*                   question={value1}*/}

                                            {/*/>*/}
                                            <DeleteExamModal refresh={handlerGetExam}
                                                             id={value1.id}/>

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}