import Box from "@mui/material/Box";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useEffect, useState} from "react";
import {Button, TextField, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DELETE from "../api/DELETE";
import {handleGetLesson} from "../../page/Lesson";
import {useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";
import {BaseUrl, FileBaseUrl} from "../api/DefaultApi";
import ReactPlayer from "react-player";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import {useForm} from "react-hook-form";
import POST from "../api/POST";
import axios from "axios";
import DeleteTestModal from "../modals/DeleteModal/DeleteTestModal";


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

export default function TestId({test, index}){

    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();

    const refresh=()=>{
        reset();
        setFileInfo(undefined);
    }


    const [searchParams, setSearchParams] = useSearchParams();
    const lesson_id=searchParams.get("lessonId");




    const onSubmit = async (data) => {

        const newData={...data, id:question.id, lesson_id:lesson_id, type:fileInfo&&fileInfo.type, file:fileInfo&&fileInfo.image};
        // console.log(newData);
        const res=await POST.addTest(newData);
        if (res.success){
            toast.success("Tahrirlandi");
            refresh();
            handleGetLesson(lesson_id)
            handleClose();
        }

    };

    const [question, setQuestion]=useState(test);
    const [fileInfo, setFileInfo]=useState(undefined);  //type, file   berib yuboramiz.
    const [progress, setProgress] = useState(0)

    useEffect(()=>{
        setQuestion(test)
        question.file!==null?setFileInfo({image:question.file, type:question.type}):setFileInfo(undefined);
    }, [])

    useEffect(()=>{
        setQuestion(test)
        question.file!==null?setFileInfo({image:question.file, type:question.type}):setFileInfo(undefined);
    }, [test])










    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleUploadFile = async (event) => {
        event.preventDefault();


        const formData = new FormData();
        formData.append("image", event.target.files[0]);
        try {
            const res=await axios.post(BaseUrl+"/api/teacher/practice/file", formData, {
                headers: {
                    "Content-Type": "multipart/form-data", "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            })
            setFileInfo(res.data);
        }catch (e) {
            toast("Xatolik");
        }
    };

    return (
        <Box marginY={"4px"}>
            {test&&
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{(index+1)+". "+question.value}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

                            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"80%"}}>
                                <Box width={"50%"}>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography>
                                                Qiymat:
                                            </Typography>

                                            <Typography>
                                                {question.value}
                                            </Typography>
                                        </Box>

    
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography>
                                                Qiymat o`zbek tilida:
                                            </Typography>

                                            <Typography>
                                                {question.value_uz}
                                            </Typography>
                                        </Box>

                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography>
                                                Qiymat rus tilida:
                                            </Typography>

                                            <Typography>
                                                {question.value_ru}
                                            </Typography>
                                        </Box>
                                    </Box>

                                {question.file!==null&&
                                    <Box>
                                        {question.type==0&&
                                            <Box>
                                                <img style={{objectFit: "contain"}} width={"300px"} height={"200px"} src={FileBaseUrl+question.file}/>
                                            </Box>
                                        }
                                        {question.type==1&&
                                            <Box>
                                                <ReactPlayer width={"300px"}
                                                             height={"150px"}
                                                             controls={true}
                                                             url={FileBaseUrl+question.file}/>
                                            </Box>
                                        }
                                        {question.type==2&&
                                            <Box>
                                                <a href={FileBaseUrl+question.file} download style={{display:"flex", justifyContent:"center"}}>
                                                    <img src={"/assets/icon/file.svg"} width={"50px"}/>
                                                </a>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>


                            {/*<Box>*/}
                            {/*    {question.is_rand==0?"Random emas":"Random"}*/}
                            {/*</Box>*/}


                            <Box display={"flex"} flexDirection={"column"}>
                                <Tooltip title={"Tahrirlash"}>
                                    <IconButton color={"warning"} onClick={handleOpen}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>
                                <DeleteTestModal lesson_id={lesson_id} id={question.id} refresh={handleGetLesson}/>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
            }




            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Box>
                            <TextField label={"Testni kiriting:"}
                                       defaultValue={question.value}
                                       fullWidth
                                       {...register("value",
                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                            />
                            {errors.value&&<Typography color={"error"}>{errors.value.message}</Typography>}
                        </Box>

                        <Box>
                            <TextField label={"Testni(o`zbek tilida) kiriting:"}
                                       defaultValue={question.value_uz}
                                       fullWidth
                                       {...register("value_uz")}
                            />
                        </Box>

                        <Box>
                            <TextField label={"Testni(rus tilida) kiriting:"}
                                       defaultValue={question.value_ru}
                                       fullWidth
                                       {...register("value_ru")}
                            />
                        </Box>

                        {fileInfo===undefined&&
                            <Box sx={{display:"flex", width:"100%", backgroundColor:"#d9dbe0", padding:"8px", justifyContent:"center"}}>
                                <label>
                                    <input style={{display:"none"}} type={"file"} onChange={handleUploadFile}/>
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                        <img src={"/assets/icon/upload.svg"} width={"24px"} height={"24px"}/>
                                        <span>Faylni yuklang</span>
                                    </div>
                                </label>
                            </Box>
                        }


                        <Box width={"100%"}>
                            {(progress>0 && 100>progress)&&
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <div style={{backgroundColor:"gray", width:progress+"%", height:"4px"}}>
                                    </div>
                                    <span>
                                        {progress}%
                                    </span>
                                </Box>
                            }
                        </Box>

                        {fileInfo!==undefined&&
                            <Box>
                                {fileInfo.type==0&&
                                    <Box>
                                        <img style={{objectFit: "contain"}} width={"100%"} height={"200px"} src={FileBaseUrl+fileInfo.image}/>
                                    </Box>
                                }
                                {fileInfo.type==1&&
                                    <Box>
                                        <ReactPlayer width={"100%"}
                                                     height={"200px"}
                                                     controls={true}
                                                     url={FileBaseUrl+fileInfo.image}/>
                                    </Box>
                                }
                                {fileInfo.type==2&&
                                    <Box>
                                        <a href={FileBaseUrl+fileInfo.image} download style={{display:"flex", justifyContent:"center"}}>
                                            <img src={"/assets/icon/file.svg"} width={"50px"}/>
                                        </a>
                                    </Box>
                                }
                                <Box display={"flex"} justifyContent={"center"} >
                                    <Button onClick={()=>setFileInfo(undefined)} color={"error"} variant={"contained"} sx={{marginTop:"4px"}}>
                                        O`chirish
                                    </Button>
                                </Box>
                            </Box>
                        }




                        {/*<Box display={"flex"} alignItems={"center"} >*/}
                        {/*    <Checkbox*/}
                        {/*        defaultChecked={question.is_rand===0?false:true}*/}
                        {/*        {...label}*/}
                        {/*        {...register("random")}*/}
                        {/*    />*/}
                        {/*    <Typography>Random bo`lsinmi</Typography>*/}
                        {/*</Box>*/}

                        <Box display={"flex"} gap={"4px"} justifyContent={"center"}>
                            <Button type={"submit"} color={"success"} variant={"contained"} disabled={(progress>0 && progress<100)&&true}>Saqlash</Button>
                            {/*<Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>*/}
                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>




        </Box>
    );
}