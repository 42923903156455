import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import {Button, TextField, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import LessonsForGroup from "../LessonsForGroup";
import DELETE from "../../api/DELETE";
import {checkAllApi} from "../../api/DefaultApi";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import POST from "../../api/POST";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import {useSearchParams} from "react-router-dom";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ParentLessonEditModal from "../../modals/ParentLessonEditModal";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DeleteLessonModal from "../../modals/DeleteModal/DeleteLessonModal";
import PassTheLesson from "../../modals/PassTheLesson";






export default function GroupAccordion({lessonLevelList}) {

    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");


    const {lessons}=useSelector(state => state.items.group_id);

    // console.log(lessons);

    useEffect(()=>{

    }, [lessons]);



    const handleEditLesson=async (data)=>{
        const res=await POST.addLesson(data);
        let checkAllApi1 = checkAllApi(res);
        if (!checkAllApi1){
            toast("Xatolik")
            return;
        }
        if (res.success){
            toast.success("Tahrirlandi ");
            handleGetByIdGroup(groupId)
            return;
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    }




    return (
        <div>
            <div>
                {lessons&&
                    <DragDropContext
                        onDragEnd={(param) => {
                            if (localStorage.getItem("role")==="teacher"){
                                const srcI = param.source.index;
                                const desI = param.destination?.index;
                                lessons.splice(desI, 0, lessons.splice(srcI, 1)[0])
                                lessons.map((value, index) => value.order=index)
                                let data=[];
                                lessons.map((value, index) =>{
                                    data.push({id:value.id, order:value.order})
                                })

                                // if (desI) {
                                    POST.sortLesson(data).then((res)=>{
                                        if (res.success){
                                            // handleGetByIdGroup(groupId);
                                        }
                                    });
                                // }
                            }else {
                                toast.error("Sizda huquq yo`q");
                            }

                        }}
                    >
                        <Box display={"flex"} flexDirection={"column"} gap={"8px"} >
                            <Droppable droppableId="droppable-1">
                                {(provided, _) => (
                                    <Box sx={{display:'flex', flexDirection:"column", gap:"4px"}} ref={provided.innerRef} {...provided.droppableProps}>
                                        {lessons.map((item, i) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={"draggable-" + item.id}
                                                index={i}
                                            >
                                                {(provided, snapshot) => (
                                                    <Box sx={{
                                                        display:"flex",
                                                        gap:"4px",
                                                        alignItems:"center",
                                                        width:"100%",
                                                        backgroundColor:"#EFF1F5",
                                                        padding:"4px"
                                                    }}
                                                         ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                        // style={{
                                                        //     ...provided.draggableProps.style,
                                                        //     boxShadow: snapshot.isDragging
                                                        //         ? "0 0 .4rem #666"
                                                        //         : "none",
                                                        // }}
                                                    >
                                                        <img src={"/assets/icon/cursor.svg"} {...provided.dragHandleProps} />
                                                        <Accordion sx={{width:"100%"}}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                                                                    <Typography>{i+1+". "}{item.name}</Typography>
                                                                    {item.level&&
                                                                        <p style={{color:item.level.color}}>
                                                                            {item.level&&item.level.name}
                                                                        </p>
                                                                    }
                                                                </Box>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Box>
                                                                    <LessonsForGroup child={item.children} parent_id={item.id}/>
                                                                </Box>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        {localStorage.getItem("role")==="teacher"&&
                                                            <Box display={"flex"}>
                                                                {item.date
                                                                    ? <Tooltip title={"K'orish"}>
                                                                        <IconButton onClick={()=>{window.open('/pass-the-lesson/'+item.id+'?group_id='+groupId, "_blank")}}>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                        </Tooltip>
                                                                        :<PassTheLesson lessonId={item.id} groupId={groupId}/>

                                                                }
                                                                <Tooltip title={item.ready===0?"Darsni ochish":"Darsni yopish"}>
                                                                    <IconButton color={item.ready===1?"success":"error"} onClick={()=>handleEditLesson({...item,ready:item.ready===0?1:0},item.ready===0?"Dars ochildi":"Dars yopildi" )}>
                                                                        {item.ready===1?<LockOpenIcon/>:<LockIcon/>}
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <Tooltip title={item.repeat===0?"Takrorlash ochish":"Takrorlash yopish"}>
                                                                    <IconButton color={item.repeat===0?"error":"success"} onClick={()=>handleEditLesson({...item,repeat:item.repeat===0?1:0}, item.repeat===0?"Takrorlash ochildi":"Takrorlash yopildi")}>
                                                                        <AutorenewIcon />
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <ParentLessonEditModal lessonLevelList={lessonLevelList} lesson={item} groupId={groupId}/>

                                                                <DeleteLessonModal id={item.id} groupId={groupId} refresh={handleGetByIdGroup}/>


                                                            </Box>
                                                        }
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Box>
                    </DragDropContext>
                }
            </div>





        </div>
    );
}