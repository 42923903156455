import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, InputLabel, MenuItem, Select, TextareaAutosize, TextField, Tooltip} from "@mui/material";
import GroupAccordion from "../GroupAccordion";
import {useForm} from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import GET from "../../api/GET";
import {checkAllApi} from "../../api/DefaultApi";
import LoadingBeatLoader from "../../LoadingBeatLoader";
import POST from "../../api/POST";
import toast from "react-hot-toast";
import {setGroupsById} from "../../../redux/action";
import {useSelector} from "react-redux";
import ImportLessonOtherGroup from "../../modals/ImportLessonOtherGroup";
import {handleGetByIdGroup} from "../../../page/GroupTabs";
import ImportMainLesson from "../../modals/ImportMainLesson";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:"flex",
    flexDirection:"column",
    gap:"16px"
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



export default function GroupLessons(){
    const {group, lessons}=useSelector(state => state.items.group_id);
    const [isLoader, setIsLoader]=useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const groupId=searchParams.get("groupId");
    const lessonLevelList=useSelector(state => state.otherInfoList.levels);





    useEffect(()=>{
        setIsLoader(true)
        setGroupsById({})
        handleGetByIdGroup(groupId).then(value => {
           value===false&&setGroupsById({});
            setIsLoader(false)
        });
    }, [groupId]);





    const { register, handleSubmit, control, watch, reset, formState: { errors },} = useForm();
    const onSubmit = async (data) => {
        // console.log(data);

        const newData={...data, group_id:groupId, order:lessons.length};
        const res=await POST.addLesson(newData);
        // checkAllApi(res);
        if (res.success){
            handleGetByIdGroup(groupId);

            toast.success("Yaratildi.");
            reset();
            handleClose();
        }
        if (!res.success){
            toast.error("Xatolik");
        }
    };

    const refresh=()=>{
        reset();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);





    return (
        <Box>
            {isLoader?
                <LoadingBeatLoader/>
                :<Box>
                    {group!==undefined?
                        <Box>
                            {lessons&&
                                    <Box sx={{display:"flex", flexDirection:"column"}}>
                                        <h1 align={"center"}>Darslar </h1>
                                        {localStorage.getItem("role")==="teacher"&&
                                            <Box display={"flex"} justifyContent={"flex-end"} sx={{marginBottom:"8px"}} gap={"4px"}>
                                                <ImportLessonOtherGroup/>
                                                <ImportMainLesson/>
                                                <Button align={"center"} variant={"contained"} color={"success"} onClick={handleOpen}>Darslar qo`shish</Button>
                                            </Box>
                                        }

                                        {lessons&&
                                            <GroupAccordion lessonLevelList={lessonLevelList} lessons={lessons}/>
                                        }
                                    </Box>
                            }




                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                slots={{ backdrop: Backdrop }}
                                slotProps={{
                                    backdrop: {
                                        timeout: 500,
                                    },
                                }}
                            >
                                <Fade in={open}>
                                    <Box sx={style} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                                        <Box >
                                            <TextField label={"Dars nomini kiriting:"}
                                                       fullWidth
                                                       {...register("name",
                                                           {required:"Bo`sh bo`lishi mumkin emas.",
                                                               minLength:{value:2, message:" 2 ta belgidan kam bo`lmasligi kerak"},
                                                               maxLength:{value:101, message:"100 ta belgidan ortiq bo`lishi mumkin emas"}})}
                                            />

                                            {errors.name&&<Typography color={"error"}>{errors.name.message}</Typography>}
                                        </Box>


                                        <Box>
                                            <Box width={"100%"}>
                                                <InputLabel id="demo-multiple-name-label">Darsni tanlang</InputLabel>
                                                <Select sx={{width:"100%"}}
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        MenuProps={MenuProps}
                                                        error={errors.level_id}
                                                        {...register("level_id",{required:"Tanlash shart"})}
                                                >
                                                    {lessonLevelList.map((value) => (
                                                        <MenuItem
                                                            key={value.id}
                                                            value={value.id}
                                                        >
                                                            {value.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                            {errors.level_id&&<Typography color={"error"}>{errors.level_id.message}</Typography>}
                                        </Box>


                                        <Box display={"flex"} gap={"4px"} justifyContent={"space-between"}>
                                            <Button type={"submit"} color={"success"} variant={"contained"}>Saqlash</Button>
                                            <Button color={"warning"} variant={"contained"} onClick={refresh}>Tozalash</Button>
                                            <Button onClick={handleClose} color={"error"} variant={"contained"}>Yopish</Button>
                                        </Box>
                                    </Box>
                                </Fade>
                            </Modal>


                        </Box>:
                        <Typography>Siz so`ragan guruh mavjud emas</Typography>
                    }
                </Box>

            }
        </Box>
    );


}